<template>
	<v-dialog v-model="dialog_open" max-width="600" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b v-html="resource.description"></b></v-card-title>
			<v-card-text class="mt-3" style="font-size:16px"><div class="k-vq-wrapper" :class="query_css_class">

				<div class="k-video-player-wrapper elevation-3"><div><youtube
					:video-id="video_id"
					:player-vars="player_vars"
					fitParent
					resize
					ref="youtube"
					@playing="playing=true"
					@paused="playing=false"
				></youtube></div></div>
				<div class="k-video-controls-wrapper">
					<div class="mr-3"><v-btn :small="small_screen" fab color="primary" @click="toggle_play"><i :class="play_btn_icon"></i></v-btn></div>
					<div style="flex:1 1 auto">
						<div style="position:relative">
							<div v-show="qstatus.stars_earned > 0" class="k-video-progress-bar__star-indicator-wrapper">
								<div v-for="spi in star_progress_indicators" class="k-video-progress-bar__star-indicator" :class="spi.awarded?'k-video-progress-bar__star-indicator--awarded':''" :style="'left:'+spi.threshold+'%'"><i class="fas fa-star"></i></div>
							</div>
							<v-slider dense hide-details color="primary" class="k-video-progress-bar"
								v-model="current_time_percent_slider"
								max="10000"
								@start="slider_sliding"
								@click="slider_changed"
								@end="slider_changed"
							></v-slider>
						</div>
						<div v-if="duration > 0" class="k-video-progress-bar__time" v-html="current_time_progress_display"></div>
					</div>
					<div v-if="small_screen" class="ml-1"><nobr>
						<v-btn v-show="transcript!=null" fab small color="secondary" @click="toggle_captions"><i :class="captions_showing?'far fa-closed-captioning':'fas fa-closed-captioning'"></i></v-btn>
						<!-- <v-btn fab small color="secondary" @click="toggle_fullscreen"><i :class="!fullscreen_on?'fas fa-expand':'fas fa-compress'"></i></v-btn> -->
					</nobr></div>
					<div class="k-vq-scoreboard" @click.stop="show_scoring_help"><nobr><span v-html="percent_complete_msg"></span><v-icon v-if="qstatus.complete" color="amber lighten-2">fas fa-check</v-icon></nobr></div>
				</div>

			</div></v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="$emit('dialog_cancel')">Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import fscreen from 'fscreen'

export default {
	props: {
		resource: { type: Object, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,

		player_vars: {
			autoplay: 0,
			// In Sparkl, we don't show controls for small screens, because a) on iphones we can't stop the user from going past max_time using the youtube slider,
			// and b) the player will take up most of the screen in a landscape phone display anyway.
			// for HC, we will always show the controls though
			// controls: ($(window).height() < 760 || $(window).width() < 760) ? 0 : 1,	// show controls
			controls: 1,
			disablekb: 0,	// disable keyboard
			enablejsapi: 1,
			fs: 1,
			modestbranding: 1,
			rel: 0,
			playsinline: 1,	// allow the video to play without automatically going to full screen mode on iOS
		},
		transcript: null,
		duration: -1,					// this will be set to the duration of the video once it's playing
		playing: false,					// this will be constantly updated with whether or not the video is currently playing
		finished_playing: false,		// this will be set to true once the video completes
		current_transcript_text: '',	// this will be constantly updated with whatever text is currently being spoken
		current_time: 0,				// this will be constantly updated with the current timestamp of the video
		captions_showing: false,
		fullscreen_on: false,
		fullscreen_was_on: false,
		interval: null,
		was_ready_to_load: false,
		current_part_index: -1,
		max_current_part_index: -1,
		show_show_all_parts_btn: false,
		show_video_play_wiggler: false,
		current_time_percent_slider: 0,
		previous_slider_value: 0,
		playing_when_slider_started: false,
		inform_showing: false,

		// simulate a Sparkl query and qstatus
		query: {
			stars_available: 10,
		},
		qstatus: {
			stars_earned: 0,
			started: false,
			complete: false,
			max_time: 0,
		},
		last_saved_todo_status: 0,
	}},
	computed: {
		...mapState(['user_info']),
		...mapGetters([]),
		small_screen() { return vapp.$vuetify.breakpoint.height < 600 || vapp.$vuetify.breakpoint.width < 600 },
		video_id() { return U.youtube_id_from_url(this.resource.url) },
		max_time() { return this.qstatus.max_time },
		query_css_class() {
			let c = ''
			if (this.qstatus.complete) return c + ' k-vq--complete'
			if (this.qstatus.started) return c + ' k-vq--in-progress'
		},
		play_btn_icon() {
			if (this.playing) {
				return 'fas fa-pause'
			} else {
				if (this.duration != -1 && this.current_time > this.duration-1 && this.qstatus.complete) {
					return 'fas fa-undo-alt'
				} else {
					return 'fas fa-play'
				}
			}
		},
		player() { return this.$refs.youtube.player },
		current_time_percent() {
			if (this.duration <= 0) return 0

			// duration is rounded to nearest second, so once ceiling of current_time >= duration, we have to count it as 100
			if (Math.ceil(this.current_time) >= this.duration) return 100

			return this.current_time / this.duration * 100
		},
		current_time_progress_display() {
			return sr('$1 / $2', U.time_string(this.current_time), U.time_string(this.duration))
		},
		percent_complete_msg() {
			if (this.qstatus.complete || this.duration <= 0) return ''
			let pct = Math.floor(this.qstatus.max_time / this.duration * 25) * 4
			return pct + '%'
		},
		star_percent_threshold() {
			// if we're giving 5 stars, we want to to award a star after each 1/4 = 25% of the video has been played
			return 1 / (this.query.stars_available-1) * 100
		},
		star_progress_indicators() {
			let a = []
			for (let i = 0; i < this.query.stars_available; ++i) {
				a.push({
					threshold: i * this.star_percent_threshold,
					// initial value of awarded is based on stars_earned
					awarded: i < this.qstatus.stars_earned
				})
			}
			return a
		},
		todo_status() {
			// return todo_status as a number, sending 0 if empty
			let ts = this.user_info.todo_status[this.resource.resource_id]
			if (empty(ts)) return 0
			return ts*1
		},
	},
	watch: {
	},
	beforeDestroy: function() {
		if (!empty(this.interval)) {
			clearInterval(this.interval)
		}
	},
	created() {
	},
	mounted() {
		// load the transcript and start_player_interval
		this.load_transcript()
		this.start_player_interval()
	},
	methods: {
		load_transcript() {
			// load the time-stamped transcript for the video
			var xml_converter = require('xml-js')
			$.ajax({
				url: document.location.protocol + '//video.google.com/timedtext?lang=en&v=' + this.video_id,
				dataType: 'text',
				success: (data) => {
					let xml = $.trim(data)
					if (!empty(xml)) {
						let json = xml_converter.xml2json(xml, {compact: false, spaces: 4})
						this.transcript = JSON.parse(json)
					} else {
						console.log('no transcript received for video_id ' + this.video_id)
					}
				},
				error: function(jqXHR, textStatus, errorThrown) {
					console.log('error retrieving transcript for video_id ' + this.video_id, jqXHR, textStatus, errorThrown)
				}
			});
		},

		// Interval for checking current video timestamp and fullscreen mode
		start_player_interval() {
			this.interval = setInterval(() => {
				if (fscreen.fullscreenElement) {
					this.fullscreen_on = true
				} else {
					this.fullscreen_on = false
				}

				if (this.$refs && this.$refs.youtube && this.$refs.youtube.player) {
					this.player.getCurrentTime().then(new_current_time => {
						// if the player just loaded, do initial initialization
						if (this.duration == -1) {
							this.initial_player_initialization()
						}

						this.player.getPlayerState().then((state)=> {
							if (state == 1) {	// playing
								this.playing = true
								this.current_time_check(new_current_time)

							} else if (state == 2 || state == 0) {	// paused or done
								this.playing = false
								if (state == 0) {
									this.finished_playing = true
								}
								if (new_current_time != this.current_time) {
									this.current_time_check(new_current_time)
								}

							} else if (state == 3) {	// buffering
								// if (this.showing_on_screen) console.log('buffering')
							}
						})
					})

				} else {
					// the video has not started yet
					this.current_time = 0
				}

			// the more often we run this, the more accurate we'll be
			}, 50);
		},

		clear_player_interval() {
			clearInterval(this.interval)
		},

		// do some initial initialization on the player (things we only have to do once, when the player is loaded)
		initial_player_initialization() {
			// console.log('initial_player_initialization')
			// get the duration of the video; then we've got it, set up some other things
			this.duration = 0
			this.player.getDuration().then(duration => {
				this.duration = duration

				// if todo_status is > 100, it's a timestamp, which means the video is complete
				if (this.todo_status > 100) {
					this.qstatus.complete = true
					this.qstatus.max_time = this.duration
				} else if (this.todo_status > 0) {
					// else if todo_status is > 0, set max_time to this:
					this.qstatus.max_time = this.duration * this.todo_status / 100

					// and jump to max_time
					this.go_to_time(this.max_time)
					this.toggle_play(false)
					// set slider; have to delay this a few ticks to make it work
					setTimeout(()=>this.current_time_percent_slider = Math.round(this.current_time_percent * 100), 10)
				}

				// set volume?
				this.player.setVolume(5)
			})
		},

		///////////////////////////////////////////////////////////
		// current_time_check: called continuously while the video is playing, and also when the user tries to start the video playing
		// this also serves to restore things when the user comes back to it in a later session.
		// This fn includes much of the business logic of the activity
		current_time_check(new_current_time) {
			// console.log(sr('check: ct = $1 / nct = $2 / max_time = $3', this.current_time, new_current_time, this.max_time))
			if (empty(new_current_time)) new_current_time = this.current_time

			//////////////////////////////////////////////////////////
			// if we're trying to move beyond max_time, show a message and return false
			if (new_current_time > this.max_time + 0.5) {
				// console.log(sr('time reset because new time ($1) > max_time ($2) + 1', new_current_time, this.max_time))
				this.show_inform('You cannot skip ahead to parts of the video you haven’t already viewed.')
				this.toggle_play(false)
				this.force_seek_to(this.max_time)
				// return false, indicating that the player should be paused
				return false
			}

			///////////////////////////////////////////////////////////
			// if we get to here, new_current_time checks out, so set current_time and, if necessary, max_time
			this.current_time = new_current_time
			if (this.max_time < new_current_time) {
				this.qstatus.max_time = new_current_time
			}
			this.current_time_percent_slider = Math.round(this.current_time_percent * 100)

			///////////////////////////////////////////////////////////
			// take care of some things now if the video is playing
			if (this.playing) {
				// if the query hasn't been started, start it now
				if (!this.qstatus.started) {
					this.qstatus.started = true
				}
			}

			///////////////////////////////////////////////////////////
			// figure out whether to save progress or completion
			// this.todo_status could be 0-99, representing the saved % completion, or > 100, which would be the timestamp when the video was completed
			// if video is done...
			if (this.current_time_percent >= 100) {
				// stop the video
				this.toggle_play(false)

				// if not already set to complete, do so now
				if (!this.qstatus.complete) {
					// set qstatus to complete
					this.qstatus.complete = true

					// and emit a video_complete message; this will save todo_status as the timestamp
					this.$emit('video_complete')
				}

			} else {
				// else save progress if necessary -- send value with % complete
				let rounded_pct = Math.floor(this.current_time_percent / 4) * 4
				console.log(rounded_pct)
				if (rounded_pct > this.todo_status && rounded_pct > this.last_saved_todo_status) {
					this.$emit('save_video_progress', rounded_pct)
					// have to keep track of last_saved_todo_status so we don't keep sending to the server while we wait for it to return
					this.last_saved_todo_status = rounded_pct
				}
			}

			///////////////////////////////////////////////////////////
			// if we're showing the video transcript, find the appropriate part of the transcript to show
			if (!empty(this.transcript) && typeof(this.transcript) == 'object' && !empty(this.transcript.elements) && !empty(this.transcript.elements[0].elements)) {
				var obj = this.transcript.elements[0].elements
				for (const sub_obj of obj) {
					var attributes = sub_obj.attributes
					if ("start" in attributes && "dur" in attributes) {
						var start = parseFloat(attributes.start),
							dur = parseFloat(attributes.dur),
							end = start + dur

						if (this.current_time >= start && this.current_time <= end) {
							if (sub_obj.elements) {
								this.current_transcript_text = sub_obj.elements[0].text
							}
						}
					}
				}
			}

			///////////////////////////////////////////////////////////
			// if we get to here return true, indicating that it's OK for the video to be playing
			return true
		},

		force_seek_to(seconds) {
			if (seconds < 0) seconds = 0
			this.current_time = seconds
			this.current_time_percent_slider = Math.round(this.current_time_percent * 100)
			this.$refs.youtube.player.seekTo(this.current_time, true)
		},

		toggle_play(val) {
			if (typeof(val)!='boolean') val = !this.playing

			// if user clicked to play...
			if (val == true) {
				if (!this.current_time_check()) return
				this.playing = true
				this.player.playVideo()

			} else {
				this.playing = false
				this.player.pauseVideo()
			}

			// console.log('toggle_play: ' + this.playing)
		},

		toggle_fullscreen(val) {
			if (typeof(val)!='boolean') val = !this.fullscreen_on

			if (val == true) {
				// if we're already in fullscreen mode, return
				if (fscreen.fullscreenElement) return

				let player_el = $('.k-video-player-wrapper').find('iframe')[0];
				fscreen.requestFullscreen(player_el).catch(err => {
					this.$alert(sr('Error attempting to enable full-screen mode: $1 ($2)', err.message, err.name));
				});
			} else {
				// if we're not already in fullscreen mode, return
				if (!fscreen.fullscreenElement) return

				fscreen.exitFullscreen()
			}

			this.fullscreen_on = val
			// see https://stackoverflow.com/questions/7615380/auto-full-screen-for-a-youtube-embed for another version
		},

		toggle_captions(val) {
			if (typeof(val)!='boolean') val = !this.captions_showing
			this.captions_showing = val
		},

		slider_sliding() {
			// console.log('slider_sliding')
			this.playing_when_slider_started = this.playing
			this.toggle_play(false)
		},

		slider_changed() {
			// console.log('slider_changed: ' + this.current_time_percent_slider)
			// if user slides and releases on the slider, we get both an "end" and a "click" event; only process one of them
			if (this.current_time_percent_slider == this.previous_slider_value) {
				// console.log('skipping event: ' + this.previous_slider_value)
				this.previous_slider_value = null
				return
			}

			// this will be called when the user clicks on, or releases, the slider
			// ctps = (ct / d) * 10000
			// ctps / 10000 * d = ct
			if (this.go_to_time(this.current_time_percent_slider / 10000 * this.duration)) {
				// restart if necessary
				if (this.playing_when_slider_started) {
					this.toggle_play(true)
				}

				this.playing_when_slider_started = false
				this.previous_slider_value = this.current_time_percent_slider
			}
		},

		go_to_time(seconds) {
			if (this.current_time_check(seconds)) {
				this.force_seek_to(seconds)
				return true
			}
			return false
		},

		show_scoring_help() {
			let html = '<div class="k-query-scoring-help">'

			html += 'You must watch the entire video to complete this assignment.'

			html += '</div>'
			this.$alert({title:'Assignment Help', text: html, dialogMaxWidth:500})
		},

		show_inform(msg) {
			if (!this.inform_showing) {
				this.$inform({text:msg, snackbarTimeout:3000})
				this.inform_showing = true
				setTimeout(()=>{this.inform_showing=false}, 3000)
			} else {
				console.log("INFORM BLOCKED")
			}
		},
	}
}
</script>

<style lang="scss">
.k-vq-wrapper {
	clear:both;

	// border:4px solid $v-indigo-darken-1;
	padding: 8px;
	margin: 0 -8px;
	border-radius:8px;
	// background-color:$v-indigo-lighten-5;
	background-color:#fff;

	.k-query-controls {
		width:auto;
	}

	.k-video-progress-bar__star-indicator-wrapper {
		.k-video-progress-bar__star-indicator {
			margin-top:-4px;
		}
		.k-video-progress-bar__star-indicator--awarded {
			// color:$v-orange-darken-3;
			// z-index:100;
		}
	}

	.k-vq-scoreboard {
		background-color:#5C6BC0;
		border-radius:8px;
		padding:4px 6px;
		color:#fff;
		font-family:$sans-serif-font;
		font-size:18px;
		font-weight:bold;
		margin-left:12px;
		width:56px;
		height:32px;
		text-align:center;
		cursor:pointer;
		.fa-check { margin-top:-3px }
	}
}

.k-vq--in-progress {
	// background-color:#fff;
	// border-color:$v-orange-darken-2;
	.k-vq-scoreboard {
		background-color:$v-orange-darken-2;
	}
}

.k-vq--complete {
	// border-color:transparent;
	// background-color:#fff;
	.k-vq-scoreboard {
		background-color:$v-green;
	}
}


.k-video-player-wrapper {
	max-width:500px;
	margin:0 auto;
	padding:6px 6px 0 6px;
	background-color:#000;
	border-radius:8px;
}

.k-video-controls-wrapper {
	display:flex;
	align-items:center;
	margin-top:8px;
}

.k-video-progress-bar {
	opacity:0.8;
	.v-slider__track-background {
		background-color:$v-indigo-lighten-3!important;
	}

	.v-slider--horizontal .v-slider__track-container {
		height:16px;
	}

	.v-slider__track-background {
		border-radius:3px;
	}

	.v-slider__track-fill.primary {
		border-radius:3px;
	}

	.v-slider__thumb.primary {
		height:20px;
		width:20px;
		left:-10px;
		background-color:$v-indigo-darken-4!important;
	}

	.v-slider__thumb:before {
		left:-8px;
		top:-8px;
		color:$v-indigo-darken-4!important;
	}
}

.k-video-progress-bar__star-indicator-wrapper {
	position:absolute;
	top:7px;
	width:calc(100% - 33px);
	margin-left:10px;

	.k-video-progress-bar__star-indicator {
		position:absolute;
		font-size:12px;
		color:#aaa;
	}
	.k-video-progress-bar__star-indicator--awarded {
		color:#000;
	}
}

.k-video-progress-bar__interaction-indicator-wrapper {
	position:absolute;
	top:4px;
	width:calc(100% - 20px);
	margin-left:10px;
	.k-video-progress-bar__interaction-indicator {
		position:absolute;
		border-left:3px solid $v-blue;
		margin-left:-1px;
		height:24px;
		// height:27px;
	}
}

.k-video-progress-bar__time {
	font-family:$sans-serif-font;
	font-size:14px;
	font-weight:bold;
	line-height:1em;
	margin-top:0px;
}

.k-video__transcript-wrapper {
	position:absolute;
	left:0;
	bottom:70px;
	width:100%;

	.k-video__transcript {
		max-width:480px;
		margin:0 auto;
		font-family:$sans-serif-font;
		font-size:14px;
		line-height:20px;
		background-color:#444;
		color:#fff;
		opacity:0.9;
		padding:4px;
		text-align:center;
		border-radius:5px;
	}
}

.k-video-wrapper--fullscreen {
	padding:10px;

	.k-video-player-wrapper {
		max-width:calc(100vw - 20px);
		width:calc(100vw - 20px);
		margin:0;
		padding:0;
		border-radius:0;
	}

	.k-video-controls-wrapper {
		align-items: center;
		position: fixed;
		bottom: 2%;
		width: calc(100vw - 6%);
		left: 3%;
	}

	.k-video-progress-bar__time {
		color:#fff;
	}

	.k-video__transcript-wrapper {
		bottom: calc(2% + 70px);
		left: 2%;
		width: calc(100vw - 4%);

		.k-video__transcript {
			max-width:100%;
			margin:0 10px;
			font-size:24px;
			line-height:28px;
			padding:6px;
			background-color:#333;
		}
	}
}

.k-video-all-parts-wrapper {
	// max-height:calc(100vh - 450px);
	overflow:auto;
}

.k-video-show-all-parts-btn {
	position:absolute;
	right:5px;
	margin-top:-27px;
}

.k-trpt-ln {
	// line-height:1.2em;
	padding:0px 5px 0px 0px;
	margin:2px 0;
	border-radius:3px;
	cursor:pointer;

	// hide break tags inside transcript lines by default
	br { content:' '; }
	br:after { content:' '; }
}
div.k-trpt-ln {
	display:inline;
}

</style>
