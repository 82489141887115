<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
	<div class="k-mc-gantt-outer" @mouseup="mouseup_outer" @mousemove="date_drag_moving">
		<div class="k-mc-gantt" :class="gantt_wrapper_css">
			<div class="k-mc-gantt-row k-mc-gantt-today-line" :style="grid_row_style">
				<span :style="'grid-column:'+now_cell+'/'+(now_cell+1)"></span>
			</div>
			<!-- <div v-for="(month) in months" :key="month.str" class="k-mc-gantt-row k-mc-gantt-today-line" :style="grid_row_style">
				<span :style="'grid-column:'+now_cell+'/'+(now_cell+1)"></span>
			</div> -->
			<div class="k-mc-gantt-row k-mc-gantt-month-line" :style="grid_row_style">
				<span v-for="(month) in months" :key="month.str" :style="'grid-column:'+month.start_cell+'/'+(month.start_cell+1)"></span>
			</div>
			<div class="k-mc-gantt-row k-mc-gantt-months" :style="grid_row_style">
				<div v-for="(month) in months" :key="month.str" class="k-mc-gantt-month" :style="'grid-column:'+month.start_cell+'/'+month.end_cell">{{month.str}}</div>
			</div>
			<div class="k-mc-gantt-row k-mc-gantt-dates" :style="grid_row_style">
				<div v-for="(date) in dates" :key="date.str" class="k-mc-gantt-date" :class="date_class(date)">{{date.day_of_week}}<br>{{date.day}}</div>
			</div>
			<div class="k-mc-gantt-row k-mc-gantt-lines" :style="grid_row_style">
				<span v-for="(date,i) in dates" :key="date.str" v-if="i>0"></span>
			</div>
			<v-hover v-slot:default="{hover}" v-for="(item,i) in ordered_items" v-show="!item.is_old||show_older_items" :key="item.id"><div class="k-mc-gantt-row k-mc-gantt-row-item-outer" :class="item_row_outer_class(item, hover)">
				<div class="k-mc-gantt-row-item" :class="item_row_inner_class(item,hover)" :style="grid_row_style" @click="item_clicked(item)">
					<div class="k-mc-gantt-item-bgd" :class="item_row_class(item,hover)" :style="item_row_style_bgd(item)">
						<div v-show="show_left_drag_target(hover,item)" class="k-mc-gant-item-drag-handle k-mc-gant-item-drag-handle-left" @mousedown.stop="date_drag_start($event,item,'left')" @click.stop=""><v-icon color="#666" small>fas fa-grip-lines-vertical</v-icon></div>
						<div v-show="show_right_drag_target(hover,item)" class="k-mc-gant-item-drag-handle k-mc-gant-item-drag-handle-right" @mousedown.stop="date_drag_start($event,item,'right')" @click.stop=""><v-icon color="#666" small>fas fa-grip-lines-vertical</v-icon></div>
						<div class="k-mc-gantt-item-bgd-inner" @mousedown="date_drag_start($event,item,'item')">
							<div class="k-mc-gantt-item-inner" :style="item_inner_style(item)">
								<div v-show="(hover||bolt_menu_showing_item==item)&&!item.o.item_showing&&!dragging_items" :style="item_bolt_btn_style(item)">
									<v-menu offset-y bottom right><template v-slot:activator="{on}"><v-btn v-on="on" v-if="is_content_editor" v-show="$vuetify.breakpoint.smAndUp&&!item_being_edited(item)" fab x-small style="width:28px;height:28px" color="primary" @click.stop="bolt_menu_clicked(item)"><v-icon small>fas fa-bolt</v-icon></v-btn></template>
										<v-list dense>
											<v-list-item @click="item_clicked(item)"><v-list-item-title><i>Click to Show {{directive_type_label(item)}}</i></v-list-item-title></v-list-item>
											<v-list-item @click="edit_item(item)"><v-list-item-icon><v-icon small color="light-blue">fas fa-edit</v-icon></v-list-item-icon><v-list-item-title>Edit {{directive_type_label(item)}}</v-list-item-title></v-list-item>
											<v-list-item @click="edit_dates"><v-list-item-icon><v-icon small color="#666">fas fa-left-right</v-icon></v-list-item-icon><v-list-item-title>Edit Lesson/Activity Dates</v-list-item-title></v-list-item>
										</v-list>
									</v-menu>
								</div>
								<div v-html="item_content(item, hover)"></div>
							</div>
						</div>
					</div>
				</div>
				<div v-show="item.o.item_showing" class="k-mc-gantt-row-item" :style="grid_row_style">
					<div :style="item_row_style_open_item(item)">
						<div class="k-mc-gantt-item-showing-outer elevation-5" :class="item_being_edited(item)?'k-directive-item-outer-edited':''">
							<DirectivesListItem :ref="'item'+item.id" :filtered_origin_teacher_id="filtered_origin_teacher_id" :show_title="false" :course_code="course_code" :lp_unit_id="lp_unit_id" :item="item" @duplicate_item="$emit('duplicate_item', $event)" @edit_item_saved="$emit('edit_item_saved', $event)" @archive_items="$emit('archive_items', $event)" />
						</div>
					</div>
				</div>
			</div></v-hover>
		</div>
		<div v-if="n_current_items==0&&n_no_date_items==0&&!show_older_items" class="text-center mt-3"><i>No items currently due. Click SHOW OLDER ITEMS above to see previously-due items.</i></div>

		<div v-show="dragging_items" class="k-mc-gantt-item-date-change-instructions elevation-3"><v-icon small class="mr-2">fas fa-calendar-week</v-icon> Drag assignments and lessons to change their dates.<v-btn class="ml-2" small color="primary" @click="dragging_items=false"><v-icon small class="mr-1">fas fa-check</v-icon>Done Editing Dates</v-btn></div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import DirectivesListItem from './DirectivesListItem'

export default {
	components: { DirectivesListItem },
	props: {
		items: { type: Array, required: true },
		max_context: { type: Number, required: true },
		items_rendered: { type: Boolean, required: true },
		course_code: { type: String, required: false, default() { return '' } },
		lp_unit_id: { type: Number, required: false, default() { return 0 } },
		n_no_date_items: { type: Number, required: false, default() { return 0 } },
		n_current_items: { type: Number, required: false, default() { return 0 } },
		assigned_to_options: { required: false, default() { return [] } },
		filtered_origin_teacher_id: { required: false, default() { return null } },
	},
	data() { return {
		months: [],	// calculated with dates
		now_cell: -1,	// calculated with dates
		bolt_menu_showing_item: null,

		ordered_items: [],
		dragging_items: false,
		drag_snap_value: 0,
		dd_item: null,
		drag_first_date: '',
		drag_last_date: '',
	}},
	computed: {
		...mapState(['user_info', 'my_lessons', 'my_activities', 'now_date_string']),
		...mapGetters(['role', 'system_role']),
		show_older_items: {
			get() { return this.$store.state.lst.show_older_items },
			set(val) { this.$store.commit('lst_set', ['show_older_items', val]) }
		},
		is_content_editor() { return this.role == 'staff' || this.role == 'admin' },
		dates() {
			// format for dates: 2022-03-24
			let first_date = '9999'
			let last_date = '0000'
			this.now_cell = -1
			function check_date(d) {
				if (!d) return
				if (d < first_date) first_date = d
				if (d > last_date) last_date = d
			}

			for (let item of this.items) {
				// skip old items if we're not showing them
				if (!this.show_older_items && item.is_old) continue

				check_date(item.o.lesson_date)
				if (item.o.activity_id) check_date(item.o.available_date())
				if (item.o.activity_id) check_date(item.o.due_date())
			}

			// make sure we're showing today
			check_date(this.now_date_string)

			// if we're currently dragging items...
			if (this.dragging_items) {
				// if drag_first_date or drag_last_date is === first_date/last_date, move them out further
				if (first_date == this.drag_first_date) {
					let temp = new Date(first_date+'T00:00:00')
					temp.setDate(temp.getDate() - 7)
					this.drag_first_date = date.format(temp, 'YYYY-MM-DD')
				}
				if (last_date == this.drag_last_date) {
					let temp = new Date(last_date+'T00:00:00')
					temp.setDate(temp.getDate() + 7)
					this.drag_last_date = date.format(temp, 'YYYY-MM-DD')
				}

				// then set first_date/last_date to drag_first_date/drag_last_date
				first_date = this.drag_first_date
				last_date = this.drag_last_date

			} else {
				// save drag_first_date / drag_last_date, in anticipation of the user choosing to drag items...
				let temp = new Date(first_date+'T00:00:00')
				temp.setDate(temp.getDate() - 7)
				this.drag_first_date = date.format(temp, 'YYYY-MM-DD')

				temp = new Date(last_date+'T00:00:00')
				temp.setDate(temp.getDate() + 7)
				this.drag_last_date = date.format(temp, 'YYYY-MM-DD')

				// then move first_date up by 3 days, and last_date forward by 3 days
				temp = new Date(first_date+'T00:00:00')
				temp.setDate(temp.getDate() - 3)
				first_date = date.format(temp, 'YYYY-MM-DD')

				temp = new Date(last_date+'T00:00:00')
				temp.setDate(temp.getDate() + 3)
				last_date = date.format(temp, 'YYYY-MM-DD')
			}

			// get integer value for day_of_week for first_date
			let day_of_week = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].indexOf(date.format(new Date(first_date+'T00:00:00'), 'dd'))

			let darr = []
			let marr = []
			let last_mobj
			let d = first_date
			// let day_of_week
			while (d <= last_date) {
				let dobj = {
					str: d,
					year: d.substr(0, 4),
					month: d.substr(5, 2),
					// day_of_week: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'][day_of_week],
					day_of_week: 'SMTWTFS'[day_of_week],
					day: d.substr(8, 2),
				}
				darr.push(dobj)
				
				// add a new month if necessary
				let mobj = {
					str: date.format(new Date(d+'T00:00:00'), 'MMMM YYYY'),
					month: dobj.month,
					start_cell: darr.length,
				}
				if (!last_mobj || last_mobj.month != mobj.month) {
					last_mobj = mobj
					marr.push(mobj)
				}
				last_mobj.end_cell = darr.length+1

				if (d == this.now_date_string) this.now_cell = darr.length

				d = date.format(new Date(d + 'T24:00:00'), 'YYYY-MM-DD')	// this adds a day
				day_of_week = (day_of_week + 1) % 7
			}

			// remove 1st/last months if they have < 4 days
			if (marr.length > 1 && (marr[0].end_cell - marr[0].start_cell) <= 4) {
				marr.shift()
			}
			if (marr.length > 1 && (marr[marr.length-1].end_cell - marr[marr.length-1].start_cell) <= 4) {
				marr.pop()
			}
			this.months = marr

			this.$nextTick(()=>this.scroll_to_today())

			this.$nextTick(()=>{
				this.drag_snap_value = $(this.$el).find('.k-mc-gantt-dates').width() / this.dates.length
			})
			
			return darr
		},
		grid_row_style() {
			return sr('grid-template-columns: repeat($1,minmax(28px, 1fr));', this.dates.length)
		},
		now_column_style() {
			if (this.now_cell == -1) return ''
			return sr('grid-column:$1/$2', this.now_cell, this.now_cell+1)
		},
		gantt_wrapper_css() {
			if (this.$store.state.activity_embed_maximized) return 'k-mc-gantt-sparkl-maximized'
		},
		my_content_assigned_to_filter: {
			get() {
				// make sure we use an Assignee object here
				if (this.max_context == 0) return new Assignee()
				return new Assignee(this.$store.state.lst.my_content_assigned_to_filter[this.course_code]) 
			},
			set(val) { 
				if (this.max_context == 0) return

				// make sure val is *not* an object when stored to 
				let o = extobj(this.$store.state.lst.my_content_assigned_to_filter)
				o[this.course_code] = new Assignee(val).copy_for_save()
				this.$store.commit('lst_set', ['my_content_assigned_to_filter', o]) 
			}
		},
	},
	watch: {
		items: { immediate: true, handler() {
			if (this.dd_item == null || this.items.length != this.ordered_items.length) {
				this.set_ordered_items()
			}
		}},
	},
	created() {
		vapp.mc_gantt_view = this
		this.set_ordered_items()
	},
	mounted() {
	},
	methods: {
		set_ordered_items() {
			// we use this construct to allow us to keep the order of items constant when dragging dates for an item, then put things in order when dragging is done
			let arr = []
			for (let item of this.items) {
				arr.push(item)
			}
			this.ordered_items = arr
		},

		scroll_to_today() {
			// don't scroll when dragging to change dates
			if (this.dragging_items) return

			let $now = $(this.$el).find('.k-mc-gantt-date-now')
			if ($now.length > 0) {
				// console.log('scroll_to_today: ' + this.course_code + '/' + this.lp_unit_id)
				// note that .k-mc-gantt-outer, which is the element that gets scolled, is currently the top-level div
				// scroll so that today is 24% from the left side of the screen
				let tp = $now.offset().left - ($(window).width() / 4)
				$(this.$el).scrollLeft(tp)
			} else {
				console.log('NO scroll_to_today: ' + this.course_code + '/' + this.lp_unit_id)
			}
		},
		date_class(dobj) {
			if (dobj.str == this.now_date_string) return 'k-mc-gantt-date-now'
			if (dobj.day == '01') return 'k-mc-gantt-date-01'
			return ''
		},
		date_index(d) { return this.dates.findIndex(x=>x.str == d) + 1 },
		item_row_outer_class(item, hover) {
			let s = ''
			if (item.o.item_showing || hover) s += ' k-mc-gantt-row-item-outer-highlighted'
			if (item.o.item_showing) s += ' k-mc-gantt-row-item-outer-showing'
			return s
		},
		item_row_inner_class(item, hover) {
			let s = ''
			if (item.o.item_showing || hover) s += ' k-mc-gantt-item-highlighted'
			if (this.dragging_items) {
				if (!this.dd_item || (this.dd_item == item && this.dd_side == 'item')) {
					if (item.o.lesson_date || item.o.available_date() || item.o.due_date()) {
						s += ' k-mc-gantt-row-item-draggable'
					}
				}
			}
			return s
		},
		item_is_undated(item) {
			return (!item.o.lesson_date && !item.o.available_date() && !item.o.due_date())
		},
		item_row_class(item, hover) {
			if (this.item_is_undated(item)) {
				return 'k-mc-gantt-item-bgd-undated'
			}
			let s = ''
			if (item.type != 'lesson') {
				if (!item.o.due_date()) s += 'k-mc-gantt-item-bgd-no-due-date'
			}
			let color = item.o.date_color()
			if (hover || item.o.item_showing) s += sr(' $1 lighten-2 elevation-3', color)
			else s += sr(' $1 lighten-3', color)

			if (hover && this.dragging_items) s += ' k-mc-gantt-item-bgd-dragged'
			return s
		},
		item_column_start(item) {
			if (item.type == 'lesson') {
				if (item.o.lesson_date) {
					return this.date_index(item.o.lesson_date)
				}
			} else {	// activity
				if (item.o.available_date()) {
					return this.date_index(item.o.available_date())
				}
				if (item.o.due_date() && !item.o.available_date()) {
					return this.date_index(item.o.due_date())
				}
			}
			// no date: return now + 1, so it will show up just after today
			return this.now_cell + 1
		},
		item_column_end(item) {
			if (item.type == 'lesson') {
				if (item.o.lesson_date) {
					return this.date_index(item.o.lesson_date) + 1
				}
			} else {	// activity
				if (item.o.due_date()) {
					return this.date_index(item.o.due_date()) + 1
				}
				// note: an activity that doesn't have a due date always has an end date of the last date
			}
			// no due date: goes to a max of 2 weeks
			let val = this.item_column_start(item) + 15
			if (val > this.dates.length - 3) return this.dates.length - 3
			else return val
		},
		item_row_style_bgd(item) {
			return sr('grid-column:$1/$2', this.item_column_start(item), this.item_column_end(item))
		},
		item_row_style_open_item(item) {
			// we'd prefer the open item's left side to line up with the bgd's left side,
			// but the open item will be 860px wide, and each column is 28px; so we need at least 31 columns
			let end = this.dates.length + 1
			let start = this.item_column_start(item)
			if ((start + 30) >= this.dates.length) {
				start = this.dates.length - 30
				if (start < 1) start = 1
			}
			return sr('grid-column:$1/$2', start, end)
		},
		item_align(item) {
			if (this.item_is_undated(item)) {
				if (this.now_cell > this.dates.length / 2) return 'end'
				else return 'start'
			}
			if (this.item_column_start(item) < this.dates.length/2) return 'start'
			else return 'end'
		},
		item_inner_style(item) {
			if (this.item_align(item) == 'start') return 'justify-content:start; padding-left:1px;'
			return 'justify-content:end; padding-right:1px;'
		},
		item_icon(item) { return U.directive_type_icon(item.type == 'lesson'?'lesson':item.o.activity_type) },
		item_title(item) { return (item.type == 'lesson') ? item.o.lesson_title : item.o.activity_title },
		item_course_unit_header(item) { return item.o.course_unit_header() },
		item_date(item) { return item.o.date_header() },
		item_content(item, hover) {
			let s = ''
			s += '<div class="k-mc-gantt-item-inner-inner">'
			if (this.item_align(item) == 'start') s += sr('<i class="primary--text $1" style="margin-right:10px;margin-left:3px; font-size:14px"></i>', this.item_icon(item))
			s += sr('<span>$1</span>', this.item_title(item))
			if (this.max_context == 0) s += sr('<div class="mr-1 ml-1">$1</div>', this.item_course_unit_header(item))
			if (this.item_is_undated(item) || (hover && !item.o.item_showing)) s += sr('<div class="ml-1">$1</div>', this.item_date(item))
			if (this.item_align(item) == 'end') s += sr('<i class="primary--text $1" style="margin-left:10px;margin-right:3px;font-size:14px"></i>', this.item_icon(item))
			s += '</div>'
			return s
		},
		item_bolt_btn_style(item) {
			let s = 'position:absolute;top:-4px;'
			// this clause shifts the bolt menu out to accommodate for drag handles. (currently we don't show the bolt menu when editing dates, but leave it here in case we want it later)
			if (this.dragging_items && item.o.activity_id && (item.o.due_date() || item.o.available_date())) {
				if (this.item_align(item) == 'start') s += 'left:-48px;'
				else s += 'right:-48px;'
			} else {
				if (this.item_align(item) == 'start') s += 'left:-34px;'
				else s += 'right:-34px;'
			}
			return s
		},

		bolt_menu_clicked(item) {
			if (this.bolt_menu_showing_item == item) {
				this.bolt_menu_showing_item = null
			} else {
				this.bolt_menu_showing_item = item
			}
		},
		directive_type_label(item) { return U.directive_type_label(item.o.activity_type) },
		item_component(item) {
			if (!this.$refs['item'+item.id] || !this.$refs['item'+item.id][0]) return null
			return this.$refs['item'+item.id][0]
		},
		item_being_edited(item) {
			// return (this.$refs['item'+item.id] && this.$refs['item'+item.id][0] && this.$refs['item'+item.id][0].item_being_edited)
			return this.item_component(item)?.item_being_edited
		},
		item_clicked(item) {
			if (this.dragging_items) return

			this.$store.commit('set', [item.o, 'item_showing', !item.o.item_showing])
			this.bolt_menu_showing_item = null
		},
		edit_item(item) {
			if (this.$store.getters.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			this.$store.commit('set', [item.o, 'item_showing', true])
			let ic = this.item_component(item)
			if (ic) ic.item_being_edited = true
			this.bolt_menu_showing_item = null
		},

		///////////////////////////
		// drag-and-drop date editing
		edit_dates() {
			if (this.$store.getters.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }

			// if we have more than one assigned_to_options value, make them choose an option
			if (this.assigned_to_options.length > 1) {
				if (this.my_content_assigned_to_filter.empty()) {
					this.$alert('Please choose an option from the “Show activities for” menu in order to drag-and-drop assignment dates.')
					return
				} else if (!empty(this.my_content_assigned_to_filter.user_sourcedId)) {
					this.$alert('To edit dates for individual students you must edit individual activities.')
					return
				}
			}

			// enable dragging for all items
			this.$nextTick(x=>this.dragging_items = true)
			this.bolt_menu_showing_item = null
		},

		show_left_drag_target(hover, item) {
			// show left drag target if we have an available date (along with other criteria)
			if (!this.dragging_items) return false
			if (!item.o.activity_id) return false
			if (!item.o.available_date()) return false
			if (!(hover || this.dd_item == item)) return false
			if (this.dd_item && (this.dd_item != item || this.dd_side != 'left')) return false
			return true
		},

		show_right_drag_target(hover, item) {
			// show left drag target if we have a due date (along with other criteria)
			if (!this.dragging_items) return false
			if (!item.o.activity_id) return false
			if (!item.o.due_date()) return false
			if (!(hover || this.dd_item == item)) return false
			if (this.dd_item && (this.dd_item != item || this.dd_side != 'right')) return false
			return true
		},

		date_drag_start($event, item, side) {
			if (!this.dragging_items) return
			// console.log('date_drag_start', item, side)

			this.dd_item = item
			this.dd_side = side
			this.dd_start_position = $event.x
			this.dd_original_lesson_date = item.o.lesson_date
			this.dd_original_available_date = item.o.available_date()
			this.dd_original_due_date = item.o.due_date()
		},

		date_drag_moving($event) {
			if (!this.dragging_items || this.dd_item === null) return
			// console.log('date_drag_moving', this.dd_item)

			let assignee, assignment
			if (this.dd_item.o.activity_id) {
				assignee = this.dd_item.o.get_current_assignee()
				if (!assignee) {
					console.log('THIS IS BAD! (date_drag_moving)')
					return
				}
				assignment = this.dd_item.o.assignments.find(x=>x.assignment_id==assignee.assignment_id)
				if (!assignment) {
					console.log('THIS IS ALSO BAD! (date_drag_moving)')
					return
				}
			}

			let delta = Math.round(($event.x - this.dd_start_position) / this.drag_snap_value)

			// when left handle is dragged, drag available date
			if (this.dd_side == 'left') {
				let new_available_date = Activity.add_to_date(this.dd_original_available_date, delta)
				if (new_available_date != this.dd_item.o.available_date()) {
					if (new_available_date > this.dd_item.o.due_date()) return
					this.$store.commit('set', [assignment, 'available_date', new_available_date])
				}
			
			// when right handle is dragged, drag due date
			} else if (this.dd_side == 'right') {
				let new_due_date = Activity.add_to_date(this.dd_original_due_date, delta)
				if (new_due_date != this.dd_item.o.due_date()) {
					if (new_due_date < this.dd_item.o.available_date()) return
					this.$store.commit('set', [assignment, 'due_date', new_due_date])
				}

			// when item is dragged...
			} else {
				// for a lesson, drag lesson_date
				if (this.dd_item.o.lesson_id) {
					let new_lesson_date = Activity.add_to_date(this.dd_original_lesson_date, delta)
					if (new_lesson_date != this.dd_item.o.lesson_date) {
						this.$store.commit('set', [this.dd_item.o, 'lesson_date', new_lesson_date])
					}

				} else {
					// else drag due and available date, if set
					let new_available_date = Activity.add_to_date(this.dd_original_available_date, delta)
					if (new_available_date != this.dd_item.o.available_date()) {
						// TODO
						this.$store.commit('set', [assignment, 'available_date', new_available_date])
					}
					let new_due_date = Activity.add_to_date(this.dd_original_due_date, delta)
					if (new_due_date != this.dd_item.o.due_date()) {
						this.$store.commit('set', [assignment, 'due_date', new_due_date])
					}
				}
			}
		},

		mouseup_outer($event) {
			if (this.dd_item) {
				// save item if any date has changed
				let payload = {suppress_loader:true}
				let service = ''
				let inform_msg
				let inform_msg_time = 2000
				let inform_color = 'green darken-3'
				if (this.dd_item.o.lesson_date != this.dd_original_lesson_date) {
					service = 'save_lesson'
					payload.lesson_class = 'teacher'	// by definition this will be a teacher lesson
					payload.lesson_data = {lesson_id: this.dd_item.o.lesson_id, lesson_date: this.dd_item.o.lesson_date}
					inform_msg = 'Lesson date updated'
				}
				if (this.dd_item.o.activity_id && (this.dd_item.o.available_date() != this.dd_original_available_date || this.dd_item.o.due_date() != this.dd_original_due_date)) {
					service = 'save_activity'
					payload.activity_data = {activity_id: this.dd_item.o.activity_id, assignments: extobj(this.dd_item.o.assignments)}
					if (this.dd_item.o.available_date() == this.dd_original_available_date) inform_msg = 'Due date updated'
					else if (this.dd_item.o.due_date() == this.dd_original_due_date) inform_msg = 'Available date updated'
					else inform_msg = 'Activity dates updated'
				}

				if (this.dd_item.o.item_is_old() && !this.show_older_items) {
					inform_msg += '. Note that the item is now hidden; click SHOW OLDER ITEMS to reveal it.'
					inform_msg_time = 5000
					inform_color = 'red darken-3'
				}

				if (service) {
					this.$store.dispatch(service, payload).then((result)=>{
						// note that the store value of the item gets updated as the drag occurs, so we don't have to do anything here except inform the user
						this.$inform({text:inform_msg, snackbarTimeout:inform_msg_time, color:inform_color})
					})
				}

				this.set_ordered_items()
				this.dd_item = null
			}
		},
	}
}
</script>

<style lang="scss">
.k-mc-gantt-outer {
	background-color:#ccc;
	max-width:100vw;
	overflow:auto;
	border:1px solid #ccc;
	padding-bottom:12px;
	user-select: none;
}

.k-mc-gantt-sparkl-maximized {
	// this causes the maximized sparkl activity to appear above the app bar, and makes sure other gant rows don't appear above the sparkl frame
	// z-index:100000000;
	left:100000px;
}

.k-mc-gantt {
	display: grid;
	// border-top: 1px solid #999;
	// border-bottom: 1px solid #999;
	// border-radius:12px;
	position: relative;
	// overflow: hidden;  
}
.k-mc-gantt-row {
	display: grid;      
	grid-template-columns:minmax(28px, 1fr);
	background-color: #fff;
}

.k-mc-gantt-months {
	background-color:#ddd;
	min-height:24px;
	z-index:1;
}
.k-mc-gantt-month {
	position:absolute;
	white-space:nowrap;
	font-size:14px;
	font-weight:bold;
	padding-left:4px;
	z-index:1;
}

.k-mc-gantt-dates {
	color: #fff;
	border-bottom: 1px solid #999; 
	z-index:1;
}
.k-mc-gantt-date {    
	text-align: center;
	font-size: 13px;
	line-height:13px;
	align-self: center;
	font-weight: bold;
	padding: 4px 0;    
	background-color: #666;
}
.k-mc-gantt-date-01 {
	background-color: #333;
}
.k-mc-gantt-date-now {
	background-color:$v-pink-accent-4;
}

.k-mc-gantt-lines {
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: transparent;
}
.k-mc-gantt-lines > span {
	display: block;
	border-right: 1px solid #ddd;
}

.k-mc-gantt-today-line {
	position: absolute;
	z-index:0;
	height: 100%;
	width: 100%;
	background-color: transparent;
}
.k-mc-gantt-today-line > span {
	display:block;
	background-color:$v-pink-lighten-5;
}

.k-mc-gantt-month-line {
	position: absolute;
	z-index:0;
	height: 100%;
	width: 100%;
	background-color: transparent;
}
.k-mc-gantt-month-line > span {
	display:block;
	background-color:#ddd;
}

.k-mc-gantt-row-item-outer {
	border-bottom:1px solid transparent;
	border-top:1px solid transparent;
}

.k-mc-gantt-row-item-outer-highlighted {
	z-index: 1;
	background-color:#eee;
}
.k-mc-gantt-row-item-outer-showing {
	z-index: 1;	// this makes the showing item appear over the lines
	background-color:#ddd;
	border-bottom-color: #999;
	border-top-color: #999;
}

.k-mc-gantt-row-item {
	z-index:1;
	display: grid;
	padding: 2px 0;
	margin: 0;
	cursor: pointer;
}
.k-mc-gantt-item-bgd {
	min-height: 31px;
	// background-color: #ddd;
	border-radius: 3px;
	margin:0 0px 0 -1px;
	position:relative;
	border-style: solid;
	border-width:1px;
	.k-mc-gantt-item-inner .k-mc-gantt-item-inner-inner {
		background-color:transparent;
	}
	transition:all 0.25s;
}
.k-mc-gantt-item-bgd.teal.lighten-3 { border-color: $v-teal!important; }
.k-mc-gantt-item-bgd.teal.lighten-2 { border-color: $v-teal!important; }
.k-mc-gantt-item-bgd.amber.lighten-3 { border-color: $v-amber!important; }
.k-mc-gantt-item-bgd.amber.lighten-2 { border-color: $v-amber!important; }
.k-mc-gantt-item-bgd.grey.lighten-3 { border-color: $v-grey!important; }
.k-mc-gantt-item-bgd.grey.lighten-2 { border-color: $v-grey!important; }
.k-mc-gantt-item-bgd.red.lighten-3 { border-color: $v-red!important; }
.k-mc-gantt-item-bgd.red.lighten-2 { border-color: $v-red!important; }

.k-mc-gantt-item-bgd-undated {
	border-color: transparent;
}

.k-mc-gantt-item-bgd-no-due-date {
	// border-top-right-radius:40px;
	// border-bottom-right-radius: 40px;
	// border-top-right-radius:0;
	// border-bottom-right-radius: 0;
	border-right-width:0;
	// clip-path: polygon(0% 0%, 98.25% 0%, 100% 50%, 98.25% 100%, 0% 100%);
}
.k-mc-gantt-item-bgd-no-due-date.teal.lighten-3 {
	border-image: linear-gradient( 90deg, rgba(0,150,136,1) 0%, rgba(0,150,136,1) 50%, rgba(0,150,136,0) 100%) 1;
	background: linear-gradient(90deg, rgba(128,203,196,1) 0%, rgba(128,203,196,1) 50%, rgba(128,203,196,0.0) 100%)!important;
}
.k-mc-gantt-item-bgd-no-due-date.teal.lighten-2 {
	border-image: linear-gradient( 90deg, rgba(0,150,136,1) 0%, rgba(0,150,136,1) 50%, rgba(0,150,136,0) 100%) 1;
	background: linear-gradient(90deg, rgba(77,182,172,1) 0%, rgba(77,182,172,1) 50%, rgba(77,182,172,0.0) 100%)!important;
}
.k-mc-gantt-item-bgd-no-due-date.grey.lighten-3 {
	border-image: linear-gradient( 90deg, rgba(158,158,158,1) 0%, rgba(158,158,158,1) 50%, rgba(158,158,158,0) 100%) 1;
	background: linear-gradient(90deg, rgba(238,238,238,1) 0%, rgba(238,238,238,1) 50%, rgba(238,238,238,0.3) 100%)!important;
}
.k-mc-gantt-item-bgd-no-due-date.grey.lighten-2 {
	border-image: linear-gradient( 90deg, rgba(158,158,158,1) 0%, rgba(158,158,158,1) 50%, rgba(158,158,158,0) 100%) 1;
	background: linear-gradient(90deg, rgba(224,224,224,1) 0%, rgba(224,224,224,1) 50%, rgba(224,224,224,0.3) 100%)!important;
}

.k-mc-gantt-item-highlighted {
	// .k-mc-gantt-item-bgd { background-color:#ccf; }
	.k-mc-gantt-item-inner { text-shadow:1px 1px 1px #ccc; }
}

// this is not currently used
// .k-mc-gantt-item {
// 	min-height: 31px;
// 	position:relative;
// }

.k-mc-gantt-item-inner {
	position:absolute;
	top:3px;
	left:0;
	right:0;
	white-space:nowrap;
	text-align: left;
	font-size: 12px;
	line-height:22px;
	color: #000;
	display:flex;

	.k-mc-gantt-item-inner-inner {
		display:flex;
		align-items:center;
		padding:0 2px;
		border-radius:6px;
		background-color:rgba(255,255,255,0.5);
	}

	.k-lesson-course-unit-header-inner {
		font-size:9px;
		line-height:12px;
		font-weight:bold;
		border-radius:4px;
		padding:1px 2px;
		background-color:#fff;
		color:#000;
		border:1px solid #999;
		text-shadow:none;
	}

	.k-lesson-date-header {
		font-size:9px;
		line-height:12px;
		border-radius:4px;
		padding:2px 2px;
		margin-left:0px;
		display:block;
		text-shadow:none;
	}
}

.k-mc-gantt-item-showing-outer {	// also see DirectivesWrapper
	background-color:#fff;
	position:relative;
	border:1px solid #ccc;
	border-radius:12px;
	margin-top:0px;
	margin-bottom:12px;
	margin-left:0!important;
	padding:8px;
	cursor: auto;
	user-select: text;

	.k-activity-header {
		padding-top:0;
		border-top:0;
		margin-top:0;
		padding-left:0;
		padding-right:0;
	}
}

.k-mc-gant-item-drag-handle {
	position:absolute!important;
	z-index:2;
	// text-align:center;
	width:18px;
	height:calc(100% + 2px);
	margin-top:-1px;
	background-color:rgba(255,255,255,0.8);
	border:1px solid #999;
}
.k-mc-gant-item-drag-handle-left {
	left:-18px;
	text-align:right;
	padding-right:6px;
	border-radius:4px 0 0 4px;
	border-right:0;
	cursor:w-resize;
}
.k-mc-gant-item-drag-handle-right {
	right:-18px;
	text-align:left;
	padding-left:6px;
	border-radius:0 4px 4px 0;
	border-left:0;
	cursor:r-resize;
}
.k-mc-gantt-row-item-draggable {
	cursor:ew-resize!important;
}
.k-mc-gantt-item-bgd-dragged {
	border-radius:0;
}

.k-mc-gantt-item-date-change-instructions {
	position:fixed;
	font-size:16px;
	z-index:10;
	bottom:12px;
	left:calc(50vw - 250px);
	width:640px;
	text-align:center;
	background-color:$v-green-lighten-4;
	border:1px solid $v-green-darken-2;
	border-radius:8px;
	padding:8px 0;
}
</style>
