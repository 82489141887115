<template>
	<div class="k-message-reply-list">
		<!-- <pre>{{ JSON.stringify(reply_thread, false, 2) }}</pre> -->
		<MessageReply :message="reply_thread" @set_expanded="set_expanded" :is_first_level_reply="true"
			:thread_length="thread_length" />
		<div v-show="has_second_level_replies && thread_expanded">
			<div v-for="reply in second_level_replies" :key="reply.message_id">
				<MessageReply v-if="reply.message_id !== 0" :message="reply" />
				<v-divider></v-divider>
			</div>
		</div>
		<v-divider class="ml-2 mr-2"></v-divider>
		<div>
			<v-btn v-if="thread_expanded && !show_message_reply_editor" x-small dense color="primary"
				class="ml-3 mt-3 mb-3 k-tight-btn elevation-2 k-message-reply-list-button" @click="add_message_reply">
				<v-icon small class="mr-2">fas fa-reply</v-icon>
				ADD REPLY
			</v-btn>
			<MessageEditor v-if="show_message_reply_editor && message_reply" :message="message_reply"
				@editor_cancel="show_message_reply_editor = false" :course_code="this.initial_reply.course_code"
				:is_reply="true" :reply_to_string="reply_to_string" />
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import MessageReply from './MessageReply'
import MessageEditor from './MessageEditor'

export default {
	components: { MessageReply, MessageEditor },
	name: 'MessageReplyList',
	props: {
		reply_thread: { type: Object, required: true },
	},
	data() {
		return {
			thread_expanded: false,
			message_reply: null,
			show_message_reply_editor: false,
		}
	},
	computed: {
		...mapState(['user_info', 'messages']),
		reply_to_string() {
			const filtered_reply_thread = this.reply_thread?.replies.filter((r) => r.message_id !== 0).filter((r) => r.author_sourcedId !== this.user_info.author_sourcedId)
			if (filtered_reply_thread.length === 0) {
				const reply_to = this.messages.find((m) => m.message_id === this.initial_reply.message_id)
				return `${reply_to.first_name} ${reply_to.last_name}`
			}
			const last_reply = filtered_reply_thread[filtered_reply_thread.length - 1]
			return `${last_reply.first_name} ${last_reply.last_name}`
		},
		initial_reply() {
			return this.reply_thread
		},
		has_second_level_replies() {
			return this.reply_thread?.replies.length > 0 ?? false
		},
		second_level_replies() {
			return this.reply_thread?.replies
		},
		thread_length() {
			return this.reply_thread?.replies?.length ?? 0
		},
		response_recipient() {
			// The sender of the most recent message in the thread is the recipient of the next response
			return new Recipient({ user_sourcedId: this.reply_thread?.replies[this.thread_length - 1]?.author_sourcedId ?? this.initial_reply?.author_sourcedId })
		},
	},
	created() { },
	mounted() { },
	methods: {
		set_expanded(is_expanded) {
			this.thread_expanded = is_expanded
			if (is_expanded) {
				this.$emit('reply_thread_expanded', is_expanded)
			}
		},
		add_message_reply() {
			let m = {
				course_code: this.initial_reply.course_code,
				subject: this.initial_reply.subject,
				send_to: this.initial_reply.send_to,
				body: '',
				parent_message_id: this.initial_reply.message_id,
				message_level: 2,
				author_user_id: this.user_info.user_id,
				author_sourcedId: this.user_info.author_sourcedId,
				recipients: [this.response_recipient],
				first_name: this.user_info.first_name,
				last_name: this.user_info.last_name,
			}
			this.message_reply = new window.Message(m)
			this.$store.commit('set', [this.messages, 'PUSH', this.message_reply])
			this.show_message_reply_editor = true
		},
	},
}
</script>

<style lang="scss" scoped>
.k-message-reply-list {
	background-color: #fdf7e4;
}
</style>
