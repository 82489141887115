<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-main-collection k-standards-home-wrapper">
	<h2 class="k-page-title d-flex align-center">
		<b>{{ site_config.learning_standards_prefix }} Learning Standards</b>
		<v-spacer/>
		<v-btn small color="primary" dark class="k-nocaps-btn k-tight-btn" :href="site_config.satchel_origin" target="_blank"><v-icon small class="mr-2">fas fa-external-link-alt</v-icon>{{site_config.satchel_app_name}}<span v-if="$vuetify.breakpoint.smAndUp" class="ml-1">({{ satchel_domain }})</span></v-btn>
	</h2>

	<div class="k-fwcc-docs">
		<FrameworkListFramework v-for="(fr, j) in framework_records" :key="fr.lsdoc_identifier" :framework_record="fr" />
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import FrameworkListFramework from './FrameworkListFramework.vue'

export default {
	components: { FrameworkListFramework },
	props: {
	},
	data() { return {
	}},
	computed: {
		...mapState(['frameworks_loaded', 'framework_records', 'user_info', 'site_config']),
		satchel_domain() {
			return this.site_config.satchel_origin.match(/^(?:https?:\/\/)?([^\/]+)/i)[1]
		}
	},
	watch: {
	},
	created() {
	},
	mounted() {
		this.initialize()
	},
	methods: {
		// when this page is loaded, get the lsdoc_list
		initialize() {
			if (this.frameworks_loaded) {
				this.initialized = true
			} else {
				this.$store.dispatch('get_lsdoc_list').then(x=>this.initialized = true)
			}
		},
	}
}
</script>

<style lang="scss">
.k-standards-home-wrapper {
}
.k-fwcc-docs {
	margin-top:8px;
	display:flex;
	align-items: flex-start;
	justify-content: center;
	flex-wrap:wrap;
}
</style>
