<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
	<div class="k-page-wrapper">
		<h2 class="k-page-title">
			<v-icon @click="return_to_admin_main" large color="primary" class="mr-2">fas fa-home</v-icon>
			Home Page Content
		</h2>

		<div v-if="initialized" style="width:980px;border:1px solid #ccc; padding:2px;border-radius:8px;background-color:#fff;" class="mx-auto my-4">
			<v-tabs v-model="tab" bg-color="primary">
				<v-tab v-for="(item) in tabs" :key="item.key"><b>{{item.text}}</b></v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab">
				<v-tab-item v-for="(item) in tabs" :key="item.key" class="pa-2">
					<froala-wrapper :config="editor_config()" v-model="edited_home_page_content[item.key]" />
					<div class="text-right mt-4 mb-2"><v-btn color="primary" @click="save_changes(item.key)" :disabled="!content_changed[item.key]"><v-icon small class="mr-2">fas fa-save</v-icon> Save Changes</v-btn></div>
				</v-tab-item>
			</v-tabs-items>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		tabs: [
			{key: 'staff', text: (true) ? 'Staff' : 'Signed In', initialized: false},
			{key: 'student', text: 'Students', initialized: false},
			{key: 'parent', text: 'Parents (Family View)', initialized: false},
			{key: 'unsignedin', text: 'Not Signed In', initialized: false},
		],
		tab: 'home_page',
		edited_home_page_content: {},
	}},
	computed: {
		...mapState(['home_page_content']),
		...mapGetters([]),
		content_changed() {
			let o = {}
			for (let item of this.tabs) {
				o[item.key] = (this.edited_home_page_content[item.key] != this.home_page_content[item.key])
			}
			console.log('content_changed', o)
			return o
		},
		initialized() {
			for (let item of this.tabs) if (!item.initialized) return false
			return true
		},
	},
	watch: {
	},
	created() {
		vapp.admin_home_page_content = this
		let o = {}
		for (let item of this.tabs) {
			o[item.key] = ''
		}
		this.edited_home_page_content = o
	},
	mounted() {
		// retrieve the home_page_content for all pages
		for (let item of this.tabs) {
			this.$store.dispatch('get_home_page_content', item.key).then(x=>{
				console.log('got content for ' + item.key)
				this.edited_home_page_content[item.key] = this.home_page_content[item.key]
				item.initialized = true
			})
		}
	},
	methods: {
		editor_config() {
			return U.get_froala_config({
				heightMin: 300,
			})
		},

		save_changes(key) {
			let payload = {
				user_id: this.$store.state.user_info.user_id,
				home_page_variant: key,
				home_page_content: this.edited_home_page_content[key],
			}
			
			U.loading_start()
			U.ajax('admin_save_home_content', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					vapp.ping()		// call ping to check if the session is expired
					vapp.$alert('An error occurred when attempting to save the home page content.')
					return
				}
				this.$store.commit('set', [this.home_page_content, key, this.edited_home_page_content[key]])
			})
		},

		return_to_admin_main() {
			this.$router.push({ path: '/' })
		},

	}
}
</script>

<style lang="scss">
</style>
