<template>
	<v-dialog v-model="dialog_open" max-width="800" persistent scrollable>
		<v-card>
			<v-card-title>
				<h3>Export content from this collection</h3>
			</v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">
				Export this collection as a Thin Common Cartridge, which can then be imported into any learning object
				repository (LOR) or learning management system (LMS) that supports the 1EdTech Common Cartridge
				standard.
				<!-- <pre>{{ JSON.stringify(filtered_item_tree, false, 2) }}</pre> -->
				<div class="mt-2">
					<div>
						<v-checkbox class="mt-0 pt-0"
							:label="`Export ${site_config.sparkl_app_name} activities as LTI links`"
							v-model="lti_toggle" hide-details>
						</v-checkbox>
					</div>
				</div>
				<div class="mt-4">
					<span>Select items for export:</span>
					<v-treeview open-on-click dense :items="treeview_source" v-model="selected_items" selectable>
					</v-treeview>
				</div>
			</v-card-text>
			<v-card-actions class="pl-4 pr-4 pb-4">
				<v-btn color="secondary" @click="$emit('dialog_cancel')" class="mr-1">
					<v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
				<v-spacer />
				<v-btn color="primary" @click="export_cartridge" class="k-tight-btn">
					<v-icon small class="mr-2">fas fa-check</v-icon> Export Cartridge</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import JSZip from 'jszip'
import { create } from 'xmlbuilder2'
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		collection: { type: Object, required: true }
	},
	data() {
		return {
			dialog_open: true,
			lti_toggle: false,
			manifest_uuid: U.new_uuid(),
			language: 'en-US',
			selected_items: [],
		}
	},
	computed: {
		...mapState(['site_config']),
		...mapGetters([]),

		manifest_metadata() {
			return {
				'schema': 'IMS Thin Common Cartridge',
				'schemaversion': '1.3.0',
				'lomm:lom': {
					'lomm:general': {
						'lomm:identifier': {
							'lomm:catalog': 'GUID',
							'lomm:entry': this.manifest_uuid,
						},
						'lomm:title': {
							'lomm:string': {
								'@language': this.language,
								'#text': this.collection.title,
							},
						},
						'lomm:description': {
							'lomm:string': {
								'@language': this.language,
								'#text': this.collection.description,
							},
						},
					}
				}
			}
		},

		item_tree() {
			// First, establish 'root' item in organization. This represents the Collection as a whole
			const items = { "@identifier": "root", item: [] }

			// Next, we need to build a nested set of 'item' objects for each unit
			// An 'item' can be a folder, a subfolder, or a resource/lesson
			this.collection.units.forEach(unit => {
				const lookup = {}
				let resource_tree = unit.resource_tree
				let root

				// Step 1: If there are more than one folders defined, we need to
				// create a lookup object to build our nested set from.
				resource_tree.folders.forEach(folder => {
					const folder_identifier = folder.folder_id == "top" ? `top_${unit.lp_unit_id}` : `folder_${folder.folder_id}`
					const folder_title = folder.folder_id == "top" ? unit.title : folder.title
					lookup[folder.folder_id] = { "@identifier": folder_identifier, title: folder_title, item: [], seq: folder.seq }
				})

				// Step 2: Populate the item arrays and find the root folder
				resource_tree.folders.forEach(folder => {
					if (folder.parent_folder_id) {
						// This folder has a parent, so push it to the parent's item array
						lookup[folder.parent_folder_id].item.push(lookup[folder.folder_id])
					} else {
						// This folder has no parent, so it's the root of the tree
						root = lookup[folder.folder_id]
					}
				})

				// Step 3: We need a cleaned folder_assigments array, with all "phantom" resources ignored
				const valid_folder_assignments = resource_tree.folder_assignments.filter((folder_assignment) => {
					const matches_resource = unit.resources.some(resource => resource.resource_id === folder_assignment.resource_id)
					if (matches_resource) return true
					const matches_lesson = unit.lessons.some(lesson => lesson.lesson_id == folder_assignment.resource_id)
					if (matches_lesson) return true
					return false
				})

				// Step 4: Add files to the item arrays
				valid_folder_assignments.forEach(folder_assignment => {
					if (folder_assignment.parent_folder_id) {
						// This file has a parent folder, so push it to the parent's item array
						const is_lesson = folder_assignment.type == 'lesson'
						const resource_title = is_lesson
							? unit.lessons.find(l => l.lesson_id == folder_assignment.resource_id)?.lesson_title ?? "n/a"
							: unit.resources.find(r => r.resource_id == folder_assignment.resource_id)?.description ?? "n/a"

						// TODO: This might break if there was the same item in multiple folders
						// Perhaps need to concat folder id?
						const item_identifier = `item_${folder_assignment.resource_id}`
						const resource_identifier = `resource_${folder_assignment.resource_id}`

						lookup[folder_assignment.parent_folder_id].item.push({ "@identifier": item_identifier, "@identifierref": resource_identifier, title: resource_title, seq: folder_assignment.seq })
					}
				})

				// Step 5: Sort the item arrays based on the seq property, then delete that property as we don't want it in the xml
				for (const folder of Object.values(lookup)) {
					folder.item.sort((a, b) => a.seq - b.seq)
					folder.item.forEach(item => delete item.seq)
				}
				delete (root.seq)
				items.item.push(root)
			})
			return items
		},

		treeview_source() {
			// We need to transform the item_tree from a TCC friendly structure to a Vuetify treeview friendly structure
			const transform_node = (node) => {
				return {
					id: node['@identifier'],
					name: node['@identifier'] !== 'root' ? node.title : this.collection.title,
					children: (node.item || []).map(transform_node),
				}
			}
			return [transform_node(this.item_tree)]
		},

		selected_item_tree() {
			// Filter item tree based on treeview selections
			const filter_node = (node) => {
				const filtered_children = node.item ? node.item.map(filter_node).filter(Boolean) : []
				if (!this.selected_items.includes(node['@identifier']) && filtered_children.length === 0) return null
				return {
					'@identifier': node['@identifier'],
					'title': node.title,
					'item': filtered_children
				}
			}
			const selected_item_tree = filter_node(this.item_tree)
			if (!selected_item_tree) return { '@identifier': 'root', 'item': [] }
			return selected_item_tree
		},

		selected_resource_list() {
			// Filters resource_list based on selected_items
			return this.resource_list.filter(resource =>
				this.selected_items.includes(resource['@identifier'].replace('resource_', 'item_'))
			)
		},

		resource_list() {
			return this.collection.units.reduce((resources, unit) => {
				const unit_resources = unit.resources.map(resource => {
					const is_activity = resource.type === 'sparkl'
					const url = this.url_from_resource(resource)
					const use_lti_link = is_activity && this.lti_toggle
					// LTI links and Web Links have unique @type attributes
					const type_attribute = use_lti_link ? 'imsbasiclti_xmlv1p3' : 'imswl_xmlv1p3'
					let resource_object = {
						"@identifier": `resource_${resource.resource_id}`,
						"@type": type_attribute,
					}

					// Here we need to determine if the resource node gets a weblink or an lti link, and append to the resource object accordingly
					if (use_lti_link) {
						resource_object["lticc:cartridge_basiclti_link"] = {
							"blti:title": "Velocity",
							"blti:description": resource.description,
							"blti:launch_url": url
							// Question: Do we need to include blti:vendor here?
						}
					} else {
						resource_object["wl:webLink"] = {
							"wl:title": resource.description,
							"wl:url": {
								"@href": url,
							},
						}
					}
					return resource_object
				})

				const unit_lessons = unit.lessons.map(lesson => ({
					"@identifier": `resource_${lesson.lesson_id}`,
					"@type": "imswl_xmlv1p3",
					"wl:webLink": {
						"wl:title": lesson.lesson_title,
						"wl:url": {
							"@href": this.url_from_lesson(lesson),
						},
					},
				}))

				return resources.concat(unit_resources, unit_lessons)
			}, [])
		},

		manifest_organization() {
			// The <organizations> node of TCC contains the hierarchy of all folders/subfolders and resources
			const organization = {
				'organization': {
					'@identifier': 'TOC',
					'@structure': 'rooted-hierarchy',
					'item': this.selected_item_tree,
				}
			}

			return organization
		},

		manifest() {
			return {
				manifest: {
					// manifest root attributes
					'@identifier': `manifest_${this.manifest_uuid}`,
					'@xmlns': 'http://www.imsglobal.org/xsd/imsccv1p3/imscp_v1p1',
					'@xmlns:lomr': 'http://ltsc.ieee.org/xsd/imsccv1p3/LOM/resource',
					'@xmlns:lomm': 'http://ltsc.ieee.org/xsd/imsccv1p3/LOM/manifest',
					'@xmlns:wl': 'http://www.imsglobal.org/xsd/imsccv1p3/imswl_v1p3',
					'@xmlns:lticc': 'http://www.imsglobal.org/xsd/imslticc_v1p3',
					'@xmlns:blti': 'http://www.imsglobal.org/xsd/imsbasiclti_v1p0',
					'@xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
					'@xsi:schemaLocation': "http://www.imsglobal.org/xsd/imsccv1p3/imscp_v1p1 http://www.imsglobal.org/profile/cc/ccv1p3/ccv1p3_imscp_v1p2_v1p0.xsd http://ltsc.ieee.org/xsd/imsccv1p3/LOM/resource http://www.imsglobal.org/profile/cc/ccv1p3/LOM/ccv1p3_lomresource_v1p0.xsd http://ltsc.ieee.org/xsd/imsccv1p3/LOM/manifest http://www.imsglobal.org/profile/cc/ccv1p3/LOM/ccv1p3_lommanifest_v1p0.xsd http://www.imsglobal.org/xsd/imsccv1p3/imscsmd_v1p0 http://www.imsglobal.org/profile/cc/ccv1p3/ccv1p3_imscsmd_v1p0.xsd http://www.imsglobal.org/xsd/imsccv1p3/imswl_v1p3 http://www.imsglobal.org/profile/cc/ccv1p3/ccv1p3_imswl_v1p3.xsd http://www.imsglobal.org/xsd/imslticc_v1p3 http://www.imsglobal.org/xsd/lti/ltiv1p3/imslticc_v1p3.xsd http://www.imsglobal.org/xsd/imsbasiclti_v1p0 http://www.imsglobal.org/xsd/lti/ltiv1p0/imsbasiclti_v1p0p1.xsd",
					// manifest nodes
					'metadata': this.manifest_metadata,
					'organizations': this.manifest_organization,
					'resources': {
						'resource': this.selected_resource_list
					}
				}
			}
		},
		thin_common_cartridge_xml() {
			return create(this.manifest, { encoding: 'UTF-8' }).end({ pretty: true })
		}
	},
	mounted() {
		this.selected_items = this.treeview_source.map(item => item.id)
	},
	methods: {
		url_from_lesson(lesson) {
			return `${window.location.origin}/lesson/${lesson.lesson_id}`
		},
		url_from_resource(resource) {
			const url = resource.url
			switch (resource.type) {
				case "website":
				case "video":
				case "equella":
				case "interactive":
				case "document":
					return url
				case "upload":
				case "html":
					// Adapted from full_url method in resources.js
					if (url.includes("https:")) return url
					return `${window.location.origin}/user-files/${url}`
				case "sparkl":
					return `${this.site_config.sparkl_origin}/${url}`
				default:
					return url
			}
		},
		export_cartridge() {
			const zip = new JSZip()
			zip.file("imsmanifest.xml", this.thin_common_cartridge_xml)

			zip.generateAsync({ type: "blob" })
				.then(blob => {
					const url = URL.createObjectURL(blob)
					const link = document.createElement('a')
					link.href = url
					link.download = 'manifest.imscc'
					document.body.appendChild(link)
					link.click()
					document.body.removeChild(link)
					this.$emit('dialog_cancel')
				})
		}
	},
}
</script>

<styles lang="scss"></styles>
