<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
<v-app v-show="app_mode!='uninitialized'">
	<img id="k-bgd-img" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" alt="">
	<v-content>
		<LoginView ref="login_view" v-if="app_mode=='login'"/>
		<MainView ref="main_view" v-if="app_mode=='main'"/>
		<ResourceLinkView ref="reslink_view" v-if="app_mode=='reslink'"/>
		<IssueReport v-if="issue_params" :item="issue_params.item" :learning_progression="issue_params.learning_progression" :lp_unit="issue_params.lp_unit" @dialog_cancel="issue_params=null" />
		<SatchelInline ref="satchel" />
		<SparklEmbed ref="sparkl_embed" />
		<!-- <GoogleEmbed ref="google_embed" /> -->
		<MathLiveEditor v-if="math_live_editor_original_latex!==null" :original_latex="math_live_editor_original_latex" @dialog_cancel="math_live_editor_original_latex=null" @mathlive_cancel="math_live_editor_save_fn(null)" @mathlive_save="math_live_editor_save_fn($event)" />
		<LessonReport v-if="lesson_report_id!==null" :lesson_report_id="lesson_report_id"  @dialog_cancel="lesson_report_id=null" />
	</v-content>
</v-app>
</template>

<script>
import LoginView from './components/login/LoginView'
import MainView from './components/main/MainView'
import ResourceLinkView from './components/resources/ResourceLinkView'
import SatchelInline from './components/utilities/SatchelInline'
import SparklEmbed from './components/resources/SparklEmbed'
import GoogleEmbed from './components/resources/GoogleEmbed'
import IssueReport from './components/resources/IssueReport'
import LessonReport from './components/lessons/LessonReport'
import { mapState, mapGetters } from 'vuex'

import ela_subject_img from './images/subject-images/ela.jpg'
import math_subject_img from './images/subject-images/math.jpg'
import science_subject_img from './images/subject-images/science.jpg'
import social_studies_subject_img from './images/subject-images/social-studies.jpg'

import pe_subject_img from './images/subject-images/pe.jpg'
import health_subject_img from './images/subject-images/health.jpg'
import compsci_subject_img from './images/subject-images/compsci.jpg'
import languages_subject_img from './images/subject-images/languages.jpg'

import dance_subject_img from './images/subject-images/dance.jpg'
import theatre_subject_img from './images/subject-images/theatre.jpg'
import music_subject_img from './images/subject-images/music.jpg'
import visual_arts_subject_img from './images/subject-images/visual-arts.jpg'
// import media_arts_subject_img from './images/subject-images/media-arts.jpg'

import ctae_subject_img from './images/subject-images/ctae.jpg'
import training_subject_img from './images/subject-images/training.jpg'
import misc_subject_img from './images/subject-images/misc.jpg'

// import misc_subject_img from './images/miscsubject.png'

import MathLiveEditor from './components/mathlive/MathLiveEditor'

export default {
	name: 'App',
	components: { LoginView, MainView, ResourceLinkView, SparklEmbed, GoogleEmbed, SatchelInline, IssueReport, MathLiveEditor, LessonReport },
	data() { return {
		app_mode: 'uninitialized',
		now: Math.round(new Date().getTime() / 1000),
		yesterday: this.now - (24*60*60),
		reslink_resource_id: '',
		lesson_report_id: null,

		subject_img: {
			'Language Arts': ela_subject_img,
			'English Language Arts': ela_subject_img,
			'Mathematics': math_subject_img,
			'Science': science_subject_img,
			'Social Studies': social_studies_subject_img,
			'Physical Education': pe_subject_img,
			'Health': health_subject_img,
			'Computer Science': compsci_subject_img,
			'World Languages': languages_subject_img,
			'English Language Development': misc_subject_img,
			'Fine Arts: Dance': dance_subject_img,
			'Fine Arts: Dramatic Arts/Theatre': theatre_subject_img,
			'Fine Arts: Music': music_subject_img,
			'Fine Arts: Visual Art': visual_arts_subject_img,
			'CTAE': ctae_subject_img,
			'Training Courses': training_subject_img,

			'misc': misc_subject_img,
		},

		math_live_editor_original_latex: null,
		math_live_editor_save_fn: null,

		issue_params: null,

		ping_timeout: null,
		ping_timeout_time: 60 * 1000 * 10,	// every 10 minutes
	}},
	computed: {
		...mapState(['site_config', 'user_info', 'lp_showing', 'framework_records', 'all_courses']),
		...mapGetters(['signed_in']),
		role() { return this.user_info.role },
		welcome_section_showing: {
			get() { return this.$store.state.lst.welcome_section_showing },
			set(val) { this.$store.commit('lst_set', ['welcome_section_showing', val]) }
		},
	},
	watch:{
		'$route.fullPath': {
			handler() {
				// Use this watcher to keep track of LP's the user has viewed; this allows us to control edit locking and also refresh any LP that gets updated after being initially viewed
				let course_code_showing = 0
				if (this.$route.fullPath.search(/(collection)\/([\w\.]+)\b/) > -1) {
					course_code_showing = RegExp.$2
				}

				// console.log('course_code_showing: ' + course_code_showing)

				// any time the route changes, make sure the sparkl and google activities, if there, are hidden
				if (this.$refs.sparkl_embed) {
					this.$refs.sparkl_embed.hide_activity()
				}
				if (this.$refs.google_embed) {
					this.$refs.google_embed.close_btn_clicked()
				}

				// if lp_showing hasn't changed, we don't have to do anything
				if (course_code_showing === this.lp_showing) return
				// note: if course_code_showing is now 0 and lp_showing is > 0, it means we've left a course.  we could do something here when this happens...

				// else set lp_showing
				this.$store.commit('set', ['lp_showing', course_code_showing])
			}, deep:true, immediate:true
		},
		app_mode() {
			// move google translate button when in login mode
			if (this.app_mode == 'login') {
				$('#google_translate_element_wrapper').css({
					'background-color': '#999',
					'padding': '4px',
					// 'bottom': '40px',
					'border': '1px solid #999',
					'opacity': '1',
					// 'left': 'calc(50vw - 90px)',
					// // 'top': 'calc(50vh + 140px)',
					// 'top': 'calc(50vh + 66px)',
					// 'bottom': 'auto',
				})

			} else {
				$('#google_translate_element_wrapper').css({
					'background-color': 'transparent',
					'padding': '0',
					'border': '0',
					'opacity': '',
					// 'left': '',
					// 'top': '',
					// 'bottom': '',
				})
			}
		},
	},
	created() {
		window.vapp = this

		console.warn('Cureum, copyright 2024 by Common Good Learning Tools')

		// determine right away if we're viewing a single item
		if (window.location.pathname.search(/\/(activity|lesson|resource)\/(\d+)/) > -1) {
			// stores, e.g., ['activity', 98]
			this.$store.commit('set', ['single_item', [RegExp.$1, RegExp.$2]])
		}

		// we could call MathLive.renderMathInDocument here, before we even show anything. This injects the styles into the dom, so that when we later inject mathml into the dom, it will render properly
		// MathLive.renderMathInDocument()
		// instead, for now at least, we have a hard-coded version of mathlive-core that we inject here, so that we can also inject this in PrintItems
		U.inject_mathlive_styles()

		// check_session as quickly as possible
		this.check_session()

		// update now and yesterday every X seconds
		setInterval(()=>{
			// console.log('update now')
			this.update_now()
		}, 10000)
	},
	mounted() {
		this.initialize_app({})
	},
	methods: {
		go_to_home(new_section, event) {
			if (!empty(event) && !empty(event.target)) $(event.target).closest('button').blur()

			// just go to the corresponding route; the route.path watcher will set welcome_section_showing accordingly
			if (new_section == 'classes') this.$router.push({ path: '/courses' })
			else if (new_section == 'resourcerepos') this.$router.push({ path: '/repos' })
			else if (new_section == 'mycollections') this.$router.push({ path: '/mycollections' })
			else if (new_section == 'pd') this.$router.push({ path: '/pd' })
			else if (new_section == 'home') this.$router.push({ path: '/home' })
			else this.$router.push({ path: '/' })
		},

		check_session() {
			// initialize local_storage settings
			this.$store.commit('lst_initialize')

			// for OIDC login, when the application first loads after the user has signed in, we should get a session_id in the search string of window.location
			// for cureum login, we handle this in store->initialize_app
			// if we *don't* find a session_id here...
			if (window.location.search.search(/session_id=(.*)\b/) == -1) {
				// look for the session_id in localstorage, where we would have stored it the previous time the user signed in
				U.session_id = U.local_storage_get('gaconnects_session_id', '')

				// the session_id will be passed through to initialize_app, and we'll get the user's user_info back if the session is still active.

			} else {
				// else we *did* find a session_id in the search string, so...

				// save session_id in U.session_id
				U.session_id = RegExp.$1
				console.log('found session_id: ' + U.session_id)

				// store the session_id in localstorage
				U.local_storage_set('gaconnects_session_id', U.session_id)

				// look for a pathname stored previously; if not found we'll go to path '/'
				let pathname = U.local_storage_get('gaconnects_pathname_after_login', '/')

				// clear the stored pathname out if there, then go to the route
				U.local_storage_clear('gaconnects_pathname_after_login')

				console.log('restoring to pathname ' + pathname)
				this.$router.replace({ path: pathname })
			}
		},

		// this will be called by the sign-in btn
		redirect_to_login(flag) {
			if (this.site_config.login_method == 'cureum') {
				this.app_mode = 'login'

				// if we get 'create_account' flag, immediately show the create account dialog
				if (flag == 'create_account') setTimeout(x=>vapp.login_view_component.show_create_account_dialog = true)

			} else {	// oidc
				// remember the pathname we're trying to get to in localstorage, so we can restore to it below
				U.local_storage_set('gaconnects_pathname_after_login', window.location.pathname)

				window.document.location.replace('/src/oidclogin.php')
			}
		},

		initialize_app(payload) {
			let params = (new URL(document.location)).searchParams
			// token login
			if (params.get('email') != undefined && params.get('token') != undefined) {
				payload.email = params.get('email')
				payload.token = params.get('token')
			}

			if (params.get('saml_attributes') != undefined) {
				//console.log('--- saml attributes for initialize_app')
				//console.log(params.get('saml_attributes'))

				payload.saml_login_claims = params.get('saml_attributes')
				payload.use_saml_login = true

				// clear saml login params
				U.clear_location_search()
			}

			U.loading_start()
			this.$store.dispatch('initialize_app', payload).then((mode)=>{
				U.loading_stop()
				// after a tick for everything to get set up, set app_mode to show the app
				this.$nextTick(()=>{
					$('body').removeClass('k-body-uninitialized')

					this.show_bgd_image()

					if (mode == 'login') {
						this.app_mode = mode

					// resource link pass-through
					} else if (document.location.pathname.search(/\/reslink\/(.*)/) == 0) {
						// console.warn('here - signed_in: ' + this.signed_in)
						// if signed in, pass through to the resource; otherwise make the user sign in
						if (this.signed_in) {
							// store resource_id in this.reslink for the reslink component to access; for an lti resource the component will get the lti form and launch
							this.reslink_resource_id = RegExp.$1
							this.app_mode = 'reslink'
						} else {
							// add to the start of the login_msg
							let login_msg = this.$store.state.login_msg
							if (!empty(login_msg)) login_msg = '<br><br>' + login_msg
							login_msg = `Please sign in to ${this.site_config.app_name} to view the specified resource.` + login_msg
							this.$store.commit('set', ['login_msg', login_msg])
							this.app_mode = 'login'
						}
						return

					} else {
						// welcome_section_showing: we have to be careful with this because the user can switch between roles...
						if (this.role == 'parent') {
							// have parents always start in family_home mode (at least for now)
							this.welcome_section_showing = 'family_home'
						} else if (!this.welcome_section_showing) {
							// by default go to classes for everyone else
							this.welcome_section_showing = 'classes'
						} else if (this.welcome_section_showing == 'family_home') {
							// if stored value is family_home but we're no longer in the parent role, go to classes
							if (this.role != 'parent') this.welcome_section_showing = 'classes'
						} else if (this.welcome_section_showing == 'admin') {
							// if stored value is admin but we're no longer in the admin role, go to classes
							if (this.role != 'admin') this.welcome_section_showing = 'classes'
						}

						// for now, at least, let's just always call get_classes as soon as we log in
						this.$store.dispatch('get_classes').then(()=>{
							this.$nextTick(()=>{
								this.app_mode = mode
							})
						})

						// start pinging
						this.ping()
					}

					// http://localhost:7048?lesson_report=822b99a9-0e0e-4117-9a4c-5ad37a5891f9
					let matches = document.location.search.match(/lesson_report=([-a-zA-Z0-9]+)/)
					if (matches) {
						this.lesson_report_id = matches[1]
						// note that if the user clicks to move to somewhere else in HC, the search string will disappear
					}
				})
			})
		},

		update_now() {
			this.now = Math.round(new Date().getTime() / 1000)
			this.yesterday = this.now - (24*60*60)
		},

		has_admin_right(right) {

			// if user is viewing in parent or student role, you can't do anything admin-related
			if (this.role == 'student' || this.role == 'parent') return false

			// central fn for determining if the user has the given admin right
			let ar = this.user_info.admin_rights

			// su users have rights to everything
			if (ar.find(x=>x=='su') || this.user_info.system_role == 'admin') return true

			// for lp.x.x, 'lp.level.all' gives rights to edit any LP
			if (right.search(/^lp\b/) == 0 && ar.find(x=>x=='lp.level.all')) return true

			// for view_lp.x.x, 'view_lp.level.all' gives rights to edit any LP
			if (right.search(/^view_lp\b/) == 0 && ar.find(x=>x=='view_lp.level.all')) return true

			// for pd_rep (pd reports) rights...
			if (right.indexOf('pd_rep') == 0) {
				// 'pd_rep.all' gives rights to view reports for any pd division/school
				if (ar.find(x=>x=='pd_rep.all')) return true

				// principals/assistant principals can see reports for their school(s)
				if (this.$store.getters.user_is_principal_or_ap) {
					// console.log('is principal!')
					if (right == 'pd_rep.any') return true
					// check for a particular school, which will use the guid
					let guid = right.substr(7)
					if (U.is_uuid(guid)) {
						let school = this.$store.state.todo_user_group_schools[guid]
						if (school && this.user_info.district_department.includes(school)) {
							// console.log('found school ' + school)
							return true
						}
					}
				}

				// if 'pd_rep.any' was sent in, return whether the user has rights to view reports for *any* divisions/schools
				if (right == 'pd_rep.any' && ar.find(x=>x.indexOf('pd_rep.') == 0)) return true
			}

			if (ar.find(x=>x==right)) return true

			return false
		},

		has_specific_admin_right(right) {
			// central fn for determining if the user has the given admin right, not taking into account sudo rights or "all" rights
			let ar = this.user_info.admin_rights

			if (ar.find(x=>x==right)) return true

			return false
		},

		// some utilities
		// color_from_number(n) {
		// 	n = n * 1
		// 	if (isNaN(n)) return 'k-list-color-0'
		// 	n = (n % 16) + 1
		// 	return 'k-list-color-'
		// },

		// color_from_string(s) {
		// 	if (empty(s)) return 'k-list-color-0'
		// 	s = s + ''
		// 	let n = 2
		// 	for (let i = 0; i < s.length; ++i) {
		// 		n += s.charCodeAt(i)
		// 	}
		// 	return this.color_from_number(n)
		// },

		open_resource_link(resource, el) {
			// if we're in the context of a froala editor, `resource` will be the resource_id, and we will have received the dom object for the link in the editor
			if (el) {
				// send a message to the editor's parent_component to show the ResourceEditor dialog
				let fco = U.get_froala_component(el)
				if (fco.parent_component) {
					let froala_resource_link_id = $(el).attr('data-resource-link-id')
					let resource_description = $(el).attr('title')
					fco.parent_component.froala_inserted_resource_clicked(fco, resource, resource_description, froala_resource_link_id)
					return
				}
			}

			// if we received a resource object, we may be able to open without pinging the server
			if (typeof(resource) == 'object') {
				if (resource.has_openable_url) {
					window.open(resource.full_url(), '_blank')
					return
				}

				// else set resource to the resource_id and fall through below to ping the server for the lti form or to find out what to do
				resource = resource.resource_id
			}

			let payload = {resource_id: resource, get_lti_form: 'yes'}
			this.$store.dispatch('get_resource_record', payload).then(result=>{
				// console.log(result)

				// if we got back an lti_form, launch
				if (!empty(result.lti_form)) {
					// for the lti_form we open a new window and write out the form, which submits itself
					// see https://developer.mozilla.org/en-US/docs/Web/API/Window/open
					let w = window.open()
					w.document.write(result.lti_form)
				} else {
					// else we just open the new window to the resource's url
					resource = new Resource(result.resource_record)
					window.open(resource.full_url(), '_blank')
				}
			})
		},

		show_bgd_image(index) {
			let filenames = this.$store.state.site_config.site_bgd_images

			// method for remembering background image
			// if (empty(index)) {
			// 	index = U.local_storage_get('district_portal_bgd_image_index', 0)
			// }
			//
			// if (index == -1) {
			// 	while (index == -1 || index == U.local_storage_get('district_portal_bgd_image_index', 0)) {
			// 		index = U.random_int(filenames.length)
			// 	}
			// }
			//
			// U.local_storage_set('district_portal_bgd_image_index', index)

			if (empty(index)) {
				index = U.random_int(filenames.length)
			}

			// this is another way of setting the bgd image.
			// it's easier, but with this if the window is bigger than the image, you get a gray bar on the sides or top
			// $('html').css('background', sr("url('/bgd-imgs/$1') no-repeat center center fixed", filenames[index]))

			// SF: Removing a prefix here, we can just store the path info in the config strings.
			// This is a bit easier because GaDOE Inspire will keep the images in /vue-cli/public/bg-imgs,
			// and everywhere else they will be in /src/bgd-imgs
			let url = sr(filenames[index])
			console.log(url)

			// Set the new image
			$("#k-bgd-img").attr('src', url);

			// set resize event if not already set
			if (empty(this.resize_bgd_image_evt)) {
				this.resize_bgd_image_evt = $(window).on('resize', ()=>{ this.resize_bgd_image() })
			}

			// call resize_bgd_image every 100 ms for 15 seconds, to make it resizes properly after the image loads
			this.resize_bgd_image_counter = 0
			clearInterval(this.resize_bgd_image_interval)
			this.resize_bgd_image_interval = setInterval(()=>{
				this.resize_bgd_image()
				if (this.resize_bgd_image_counter > 150) clearInterval(this.resize_bgd_image_interval)
				++this.resize_bgd_image_counter
			}, 100)
		},

		change_academic_year() {
			// academic year options are specified in config.php and passed in initialize_app
			let options = []
			for (let ay of this.$store.state.available_academic_years) {
				options.push({ value:ay+'', text:((ay) + '-' + (ay*1+1)) })
			}

			this.$prompt({
				title: 'Academic Year',
				text: '<div class="mb-1">Select the academic year for which you’d like to view courses.</div>',
				promptType: 'select',		// default is 'text'
				selectOptions: options,
				initialValue: this.user_info.academic_year,
				acceptText: 'Select',
			}).then(year => {
				// dispatch change_academic_year, which will change the session data and reload
				this.$store.dispatch('change_academic_year', year)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		change_password() {
			this.$prompt({
				title: 'Change Password',
				text: 'Enter the new password you would like to use for your <nobr>' + this.$store.state.site_config.app_name + '</nobr> account:',
				password: true,
				acceptText: 'Use this password',
			}).then(password => {
				if (!empty(password)) {
					this.$prompt({
						title: 'Confirm New Password',
						text: 'Please confirm the new password you just entered:',
						password: true,
						acceptText: 'Confirm and Save new password',
					}).then(new_password => {
						if (password != new_password) {
							this.$alert('The two passwords you entered do not match!').then(x=>this.change_password())
							return
						}

						let payload = {
							user_id: this.user_info.user_id,
							password: password,
						}

						U.loading_start()
						U.ajax('change_password', payload, result=>{
							U.loading_stop()
							if (result.status != 'ok') {
								console.log('Error in admin ajax call'); vapp.ping(); return;
							}

							this.$alert({title:'Success!', text:'Password changed.'})
						});

					}).catch(n=>{console.log(n)}).finally(f=>{})
				}
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		simulate_sandbox_role(email) {
			// if email is '', we return to the user's actual role
			this.$store.commit('lst_set', ['simulated_user', email])

			// clear some lst values and reload to home screen 
			this.$store.commit('lst_set', ['unsigned_index_view_flavor', 'index'])
			this.$store.commit('lst_set', ['course_index_view_flavor', 'favorites'])
			this.$store.commit('lst_set', ['my_index_view_flavor', 'index'])
			this.$store.commit('lst_set', ['repo_index_view_flavor', 'index'])
			this.$store.commit('lst_set', ['courseindex_opened_category', null])
			this.$store.commit('lst_set', ['courseindex_opened_subcats', {}])
			this.$store.commit('lst_set', ['collections_opened_folders', {}])
			this.$store.commit('lst_set', ['unit_mode', 'resources'])
			this.$store.commit('lst_set', ['last_collections_viewed', []])
			this.$store.commit('lst_set', ['selected_resource_search_types', [0,1,2]])
			this.$store.commit('lst_set', ['selected_resource_filter', 'none'])

			window.location.replace('/home')
		},

		// central place to show the issue reporting interface; components should call vapp.report_issue(issue_params)
		report_issue(issue_params) {
			// console.log('report_issue', issue_params)
			this.issue_params = issue_params
		},

		resize_bgd_image() {
			let $bg = $("#k-bgd-img")
			let bg_w = $bg.width()
			let bg_h = $bg.height()
			let win_w = $(window).width()
			let win_h = $(window).height()

			// console.log(sr('$1 / $2', bg_w, bg_h))

			// Determine whether width or height should be 100%; shift image left or up to compensate for difference in img/window width/height
			let left, top
			if ((win_w / win_h) < (bg_w / bg_h)) {
				left = '-' + Math.round((bg_w - win_w) / 2) + 'px'
				top = '0'
				$bg.css({height: '100%', width: 'auto', left:left, top:top});
			} else {
				left = '0'
				top = '-' + Math.round((bg_h - win_h) / 2) + 'px'
				$bg.css({width: '100%', height: 'auto', left:left, top:top});
			}
		},

		show_math_live_editor(original_latex, save_fn) {
			this.math_live_editor_original_latex = original_latex
			this.math_live_editor_save_fn = save_fn

			// call like this:
			// vapp.show_math_live_editor('\\sqrt{x}', new_latex => { })
		},

		share_item(item_noun, shared_item_id, flag) {
			let msg, title
			
			if (flag == 'district') {
				title = `Import ${item_noun} to HenryConnects`
				msg = sr('<div class="mb-2">To import this $1 to HenryConnects, start by copying the following $1 ID:</div>', item_noun)
				msg += sr('<div class="mb-2 text-center" style="font-size:22px"><b>$1</b></div>', shared_item_id)
				msg += sr('<div style="font-size:14px; line-height:18px;">You can import this $1 to HenryConnects by navigating to a course unit, clicking the <nobr>“+ CREATE” button,</nobr> choosing “<b><i class="fas fa-share-nodes"></i> Import Lesson Plan or Activity from GaDOE Inspire</b>”, and entering this $1 ID.</div>', item_noun)

			} else if (flag == 'copy') {
				title = 'Copy ' + item_noun

				let start = `To copy this ${item_noun}`
				if (item_noun != 'resource') start += ', and edit it if you wish'

				msg = `<div class="mb-2">${start}, start by copying the following “Import ID”:</div>`
				msg += `<div class="mb-2 text-center" style="font-size:22px"><b>${shared_item_id}</b></div>`
				msg += `<div class="mb-2">Then:</div>`
				msg += `<ul>
						<li>Navigate to the place you wish to place the ${item_noun} copy<br>(for example, you could put the copy in your “My Content Sandbox”).</li>
						<li>Click the “+ ADD CONTENT” button.</li>
						<li>Choose the “Import SHARED” option, click “${item_noun.toUpperCase()}”, and enter the Import ID.</li>
					</ul>`

			} else {
				title = 'Share ' + item_noun
				// msg = sr('<div class="mb-2">To share this $1, give the following $1 ID to a colleague:</div>', item_noun)
				// msg += sr('<div class="mb-2 text-center" style="font-size:22px"><b>$1</b></div>', shared_item_id)
				// msg += sr('<div style="font-size:14px; line-height:18px;">Your colleague can import this $1 by clicking the “+ CREATE / IMPORT” button from the “My Content” area of a course or $2, choosing “<i class="fas fa-share-nodes"></i> Import a shared Lesson or Activity”, and entering this $1 ID.</div>', item_noun, this.site_config.app_name)

				// msg = `<div class="mb-2">The Import ID for this ${item_noun} is:</div>`
				msg = `<div class="mb-2">To share this ${item_noun}, give the following Import ID to a colleague:</div>`
				msg += `<div class="mb-2 text-center" style="font-size:22px"><b>${shared_item_id}</b></div>`
				msg += `<div class="mb-2">To use this Import ID, your colleague should:</div>`
				msg += `<ul>
						<li>Navigate to the place in ${this.site_config.app_name} where they wish to place the ${item_noun} copy<br>(for example, they could put the copy in their “My Content Sandbox”).</li>
						<li>Click the “+ ADD CONTENT” button.</li>
						<li>Choose the “Import SHARED” option, click “${item_noun.toUpperCase()}”, and enter the Import ID.</li>
					</ul>`
			}

			this.$confirm({
				title: title,
				text: msg,
				cancelText: 'Copy ' + item_noun + ' ID',
				cancelIcon: 'fas fa-clipboard',
				acceptText: '  OK',
				acceptIconAfter: 'fas fa-circle-check',
				dialogMaxWidth: 650,
			}).catch(e=>{
				U.copy_to_clipboard(shared_item_id)
				this.$inform(sr('$1 ID copied to clipboard', item_noun))
			})
		},

		collection_edit_lock_conflict_msg(result, lp_id, code, default_msg) {
			// if we're here the edit lock was probably aquired by someone else during idle period or has been transferred to a different window
			let msg = ''
			if (result.status == 'lock_conflict') {
				// for agency-sanctioned collections, only a system admin can remove edit locks; but for my collections, any editor can remove them
				let clause = ''
				let collection = this.$store.state.all_courses.find(x=>x.lp_id == lp_id)
				if (!collection || collection.agency_sanctioned) {
					clause = `or contact ${this.$store.getters.app_name_with_indefinite_article} system administrator, who can remove the edit lock for you if necessary`
				} else {
					if (collection.user_is_lp_admin()) {
						clause = `or choose “Remove edit lock for this collection” in the “kebab menu” ( <i class="fas fa-ellipsis-v"></i> ) to remove the edit lock`
					} else {
						// note that if you don't have edit rights you probably can't check the collection out, so this clause will probably never be used.
						clause = `or contact an editor for the collection (or a ${this.$store.getters.app_name_with_indefinite_article} system administrator), who can remove the edit lock for you if necessary by choosing “Remove edit lock for this collection” in the “kebab menu” ( <i class="fas fa-ellipsis-v"></i> )`
					}
				}

				msg = `This collection is currently “checked out” for editing by ${result.message}. Please try again later (the edit lock will automatically expire within 30 minutes), ${clause}.`

			} else if (result.status == 'session_conflict') {
				msg = 'You transferred editing to another session/browser window. Please apply these changes in that other window.'

			} else if (result.status == 'updated_at_conflict') {
				// // if we got the no_loader param, don't show this error -- we use this, e.g., when converting gavs activities, and it's not a big deal if the save doesn't happen
				// if (show_loader) vapp.$alert('This collection has been very recently updated, so it can not be saved. Please make note of changes you wish to apply, reload your browser window, then try again')
				msg = 'This collection has been very recently updated, so your changes could not be saved. Please make note of changes you wish to apply, reload your browser window, then try again.'

			} else {
				msg = default_msg ?? 'A problem occurred while attempting to save the collection.'
			}

			msg += ` (${code})`
			vapp.$alert(msg)
		},

		// determine if the user's session is still active; if not, show a message and call the sign out process
		ping() {
			// only ping if the user was signed in before
			if (!this.signed_in) return

			// we want to automatically call this fn every ping_timeout_time ms. if it's manually called by something else, we reset the timeout
			clearTimeout(this.ping_timeout)

			let sign_user_out = () => {
				let msg = 'You are not signed in. You may have been automatically signed out due to inactivity.'
				this.$alert({
				    text: msg,
				}).finally(f=>{
					this.$store.dispatch('sign_out')
				})
			}

			// use plain-vanilla XMLHttpRequest to call the ping service
			var xhr = new XMLHttpRequest()
			xhr.onreadystatechange = function() {
			    if (xhr.readyState === 4) {		// fetch operation is done
					if (xhr.responseText == 'ok') {
						console.log('ping OK')
						// set the timeout to re-call this fn after ping_timeout_time
						vapp.ping_timeout = setTimeout(()=>{ vapp.ping() }, vapp.ping_timeout_time)

					} else {
						sign_user_out()
					}
				}
			}
			xhr.open('GET', '/src/ping.php')
			xhr.send()
		},

		toggle_google_translate_btn(fn) {	// 'show' or 'hide'
			$('#google_translate_element_wrapper')[fn]()
		},
		enter_subscription_code() {
			this.$prompt({
				title: 'Subscribe to a Content Collection',
				text: 'Enter the “Subscription Code” for the content collection you’d like to subscribe to: (This code should have the format “XXXXX”.)',
				initialValue: '',
				disableForEmptyValue: true,
				acceptText: 'Check Code',
				acceptIconAfter: 'fas fa-arrow-right',
				dialogMaxWidth: 480,
			}).then(subscription_code => {
				subscription_code = $.trim(subscription_code)

				this.$store.dispatch('subscribe_to_collection', subscription_code).then((result) => {
					// submit subscription_code to service to find the LP corresponding to the code (if it exists); then show the user the title of the collection, and confirm that they want to subscribe
					if (result.course_code) {
						this.$confirm({
							title: 'Are you sure?',
							text: `Are you sure you want to subscribe to collection “${result.title}?”`,
							acceptText: 'I’m Sure',
						}).then(y => {
							const lp = new Learning_Progression(result)
							const payload = {
								user_id: this.user_info.user_id,
								course_code: lp.course_code,
								recipient_email: this.user_info.email,
								grant_edit_rights: 'false',
								from_subscription: true
							}
							this.$store.dispatch('share_learning_progression', payload).then((result) => {
								this.$store.commit('add_to_array', [this.all_courses, lp])
								this.$store.commit('add_to_array', [this.user_info.admin_rights, result.rights])
							})
						}).catch(n => console.log(n))
					}
					else {
						vapp.$alert('The subscription code you entered does not exist.')
					}
				}).catch(n => { console.log(n) })
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		show_llm_lesson_plan_description() {
			this.$alert({
				title: 'Lesson Plan Companion<i class="fas fa-dog k-lpe-dog-icon mr-2" style="margin-top:-5px"></i>(BETA)',
				text: 'The Lesson Plan Companion is a teacher driven tool that leverages a large language model to offer suggestions based on a learning designer’s inputs like standards, key concepts, vocabulary, misconceptions, ideas for differentiation, and more. In this workflow, choose to skip, add, modify or reject suggestions to best meet the needs of your learners. The result of the collaboration can be a lesson plan, a starter template for student activity, or a combination of both.',
				dialogMaxWidth: 600,
				acceptText: ' Got It',
				acceptIconAfter: 'fas fa-arrow-right',
			})
		}
	}
}
</script>

<style lang="scss">
html {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

#k-bgd-img {
	position: fixed;
	z-index:-1;
	left: 0;
	top: 0;
}

body {
	font-size:18px;
}

.k-body-uninitialized {
	background-color:#fff;
	.spinner-wrapper {
		display:none;
	}
}

.v-application {
	font-family: $sans-serif-font;
	background-color:transparent!important;
}

.k-shadow-text {
	color:#fff;
	text-shadow: 2px 2px 8px #000;
}

// Google translate functionality:
// fix the translate menu in the window
#google_translate_element_wrapper { 
	text-align:center;
	// z-index:10000000;
	z-index:190;

	// position:fixed; 
	// top:15px; 
	// left: calc(50vw - 90px);
	left:8px;
	min-width:180px;
	position:absolute;
	bottom:9px;
	border-radius:8px;
	opacity:0.4;

	.goog-te-gadget-simple {
		border-radius:4px;
	}
}

#google_translate_element_wrapper:hover {
	opacity:1;
}

// When another language is selected, Google tries to show a bar at the top of the page; hide it
.skiptranslate iframe.skiptranslate {
	display: none !important;
} 
body {
	// google tries to set the top property of body; this has to be overridden
	top: 0px !important; 
}

</style>
