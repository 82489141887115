U.framework_color = function(identifier) {
	let framework_record = vapp.$store.state.framework_records.find(x=>x.lsdoc_identifier == identifier)


	// if we don't have a framework record (which shouldn't happen), or single_color_scheme_on is true (which could well happen), or the framework color is 14, then use always use color '0', which is grey
	if (empty(framework_record) || vapp.single_color_scheme_on || framework_record.ss_framework_data.color === '14') return 'k-framework-color-0'

	return 'k-framework-color-' + framework_record.ss_framework_data.color
}

// unfortunately, an item's type can be in either CFItemType or CFItemTypeURI.title
U.item_type = function(item) {
	let type = item.CFItemType ? item.CFItemType : (item.CFItemTypeURI ? item.CFItemTypeURI.title : '')

	// normalize from "Standard" to "Content Standard" (??)
	// if (type == 'Standard') type = 'Content Standard'

	return type
}

U.remove_line_breaks_from_text_field = function(s) {
	if (empty(s) || typeof(s) != 'string') return s

	// remove line breaks from text fields, unless they look like they might be the starts of markup lines
	let lines = s.split('\n')
	s = ''
	for (let line of lines) {
		let trim_line = $.trim(line)

		// pass completely blank lines through as double-\n's, to preserve markdown line breaks
		if (!trim_line) {
			s += '\n\n'
			continue
		}

		// if the first non-space char in the line isn't one of the characters that have special significance in a markdown line,
		if (trim_line.search(/^[-*+#_>\d]/) == -1) {
			// if s currently doesn't end with a line break, add a space to separate this line's text from the previous line's text
			if (s.search(/\n$/) == -1) s += ' '

			// then add the trimmed line without a line break
			s += trim_line

		} else {
			// else it's supposed to start a new line, so add the non-trimmed line, preceded by a line break
			s += '\n' + line
		}
	}

	// now replace two or more spaces with one space
	s = s.replace(/  +/g, ' ')
	// and three or more line breaks with two line breaks
	s = s.replace(/\n\n\n+/g, '\n\n')

	return $.trim(s)
}

// convert educationLevel array to a string (e.g. '9-12'), using `grades` array as the ordered list of possible educationLevels
U.grade_level_display = function(educationLevel, grades, separator) {
	if (empty(educationLevel) || educationLevel.length == 0) return ''

	let s = ''

	// if grades not supplied, use vapp.$store.state.grades
	if (!grades) grades = vapp.$store.state.grades

	let el = educationLevel[0]
	let grade = grades.find(g => { return (g.value == el || ( !isNaN(el*1) && (g.value*1 == el*1) )) })
	if (!empty(grade)) s = grade.text
	else s = el

	if (educationLevel.length == 1) return s

	// caller can optionally send in a separator; by default we just use a plain hyphen
	s += (separator) ? separator : '-'

	el = educationLevel[educationLevel.length - 1]
	grade = grades.find(g => { return (g.value == el || ( !isNaN(el*1) && (g.value*1 == el*1) )) })
	if (!empty(grade)) s += grade.text
	else s += el

	return s
}

// convert from grade_level_display format back to an educationLevel array
U.grade_level_display_to_educationLevel = function(gld, grades, separator) {
	if (empty(gld)) return []

	// caller can optionally send in a separator; by default assume a plain hyphen
	if (!separator) separator = '-'

	// if grades not supplied, use vapp.$store.state.grades
	if (!grades) grades = vapp.$store.state.grades

	let arr = gld.split(separator)
	let lo = $.trim(arr[0])
	let hi = $.trim(arr[1])

	// convert 'K' to 'KG'
	if (lo == 'K') lo = 'KG'
	if (hi == 'K') hi = 'KG'

	let lo_index = grades.findIndex(x=>x.value == lo)
	let hi_index = grades.findIndex(x=>x.value == hi)

	// if we didn't get a lo_index, nothing to parse
	if (lo_index == -1) return []

	// if we have no hi value, it's a single grade
	if (!hi_index) {
		return [grades[lo_index].value]
	}

	// if we get to here process range
	if (lo_index > hi_index) {
		let temp = lo_index
		lo_index = hi_index
		hi_index = temp
	}

	arr = []
	for (let i = lo_index; i <= hi_index; ++i) {
		arr.push(grades[i].value)
	}

	return arr
}

U.case_uri_html = function(o) {
	return sr('$1 (<a href="$2">$3</a>)', o.title, o.uri, o.identifier)
}

U.case_array_html = function(arr) {
	return arr.join(', ')
}

U.case_array_of_uris_html = function(arr) {
	let a2 = []
	for (let uri of arr) {
		a2.push(U.case_uri_html(uri))
	}
	return U.case_array_html(a2)
}

U.case_field_value_html = function(field, val) {
	if ($.isArray(val)) {
		if (field.indexOf('URI') > -1) {
			return U.case_array_of_uris_html(val)
		} else {
			return U.case_array_html(val)
		}
	} else {
		if (field.indexOf('URI') > -1) {
			return U.case_uri_html(val)
		} else {
			return val
		}
	}
}

U.case_current_time_string = function() {
	// current time, GMT, in format for lastChangeDateTime
	return date.format(new Date(), 'YYYY-MM-DDTHH:mm:ss+00:00', true)
}

U.local_last_change_date_time = function(s) {
	// convert given lastChangeDateTime string to the local time zone, and format nicely
	return date.format(new Date(s), 'YYYY-MM-DD HH:mm')
}

U.get_license_json = function(framework_record) {
	if (framework_record.json.CFDocument.licenseURI) {
		// look for the license in the framework's CFDefinitions.CFLicenses area
		let licenses = oprop(framework_record.json, 'CFDefinitions', 'CFLicenses')
		if (licenses && licenses[0]) {
			return licenses[0]
			// return value should include 'title' and licenseText
		}
	}
	return {}
}

U.generate_document_uri = function(doc) {
	return new LinkURI({
		title: doc.title,
		identifier: doc.identifier,
		uri: doc.uri,
	})
}

U.generate_child_uri = function(doc, identifier) {
	// sub the document identifier for the incoming identifier in the document's uri
	// so, e.g., `https://case.georgiastandards.org/uri/00fcf0e2-b9c3-11e7-a4ad-47f36833e889` becomes `https://case.georgiastandards.org/uri/00ad9d88-192b-11eb-8deb-0242ac130004`
	return doc.uri.replace(new RegExp(doc.identifier), identifier)
}

// generate the best value for the "title" field of a destinationNodeURI or originNodeURI for a CFAssociation; the incoming item could be either a CFItem or a CFDocument
// this is also used in other situations for generating the corresponding value
U.generate_cfassociation_node_uri_title = function(item, length) {
	// if length === true, it means to generate the full title (for a document) or hcs+fullstatement
	if (length === true) {
		if (!empty(item.title)) return item.title
		if (!empty(item.humanCodingScheme)) return item.humanCodingScheme + ' ' + item.fullStatement
		return item.fullStatement
	}

	let s = ''
	if (!empty(item.humanCodingScheme)) s = item.humanCodingScheme + ' '
	if (!empty(item.fullStatement)) s += item.fullStatement
	if (!empty(item.title)) s = item.title
	if (empty(s)) s = '???'
	s = $.trim(s)

	// if length is false or isn't set, use default 20 characters
	if (!length || typeof(length) != 'number') length = 20
	// use max length characters, to keep file sizes small
	if (s.length > length) s = s.substr(0,length) + '…'
	return s
}

U.create_ischildof_association = function(parent_cfitem, child_cfitem, sequenceNumber, cfdocument) {
	// note that:
	//     for xNodeURI titles, we use the humanCodingScheme (if there) or the first X chars of the fullStatement
	//     the origin (of "isChildOf") is the child (the item we're saving)
	//     the destination is the child's parent

	let association_identifier = U.new_uuid()
	let date_string = new Date().toISOString().replace(/\.\d+Z/, '+00:00')	// 2020-12-17T01:49:46+00:00
	let dest_title = U.generate_cfassociation_node_uri_title(parent_cfitem)
	let child_title = U.generate_cfassociation_node_uri_title(child_cfitem)

	let a = new CFAssociation({
		originNodeURI: {
			title: child_title,
			identifier: child_cfitem.identifier,
			uri: child_cfitem.uri
		},

		associationType: "isChildOf",

		destinationNodeURI: {
			title: dest_title,
			identifier: parent_cfitem.identifier,
			uri: parent_cfitem.uri
		},
		sequenceNumber: sequenceNumber
	})

	// complete the item, filling in identifier, uri, and lastChangeDateTime
	a.complete_data(cfdocument)

	return a
}

// the following three functions are used when deleting items (including new items that have been temporarily added when creating new items)
U.delete_node_from_cfo = function(cfo, node, preserve_cfitem) {
	let cfitem = node.cfitem

	// the deleted item's parent may exist in multiple places, so deal with it in all such parent nodes
	// for each parent_node ...
	let parent_nodes = node.parent_node.cfitem.tree_nodes
	for (let parent_node of parent_nodes) {
		// get the index of the child node with this node's cfitem (it should always be there)
		let index = parent_node.children.findIndex(x=>x.cfitem.identifier == cfitem.identifier)
		if (index > -1) {
			let this_child = parent_node.children[index]

			// splice the child node out of the parent_node's children
			vapp.$store.commit('set', [parent_node.children, 'SPLICE', index])

			// and remove the child node from the cfitem's tree_nodes array
			let index2 = cfitem.tree_nodes.findIndex(x=>x.tree_key == this_child.tree_key)
			if (index2 > -1) {
				vapp.$store.commit('set', [cfitem.tree_nodes, 'SPLICE', index2])
			}

			// remove from cfo.tree_nodes_hash
			vapp.$store.commit('set', [cfo.tree_nodes_hash, this_child.tree_key+'', '*DELETE_FROM_STORE*'])
		}
	}

	// if preserve_cfitem isn't true...
	if (preserve_cfitem !== true) {
		// if the cfitem has no tree_nodes left, remove from cfitems
		if (cfitem.tree_nodes.length == 0) {
			// remove from cfitems
			vapp.$store.commit('set', [cfo.cfitems, cfitem.identifier, '*DELETE_FROM_STORE*'])
		}
	}
}

U.delete_item_from_json = function(framework_record, identifier) {
	let i = framework_record.json.CFItems.findIndex(x=>x.identifier == identifier)
	if (i > -1) {
		vapp.$store.commit('set', [framework_record.json.CFItems, 'SPLICE', i])
	}
}

U.delete_association_from_json = function(framework_record, identifier) {
	let i = framework_record.json.CFAssociations.findIndex(x=>x.identifier == identifier)
	if (i > -1) {
		vapp.$store.commit('set', [framework_record.json.CFAssociations, 'SPLICE', i])
	}
}

U.update_associations_hash = function(framework_record, assoc) {
	let associations_hash = framework_record.cfo.associations_hash

	// if an array for the destination identifier's associations in the associations_hash doesn't already exist, create the array
	if (!associations_hash[assoc.destinationNodeURI.identifier]) {
		vapp.$store.commit('set', [associations_hash, assoc.destinationNodeURI.identifier, []])
	}
	// then push the assocation onto the array
	vapp.$store.commit('set', [associations_hash[assoc.destinationNodeURI.identifier], 'PUSH', assoc])

	// repeat for the origin identifier
	if (!associations_hash[assoc.originNodeURI.identifier]) {
		vapp.$store.commit('set', [associations_hash, assoc.originNodeURI.identifier, []])
	}
	vapp.$store.commit('set', [associations_hash[assoc.originNodeURI.identifier], 'PUSH', assoc])
}

U.remove_from_associations_hash = function(framework_record, assoc) {
	let associations_hash = framework_record.cfo.associations_hash

	// if an array for the destination identifier's associations exists,
	let hash = associations_hash[assoc.destinationNodeURI.identifier]
	if (hash) {
		// look for the given assoc in the array, and splice from array if found
		let index = hash.findIndex(x=>x.identifier == assoc.identifier)
		if (index > -1) {
			vapp.$store.commit('set', [hash, 'SPLICE', index])
		}
	}

	// repeat for the origin identifier
	hash = associations_hash[assoc.originNodeURI.identifier]
	if (hash) {
		// look for the given assoc in the array, and splice from array if found
		let index = hash.findIndex(x=>x.identifier == assoc.identifier)
		if (index > -1) {
			vapp.$store.commit('set', [hash, 'SPLICE', index])
		}
	}
}


U.find_cfassociation_index = function(cfassociations, child_identifier, parent_identifier) {
	return cfassociations.findIndex(x=>
		x.associationType == 'isChildOf' &&
		x.originNodeURI.identifier == child_identifier &&
		x.destinationNodeURI.identifier == parent_identifier
	)
}

U.count_descendents = function(node) {
	let ct = node.children.length
	for (let child of node.children) {
		ct += U.count_descendents(child)
	}
	return ct
}

U.get_next_sequenceNumber = function(parent_node, framework_record) {
	// determine the sequenceNumber to use for a new item being placed at the end of a parent node's children:
	// (the sequenceNumber of the parent's last child) + 1 -- or 1 if the parent has no children
	let child_count = parent_node.children.length
	if (child_count == 0) {
		return 1

	} else {
		let last_child_identifier = parent_node.children[child_count-1].cfitem.identifier
		let parent_identifier = parent_node.cfitem.identifier
		let assoc = framework_record.json.CFAssociations.find(x=>x.destinationNodeURI.identifier == parent_identifier && x.originNodeURI.identifier == last_child_identifier)
		// if we can't find a sequenceNumber for some reason, use child_count + 1
		if (empty(assoc) || empty(assoc.sequenceNumber)) return child_count + 1
		else return assoc.sequenceNumber*1 + 1
	}
}

// a `framework_record` is an object that includes the raw CASE json for the framework along with some additional non-CASE data about the framework
U.create_framework_record = function(lsdoc_identifier, case_json, ss_framework_data, framework_json_loaded) {
	// make sure case_json has the five top-level components (it must definitionally have a CFDocument already)
	if (empty(case_json.CFItems)) case_json.CFItems = []
	if (empty(case_json.CFAssociations)) case_json.CFAssociations = []
	if (empty(case_json.CFDefinitions)) case_json.CFDefinitions = {}
	if (empty(case_json.CFRubrics)) case_json.CFRubrics = []

	let framework_record = {
		lsdoc_identifier: lsdoc_identifier,		// if this is empty, it means it's a new framework (not yet saved)
		ss_framework_data: new SSFrameworkData(ss_framework_data, lsdoc_identifier),	// this can be empty coming in; if so default values will be set
		framework_json_loaded: (framework_json_loaded ? true: false),		// if this is null/false, it means we haven't actually tried to load the full framework json from the server
		json: case_json,
		exemplar_json: {},
		exemplar_hash: {},
		cfo: null,
		fw_changes: null,	// this may be used to expose item changes in the tree view
		open_nodes: {},		// nodes (items with children) that are currently open (i.e. their children are viewable); this will be a hash of tree_keys
		pinned_items: [],	// items (identified by tree_keys) that are currently "pinned"
		active_node: '',	// node (tree_key) whose tile is currently viewed in the active tile slot
		last_clicked_node: '',	// node last clicked by the user
		chosen_node: '',	// node (tree_key) selected via the 'show_chooser' interface
		featured_node: '',	// node (tree_key) that's currently “featured” for special processing; see e.g. MakeAssociationsAssistantMixin
		hovered_tile: '', 	// pinned tile that the user is currently hovering over
		checked_items: {},	// items checked; used for batch update and other things; this will be a hash of tree_keys
		clear_checkboxes_trigger: false,	// used to clear checkboxes in CASEItem, when not in viewer mode
		search_terms: '',
		document_identifier_showing_in_tree: lsdoc_identifier,
	}
	return framework_record
}

U.framework_record_load_json = function(framework_record, case_json) {
	// make sure case_json has the five top-level components (it must definitionally have a CFDocument already)
	if (empty(case_json.CFItems)) case_json.CFItems = []
	if (empty(case_json.CFAssociations)) case_json.CFAssociations = []
	if (empty(case_json.CFDefinitions)) case_json.CFDefinitions = {}
	if (empty(case_json.CFRubrics)) case_json.CFRubrics = []

	vapp.$store.commit('set', [framework_record, 'json', case_json])
	vapp.$store.commit('set', [framework_record, 'framework_json_loaded', true])
}

U.cfitem_stats = {
	is_duplicate_of: null,
	possible_duplicate_ofs: null,
	possible_related_to: null,
	add_is_child_of_to_duplicate: null,
	to_be_deleted: null,
	duplicate_hovered: null,
	level: null,
	descendent_count: null,
	duplicate_count: 0,	// (only counted for document)
	items_removed: null,
	change: null,
}

U.create_cfo_cfitem = function(original_object) {
	return $.extend(true, original_object, {
		tree_nodes: [],
		stats: U.cfitem_stats
	})
}

U.create_cfo_node = function(cfo, new_cfitem, parent_node, sequence) {
	return {
		tree_key: cfo.next_tree_key,
		cfitem: new_cfitem,
		parent_node: parent_node,
		children: [],
		sequence: sequence,
	}
	// note: while node.sequence will usually be set to CFAssociation.sequenceNumber to start, this correspondence is not guaranteed to remain true
}

U.add_child_to_cfo = function(cfitem_data, parent_node, $store, cfo, sequence, children, recursing) {
	// see if the cfitem already exists
	let cfitem = cfo.cfitems[cfitem_data.identifier]

	// if it doesn't already exist, create a cfo cfitem (with extra data added)
	if (empty(cfitem)) {
		cfitem = U.create_cfo_cfitem(cfitem_data)

		// add the cfitem to the cfo's cfitems hash, then get a reference to the now-reactive cfitem
		$store.commit('set', [cfo.cfitems, cfitem.identifier, cfitem])
		cfitem = cfo.cfitems[cfitem.identifier]
	}

	// if sequence is not provided, add to the end of any children already in the parent_node
	if (sequence == null) {
		// setting sequence value to children.length will make it be spliced onto the end of the list, even if the list was formerly empty
		sequence = parent_node.children.length
	}

	// the new item's parent may exist in multiple places, so add the item's node to all such parent nodes, unless recursing is true, in which we only do the parent_node
	let parent_nodes
	if (recursing === true) parent_nodes = [parent_node]
	else parent_nodes = parent_node.cfitem.tree_nodes

	let returned_node
	for (let pn of parent_nodes) {
		// create a node
		let new_node = U.create_cfo_node(cfo, cfitem, parent_node, sequence)

		// add node to tree_nodes_hash
		$store.commit('set', [cfo.tree_nodes_hash, new_node.tree_key+'', new_node])

		// store tree_node in cfitem's tree_nodes array, then increment cfo.next_tree_key
		$store.commit('set', [cfitem.tree_nodes, 'PUSH', new_node])
		$store.commit('set', [cfo, 'next_tree_key', cfo.next_tree_key+1])

		// splice new_node into the parent's children (unless a sequence value was explicitly provided [which happens when an item is moved], we'll splice onto the end of the array)
		$store.commit('set', [pn.children, 'SPLICE', sequence, 0, new_node])

		// we want to return the child of the specified parent node, so stash it when we get to it
		if (pn == parent_node) returned_node = new_node

		// if the added node has children, we have to add them (and any grandchildren) as well.
		// Rather than searching for children here, we add children if they are provided as a param (the param should be an array of nodes)
		if (!empty(children) && children.length > 0) {
			for (let child_node of children) {
				// console.log('recurse for children!', children.length, child_node.cfitem.fullStatement)
				// send true as the last argument so that in the recursion, we only add to the returned_node
				U.add_child_to_cfo(child_node.cfitem, new_node, $store, cfo, child_node.sequence, child_node.children.concat([]), true)
				// console.log('back after recurse: children.length = ' + children.length)
			}
		}
	}

	// return the new_node
	return returned_node
}

// a `cfo` (“CASE Framework Object”) is a structure that starts with the CASE json and adds additional data used to show the framework as a tree structure.
// in the cfo, we have "cfitems"; these objects include some of the data from the original json's CFDocument and CFItems; but additional data is added in
// whenever we want to refer to the original json, we should use the capitalized versions of the names (e.g. "CFDocument")
U.build_cfo = function($worker, case_json) {
	return new Promise((resolve, reject)=>{
		U.loading_start('Processing CASE data …')

		let ts = new Date().getTime()
		$worker.run((json, cfitem_stats) => {
			// note that the incoming json object will be copied when it comes into the worker
			let cfo = {}

			// prepare to create the cftree, by copying in the cfdocument with added fields
			let cfdocument = json.CFDocument
			cfdocument.tree_nodes = []
			cfdocument.stats = cfitem_stats

			// put the CFItems into a hash, to make it easy to pull the data out [also keep an array of items in "top-to-bottom/left-to-right" order in the tree??]
			// also tabulate a list of item types used in the framework; used, e.g., in the search interface
			cfo.cfitems = {}
			cfo.item_types = []
			let item_type_hash = {}
			for (let i = 0; i < json.CFItems.length; ++i) {
				let item = json.CFItems[i]
				// add tree_nodes array for use below; also add stats
				item.tree_nodes = []
				item.stats = cfitem_stats

				cfo.cfitems[item.identifier] = item

				// update item_types
				let item_type = item.CFItemType ? item.CFItemType : (item.CFItemTypeURI ? item.CFItemTypeURI.title : '')
				// normalize from "Standard" to "Content Standard" (??)
				if (item_type == 'Standard') item_type = 'Content Standard'
				if (item_type && !item_type_hash[item_type]) {
					cfo.item_types.push(item_type)
					item_type_hash[item_type] = true
				}
			}

			// sort the item_types
			cfo.item_types.sort()

			// also put the nodes in a hash
			cfo.tree_nodes_hash = {}

			cfo.next_tree_key = 1

			// create temporary hash of isChildOf associations to use when building the tree structure; for a huge framework like CTAE (31 MB), speeds up tree-building from ~30 seconds to .75 seconds
			var is_child_of_hash = {}
			var associations_hash = {}
			// also find associated documents, and build hash of other associations for use when showing associations in the tree
			var associated_documents = []
			for (var i = 0; i < json.CFAssociations.length; ++i) {
				var a = json.CFAssociations[i]
				// isChildOf associations...
				if (a.associationType == 'isChildOf') {
					if (!is_child_of_hash[a.destinationNodeURI.identifier]) is_child_of_hash[a.destinationNodeURI.identifier] = []
					is_child_of_hash[a.destinationNodeURI.identifier].push({
						child_identifier: a.originNodeURI.identifier,
						sequence: a.sequenceNumber
					})

				// other associations...
				} else {
					// if framework A includes an association between an item in framework A and framework B, we include a document A -> isRelatedTo -> document B association,
					//     to mark the fact that framework A needs to look at framework B for the items in framework B
					//     if A includes an association between an item in B and an item in C, we include an A -> isRelatedTo -> B association and an A -> isRelatedTo -> C association
					// so if the originNodeURI.identifier is the document and the associationType is isRelatedTo, it marks an associated document
					if (a.originNodeURI.identifier == json.CFDocument.identifier && a.associationType == 'isRelatedTo') {
						if (!associated_documents.find(x=>x==a.destinationNodeURI.identifier)) {
							// we can derive the basics of the document CASE json -- the identifier, title, and URI -- from the destinationNodeURI
							associated_documents.push({
								identifier: a.destinationNodeURI.identifier,
								uri: a.destinationNodeURI.uri,
								title: a.destinationNodeURI.title,
							})
						}

					} else {
						// else put in associations_hash; here we want to be able to easily access this association via either the destination or origin identifier
						if (!associations_hash[a.destinationNodeURI.identifier]) associations_hash[a.destinationNodeURI.identifier] = []
						associations_hash[a.destinationNodeURI.identifier].push(a)

						if (!associations_hash[a.originNodeURI.identifier]) associations_hash[a.originNodeURI.identifier] = []
						associations_hash[a.originNodeURI.identifier].push(a)

						// see U.update_associations_hash for how we should be updating this when new associations are created
					}
				}
			}
			cfo.associated_documents = associated_documents
			cfo.associations_hash = associations_hash

			var item_type_levels = {}

			/////////////////// construct tree structure using recursive function
			var max_level = 0
			var build_node = function(cfitem, parent_node, sequence, level) {
				// build up a record of the tree “levels” for each item type, used to color items
				if (!level) level = 0
				if (level > max_level) max_level = level
				let item_type = cfitem.CFItemType ? cfitem.CFItemType : (cfitem.CFItemTypeURI ? cfitem.CFItemTypeURI.title : '')
				if (item_type) {
					if (!item_type_levels[item_type]) {
						item_type_levels[item_type] = {sum: 0, count: 0}
					}
					item_type_levels[item_type].sum += level
					item_type_levels[item_type].count += 1

					// For debugging purposes if needed
					// if (item_type == 'Cluster') {
					// 	console.log('Cluster: ' + level + ' ' + cfitem.fullStatement)
					// }
				}

				var node = {
					tree_key: cfo.next_tree_key,
					cfitem: cfitem,
					parent_node: parent_node,
					children: [],
					sequence: sequence,
					flashing: false,	// used to flash the node in the tree, e.g. when making an association
					sim_score: -1,		// this and the next three values are used for the "associations assistant"
					sim_score_tooltip: '',
					sim_score_highlighted: false,
					cat: '',	// current association type
				}

				// add new node to tree_nodes_hash
				cfo.tree_nodes_hash[node.tree_key] = node

				// store tree_node in cfitem, then increment cfo.next_tree_key for the next one
				cfitem.tree_nodes.push(node)
				++cfo.next_tree_key

				// recursively process children
				if (is_child_of_hash[node.cfitem.identifier]) {
					for (var i = 0; i < is_child_of_hash[node.cfitem.identifier].length; ++i) {
						node.children.push(build_node(cfo.cfitems[is_child_of_hash[node.cfitem.identifier][i].child_identifier], node, is_child_of_hash[node.cfitem.identifier][i].sequence, level + 1))
					}
				}

				// order children by sequence. Note that the sequenceNumbers come from the *associations*, and that not all nodes have sequenceNumbers
				node.children.sort((a,b)=>{
					if (a.sequence!=null && b.sequence!=null) return a.sequence - b.sequence
					if (a.sequence!=null && b.sequence==null) return -1
					if (b.sequence!=null && a.sequence==null) return 1
					return 0
				})

				// Note: once we use the sequence values to sort above, we don't actually use them again

				return node
			}
			/////////////////// end of recursive fn
			cfo.cftree = build_node(cfdocument)
			console.log('max_level: ' + max_level)

			// process item type level data
			cfo.item_type_levels = []
			for (var item_type in item_type_levels) {
				cfo.item_type_levels.push({
					item_type: item_type,
					sum: item_type_levels[item_type].sum,
					count: item_type_levels[item_type].count,
					avg_level_raw: item_type_levels[item_type].sum / item_type_levels[item_type].count,
				})
			}
			cfo.item_type_levels.sort((a,b) => {
				if (a.avg_level_raw != b.avg_level_raw) return a.avg_level_raw - b.avg_level_raw
				else return (a.item_type < b.item_type) ? -1 : 1
			})

			// if we have 8 or fewer levels (which will almost always be the case), just use a different color for each one
			// (this data is used in CASEItem, where a level-specific class is applied)
			var last_index = -1
			var last_rounded_level = -1
			for (var i = 0; i < cfo.item_type_levels.length; ++i) {
				if (cfo.item_type_levels.length <= 8) {
					cfo.item_type_levels[i].level_index = i
				} else {
					// if necessary use this algorithm, which attempts to get a good spread of colors, with the constraint that there are only so many distinguishable colors we can use
					let rounded_level = Math.round(cfo.item_type_levels[i].avg_level_raw * 2)
					if (rounded_level != last_rounded_level) {
						++last_index
					}
					cfo.item_type_levels[i].level_index = last_index
					last_rounded_level = rounded_level
				}
			}

			return cfo

		}, [case_json, U.cfitem_stats])	// this is where we pass the original case_json into the fn
		.then((cfo)=>{
			U.loading_stop()
			let time_elapsed = (new Date().getTime() - ts) / 1000
			console.log('time elapsed processing tree (s): ' + time_elapsed)
			console.log($.extend(true, {}, cfo.item_type_levels))
			resolve(cfo)
		})
		.catch((e)=>{
			U.loading_stop()
			console.log('ERROR RUNNING BUILD_CFO', e)
			reject({})
		})
	})
}

U.process_cfo_stats = function($worker, original_cfo) {
	return new Promise((resolve, reject)=>{
		U.loading_start('Processing framework stats …')
		$worker.run((cfo) => {
			// first process nodes to count descendents and get *possible* duplicates
			var processed_items = []
			// duplicate_count is the number of duplicate items (only calculated for the document)
			var duplicate_count = 0

			function process_node_1(level, this_node) {
				var this_item = this_node.cfitem

				// for some reason this gets screwed up of we assign directly to this_item.stats
				var stats = Object.assign({}, this_item.stats)
				stats.level = level
				stats.possible_duplicate_ofs = []

				if (level != 0) {
					// for comparison purposes, make versions of fullStatement and hcs that are a) lowercase; b) space-normalized; c) trimmed; periods removed
					stats.fullStatement_lc = (this_item.fullStatement.toLowerCase().replace(/\.+/g, ' ').replace(/\s+/g, ' ')).replace(/^\s*(.*?)\s*$/, '$1')
					stats.humanCodingScheme_lc = (this_item.humanCodingScheme) ? (this_item.humanCodingScheme.toLowerCase().replace(/\.+/g, ' ').replace(/\s+/g, ' ')).replace(/^\s*(.*?)\s*$/, '$1') : ''

					for (var i = 0; i < processed_items.length; ++i) {
						var other_item = processed_items[i]

						// if hcs and fullstatement match...
						if (other_item.stats.humanCodingScheme_lc == stats.humanCodingScheme_lc && other_item.stats.fullStatement_lc == stats.fullStatement_lc) {
							// if the other_item is actually a duplicate of this item
							if (other_item.identifier == this_item.identifier) {
								// record the duplicate -- but only the first one
								if (!stats.is_duplicate_of) {
									stats.is_duplicate_of = other_item.identifier
								}
								// but always increment duplicate_count
								duplicate_count += 1

								// and for items that are already duplicates of other items, never suggest alternative duplicates
								stats.possible_duplicate_ofs = []
								break

							// else the other_item isn't already a duplicate of this item
							} else {
								// push to possible_duplicate_ofs
								stats.possible_duplicate_ofs.push(other_item.identifier)
							}
						}
					}
				}

				// note that descendent_count includes the parent plus its children
				stats.descendent_count = 1
				for (var j = 0; j < this_node.children.length; ++j) {
					var c = this_node.children[j]
					process_node_1(level+1, c)
					stats.descendent_count += c.cfitem.stats.descendent_count
				}

				this_node.cfitem.stats = stats
				// if (stats.possible_duplicate_ofs.length > 0) {
				// 	console.log('possible_duplicate_ofs: ' + this_node.cfitem.fullStatement.substr(0,15) + ' /' + stats.possible_duplicate_ofs.length)
				// }

				// push this after we process the children, because definitionally this item's children can't be duplicates of this item
				processed_items.push(this_node.cfitem)
			}
			process_node_1(0, cfo.cftree)

			// record duplicate_count for top-level cftree item (the document)
			cfo.cftree.cfitem.stats.duplicate_count = duplicate_count

			// define two more recursive functions
			function mark_item_and_children_as_deleted(this_node, pd_node) {
				// we need to know what item is being subbed for this item, so that we can re-map associations to the item if necessary
				this_node.cfitem.stats.to_be_deleted = pd_node.cfitem.identifier
				for (var z = 0; z < this_node.children.length; ++z) {
					mark_item_and_children_as_deleted(this_node.children[z], pd_node.children[z])
				}
			}

			function branches_are_duplicates(node1, node2) {
				// ??? assumes that node1 and node2 are themselves possible duplicates
				// if nodes have different numbers of children, they can't be duplicates
				if (node1.children.length != node2.children.length) {
					return false
				}

				// if item2 isn't a possible duplicate of item1, return false; the top-level branches don't match
				if (!node1.cfitem.stats.possible_duplicate_ofs.find(identifier=>identifier==node2.cfitem.identifier)) {
					return false
				}

				// go through each child (if there are any)
				for (var y = 0; y < node1.children.length; ++y) {
					if (!branches_are_duplicates(node1.children[y], node2.children[y])) {
						// if any pair of children aren't duplicates, the whole top-level branches don't match
						return false
					}
				}

				// if we get to here, the branch from here on down matches
				return true
			}

			// then re-process nodes...
			function process_node_2(this_node, possible_duplicate_of_parent) {
				var this_item = this_node.cfitem

				this_item.stats.items_removed = 0

				// if this item is a possible duplicate of at least one other item, check...
				if (this_item.stats.possible_duplicate_ofs.length > 0) {
					for (var i = 0; i < this_item.stats.possible_duplicate_ofs.length; ++i) {
						var possible_duplicate_identifier = this_item.stats.possible_duplicate_ofs[i]

						var pd_node = cfo.cfitems[possible_duplicate_identifier].tree_nodes[0]
						if (branches_are_duplicates(this_node, pd_node)) {
							// if branches_are_duplicates returns true, it's OK to remove this item and add an is_child_of to the duplicate
							this_item.stats.add_is_child_of_to_duplicate = possible_duplicate_identifier
							this_item.stats.items_removed = this_item.stats.descendent_count

							// and mark all children to be *deleted*, because the children will all implicitly be added to the tree structure along with this item
							for (var p = 0; p < this_node.children.length; ++p) {
								mark_item_and_children_as_deleted(this_node.children[p], pd_node.children[p])
							}

							// then return -- we don't have to further process this node's children (if there are any)
							return
						}
					}

					// if we get to here, the item can't be removed, but set possible_related_to to the first possible_duplicate_of
					this_item.stats.possible_related_to = this_item.stats.possible_duplicate_ofs[0]
				}

				// if we get to here, this item can't be duplicated, so recurse through the node's children
				for (var j = 0; j < this_node.children.length; ++j) {
					process_node_2(this_node.children[j])
					this_item.stats.items_removed += this_node.children[j].cfitem.stats.items_removed
				}
			}
			process_node_2(cfo.cftree)

			return cfo

		}, [original_cfo])	// this is where we pass the original cfo into the fn
		.then((cfo)=>{
			U.loading_stop()
			resolve(cfo)
		})
		.catch((e)=>{
			U.loading_stop()
			console.log('ERROR RUNNING process_cfo_stats', e)
			reject(original_cfo)
		})
	})
}

U.process_duplicates = function($worker, ojson, cfitems, case_current_time_string) {
	return new Promise((resolve, reject)=>{
		U.loading_start('Processing duplicate items …')
		$worker.run((ojson, cfitems, case_current_time_string) => {
			// initialize new json file; start with document and definitions, then add shell for items and associations
			var nf = {}
			nf.CFDocument = ojson.CFDocument
			nf.CFDefinitions = ojson.CFDefinitions
			nf.CFItems = []
			nf.CFAssociations = []

			// keep track of deleted_associations for final process below
			let deleted_associations = {}

			// DEBUG: keep track of items that are subbed for other items
			let subbed_identifiers = []

			// go through every item
			for (var j = 0; j < ojson.CFItems.length; ++j) {
				let item = ojson.CFItems[j]
				// get the cfitem stats from cfo_items
				let stats = cfitems[item.identifier].stats

				// if the item is marked as to_be_deleted or add_is_child_of_to_duplicate...
				if (stats.to_be_deleted || stats.add_is_child_of_to_duplicate) {
					let sub_identifier = (stats.to_be_deleted) ? stats.to_be_deleted : stats.add_is_child_of_to_duplicate
					subbed_identifiers.push(sub_identifier)

					// get the item we're going to sub in for this item, along with the originNodeURI from this item's isChildOf assoc, which we use below
					let sub_item = ojson.CFItems.find(o=>o.identifier == sub_identifier)
					let sub_item_isChildOf = ojson.CFAssociations.find(o => o.originNodeURI.identifier == sub_identifier && o.associationType == 'isChildOf')
					if (!sub_item_isChildOf) {
						let to_be_deleted = !stats.add_is_child_of_to_duplicate
						console.log('XXX (' + to_be_deleted + '): ' + sub_item.fullStatement, sub_item)
						continue
					}
					let sub_item_nodeURI = sub_item_isChildOf.originNodeURI

					// go through all associations *from* this item (associations where this item is the originNode)
					let assocs_from = ojson.CFAssociations.filter(o => o.originNodeURI.identifier == item.identifier)
					for (let i = 0; i < assocs_from.length; ++i) {
						let a = assocs_from[i]
						// if we already added the assoc, continue (I don't think this is necessary, but check just in case)
						if (nf.CFAssociations.find(o=>o.identifier==a.identifier)) {
							console.log('already added assoc FROM ' + a.identifier)
							continue
						}

						// don't copy isChildOfs for to_be_deleted items (but do copy other associations for these items)
						if (stats.to_be_deleted) {
							if (a.associationType == 'isChildOf') {
								// console.log('deleting assoc ' + a.identifier)
								deleted_associations[a.identifier] = true
								continue
							}
						}
						// isChildOfs for add_is_child_of_to_duplicate do get copied through, but the originNodeURI is changed to the subbed item

						// make a shallow copy of the association, so we leave ojson alone in case this item is subbing for something else
						let ca = Object.assign({}, a)

						// set originNodeURI to the value from sub_item_isChildOf
						if (ca.originNodeURI.identifier == item.identifier) {
							ca.originNodeURI = Object.assign({}, sub_item_nodeURI)
						}

						// update lastChangeDateTime; leave everything else (e.g. destinationNodeURI, identifier, sequenceNumber) alone; add to CFAssociations
						ca.lastChangeDateTime = case_current_time_string
						nf.CFAssociations.push(ca)
					}

					// now look at non-isChildOf associations *to* these items (associations where this item is the destinationNode)
					let assocs_to = ojson.CFAssociations.filter(o => o.destinationNodeURI.identifier == item.identifier)
					for (let i = 0; i < assocs_to.length; ++i) {
						let a = assocs_to[i]

						// *don't* add isChildOf associations to these items, because if the item is being removed, becuase isChildOf associations are always "internal" (they don't involve outside frameworks),
						// and we've already determined that the item's children can be "copied" into the tree structure as children of the sub_item
						if (a.associationType == 'isChildOf') {
							deleted_associations[a.identifier] = true
							continue
						}

						// if we already added the assoc, continue (I don't think this is necessary, but check just in case)
						if (nf.CFAssociations.find(o=>o.identifier==a.identifier)) {
							console.log(sr('already added assoc TO ' + a.identifier))
							continue
						}

						// make a shallow copy of the association, so we leave ojson alone in case this item is subbing for something else
						let ca = Object.assign({}, a)

						// set destinationNodeURI to the value from sub_item_isChildOf
						if (ca.destinationNodeURI.identifier == item.identifier) {
							ca.originNodeURI = Object.assign({}, sub_item_nodeURI)
						}

						// update lastChangeDateTime; leave everything else (e.g. destinationNodeURI, identifier, sequenceNumber) alone; add to CFAssociations
						ca.lastChangeDateTime = case_current_time_string
						nf.CFAssociations.push(ca)
					}

					// *don't* add these items
					continue
				}

				// for items that are passing through, add the item here; we'll add its associations below
				nf.CFItems.push(item)
			}

			// for all associations we didn't copy above and didn't mark to be deleted (i.e. associations for passed-through items and associations exclusively about outside items), add them to nf.CFAssociations here
			for (let i = 0; i < ojson.CFAssociations.length; ++i) {
				let a = ojson.CFAssociations[i]
				if (!nf.CFAssociations.find(o=>o.identifier == a.identifier) && !deleted_associations[a.identifier]) {
					nf.CFAssociations.push(a)
				}
			}

			return nf

		}, [ojson, cfitems, case_current_time_string])	// this is where we pass the original cfo into the fn
		.then((nf)=>{
			U.loading_stop()
			resolve(nf)
		})
		.catch((e)=>{
			U.loading_stop()
			console.log('ERROR RUNNING process_duplicates', e)
			reject()
		})
	})
}

U.reduce_case_json = function($worker, ojson, fns) {
	return new Promise((resolve, reject)=>{
		U.loading_start()
		console.log(fns)
		$worker.run((json, fns) => {
			// var original_json_length = window.JSON.stringify(json).length
			// var last_json_length = original_json_length

			// var report_text = 'REDUCE JSON: ' + json.CFDocument.title + ' (' + json.CFDocument.identifier + ')'
			// report_text += '\n'
			// report_text += original_json_length.toLocaleString() + ': ' + json.CFItems.length + ' items / ' + json.CFAssociations.length + ' associations'
			// report_text += '\n'
			//
			// function report(operation) {
			// 	return
			// 	var json_length = JSON.stringify(json).length
			//
			// 	var this_reduction = Math.round((last_json_length - json_length) / original_json_length * 10000) / 100
			// 	var cumulative_reduction = Math.round((1 - json_length / original_json_length) * 10000) / 100
			//
			// 	report_text += json_length.toLocaleString() + ' (this: ' + this_reduction + '% / cum: ' + cumulative_reduction + '%): ' + operation
			// 	report_text += '\n'
			//
			// 	last_json_length = json_length
			// }
			function report(operation) {
				console.log(operation)
			}

			var fn_defs = {}

			//////////////////////////////
			// STILL VALID CASE
			fn_defs.remove_ItemTypeURIs = function(json) {
				for (var i = 0; i < json.CFItems.length; ++i) {
					var obj = json.CFItems[i]
					// if we have a CFItemTypeURI get rid of it...
					if (obj.CFItemTypeURI) {
						// I observed in some CTAE items that there was sometimes a discrepency between CFItemType and CFItemTypeURI; if we find such a discrepency, go with CFItemType
						// if we don't have a CFItemType, go with the CFItemTypeURI title
						if (!obj.CFItemType) {
							obj.CFItemType = obj.CFItemTypeURI.title
							delete obj.CFItemTypeURI
						} else {
							// else just delete the CFItemTypeURI
							delete obj.CFItemTypeURI
						}
					}
				}
				report('remove_ItemTypeURIs')
			}

			fn_defs.remove_ConceptKeywordsURIs = function(json) {
				for (var i = 0; i < json.CFItems.length; ++i) {
					var obj = json.CFItems[i]
					if (obj.conceptKeywordsURI) {
						// TODO: for now just delete this; work later on converting if needed
						delete obj.conceptKeywordsURI
					}
				}
				report('remove_ConceptKeywordsURIs')
			}

			fn_defs.shorten_node_titles = function(json, empty) {
				for (var i = 0; i < json.CFAssociations.length; ++i) {
					var obj = json.CFAssociations[i]
					if (empty === true) {
						obj.originNodeURI.title = ''
						obj.destinationNodeURI.title = ''
					} else if (obj.associationType == 'isChildOf') {
						// for isChildOf assocs, shorten to max 20 chars + …
						// (for other assocs, leave full text so we can show it without having to look up the item's framework)
						obj.originNodeURI.title = obj.originNodeURI.title.replace(/^(....................).+/, '$1…')
						obj.destinationNodeURI.title = obj.destinationNodeURI.title.replace(/^(....................).+/, '$1…')
					}
				}
				report('shorten_node_titles')
			}

			fn_defs.remove_empty_properties = function(json) {
				// remove non-required properties that are empty from CFAssociations and CFItems
				for (var i = 0; i < json.CFItems.length; ++i) {
					var obj = json.CFItems[i]

					if (obj.humanCodingScheme && obj.humanCodingScheme === '') delete obj.humanCodingScheme
					if (obj.abbreviatedStatement && obj.abbreviatedStatement === '') delete obj.abbreviatedStatement
					if (obj.notes && obj.notes === '') delete obj.notes

					if (obj.language && obj.language === '') delete obj.language
					if (obj.alternativeLabel && obj.alternativeLabel === '') delete obj.alternativeLabel
					if (obj.listEnumeration && obj.listEnumeration === '') delete obj.listEnumeration
					if (obj.CFItemType && obj.CFItemType === '') delete obj.CFItemType
					if (obj.statusStartDate && obj.statusStartDate === '') delete obj.statusStartDate
					if (obj.statusEndDate && obj.statusEndDate === '') delete obj.statusEndDate

					if (obj.educationLevel && (obj.educationLevel === '' || obj.educationLevel.length == 0)) delete obj.educationLevel
					if (obj.conceptKeywords && (obj.conceptKeywords === '' || obj.conceptKeywords.length == 0)) delete obj.conceptKeywords
					if (obj.CFItemTypeURI && obj.CFItemTypeURI.identifier === '') delete obj.CFItemTypeURI
					if (obj.conceptKeywordsURI && obj.conceptKeywordsURI.identifier === '') delete obj.conceptKeywordsURI
					if (obj.licenseURI && obj.licenseURI.identifier === '') delete obj.licenseURI
				}
				for (var i = 0; i < json.CFAssociations.length; ++i) {
					var obj = json.CFAssociations[i]
					if (obj.CFAssociationGroupingURI && obj.CFAssociationGroupingURI.identifier === '') {
						// TODO: for now we're just deleting empty CFAssociationGroupingURIs; work later on deciding if we can validly delete others
						delete obj.CFAssociationGroupingURI
					}
				}
				report('remove_empty_properties')
			}

			fn_defs.remove_CFDocumentURIs = function(json) {
				for (var i = 0; i < json.CFItems.length; ++i) {
					var obj = json.CFItems[i]
					if (obj.CFDocumentURI) delete obj.CFDocumentURI
				}
				for (var i = 0; i < json.CFAssociations.length; ++i) {
					var obj = json.CFAssociations[i]
					if (obj.CFDocumentURI) delete obj.CFDocumentURI
				}
				report('remove_CFDocumentURIs')
			}

			// note that this should not be run before anything checking CFAssociations
			fn_defs.remove_orphan_items = function(json) {
				// go through all items; any item that (doesn't have an association where it is an origin) -- i.e. (isn't a child of something) -- should be deleted
				// 		also delete all associations where that item is the destination
				// keep doing this until we don't change anything; this removes orphan children, grandchildren, etc.
				var data = {
					CFItems: [],
					CFAssociations: [],
				}

				var ct = json.CFItems.length
				var last_ct, act
				do {
					last_ct = ct
					ct = 0
					// go through all items
					for (var i = json.CFItems.length - 1; i >= 0; --i) {
						var item = json.CFItems[i]

						if (item.delete) continue

						// if this item isn't the origin of an association
						act = 0
						if (json.CFAssociations.findIndex(x=>x.originNodeURI && x.originNodeURI.identifier == item.identifier) == -1) {
							// go through all associations
							for (var j = json.CFAssociations.length - 1; j >= 0; --j) {
								var assoc = json.CFAssociations[j]
								if (assoc.delete) continue
								++act
								// if this assoc's destination is this item, delete the association
								if (assoc.destinationNodeURI.identifier == item.identifier) {
									json.CFAssociations[j] = {identifier:assoc.identifier, delete:'yes'}
									data.CFAssociations.push(json.CFAssociations[j])
								}
							}

							// now mark item for deletion
							json.CFItems[i] = {identifier:item.identifier, delete:'yes'}
							data.CFItems.push(json.CFItems[i])

							var title = item.fullStatement
							if (item.humanCodingScheme) title = item.humanCodingScheme + ' ' + title
							console.log('DEL: ' + item.identifier + ' ' + title)

						} else {
							++ct
						}
					}
				} while (ct != last_ct)

				if (json.CFItems.length == ct) report('remove_orphan_items: no items removed')
				else report('remove_orphan_items: removed ' + (json.CFItems.length - ct) + ' items and ' + (json.CFAssociations.length - act) + ' associations')

				return data
			}

			fn_defs.remove_zero_sequenceNumbers = function(json, empty) {
				let total_update_count = 0
				let zero_update_count = 0
				let empty_update_count = 0
				for (var i = 0; i < json.CFAssociations.length; ++i) {
					var obj = json.CFAssociations[i]
					// if/when we find an isChildOf assoc with sequenceNumber 0, or without sequenceNumbers
					if (obj.associationType == 'isChildOf' && (obj.sequenceNumber*1 === 0 || obj.sequenceNumber === undefined)) {
						if (obj.sequenceNumber === undefined) ++empty_update_count
						else ++zero_update_count

						// make a list of all its siblings
						let sibs = []
						for (var j = 0; j < json.CFAssociations.length; ++j) {
							if (json.CFAssociations[j].associationType == 'isChildOf' && json.CFAssociations[j].destinationNodeURI.identifier == obj.destinationNodeURI.identifier) {
								sibs.push(json.CFAssociations[j])
							}
						}

						// Sort sibs; note that assocs are not guaranteed to have sequenceNumbers
						sibs.sort((a,b)=>{
							if (a.sequenceNumber!=null && b.sequenceNumber!=null) return a.sequenceNumber - b.sequenceNumber
							if (a.sequenceNumber!=null && b.sequenceNumber==null) return -1
							if (b.sequenceNumber!=null && a.sequenceNumber==null) return 1
							return 0
						})

						// set new sequenceNumbers for all sibs, starting at 1
						for (var j = 0; j < sibs.length; ++j) {
							sibs[j].sequenceNumber = j + 1
							++total_update_count
						}
					}
				}
				report('remove_zero_sequenceNumbers: ' + zero_update_count + ' zeros and ' + empty_update_count + ' empties / ' + total_update_count + ' total changed')

				// return total number of fixed sequenceNumbers
				return {
					zero_update_count: zero_update_count,
					empty_update_count: empty_update_count,
					total_update_count: total_update_count,
				}
			}

			//////////////////////////////
			// recoverable from context
			fn_defs.remove_uris = function(json) {
				var doc_uri_stem = json.CFDocument.uri.replace(new RegExp(json.CFDocument.identifier), '')

				for (var i = 0; i < json.CFItems.length; ++i) {
					var obj = json.CFItems[i]
					if (obj.uri.replace(new RegExp(obj.identifier), '') == doc_uri_stem) delete obj.uri
				}
				for (var i = 0; i < json.CFAssociations.length; ++i) {
					var obj = json.CFAssociations[i]
					if (obj.uri.replace(new RegExp(obj.identifier), '') == doc_uri_stem) delete obj.uri
				}
				report('remove_uris')
			}

			fn_defs.remove_licenseURIs = function(json) {
				for (var i = 0; i < json.CFItems.length; ++i) {
					var obj = json.CFItems[i]
					if (obj.licenseURI && obj.licenseURI == json.CFDocument.licenseURI) {
						obj.licenseURI = 'doc'
					}
				}
				report('remove_licenseURIs')
			}

			fn_defs.reduce_nodes = function(json) {
				for (var i = 0; i < json.CFAssociations.length; ++i) {
					var obj = json.CFAssociations[i]
					if (json.CFItems.find(x=>x.identifier == obj.originNodeURI.identifier) || json.CFDocument.identifier == obj.originNodeURI.identifier) {
						obj.originNodeURI = obj.originNodeURI.identifier
					}
					if (json.CFItems.find(x=>x.identifier == obj.destinationNodeURI.identifier) || json.CFDocument.identifier == obj.destinationNodeURI.identifier) {
						obj.destinationNodeURI = obj.destinationNodeURI.identifier
					}
				}
				report('reduce_nodes')
			}

			fn_defs.remove_timezones = function(json) {
				for (var i = 0; i < json.CFItems.length; ++i) {
					var obj = json.CFItems[i]
					obj.lastChangeDateTime = obj.lastChangeDateTime.replace(/\+00:00$/, '')
				}
				for (var i = 0; i < json.CFAssociations.length; ++i) {
					var obj = json.CFAssociations[i]
					obj.lastChangeDateTime = obj.lastChangeDateTime.replace(/\+00:00$/, '')
				}
				report('remove_timezones')
			}

			// note that if you do this before other fns, it will screw the fns up
			fn_defs.reduce_key_lengths = function(json) {
				var item_map = {
					'identifier': 'i',
					'fullStatement': 's',
					'alternativeLabel': 'al',
					'CFItemType': 't',
					'uri': 'u',
					'humanCodingScheme': 'c',
					'listEnumeration': 'le',
					'abbreviatedStatement': 'as',
					'conceptKeywords': 'k',
					'conceptKeywordsURI': 'ku',
					'notes': 'n',
					'language': 'l',
					'educationLevel': 'e',
					'CFItemTypeURI': 'tu',
					'licenseURI': 'lu',
					'statusStartDate': 'ss',
					'statusEndDate': 'se',
					'lastChangeDateTime': 'd',
				}

				var assoc_map = {
					'identifier': 'i',
					'associationType': 't',
					'sequenceNumber': 's',
					'uri': 'u',
					'originNodeURI': 'r',
					'destinationNodeURI': 'e',
					'CFAssociationGroupingURI': 'g',
					'lastChangeDateTime': 'd',
				}

				var assoc_type_map = {
					'isChildOf': 'c',
					'exactMatchOf': 'm',
					'isRelatedTo': 'r',
					'replacedBy': 'b',
					'exemplar': 'e',
					'hasSkillLevel': 's',
					'isPartOf': 'x',
					'isPeerOf': 'y',
					'precedes': 'z',
				}

				for (var i = 0; i < json.CFItems.length; ++i) {
					var obj = json.CFItems[i]
					for (var key in item_map) {
						if (obj[key] !== undefined) {
							obj[item_map[key]] = obj[key]
							delete obj[key]
						}
					}
				}
				for (var i = 0; i < json.CFAssociations.length; ++i) {
					var obj = json.CFAssociations[i]

					// first map associationType, then map keys
					var new_type_key = assoc_type_map[obj.associationType]
					if (new_type_key) obj.associationType = new_type_key

					for (var key in assoc_map) {
						if (obj[key] !== undefined) {
							obj[assoc_map[key]] = obj[key]
							delete obj[key]
						}
					}
				}
				report('reduce_key_lengths')
			}

			// if fns is a string, run that single fn and return the return value (see, e.g., store.delete_framework_items)
			if (typeof(fns) == 'string') {
				return fn_defs[fns](json)
			}

			// do all provided fns, once each
			var fns_run = {}
			var fn_return_vals = {}
			for (var z = 0; z < fns.length; ++z) {
				var fn = fns[z]
				if (fns_run[fn]) continue
				// debugger
				fn_return_vals[fn] = fn_defs[fn](json)
				fns_run[fn] = true
			}

			json.fn_return_vals = fn_return_vals

			return json

		}, [ojson, fns])	// this is where we pass the original data into the fn
		.then((rv)=>{
			U.loading_stop()

			// if fns is a string, run that single fn and return the return value (see, e.g., store.delete_framework_items)
			if (typeof(fns) == 'string') {
				resolve(rv)
				return
			}

			// extract fn_return_vals from rv json prior to doing counts below
			var fn_return_vals = rv.fn_return_vals
			delete rv.fn_return_vals
			console.log('fn_return_vals', fn_return_vals)

			var original_json_length = JSON.stringify(ojson).length
			var final_json_length = JSON.stringify(rv).length
			var cumulative_reduction = Math.round((1 - final_json_length / original_json_length) * 10000) / 100

			console.log('-----------------')
			var report_text = 'REDUCE JSON: ' + ojson.CFDocument.title + ' (' + ojson.CFDocument.identifier + ')'
			report_text += '\n'
			report_text += original_json_length.toLocaleString() + ': ' + ojson.CFItems.length + ' items / ' + ojson.CFAssociations.length + ' associations'
			report_text += '\n'
			report_text += final_json_length.toLocaleString() + ': reduction of ' + cumulative_reduction + '%'
			report_text += '\n'
			console.log(report_text)

			// return json and fn_return_vals
			resolve({json: rv, fn_return_vals: fn_return_vals})
		})
		.catch((e)=>{
			U.loading_stop()
			console.log('ERROR RUNNING reduce_case_json', e)
			reject()
		})
	})
}

U.framework_image_src = function (ss_framework_data) {
	if (!isNaN(ss_framework_data.image * 1)) {
		// if we're using a standard image icon, return it
		let o = U.framework_icons.find(x => x.id == ss_framework_data.image * 1)
		if (!o) o = U.framework_icons[0]
		return o.img
	} else {
		// otherwise return the image value, which should be a data-url
		return ss_framework_data.image
	}
}
