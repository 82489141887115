<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-froala-wrapper-outer">
	<froala :config="config_x" :data-froala_wrapper_id="froala_wrapper_id" ref="froala_component" v-model="model_value"></froala>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	name : "FroalaWrapper",
	// components: { TemplateComponent },
	props: {
		value: { required: false, default() { return null } },
		parameter: { required: false, default() { return '' } },
		parameter_object: { required: false, default() { return null } },
		config: { required: false, default() { return null } },
		parent_component: { required: false, default() { return null } },
	},
	data() { return {
		config_x: {},
		froala_wrapper_id: U.new_uuid(),
		model_value: '',
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
		// this might seem a little hackish, but we have to use this watcher to send updated html to the component includer, so that we can handle math equations properly.
		model_value(val) {
			// console.log('B', val)
			if (empty(val)) val = ''	// make sure val is a string
			
			// replace mathlive spans with latex
			if (val.includes('k-mathlive-span')) {
				let jq = $(`<div>${val}</div>`)
				jq.find('.k-mathlive-span').replaceWith(function(){return '$' + $(this).attr('data-latex') + '$'})
				val = jq.html()
			}

			// remove empty spans (Froala would by default do this for us, but if we let it do it, it screws up "struts" in mathlive
			val = val.replace(/<span[^>]*><\/span>/g, '')

			// console.log('updating model_value', val)

			// we provide multiple options for mapping the model_value returned by the froala editor onto the parent's parameter...
			if (this.parameter_object) {
				this.parameter_object[this.parameter] = val

			} else if (this.parameter) {
				this.$parent[this.parameter] = val

			} else {
				this.$emit('input', val)
				// this.$emit('update:value', val)	// for vue 3, we will need to switch to this, and use "modelValue" instead of "value"
				// https://vuejs.org/guide/components/events.html#usage-with-v-model
			}
		},
	},
	created() {
		// if we're passed an explicit froala config object, use it; otherwise use the default supplied by U.get_froala_config
		if (!this.config) this.config_x = U.get_froala_config({})
		else if (this.config.key) this.config_x = this.config
		else this.config_x = U.get_froala_config(this.config)

		// create the froala_wrapper_components object in the store if not already there
		if (!this.$store.state.froala_wrapper_components) {
			this.$store.commit('set', ['froala_wrapper_components', {}])
		}

		// then "register" this wrapper component
		this.$store.commit('set', ['froala_wrapper_components', this.froala_wrapper_id, this])

		// get initial model value; we provide multiple options for mapping the model_value returned by the froala editor onto the parent's parameter...
		let s
		// if a parameter_object is passed in, we directly get/set parameter_object[this.parameter]
		if (this.parameter_object) {
			s = this.parameter_object[this.parameter]

		} else if (this.parameter) {
			// if we get a parameter (but not a parameter_object), set this.$parent.parameter
			// note that this requires the froala-wrapper component to be a *direct child* of the component it's in
			s = this.$parent[this.parameter]
			// (we might want to use this if we need access to the parameter name for some other reason...)

		} else {
			// if parameter/parameter_object not supplied, use the standard vue v-model method
			// https://v2.vuejs.org/v2/guide/components-custom-events.html
			// https://www.digitalocean.com/community/tutorials/how-to-add-v-model-support-to-custom-vue-js-components
			s = this.value
		}

		this.model_value = U.render_latex(s)
		// console.log('setting model_value: ', this.model_value)
	},
	mounted() {
	},
	methods: {
		get_parent() {
			if (this.parent_component) return this.parent_component
			else return this.$parent
		},

		set_value(s) {
			this.model_value = U.render_latex(s)
		},
	}
}
</script>

<style lang="scss">
.k-froala-wrapper-outer {
	// let the user know that equations are clickable to edit
	.k-mathlive-span, .ML__base {
		cursor:pointer;
	}
}
</style>
