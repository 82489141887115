<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="700" persistent scrollable>
		<v-card>
			<v-card-title style="background-color:#ddd"><b>Create New Account</b></v-card-title>
			<v-card-text class="mt-3" style="font-size:16px;line-height:21px;">
				<div>Signing in with a {{site_config.app_name}} account will let you create your own activities, lesson plans, and other resources. You will also be able to organize premade and newly-created resources into collections, which you can then share with other users.</div>
				<div class="mt-2 pt-2" style="border-top:1px solid #999">Enter your name and email address, then click “CREATE NEW ACCOUNT”. You should then receive an email with a link to activate your new account. Upon clicking the link, you will be automatically signed in to {{site_config.app_name}}, then asked to establish a password.</div>
				<div>
					<div class="d-flex">
						<v-text-field outlined hide-details label="First name" class="mt-4 mr-1" v-model="first_name" placeholder=""></v-text-field>
						<v-text-field outlined hide-details label="Last name" class="mt-4 ml-1" v-model="last_name" placeholder=""></v-text-field>
					</div>
					<div class="d-flex">
						<v-spacer/>
						<div style="flex:0 0 400px"><v-text-field outlined hide-details label="Email" class="mt-4" v-model="email" placeholder=""></v-text-field></div>
						<v-spacer/>
					</div>
					<!-- <div class="d-flex align-center mt-4 pt-4" style="border-top:1px solid #999">
						<v-spacer/>
						<div class="mr-2"><b>Account type:</b></div>
						<v-btn-toggle dense active-class="k-toggle-btn-active-class indigo darken-4" class="k-toggle-btn" v-model="account_type" mandatory>
							<v-btn style="width:132px" light class="k-nocaps-btnx xk-tight-btn" value="student" @click.stop=""><v-icon small :color="account_type=='student'?'white':'black'" class="mr-2">fas fa-user-graduate</v-icon>Student</v-btn>
							<v-btn style="width:132px" light class="k-nocaps-btnx xk-tight-btn" value="teacher" @click.stop=""><v-icon small :color="account_type=='teacher'?'white':'black'" class="mr-2">fas fa-person-chalkboard</v-icon>Teacher</v-btn>
						</v-btn-toggle>
						<v-spacer/>
					</div> -->
				</div>
				<!-- <ul class="mt-2">
					<li>Users with <b>TEACHER</b> accounts can create new {{site_config.sparkl_app_name}} activities and collections, and assign activities to students.</li>
					<li class="mt-1">Users with <b>STUDENT</b> accounts can sign in to see activities assigned by their teachers.</li>
				</ul> -->
			</v-card-text>
			<v-card-actions class="pa-3" style="background-color:#ddd">
				<v-btn color="secondary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-xmark</v-icon>Cancel</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="create_new_account">Create New Account<v-icon small class="ml-2">fas fa-arrow-right</v-icon></v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		first_name: '',
		last_name: '',
		email: '',
		account_type: 'staff',
		dialog_open: true,
	}},
	computed: {
		...mapState(['site_config']),
		...mapGetters([]),
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		create_new_account() {
			let payload = {
				first_name: this.first_name,
				last_name: this.last_name,
				user_email: this.email,
				account_type: this.account_type,
				token_duration: 60,
				token_type: 'create_account'
			}
			U.ajax('send_token_signin_email', payload, result=>{
					U.loading_stop()
					let msg
					if (result.status != 'ok') {
						if (result.status == 'account_previously_created') {
							msg = 'An account for this email already exists! Click on Forgot Password to reset password.'
						} else {
							console.log(sr('Error in token create account ajax call: $1', result.status))
							msg = 'The one-time sign-in link could not be sent.'
							//msg = result.status
						}

					} else {
						msg = 'New account verification email sent. <b>Please check your spam or junk folder</b> if you don’t receive the link within one minute, and remember that <b>the link will expire after one hour</b>.'
					}
					this.$alert(msg)
				})
		}
	}
}
</script>

<style lang="scss">
</style>