U.googleTranslateElementInit = function() {
	// uncomment the below to cancel google translate functionality
	// $('#google_translate_element_wrapper').hide()
	// return

	console.log('GTI2')
	new google.translate.TranslateElement({
			pageLanguage: 'en', 
			includedLanguages: 'en,es,vi,fr,zh-CN,hi',
			// includedLanguages: 'ar,zh-CN,en,es',
			layout: google.translate.TranslateElement.InlineLayout.SIMPLE,
			autoDisplay: false,
		}, 'google_translate_element'
	);

	// once every second...
	setInterval(x=>{
		// if google translate is showing the annoying spinner, hide it. This will likely need to be updated as google translate evolves
		let jq = $('#loom-companion-mv3').next()
		if (jq.find('svg').length > 0) {
			jq.hide()
		}
	}, 1000)
}
