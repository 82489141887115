<template>
	<div class="k-page-wrapper">
		<h2 class="k-page-title">
			<v-icon @click="return_to_admin_main" large color="primary" class="mr-2">fas fa-cog</v-icon>
			Term Management
		</h2>
		<v-card class="mb-4 pa-4">
			<v-card-text>
				<div class="d-flex align-center mb-12">
					<div v-for="(term_dates, index) in term_settings.term_dates" :key="index" class="mr-10">
						<TermDatePicker :label="`Term ${index + 1} Start Date`" v-model="term_dates['start_date']" />
						<TermDatePicker class="mt-4" :label="`Term ${index + 1} End Date`" v-model="term_dates['end_date']" />
					</div>
				</div>
				<div class="d-flex mt-8 align-center">
					<div style="width:220px"><v-text-field label="Default Academic Year"
							v-model="term_settings.default_academic_year" :mask="year_mask" :rules="year_rules" dense
							outlined hide-details></v-text-field></div>
					<v-checkbox class="my-0 ml-4" label="Block SIS data for students and parents" hide-details
						v-model="term_settings.block_sis_data_for_students"></v-checkbox>
				</div>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="save_settings">Save</v-btn>
			</v-card-actions>
		</v-card>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import TermDatePicker from './TermDatePicker'

export default {
	components: { TermDatePicker },
	data() {
		return {
			term_settings: [],
			year_mask: '####',
			year_rules: [
				value => /^\d{4}$/.test(value) || 'Invalid format. Please enter a valid year.',
				value => {
					const year = parseInt(value);
					return year >= 1900 && year <= new Date().getFullYear() + 10 || 'Invalid year. Please enter a year between 1900 and the current year + 10.';
				}
			]
		}
	},
	computed: {
		...mapState(['']),
	},
	created() {
		vapp.admin_terms_component = this
		// copy term_settings from state; we'll push them back to state when we save
		this.term_settings = object_copy(this.$store.state.term_settings)
	},
	mounted() { },
	watch: {},
	methods: {
		return_to_admin_main() {
			this.$router.push({ path: '/welcome' })
		},
		save_settings() {
			vapp.$store.dispatch('admin_update_term_settings', this.term_settings).then(() => {
				this.$store.commit('set', ['term_settings', this.term_settings])
				this.$alert('Settings updated.')
			})
		},
	},
}
</script>

<style lang="scss" scoped>
</style>
