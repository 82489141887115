class LinkURI {
	constructor(data) {
		if (empty(data) || typeof(data) != 'object') data = {}

		sdp(this, data, 'title', '')
		sdp(this, data, 'identifier', '')
		sdp(this, data, 'uri', '')
	}

	is_complete() {
		if (this.title == undefined) return false	// empty string titles are allowed
		if (!this.identifier) return false
		if (!this.uri) return false
		return true
	}
}
window.LinkURI = LinkURI

window.StringArray = function(data) {
	// if empty, or not an array, use empty array
	if (empty(data) || !Array.isArray(data)) return []

	// (this is what we previously did) if not an array, convert to string and return the string as an array
	// if (!Array.isArray(data)) return [data + '']

	let arr = []
	for (let s of data) {
		// convert each array val to a string
		arr.push(s + '')
	}

	return arr
}

window.LinkURIArray = function(data) {
	// if empty, or not an array, use empty array
	if (empty(data) || !Array.isArray(data)) return []

	let arr = []
	for (let o of data) {
		arr.push(new LinkURI(o))
	}

	return arr
}

class CFDocument {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'identifier', '')
		sdp(this, data, 'uri', '')
		sdp(this, data, 'creator', '')
		sdp(this, data, 'title', '')
		sdp(this, data, 'lastChangeDateTime', '')
		sdp(this, data, 'officialSourceURL', '')
		sdp(this, data, 'publisher', '')
		sdp(this, data, 'description', '')
		this.subject = StringArray(data.subject)
		this.subjectURI = LinkURIArray(data.subjectURI)
		sdp(this, data, 'language', '')
		sdp(this, data, 'version', '')
		sdp(this, data, 'adoptionStatus', '')
		sdp(this, data, 'statusStartDate', '')
		sdp(this, data, 'statusEndDate', '')
		sdp(this, data, 'licenseURI', '')
		sdp(this, data, 'notes', '')
	}

	is_valid() {
		if (empty(this.identifier)) return false
		if (empty(this.uri)) return false
		if (empty(this.creator)) return false
		if (empty(this.title)) return false
		if (empty(this.lastChangeDateTime)) return false
	}

	generate_identifier() {
		this.identifier = U.new_uuid()
	}

	// this should be overwritten on server prior to file save
	// client will then receive server change timestamp and update doc in store
	generate_date() {
		this.lastChangeDateTime = '*NOW*'
		//this.lastChangeDateTime = U.case_current_time_string()
	}

	complete_data() {
		if (!this.identifier) this.generate_identifier()
		this.generate_date()
	}

	to_json() {
		let o = {}

		// required params
		o.identifier = this.identifier
		o.uri = this.uri
		o.creator = this.creator
		o.title = this.title
		o.lastChangeDateTime = this.lastChangeDateTime

		// optional params
		if (this.officialSourceURL) o.officialSourceURL = this.officialSourceURL
		if (this.publisher) o.publisher = this.publisher
		if (this.description) o.description = this.description
		if (this.subject.length > 0) o.subject = this.subject
		if (this.subjectURI.length > 0) o.subjectURI = this.subjectURI
		if (this.language) o.language = this.language
		if (this.version) o.version = this.version
		if (this.adoptionStatus) o.adoptionStatus = this.adoptionStatus
		if (this.statusStartDate) o.statusStartDate = this.statusStartDate
		if (this.statusEndDate) o.statusEndDate = this.statusEndDate
		if (this.licenseURI) o.licenseURI = this.licenseURI
		if (this.notes) o.notes = this.notes

		return o
	}

	to_json_for_update() {
		// just like to_json, but for empty optional params, set to '*CLEAR*' so that the service fn will make sure to delete the params from the item
		let o = {}

		// required params
		o.identifier = this.identifier
		o.uri = this.uri
		o.creator = this.creator
		o.title = this.title
		o.lastChangeDateTime = this.lastChangeDateTime

		// optional params
		if (!empty(this.officialSourceURL)) o.officialSourceURL = this.officialSourceURL; else o.officialSourceURL = '*CLEAR*';
		if (!empty(this.publisher)) o.publisher = this.publisher; else o.publisher = '*CLEAR*';
		if (!empty(this.description)) o.description = this.description; else o.description = '*CLEAR*';
		if (this.subject.length > 0) o.subject = this.subject; else o.subject = '*CLEAR*';
		if (this.subjectURI.length > 0) o.subjectURI = this.subjectURI; else o.subjectURI = '*CLEAR*';
		if (!empty(this.language)) o.language = this.language; else o.language = '*CLEAR*';
		if (!empty(this.version)) o.version = this.version; else o.version = '*CLEAR*';
		if (!empty(this.adoptionStatus)) o.adoptionStatus = this.adoptionStatus; else o.adoptionStatus = '*CLEAR*';
		if (!empty(this.statusStartDate)) o.statusStartDate = this.statusStartDate; else o.statusStartDate = '*CLEAR*';
		if (!empty(this.statusEndDate)) o.statusEndDate = this.statusEndDate; else o.statusEndDate = '*CLEAR*';
		if (!empty(this.licenseURI)) o.licenseURI = this.licenseURI; else o.licenseURI = '*CLEAR*';
		if (!empty(this.notes)) o.notes = this.notes; else o.notes = '*CLEAR*';

		return o
	}
}
window.CFDocument = CFDocument


class CFItem {
	constructor(data) {
		if (empty(data)) data = {}

		// note that within the context of a framework, items and associations do not need CFDocumentURIs
		// this.CFDocumentURI = new LinkURI(data.CFDocumentURI)

		sdp(this, data, 'identifier', '')
		sdp(this, data, 'uri', '')
		sdp(this, data, 'fullStatement', '')
		sdp(this, data, 'lastChangeDateTime', '')

		sdp(this, data, 'humanCodingScheme', '')
		sdp(this, data, 'abbreviatedStatement', '')
		sdp(this, data, 'notes', '')

		sdp(this, data, 'language', '')
		this.educationLevel = StringArray(data.educationLevel)
		sdp(this, data, 'alternativeLabel', '')
		sdp(this, data, 'listEnumeration', '')
		sdp(this, data, 'CFItemType', '')
		this.CFItemTypeURI = new LinkURI(data.CFItemTypeURI)
		this.conceptKeywords = StringArray(data.conceptKeywords)
		this.conceptKeywordsURI = new LinkURI(data.conceptKeywordsURI)	// seems like this should be an array, but it's not
		this.licenseURI = new LinkURI(data.licenseURI)
		sdp(this, data, 'statusStartDate', '')
		sdp(this, data, 'statusEndDate', '')
	}

	is_valid() {
		// note that within the context of a framework, items and associations do not need CFDocumentURIs
		// if (!this.CFDocumentURI.is_complete()) return false
		if (empty(this.identifier)) return false
		if (empty(this.uri)) return false
		if (empty(this.fullStatement)) return false
		if (empty(this.lastChangeDateTime)) return false
	}

	generate_identifier() {
		this.identifier = U.new_uuid()
	}

	generate_CFDocumentURI(document) {
		this.CFDocumentURI = U.generate_document_uri(document)
	}

	generate_uri(document) {
		this.uri = U.generate_child_uri(document, this.identifier)
	}

	// this should be overwritten on server prior to file save
	// client will then receive server change timestamp and update doc in store
	generate_date() {
		this.lastChangeDateTime = '*NOW*'
		//this.lastChangeDateTime = U.case_current_time_string()
	}

	complete_data(document) {
		// note that within the context of a framework, items and associations do not need CFDocumentURIs
		// this.generate_CFDocumentURI(document)
		if (!this.identifier) this.generate_identifier()
		if (!this.uri) this.generate_uri(document)
		this.generate_date()
	}

	to_json() {
		let o = {}

		// required params
		// note that within the context of a framework, items and associations do not need CFDocumentURIs
		// o.CFDocumentURI = this.CFDocumentURI
		o.identifier = this.identifier
		o.uri = this.uri
		o.fullStatement = this.fullStatement
		o.lastChangeDateTime = this.lastChangeDateTime

		// optional params
		if (!empty(this.humanCodingScheme)) o.humanCodingScheme = this.humanCodingScheme
		if (!empty(this.abbreviatedStatement)) o.abbreviatedStatement = this.abbreviatedStatement
		if (!empty(this.notes)) o.notes = this.notes

		if (!empty(this.language)) o.language = this.language
		if (this.educationLevel.length > 0) o.educationLevel = this.educationLevel
		if (!empty(this.alternativeLabel)) o.alternativeLabel = this.alternativeLabel
		if (!empty(this.listEnumeration)) o.listEnumeration = this.listEnumeration
		if (!empty(this.CFItemType)) o.CFItemType = this.CFItemType
		if (this.CFItemTypeURI && this.CFItemTypeURI.is_complete()) o.CFItemTypeURI = this.CFItemTypeURI
		if (this.conceptKeywords && this.conceptKeywords.length > 0) o.conceptKeywords = this.conceptKeywords
		if (this.conceptKeywordsURI && this.conceptKeywordsURI.is_complete()) o.conceptKeywordsURI = this.conceptKeywordsURI
		if (this.licenseURI && this.licenseURI.is_complete()) o.licenseURI = this.licenseURI
		if (!empty(this.statusStartDate)) o.statusStartDate = this.statusStartDate
		if (!empty(this.statusEndDate)) o.statusEndDate = this.statusEndDate

		return o
	}

	to_json_for_update() {
		// just like to_json, but for empty optional params, set to '*CLEAR*' so that the service fn will make sure to delete the params from the item
		let o = {}

		// required params
		// note that within the context of a framework, items and associations do not need CFDocumentURIs
		// o.CFDocumentURI = this.CFDocumentURI
		o.identifier = this.identifier
		o.uri = this.uri
		o.fullStatement = this.fullStatement
		o.lastChangeDateTime = this.lastChangeDateTime

		// optional params
		if (!empty(this.humanCodingScheme)) o.humanCodingScheme = this.humanCodingScheme; else o.humanCodingScheme = '*CLEAR*';
		if (!empty(this.abbreviatedStatement)) o.abbreviatedStatement = this.abbreviatedStatement; else o.abbreviatedStatement = '*CLEAR*';
		if (!empty(this.notes)) o.notes = this.notes; else o.notes = '*CLEAR*';

		if (!empty(this.language)) o.language = this.language; else o.language = '*CLEAR*';
		if (this.educationLevel.length > 0) o.educationLevel = this.educationLevel; else o.educationLevel = '*CLEAR*';
		if (!empty(this.alternativeLabel)) o.alternativeLabel = this.alternativeLabel; else o.alternativeLabel = '*CLEAR*';
		if (!empty(this.listEnumeration)) o.listEnumeration = this.listEnumeration; else o.listEnumeration = '*CLEAR*';
		if (!empty(this.CFItemType)) o.CFItemType = this.CFItemType; else o.CFItemType = '*CLEAR*';
		if (this.CFItemTypeURI && this.CFItemTypeURI.is_complete()) o.CFItemTypeURI = this.CFItemTypeURI; else o.CFItemTypeURI = '*CLEAR*';
		if (this.conceptKeywords && this.conceptKeywords.length > 0) o.conceptKeywords = this.conceptKeywords; else o.conceptKeywords = '*CLEAR*';
		if (this.conceptKeywordsURI && this.conceptKeywordsURI.is_complete()) o.conceptKeywordsURI = this.conceptKeywordsURI; else o.conceptKeywordsURI = '*CLEAR*';
		if (this.licenseURI && this.licenseURI.is_complete()) o.licenseURI = this.licenseURI; else o.licenseURI = '*CLEAR*';
		if (!empty(this.statusStartDate)) o.statusStartDate = this.statusStartDate; else o.statusStartDate = '*CLEAR*';
		if (!empty(this.statusEndDate)) o.statusEndDate = this.statusEndDate; else o.statusEndDate = '*CLEAR*';

		return o
	}
}
window.CFItem = CFItem

class CFAssociation {
	constructor(data) {
		if (empty(data)) data = {}

		// note that within the context of a framework, items and associations do not need CFDocumentURIs
		// this.CFDocumentURI = new LinkURI(data.CFDocumentURI)
		sdp(this, data, 'identifier', '')
		sdp(this, data, 'uri', '')
		sdp(this, data, 'associationType', '', ['isChildOf', 'exactMatchOf', 'isRelatedTo', 'replacedBy', 'isPeerOf', 'isPartOf', 'precedes', 'exemplar', 'hasSkillLevel'])
		this.originNodeURI = new LinkURI(data.originNodeURI)
		this.destinationNodeURI = new LinkURI(data.destinationNodeURI)
		sdp(this, data, 'lastChangeDateTime', '')

		sdp(this, data, 'sequenceNumber', -1)
		this.CFAssociationGroupingURI = new LinkURI(data.CFAssociationGroupingURI)
		// add CFAssociationGroupingURI if it exists and has an identifier
		// if (data.CFAssociationGroupingURI && data.CFAssociationGroupingURI.identifier) this.CFAssociationGroupingURI = new LinkURI(data.CFAssociationGroupingURI)
	}

	is_valid() {
		// note that within the context of a framework, items and associations do not need CFDocumentURIs
		// if (!this.CFDocumentURI.is_complete()) return false
		if (empty(this.identifier)) return false
		if (empty(this.uri)) return false
		if (empty(this.associationType)) return false
		if (!this.originNodeURI.is_complete()) return false
		if (!this.destinationNodeURI.is_complete()) return false
		if (empty(this.lastChangeDateTime)) return false
	}

	generate_identifier() {
		this.identifier = U.new_uuid()
	}

	generate_CFDocumentURI(document) {
		this.CFDocumentURI = U.generate_document_uri(document)
	}

	generate_uri(document) {
		this.uri = U.generate_child_uri(document, this.identifier)
	}

	// this should be overwritten on server prior to file save
	// client will then receive server change timestamp and update doc in store
	generate_date() {
		this.lastChangeDateTime = '*NOW*'
		//this.lastChangeDateTime = U.case_current_time_string()
	}

	complete_data(document) {
		// note that within the context of a framework, items and associations do not need CFDocumentURIs
		// this.generate_CFDocumentURI(document)
		if (!this.identifier) this.generate_identifier()
		if (!this.uri) this.generate_uri(document)
		this.generate_date()
	}

	to_json() {
		let o = {}

		// required params
		// note that within the context of a framework, items and associations do not need CFDocumentURIs
		// o.CFDocumentURI = this.CFDocumentURI
		o.identifier = this.identifier
		o.uri = this.uri
		o.associationType = this.associationType
		o.originNodeURI = this.originNodeURI
		o.destinationNodeURI = this.destinationNodeURI
		o.lastChangeDateTime = this.lastChangeDateTime

		if (this.sequenceNumber > -1) o.sequenceNumber = this.sequenceNumber
		if (this.CFAssociationGroupingURI && this.CFAssociationGroupingURI.is_complete()) o.CFAssociationGroupingURI = this.CFAssociationGroupingURI

		return o
	}
}
window.CFAssociation = CFAssociation

class CFLicense {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'identifier', '')
		sdp(this, data, 'uri', '')
		sdp(this, data, 'title', '')
		sdp(this, data, 'description', '')
		sdp(this, data, 'licenseText', '')
		sdp(this, data, 'lastChangeDateTime', '')
	}

	generate_identifier() {
		this.identifier = U.new_uuid()
	}

	generate_uri(document) {
		this.uri = U.generate_child_uri(document, this.identifier)
	}

	// this should be overwritten on server prior to file save
	// client will then receive server change timestamp and update doc in store
	generate_date() {
		this.lastChangeDateTime = '*NOW*'
		//this.lastChangeDateTime = U.case_current_time_string()
	}

	complete_data(document) {
		if (!this.identifier) this.generate_identifier()
		if (!this.uri) this.generate_uri(document)
		this.generate_date()
	}

	to_json() {
		let o = {}
		o.identifier = this.identifier
		o.uri = this.uri
		o.title = this.title
		o.licenseText = this.licenseText
		o.lastChangeDateTime = this.lastChangeDateTime

		// description is the only optional field; in Satchel we don't provide an input field for this, as it seems to be redundant
		if (this.description) o.description = this.description
		return o
	}

	// {
    //   "uri": "https://api.standards.isbe.net/server/api/v1/ISBOE/ims/case/v1p0/CFLicenses/9b516ff5-1cd2-41aa-8618-f20eee60b06d",
    //   "identifier": "9b516ff5-1cd2-41aa-8618-f20eee60b06d",
    //   "lastChangeDateTime": "2019-02-06T15:48:37+00:00",
    //   "title": "IMS Global License",
    //   "description": "IMS Global License",
    //   "licenseText": "\u00a9 2018-2019 IMS Global Learning Consortium Inc. All Rights Reserved."
    // }

	// {
	//   "uri": "https://case.georgiastandards.org/uri/ce259010-07d7-11ea-a815-0242ac150004",
	//   "identifier": "ce259010-07d7-11ea-a815-0242ac150004",
	//   "lastChangeDateTime": "2019-11-15T18:43:24+00:00",
	//   "title": "Attribution 4.0 International",
	//   "licenseText": "https://creativecommons.org/licenses/by/4.0/legalcode"
	// }
}
window.CFLicense = CFLicense


class SSFrameworkData {
	constructor(data, document_identifier) {
		if (empty(data)) data = {}

		// if color is empty pick a random color based on the document_identifier
		if (empty(data.color) || data.color == 0) {
			if (empty(document_identifier)) {
				this.color = U.random_int(1,13)
			} else {
				this.color = 1
				for (let i = 0; i < document_identifier.length; ++i) {
					this.color += document_identifier.charCodeAt(i)
				}
				this.color = (this.color % 13) + 1
			}
		} else {
			this.color = data.color
		}

		sdp(this, data, 'image', '')
		sdp(this, data, 'category', '')
		sdp(this, data, 'exemplar_label', '')
		sdp(this, data, 'exemplar_framework_identifier', '')
		sdp(this, data, 'shortcuts', [])
		sdp(this, data, 'render_latex', false)
		sdp(this, data, 'last_access_date_webapp', '1970-01-01 00:00:01')
		sdp(this, data, 'access_count_webapp', 0)
		sdp(this, data, 'last_access_date_api', '1970-01-01 00:00:01')
		sdp(this, data, 'access_count_api', 0)
		sdp(this, data, 'user_rights', {}) // per framework rights
	}

	to_json_for_update() {
		// for empty optional params, set to '*CLEAR*' so that the service fn will make sure to delete the params from the item
		let o = {}
		o.color = this.color
		o.render_latex = this.render_latex
		if (!empty(this.image)) o.image = this.image; else o.image = '*CLEAR*';
		if (!empty(this.category)) o.category = this.category; else o.category = '*CLEAR*';
		if (!empty(this.exemplar_label)) o.exemplar_label = this.exemplar_label; else o.exemplar_label = '*CLEAR*';
		if (!empty(this.exemplar_framework_identifier)) o.exemplar_framework_identifier = this.exemplar_framework_identifier; else o.exemplar_framework_identifier = '*CLEAR*';
		if (!empty(this.shortcuts) && this.shortcuts.length > 0) o.shortcuts = this.shortcuts; else o.shortcuts = '*CLEAR*';
		return o
	}
}
window.SSFrameworkData = SSFrameworkData
