U.auto_blank_stop_words = ['about', 'above', 'after', 'again', 'against', 'all', 'and', 'any', 'are', 'because', 'been', 'before', 'being', 'below', 'between', 'both', 'but', 'can', 'cannot', 'could', 'did', 'does', 'doing', 'down', 'during', 'each', 'few', 'for', 'from', 'further', 'had', 'has', 'have', 'having', 'her', 'here', 'hers', 'herself', 'him', 'himself', 'his', 'how', 'into', 'it', 'its', 'itself', 'more', 'most', 'myself', 'nor', 'not', 'off', 'once', 'only', 'other', 'ought', 'our', 'ours', 'ourselves', 'out', 'over', 'own', 'same', 'she', 'should', 'some', 'such', 'than', 'that', 'the', 'their', 'theirs', 'them', 'themselves', 'then', 'there', 'these', 'they', 'this', 'those', 'through', 'too', 'under', 'until', 'very', 'was', 'were', 'what', 'when', 'where', 'which', 'while', 'who', 'whom', 'why', 'with', 'would', 'you', 'your', 'yours', 'yourself', 'yourselves']

U.auto_blank_process_html = function(html, blanks, options) {
	if (empty(options)) options = {}
	let cons = false

	let blankable_words = []

	// remove spaces from query prompt paragraphs, titles, info blocks so we don't count these as blankable words
	let $jq = $(`<div>${html}</div>`)
	$jq.find('.k-exercise__question-prompt, .k-exercise__exercise-title, .k-exercise__instructions, .k-mathlive-span').each((i, el)=>{
		let p_html = $(el).html()
		p_html = p_html.replace(/\s/g, 'QPXXXXQP')
		console.log(p_html)
		$(el).html(p_html)
	})
	html = $jq.html()

	html = html.replace(/\&nbsp;/g, ' ')	// option-space

	// split on html tags
	let pieces = html.split('<')
	if (cons) console.log(pieces)
	let new_html = ''
	for (let piece of pieces) {
		if (empty(piece)) continue

		new_html += '<'

		if (cons) console.log('piece: ' + piece)

		// if this piece actually starts with a tag (and not a < symbol), add the tag to new_html
		if (piece.search(/^([a-z\/][^>]*>)(.*)/) == 0) {
			new_html += RegExp.$1
			piece = RegExp.$2
			if (empty($.trim(piece))) {
				if (cons) console.log('CLOSE TAG DETECTED -- continuing')
				// if this piece is a closing tag (e.g. `/p>`), just add piece (in case there are spaces there) and continue; if we don't do this, we insert spaces between <p> tags (e.g.)
				new_html += piece
				continue
			}
		} else {
			new_html += '<'
		}

		let arr = piece.split(/\s/)
		let first_word_done = false
		for (let word of arr) {
			if (empty(word)) {
				new_html += ' '
				continue
			}

			if (first_word_done) new_html += ' '
			first_word_done = true

			let close = ''

			// skip latex equations; note that if an equation somehow has a space within it, we might be in trouble
			if (word[0] == '$') {
				new_html += word
				continue
			}

			// if string starts or ends with a non-word character, strip the character out -- or maybe we should be excluding these altogether unless the character is something we want to support, e.g. "',.;:
			if (word.search(/^([^a-zA-Z]+)(.*)/) > -1) {
				new_html += RegExp.$1
				word = RegExp.$2
			}
			if (word.search(/(.*?)([^a-zA-Z]+)$/) > -1) {
				word = RegExp.$1
				close = RegExp.$2
			}

			if (cons) console.log(' word: ' + word)

			// initially, any non-empty string is blankable
			let blankable = !empty(word)

			// if allow_all_words option isn't on, use some criteria to weed out some words
			if (options.allow_all_words !== true) {
				// skip any strings that include chars we don't want to deal with
				// allow words with apostrophes, but not words with hyphens
				if (word.search(/[^a-zA-Z'’]/) > -1) {	// -–—
					if (cons) console.log('  skipping string with bad char: ' + word)
					blankable = false
				}

				// skip any words with uppercase letters -- avoid complaints about proper nouns
				if (options.allow_capitals !== true) {
					if (word.search(/[A-Z]/) > -1) {
						if (cons) console.log('  skipping string with uppercase: ' + word)
						blankable = false
					}
				}

				// skip any strings with non-word characters??
				// if (word.search(/[^a-zA-Z]/) > -1) {
				// 	if (cons) console.log('  skipping string with non-letter char: ' + word)
				// 	blankable = false
				// }

				// skip any words in the stop words list; the replace here turns it’s => it
				let sl = word.toLowerCase().replace(/['’].*/, '')
				if (options.allow_stop_words !== true) {
					if (U.auto_blank_stop_words.findIndex(o=>o==sl) > -1) {
						if (cons) console.log('  skipping stop word: ' + word)
						blankable = false
					}
				}

				// skip words that have 3 or fewer unique chars
				if (options.allow_short_words !== true) {
					sl = U.unique_chars(sl)
					// console.log(sl)
					if (sl.length <= 3) {
						if (cons) console.log('  skipping short word: ' + word)
						blankable = false
					}
				}
			}

			// if this is a blankable word...
			if (blankable) {
				// determine count of previous instances of the word
				let index = 0
				for (let bw of blankable_words) {
					if (bw.word == word) ++index
				}

				// if we received a set of blanks,
				if (!empty(blanks)) {
					// than if this is one of the specified blanks, add to new_html
					let blank = blanks.find(o=>o.word == word && o.index == index)
					if (!empty(blank)) {
						// new_html += sr('<span id="query-$1"></span>', blank.uuid)
						new_html += sr('<span id="query-$1"></span>', blank.uuid)
					} else {
						// else add the word to new_html
						new_html += word
					}
				}

				// push to blankable_words
				blankable_words.push({word: word, index: index})
			} else {
				new_html += word
			}

			// add closing punctuation if there was any
			new_html += close
		}
	}

	// now that we've processed all the words, if the `allow_all_words_if_necessary` option is on, the `allow_all_words` option *isn't* on, and we didn't get any blankable_words...
	if (options.allow_all_words_if_necessary === true && options.allow_all_words !== true && blankable_words.length == 0) {
		// then re-call the fn, setting allow_all_words to true
		options.allow_all_words = true
		return U.auto_blank_process_html(html, blanks, options)
	}

	// put spaces back in question prompts
	$jq = $(`<div>${new_html}</div>`)
	$jq.find('.k-exercise__question-prompt, .k-exercise__exercise-title, .k-exercise__instructions').each((i, el)=>{
		let p_html = $(el).html()
		p_html = p_html.replace(/QPXXXXQP/g, ' ')
		$(el).html(p_html)
	})
	new_html = $jq.html()

	html = html.replace(/ /g, '\&nbsp;')	// option-space

	// console.log('blankable_words: ' + blankable_words.length)

	// return new_html or blankable_words
	if (!empty(blanks)) return new_html
	else return blankable_words
}

// calculate number of blanks to create for a passage with n_words non-stop-words
U.auto_blank_num_blanks = function(n_words, ab_count) {
	// this parameter determines how many blanks we'll get for a block of text.
	// if words_per_blank is, for example, 10, it means that every 10th word will be turned into a blank
	// If the auto_blank_process_html algorithm is changed to exclude more words, that means we will get fewer blanks for a passage of a given length,
	// UNLESS we adjust words_per_blank to be lower
	let words_per_blank
	// on 4/11, reduced these numbers
	if (ab_count == 'fewer') words_per_blank = 30		// used to be 15
	else if (ab_count == 'more') words_per_blank = 10	// used to be 6
	else words_per_blank = 20	// 'normal'				   used to be 8

	// 1 blank per U.words_per_blank words, rounded up or down
	// note that this means that if U.words_per_blank is 12, a string with <= 5 words gets 0 blanks; 6 words gets 1 blank
	let words = Math.round(n_words/words_per_blank)
	if (words == 0) return 1
	else return words
}
