import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)

// 1. Define route components. These can be imported from other files
import BadRoute from './components/main/BadRoute'
import WelcomeView from './components/main/WelcomeView'
import CollectionBase from './components/collectionlist/CollectionBase'
import FrameworksMain from './components/standards/FrameworksMain'
import AdminView from './components/admin/AdminView'
import AdminUsers from './components/admin/AdminUsers'
import AdminLTI from './components/admin/AdminLTI'
import AdminHomePageContent from './components/admin/AdminHomePageContent'
import AdminLearningProgressions from './components/admin/AdminLearningProgressions'
import AdminLessonMasters from './components/admin/AdminLessonMasters'
import AdminUsage from './components/admin/AdminUsage'
import AdminCourseEditLog from './components/admin/AdminCourseEditLog'
import AdminResourceUsage from './components/admin/AdminResourceUsage'
import AdminTerms from './components/admin/AdminTerms'
import ChangeLog from './components/main/ChangeLog'
import LessonStandaloneView from './components/lessons/LessonStandaloneView'
import AdminSiteIssues from './components/admin/AdminSiteIssues'
import DirectivesWrapper from './components/directives/DirectivesWrapper'

// 2. Define some routes
const routes = [
	{ path: '*', component: BadRoute },
	// see MainView if new component routes are added...

	// { path: '', redirect: '/welcome' },
	{ path: '', name: 'welcome', component: WelcomeView },
	{ path: '/signin', name: 'signin', component: WelcomeView },
	{ path: '/welcome', name: 'welcome_alt', component: WelcomeView },
	// { path: '/welcome', redirect: '' },
	{ path: '/home', name: 'home', component: WelcomeView },
	{ path: '/courses', name: 'courses', component: WelcomeView },
	{ path: '/courses/:category', name: 'courses_cat', component: WelcomeView },
	{ path: '/courses/:category/:subcat', name: 'courses_subcat', component: WelcomeView },
	{ path: '/repos', name: 'repos', component: WelcomeView },
	{ path: '/mycollections', name: 'mycollections', component: WelcomeView },
	{ path: '/pd', name: 'pd_main', component: WelcomeView},
	{ path: '/admin', name: 'admin', component: WelcomeView },
	{ path: '/standards', name: 'standards', component: WelcomeView },
	// Non-admin route for users who have been granted admin rights to the resource report, but are not superusers
	{ path: '/resource_usage', name: 'resource_usage', component: WelcomeView },

	{ path: '/standards_base', name: 'standards_main', component: FrameworksMain },

	{ path: '/lesson/:lesson_id', name: 'standalone_lesson_view', component: LessonStandaloneView, props: true },
	{ path: '/activity/:single_activity_id', name: 'single_activity', component: DirectivesWrapper, props: true },

	{ path: '/collection/:course_code/:unit_id', name: 'collection_view', component: CollectionBase, props: true },

	// aliases for old routes:
	{ path: '/course/:course_code/:unit_id', redirect: { name: 'collection_view' }, props: true },
	{ path: '/repo/:course_code/:unit_id', redirect: { name: 'collection_view' }, props: true },
	{ path: '/mycollection/:course_code/:unit_id', redirect: { name: 'collection_view' }, props: true },
	{ path: '/pd/:course_code/:unit_id', redirect: { name: 'collection_view' }, props: true },

	{ path: '/admin_base', name: 'admin_main', component: AdminView, children: [
		{ path: '/admin/users', name: 'admin_users', component: AdminUsers },
		{ path: '/admin/home_page', name: 'admin_home_page', component: AdminHomePageContent },
		{ path: '/admin/lp', name: 'admin_lp', component: AdminLearningProgressions },
		{ path: '/admin/usage', name: 'admin_usage', component: AdminUsage },
		{ path: '/admin/editlog', name: 'admin_edit_log', component: AdminCourseEditLog },
		{ path: '/admin/resource_usage', name: 'admin_resource_usage', component: AdminResourceUsage },
		{ path: '/admin/site_issue_reports', name: 'admin_site_issue_reports', component: AdminSiteIssues },
		{ path: '/admin/lessonmasters', name: 'admin_lessonmasters', component: AdminLessonMasters },
		{ path: '/admin/lti', name: 'admin_lti', component: AdminLTI },
		{ path: '/admin/terms', name: 'admin_terms', component: AdminTerms, beforeEnter: (to, from, next) => {
			if (store.state.site_config.enable_admin_term_management === 'yes') {
				next();
			} else {
				next(false)
			}
		} },
	] },
	{ path: '/changelog', name: 'changelog', component: ChangeLog },
]

// 3. Create the router instance and pass the `routes` option
const router = new VueRouter({
	// for local development, base is '/'; on the server, base is /
	base: (document.location.href.indexOf('localhost') > -1) ? '' : '/',
	mode: 'history',
	routes // short for `routes: routes`
})

// override push and replace functions to avoid useless "NavigationDuplicated" errors
// https://stackoverflow.com/questions/62462276/how-to-solve-avoided-redundant-navigation-to-current-location-error-in-vue
const _push = router.__proto__.push
router.__proto__.push = function push (...args) {
	return _push.call(this, ...args).catch(error => {
		if (error.name !== 'NavigationDuplicated') throw error
	})
}

const _replace = router.__proto__.replace
router.__proto__.replace = function replace (...args) {
	return _replace.call(this, ...args).catch(error => {
		if (error.name !== 'NavigationDuplicated') throw error
	})
}

// 4. export router object for inclusion in main.js
export default router
