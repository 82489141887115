<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div>
	<div v-if="unit.standards.length==0" class="pa-2 text-center"><i>This unit does not have any standards specified.</i></div>
	<div class="mx-1 my-2" v-if="unit.standards.length>0">
		<div class="k-unit-view-header">Standards</div>
		<div class="k-lp-unit-description">
			<div v-if="!standards_tree_loaded" class="pa-4" style="font-size:16px">Loading...</div>
			<div v-for="(o) in unit_standards_tree">
				<div class="k-lp-unit-standard" :class="o.cfitem.humanCodingScheme?'':'k-lp-unit-standard-top-category'">
					<div class="k-lp-unit-standard-statement-wrapper" :style="o.children.length>0?'border-top:1px solid #ccc; padding-top:8px;':''">
						<v-btn v-if="o.children.length>0" icon x-small style="margin-right:6px" @click.stop="toggle_standard_children(o.cfitem.identifier)"><v-icon style="font-size:18px" color="deep-orange darken-2">{{standard_children_showing[o.cfitem.identifier] ? 'fas fa-circle-chevron-down' : 'fas fa-circle-chevron-right'}}</v-icon></v-btn>
						<b v-if="o.cfitem.humanCodingScheme" style="margin-right:6px" :style="o.children.length==0?'margin-left:26px':''" v-html="o.cfitem.humanCodingScheme"></b>
						<div class="k-lp-unit-standard-statement" @click.stop="standard_clicked(o.cfitem)" v-html="o.cfitem.fullStatement"></div>
					</div>

					<div v-if="o.children.length>0" class="k-lp-unit-standard-children">
						<v-expand-transition><div v-show="standard_children_showing[o.cfitem.identifier]">
							<div v-for="(standard) in o.children" class="k-lp-unit-standard">
								<div class="k-lp-unit-standard-statement-wrapper">
									<!-- <v-icon color="deep-orange darken-2" style="font-size:18px; margin-right:6px">fas fa-circle-dot</v-icon> -->
									<div class="k-lp-unit-standard-statement" @click.stop="standard_clicked(standard)">
										<b v-if="standard.humanCodingScheme"><span v-html="standard.humanCodingScheme"></span> &nbsp;</b> <span v-html="standard.fullStatement"></span>
									</div>
								</div>
							</div>
						</div></v-expand-transition>

					</div>
				</div>
			</div>
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: {  },
	props: {
		lp: { type: Object, required: true },
		unit: { type: Object, required: true },
	},
	data() { return {
		standard_text_showing: {},
		standard_children_showing: {},
		standards_tree_loaded: false,
		// tried_to_load_lsdoc_list: false,
		// tried_to_load_framework: false,
	}},
	computed: {
		...mapState(['user_info']),
		case_framework_identifier() {
			if (this.lp.subject_case_identifier) return this.lp.subject_case_identifier
			return this.$store.state.subjects[this.lp.subject]?.framework_identifier	// may return null
		},
		framework_record() {
			let o = this.$store.state.framework_records.find(x=>x.lsdoc_identifier == this.case_framework_identifier)
			if (empty(o)) return {}
			else return o
		},
		unit_standards_tree() {
			if (!this.$store.state.frameworks_loaded) {
				if (!this.tried_to_load_lsdoc_list) {
					this.tried_to_load_lsdoc_list = true
					console.log('get_lsdoc_list!!')
					this.$store.dispatch('get_lsdoc_list')
				}
				return
			}
			if (!this.framework_record.framework_json_loaded && this.case_framework_identifier && this.case_framework_identifier != 'none') {
				if (!this.tried_to_load_framework) {
					this.tried_to_load_framework = true
					console.log('loading!! ' + this.case_framework_identifier)
					this.$store.dispatch('get_lsdoc', this.case_framework_identifier)
				}
				return
			}

			console.log('unit_standards_tree', this.framework_record)
			let arr = []
			let current_parent = null
			let current_parent_hcs = ''
			let standard_children_showing = {}

			for (let ust of this.unit.standards) {
				let cfitem = this.framework_record.json?.CFItems.find(x=>x.identifier == ust.identifier)
				if (!cfitem) {
					cfitem = ust.case_item
					console.log('couldn’t find cfitem in full framework')
				}

				let hcs = cfitem.humanCodingScheme
				standard_children_showing[cfitem.identifier] = true

				if (!empty(current_parent_hcs) && hcs?.indexOf(current_parent_hcs) > -1) {
					current_parent.children.push(cfitem)
					continue
				}

				let o = {
					cfitem: cfitem,
					children: [],
				}
				arr.push(o)
				current_parent = o
				current_parent_hcs = hcs

				this.standards_tree_loaded = true
			}

			// all children should be showing by default
			this.standard_children_showing = standard_children_showing

			return arr
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		standard_clicked(case_item) {
			// show the framework, highlight this item, and send in selected_items and limit_to_selected_items if there
			let data = { 
				framework_identifier: case_item.framework_identifier, 
				item_identifier: case_item.identifier, 
				no_framework_reset: true,
			}

			if (!data.framework_identifier) {
				// if case_item doesn't have a framework_identifier, it was probably selected before we started recording this.
				// so trace up through parents looking for a case_framework_identifier value; we should find it in in CourseView
				let parent = this.$parent
				while (parent) {
					if (parent.case_framework_identifier) { data.framework_identifier = parent.case_framework_identifier; break; }
					parent = parent.$parent
				}
			}

			// add all unit standards as selected_items
			if (this.unit.standards.length > 0) {
				data.selected_items = []
				for (let standard of this.unit.standards) data.selected_items.push(standard.case_item.identifier)
				data.limit_to_selected_items = 'only'
			}

			vapp.$refs.satchel.execute('show').then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
				})
			})
		},

		toggle_standard_children(identifier) {
			if (!this.standard_children_showing[identifier]) {
				this.$set(this.standard_children_showing, identifier, true)
			} else {
				this.standard_children_showing[identifier] = false
			}
		},

		find_resources() {
			this.$alert('Find resources associated with this standard…')
		},
	}
}
</script>

<style lang="scss">
.k-lp-unit-standard {
	// border-top:1px solid #ccc;
	// border-radius:5px;
	padding-top:8px;
	margin:0 12px;
	font-size:14px;
	line-height:18px;
}

.k-lp-unit-standard-top-category {
	font-weight:bold;
	color:$v-deep-orange-darken-4;
	margin-top:8px;
}

.k-lp-unit-standard-statement-wrapper {
	display:flex;
	align-items: flex-start;
}

.k-lp-unit-standard-statement {
	cursor:pointer;
}
.k-lp-unit-standard-statement:hover {
	text-decoration:underline;
}

.k-lp-unit-standard-children {
	margin-left:32px;
	margin-bottom:8px;
}
</style>
