<!--
 This hosts the AdminResourceUsage report component in a non-admin/non-superuser context.
 The Resource Usage report can (as of 08-22-24) be viewed by non-admin users who have been
 granted Resource Usage Report ('res_rep') level permissions by an admin.
 -->
<template>
	<div class="d-flex justify-center align-center">
		<div class="k-resource-report">
			<AdminResourceUsage />
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import AdminResourceUsage from './../admin/AdminResourceUsage'

export default {
	components: { AdminResourceUsage },
	props: {},
	data() { return {} },
	computed: {
		...mapState([]),
	},
	created() {
		// This is only available if user has superuser or Resource Report ('res_rep') admin rights
		if (!vapp.has_admin_right('su') && (!vapp.has_admin_right('res_rep'))) {
			this.$router.push({ path: '/home' })
		}
	},
	mounted() { },
	methods: {}
}
</script>

<style lang="scss" scoped>
.k-resource-report {
	text-align: left;
	width: 100vw;
	margin-top: -30px;
	display: inline-block;
	overflow: auto;
}
</style>
