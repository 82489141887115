<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<v-dialog v-model="dialog_open" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999">
				<b>Resource Completion Report:</b>
				&nbsp;
				<span v-html="collection.title"></span>&nbsp;/&nbsp;<span v-html="unit.title"></span>
				<v-spacer/>
				<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" fab x-small class="mr-3" color="secondary" @click="get_todo_report_data"><v-icon small>fas fa-sync-alt</v-icon></v-btn></template>Refresh report data</v-tooltip>
				<v-btn small color="secondary" @click="download_report"><v-icon small class="mr-1">fas fa-download</v-icon> Download Report</v-btn>
			</v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">

				<div class="py-4 d-flex align-center">
					<v-select outlined dense background-color="#fff" hide-details class="" label="Show report for division/school…" placeholder="" :items="groups" v-model="todo_report_group_showing"></v-select>
					<v-spacer/>
					<v-checkbox class="mt-0 pt-0 mx-3" :label="'Show users with no completions'" v-model="todo_report_show_empty_users" hide-details></v-checkbox>
					<v-spacer/>
					<v-text-field
						v-model="search"
						prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
						label="Search" single-line hide-details outlined dense background-color="#fff" style="flex:0 1 450px"
					></v-text-field>
				</div>

				<v-data-table v-if="todo_report_group_showing" light dense
					:headers="headers"
					:items="rows"
					:search="search"
					:footer-props="footer_options"
					:items-per-page="100"
					class="k-todo-report-table"
				>
					<template v-slot:item="{ item }"><tr>
						<td><nobr><v-btn icon x-small class="mr-1" @click="show_user_report(item.raw_data.user_id)"><v-icon>fas fa-arrow-circle-right</v-icon></v-btn><span v-html="item.user_name"></span></nobr><br><nobr style="padding-left:24px"><a :href="`mailto:${item.email}`">{{item.email}}</a></nobr></td>
						<td style="font-size:12px; line-height:15px"><span v-html="item.todo_user_group_display"></span></td>
						<td class="text-center"><b>{{item.complete_pct}}</b></td>
						<td v-for="(header, index) in headers" v-if="index >= 3" class="text-center">
							<!--note that the todo_status for a video may be 5-95, indicating partial completion-->
							<nobr v-if="item.raw_data.todo_status[header.value] > 100"><v-icon small color="green" class="mr-2">fas fa-check</v-icon> {{item.last_completion_date}}</nobr>
						</td>
					</tr></template>
				</v-data-table>

			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-spacer/>
				<v-btn color="primary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-times</v-icon> Close Report</v-btn></v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
	<TodoReportUser v-if="user_report_showing" :collection="collection" :user_id="user_report_showing" @dialog_cancel="hide_user_report" />
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import TodoReportUser from './../reports/TodoReportUser'

export default {
	components: { TodoReportUser, },
	props: {
		collection: { type: Object, required: true },
		unit: { type: Object, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		footer_options: {
			itemsPerPageOptions: [10,50,100,-1],
		},
		search: '',
		resource_ids: [],
		user_report_showing: false,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		todo_report_show_empty_users: {
			get() { return this.$store.state.lst.todo_report_show_empty_users },
			set(val) { this.$store.commit('lst_set', ['todo_report_show_empty_users', val]) }
		},
		todo_report_group_showing: {
			get() { return this.$store.state.todo_report_group_showing },
			set(val) {
				this.$store.commit('set', ['todo_report_group_showing', val])
			}
		},
		todo_report_department_showing() {
			if (this.todo_report_group_showing == 'all') return 'all'
			let o = this.groups.find(x=>x.value == this.todo_report_group_showing)
			if (!o) return '???'	// shouldn't happen
			return o.text
		},
		// remember that this, and many other things here, are repeated in TodoReporCollectionUnit
		groups() {
			let arr = []

			let divisions_added = 0
			let schools_added = 0

			// add divisions
			for (let uuid in this.$store.state.todo_user_group_divisions) {
				// skip divisions the user can't see
				if (!vapp.has_admin_right('pd_rep.' + uuid)) continue

				++divisions_added
				arr.push({value: uuid, text: this.$store.state.todo_user_group_divisions[uuid]})
			}

			// add schools
			for (let uuid in this.$store.state.todo_user_group_schools) {
				// skip schools the user can't see
				if (!vapp.has_admin_right('pd_rep.' + uuid)) continue

				++schools_added
				arr.push({value: uuid, text: this.$store.state.todo_user_group_schools[uuid]})
			}

			// if the user is allowed to access at least one division AND at least one school, add headers
			if (schools_added > 0 && divisions_added > 0) {
				// insert schools header after the last division
				arr.splice(divisions_added, 0, {header: '--- SCHOOLS ---'})

				// insert divisions header at the start
				arr.unshift({header: '--- DIVISIONS ---'})
			}

			// add 'all' at the start of the list if appropriate
			if (vapp.has_admin_right('pd_rep.all')) {
				arr.unshift({value: 'all', text: 'All divisions and schools'})
			}

			// if the menu only has one item, select it after the component has time to load
			if (arr.length == 1) {
				setTimeout(x=>{
					this.todo_report_group_showing = arr[0].value
				}, 100)
			}

			return arr
		},
		headers() {
			const add_items_from_folder = (folder_id) => {
				let arr = []
				let top_folder_item_seq = 10000
				// for each resource...
				for (let resource of this.unit.resources) {
					// skip resources that aren't marked todo
					if (!resource.todo) continue

					// look for a folder_assignment for the resource
					let folder_assignment = this.unit.resource_tree.folder_assignments.find(x=>x.resource_id==resource.resource_id)

					// if not found, assume it's a top-level item, use top_folder_item_seq for the array sequence value (putting it at the end of the top folder)
					if (empty(folder_assignment)) {
						folder_assignment = {
							parent_folder_id: 'top',
							seq: top_folder_item_seq
						}
						++top_folder_item_seq
					}

					// if this resource goes in this folder, add it in seq order
					if (oprop(folder_assignment, 'parent_folder_id') == folder_id) {
						let title = resource.description.substr(0, 12)
						if (title != resource.description) title += '…'
						arr[folder_assignment.seq] = {
							text: title,
							align: 'center',
							sortable: true,
							value:resource.resource_id,
						}
					}
				}

				// now deal with sub-folders, recursing to add an array of items for each sub-folder
				for (let folder of this.unit.resource_tree.folders) {
					if (folder.parent_folder_id == folder_id) {
						arr[folder.seq] = add_items_from_folder(folder.folder_id)
					}
				}

				return arr
			}

			// get the initial structure, which will be a tree
			let tree = add_items_from_folder('top')

			// now flatten the tree into an array with the items in the same order as shown in the unit folder structure
			let arr = []
			this.resource_ids = []	// also build up the list of resource_ids we're showing
			const flatten_tree = (tarr) => {
				for (let item of tarr) {
					if (empty(item)) continue	// skip empty index values

					if ($.isArray(item)) {
						flatten_tree(item)
					} else {
						this.resource_ids.push(item.value)
						arr.push(item)
					}
				}
			}
			flatten_tree(tree)

			// add user, division/school, and % columns on top
			arr.unshift({ text: '% Complete', align: 'center', sortable: true, value:'complete_pct' })
			arr.unshift({ text: 'Division or School', align: 'left', sortable: true, value:'todo_user_group_display' })
			arr.unshift({ text: 'User', align: 'left', sortable: true, value:'user_name' })

			return arr
		},
		todo_report_group_data() {
			// if the user hasn't yet chosen a group, or if that group's data hasn't been loaded, return an empty array; otherwise return the group's data array
			if (empty(this.todo_report_group_showing)) return []
			let arr = this.$store.state.todo_report_group_data[this.todo_report_group_showing]
			if (empty(arr)) return []
			else return arr
		},
		rows() {
			let arr = []

			for (let user of this.todo_report_group_data) {
				// skip users with 'xxx' in the email
				if (user.email.includes('xxx')) {
					continue
				}

				// if we have a district_department value for this user, don't show the user unless the selected group is one of the district_department values
				if (this.todo_report_department_showing != 'all') {
					if (user.district_department && user.district_department.length > 0) {
						if (!this.correct_school_errors(user.district_department).includes(this.todo_report_department_showing)) {
							continue
						}
					}
				}

				// get todo_user_group_display: first try using district_department values; use todo_user_group value as a backup
				let todo_user_group_display = '<b style="color:#999">Not chosen</b>'
				if (user.district_department && user.district_department.length > 0) {
					todo_user_group_display = user.district_department.join(', ')
				} else if (user.todo_user_group) {
					if (this.$store.state.todo_user_group_divisions[user.todo_user_group]) {
						todo_user_group_display = this.$store.state.todo_user_group_divisions[user.todo_user_group] + '*'
					} else if (this.$store.state.todo_user_group_schools[user.todo_user_group]) {
						todo_user_group_display = this.$store.state.todo_user_group_schools[user.todo_user_group] + '*'
					} else {
						todo_user_group_display = '???'
					}
				}

				// count number of complete resources
				let n_complete = 0
				let last_completion_date = 0
				for (let resource_id of this.resource_ids) {
					// note that the todo_status for a video may be 5-95, indicating partial completion
					if (user.todo_status[resource_id] > 100) {
						++n_complete
						if (user.todo_status[resource_id] > last_completion_date) last_completion_date = user.todo_status[resource_id]
					}
				}

				// if todo_report_show_empty_users is false, skip users with no competions
				if (!this.todo_report_show_empty_users && n_complete == 0) continue

				let row = {
					user_name: user.last_name + ', ' + user.first_name,
					email: user.email,
					raw_data: user,
					// in complete_pct, account for case where we actually don't have any resources to show here
					complete_pct: (this.resource_ids.length == 0) ? '' : Math.round(n_complete / this.resource_ids.length * 100) + '%',
					last_completion_date: (last_completion_date == 0) ? '' : date.format(new Date(last_completion_date*1000), 'M/D/Y'),
					todo_user_group_display: todo_user_group_display,
				}

				arr.push(row)
			}

			// by default sort by name
			arr.sort((a,b)=>{
				if (a.user_name < b.user_name) return -1
				if (b.user_name < a.user_name) return 1
				return 0
			})

			return arr
		},
	},
	watch: {
		todo_report_group_showing: { immediate: true, handler() {
			if (!empty(this.todo_report_group_showing) && this.todo_report_group_data.length == 0) {
				this.get_todo_report_data()
			}
		}},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		get_todo_report_data() {
			let payload = {
				todo_user_group: this.todo_report_group_showing
			}
			if (this.todo_report_department_showing != 'all') {
				payload.department = this.todo_report_department_showing
			}
			this.$store.dispatch('get_todo_report_data', payload)
		},

		show_user_report(user_id) {
			this.user_report_showing = user_id*1
		},

		hide_user_report() {
			this.user_report_showing = false
		},

		download_report() {
			let filename = sr('Resource Completion Report - $1 - $2.csv', this.collection.title, this.unit.title)

			let table = [['User', 'Division/School', '% Complete']]
			for (let i = 3; i < this.headers.length; ++i) {
				table[0].push(this.headers[i].text.replace(/…/g, '...'))
			}
			for (let row of this.rows) {
				let user_name = $('<div>' + row.user_name + '</div>').text()
				let group = $('<div>' + row.todo_user_group_display + '</div>').text()
				let arr = [user_name, group, row.complete_pct]

				for (let i = 3; i < this.headers.length; ++i) {
					let resource_id = this.headers[i].value
					// note that the todo_status for a video may be 5-95, indicating partial completion
					if (row.raw_data.todo_status[resource_id] > 100) {
						arr.push('X')
					} else {
						arr.push('')
					}
				}
				table.push(arr)
			}

			U.download_file(CSV.stringify(table), filename)
		},

		correct_school_errors(dept_arr) {
			let arr = []
			for (let s of dept_arr) {
				if (s.includes('Carmel')) s = 'Mount Carmel Elementary'
				if (s.includes('Pate')) s = 'Pates Creek Elementary'
				if (s.includes('Timber')) s = 'Timber Ridge Elementary'
				s = s.replace(/^Eagle.* (\w+)$/, 'Eagles Landing $1')
				arr.push(s)
			}
			return arr
		},
	}
}
</script>

<style lang="scss">
</style>
