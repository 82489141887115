<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="d-flex justify-center align-center">
	<MiniNav v-show="admin_tool_showing" current_section="admin" />
	<div class="k-main-collection" :class="admin_tool_showing?'k-main-collection--item-showing k-main-collection--admin-showing':''">
		<div class="k-main-collection-header">
			<h2 class="k-page-title d-flex">
				<v-icon large color="primary" class="mr-4" style="margin-top:-2px">fas fa-gear</v-icon>
				<b>Site Admin Tools</b>
				<v-spacer/>
			</h2>
		</div>
		<div class="k-main-collection-body">
			<div @click="go_to_admin_route('users')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between white--text" :style=" { 'background-color':'#c62828' }" :class="admin_tool_showing=='users'?'k-admin-item-showing':''"><div><b>Admin User Privileges</b></div><div><v-icon large color="#fff">fas fa-user-cog</v-icon></div></div>
			<div @click="go_to_admin_route('home_page')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between white--text" :style=" { 'background-color':'#ad1457' }" :class="admin_tool_showing=='home_page'?'k-admin-item-showing':''"><div><b>Home Page Content</b></div><div><v-icon large color="#fff">fas fa-home</v-icon></div></div>
			<div @click="go_to_admin_route('lp')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between white--text" :style="{ 'background-color':'#4527a0' }" :class="admin_tool_showing=='lp'?'k-admin-item-showing':''"><div><b>Content Collections</b></div><div><v-icon large color="#fff">fas fa-chalkboard</v-icon></div></div>
			<div v-if="enable_admin_lti_management" @click="go_to_admin_route('lti')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between white--text" :style="{ 'background-color':'#283593' }" :class="admin_tool_showing=='lti'?'k-admin-item-showing':''"><div><b>{{site_config.sparkl_app_name}} LTI Installations</b></div><div><v-icon large color="#fff">fas fa-plug</v-icon></div></div>
			<div @click="go_to_admin_route('usage')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between white--text" :class="admin_tool_showing=='usage'?'k-admin-item-showing':''" :style="{ 'background-color': '#1565c0' }"><div><b>Site Usage Stats</b></div><div><v-icon large color="#fff">fas fa-chart-line</v-icon></div></div>
			<div @click="go_to_admin_route('editlog')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between white--text" :class="admin_tool_showing=='editlog'?'k-admin-item-showing':''" :style="{ 'background-color': '#0277bd' }"><div><b>Course Editing Logs</b></div><div><v-icon large color="#fff">fas fa-person-digging</v-icon></div></div>
			<div @click="go_to_admin_route('resource_usage')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between white--text" :class="admin_tool_showing=='resource_usage'?'k-admin-item-showing':''" :style="{'background-color': '#00838f'}"><div><b>Resource Usage Report</b></div><div><v-icon large color="#fff">fas fa-table</v-icon></div></div>
			<div v-if="enable_site_issue_reports" @click="go_to_admin_route('site_issue_reports')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between white--text" :class="admin_tool_showing=='site_issue_reports'?'k-admin-item-showing':''" :style="{ 'background-color': '#00838f' }"><div><b>Site Issue Reports</b></div><div><v-icon large color="#fff">fas fa-bug</v-icon></div></div>
			<div v-if="enable_admin_term_management" @click="go_to_admin_route('terms')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between white--text" :class="admin_tool_showing == 'terms' ? 'k-admin-item-showing' : ''" :style="{ 'background-color': '#00838f' }"><div><b>Term Management</b></div><div><v-icon large color="#fff">fas fa-calendar</v-icon></div></div>
			<!-- <div @click="go_to_admin_route('lessonmasters')" class="k-elevated k-main-collection-item pb-5 pt-7 justify-space-between k-list-color-6" :class="admin_tool_showing=='lessonmasters'?'k-admin-item-showing':''"><div><b>Lesson Masters</b></div><div><v-icon large color="#000">fas fa-file-code</v-icon></div></div> -->
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import MiniNav from '../main/MiniNav'

export default {
	components: { MiniNav },
	props: {
	},
	data() { return {
	}},
	computed: {
		...mapState(['user_info', 'site_config']),
		admin_tool_showing() {
			if (this.$route.fullPath.search(/admin\/(\w+)\b/) > -1) {
				return RegExp.$1
			} else {
				return null
			}
		},
		enable_admin_term_management() {
			return this.site_config.enable_admin_term_management === 'yes'
		},
		enable_site_issue_reports() {
			// site issue reports is alsos used for the enhanced lesson plan editor
			return this.site_config.show_submit_feedback_option_for_resources === 'yes' || this.site_config.enable_llm_lesson_plans != 'no'
		},
		enable_admin_lti_management() {
			return this.site_config.enable_admin_lti_management === 'yes'
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		go_to_admin_route(which) {
			this.$router.push({ path: '/admin/' + which })
		},
		// // MC: Not used???
		// tile_color(i) {
		// 	return {
		// 		'background-color': `${this.site_config.swatches[i-1][0]} !important`
		// 	}
		// }
	}
}
</script>

<style lang="scss">
.k-main-collection--admin-showing {
	display: inline-block;
	padding:0px 10px!important;
	overflow:auto;

	.k-main-collection-header {
		display:none!important;
	}

	.k-main-collection-body {
		flex-wrap:nowrap;
	}

	.k-main-collection-item {
		width:100px;
		height:100px;
		border-radius:16px;
		opacity:0.6;
		margin:18px 8px;
		background-color:#f8f8f8;
		font-size:13px!important;
		line-height:16px;

		.v-icon {
			font-size:20px!important;
		}
	}

	.k-admin-item-showing {
		opacity:1.0!important;
	}
}
</style>
