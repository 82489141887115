import { mapState, mapGetters } from 'vuex'
export default {
	data() { return {
		fco: null,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
	},
	methods: {
		froala_insert_resource_callback(fco) {
			this.fco = fco
			this.fco.froala_editor.html.insert('<link class="k-lesson-component-resource-link-placeholder fas fa-ellipsis-h">')
			this.create_resource_start()
		},

		insert_resource_cancel() {
			if (this.fco) {
				let html = this.fco.froala_editor.html.get()
				html = html.replace(/<link class="k-lesson-component-resource-link-placeholder.*?>/g, '')
				this.fco.froala_editor.html.set(html)
				this.fco.froala_component.updateModel()
			}
			this.fco = null
		},

		insert_resource_selected(resource) {
			let title = resource.description.replace(/"/g, '\\"')
			// note nbsp's on either side, to make sure we can edit around the resource link
			let link = sr('&nbsp;<link class="k-lesson-component-resource-link fas $1" title="$2" data-resource-link-id="$3" onclick="vapp.open_resource_link(\'$4\',this)">&nbsp;', resource.icon(), title, U.new_uuid(), resource.resource_id)

			let html = this.fco.froala_editor.html.get()
			html = html.replace(/<link class="k-lesson-component-resource-link-placeholder.*?>/, link)
			this.fco.froala_editor.html.set(html)
			this.fco.froala_component.updateModel()

			this.fco = null
		},

		froala_inserted_resource_clicked(fco, resource_id, resource_description, froala_resource_link_id) {
			// this gets called when the user clicks a previously-inserted resource from the froala editor.
			// - fco is the get_froala_component return value
			// - resource_id is the resource_id of the resource (duh)
			// - froala_resource_link_id is a uuid we assign to the link, so that we can easily remove it here if needed

			// show a confirm dialog with a button to preview the resource, along with its description
			let btn = sr('<button type="button" class="v-btn v-btn--is-elevated v-btn--has-bg theme--dark v-size--small primary" onclick="vapp.open_resource_link(\'$1\')"><span class="v-btn__content"><i aria-hidden="true" class="v-icon notranslate mr-2 fas fa-arrow-up-right-from-square theme--dark" style="font-size: 16px;"></i>Preview</span></button>', resource_id)
			let text = sr('<div class="d-flex justify-center align-center"><div>$1</div><div class="ml-2">$2</div></div>', resource_description, btn)

			this.$confirm({
			    title: 'Resource Link',
			    text: text,
			    acceptText: 'Remove Resource Link',
				acceptColor: 'red darken-2',
				dialogMaxWidth: 600
			}).then(y => {
				// if we're here, the user said to remove the resource link
				let html = fco.froala_editor.html.get()
				let re = new RegExp(sr('<link[^>]*?data-resource-link-id="$1"[^>]*?>', froala_resource_link_id))
				html = html.replace(re, '')
				fco.froala_editor.html.set(html)
				fco.froala_component.updateModel()

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},
	}
}
