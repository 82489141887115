<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
	<v-card-text :id="print_lesson_dom_id" class="k-lesson-card-text">
		<div v-if="student_view && (activity.activity_instructions || standards.length>0)" class="k-activity-instructions fr-view mt-1 mb-3 pt-2">
			<div v-if="standards.length>0" class="mb-2 pb-2" :style="activity.activity_instructions?'border-bottom:1px solid #999; padding-bottom:4px':''">
				<div class="k-activity-instructions-header">Learning Standards</div>
				<div class="d-flex flex-wrap mx-2"><CASEItemBtn v-for="(s) in standards" :key="s.identifier" :item="s" satchel_determines_framework_id="yes" :selected_items="standards" small :outer_class="'ma-1'" /></div>
			</div>
			<div v-if="activity.activity_instructions" class="k-activity-instructions-body">
				<v-icon small color="light-blue darken-1">fas fa-info-circle</v-icon>
				<div v-if="activity.activity_instructions" v-html="activity_instructions_formatted"></div>
			</div>
		</div>
		<div class="k-activity-header">
			<div class="d-flex align-start mb-1">
				<div v-if="student_view" class="mr-6">
					<v-btn small color="teal darken-2" :style="disable_student_activity?'opacity:0.5':''" class="k-tight-btn" dark @click="show_student_activity"><v-icon small class="mr-2">fas fa-rocket</v-icon>Launch Activity</v-btn>
				</div>
				<div v-if="student_view" class="mr-2" v-html="student_status">
					<div class="k-status k-status-not-started">Not started</div>
				</div>
				<div v-if="!student_view&&is_activity_creator&&gradebook_display" class="text-center mr-2">
					<v-icon small class="mr-1">fas fa-table-cells</v-icon>
					<span v-html="gradebook_display"></span>
				</div>
				<!-- <div v-if="!student_view&&activity.assigned_to.length==0&&is_activity_creator" class="text-center mr-2">/</div>
				<div v-if="!student_view&&activity.assigned_to.length==0&&is_activity_creator" class="text-center mr-2"><nobr>Not assigned to students</nobr></div> -->
				<div v-if="!student_view&&!is_activity_creator" class="text-center mr-2">Activity created by {{ activity_creator_name }}</div>
				<v-spacer/>
				<div class="text-center ml-2 mr-4">
					<div v-if="activity.available_date()==''" class="d-flex align-center"><nobr><v-icon small class="mr-2">fas fa-calendar-day</v-icon><b>Not available to students</b></nobr></div>
					<div v-else><v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on" class="d-flex align-center"><v-icon small class="mr-2">fas fa-calendar-day</v-icon><div class="mr-2">Available:</div><div v-html="activity.available_date_header()"></div></div></template>Date this activity <b>becomes available</b> to students<div v-if="activity.has_multiple_available_dates()" class="text-center">*Multiple dates assigned</div></v-tooltip></div>
				</div>
				<div class="text-center" v-if="activity.available_date()!=''">
					<div v-if="activity.due_date()==''"><nobr><v-icon small class="mr-2" style="transform:scaleX(-1)">fas fa-calendar-day</v-icon><b>No Due Date</b></nobr></div>
					<div v-else><v-tooltip bottom><template v-slot:activator="{on}"><div v-on="on" class="d-flex align-center"><v-icon small class="mr-2" style="transform:scaleX(-1)">fas fa-calendar-day</v-icon><div class="mr-2">Due:</div><div v-html="activity.due_date_header()"></div></div></template>Date this activity <b>should be completed</b> by students<div v-if="activity.has_multiple_due_dates()" class="text-center">*Multiple dates assigned</div></v-tooltip></div>
				</div>
				<div v-if="student_view" class="ml-4">
					<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-on="on" fab x-small color="light-blue darken-2" dark @click="create_message"><v-icon small style="margin-bottom:-3px">fas fa-comment</v-icon></v-btn></template>Message Teacher</v-tooltip>
				</div>
			</div>
			<div v-if="!student_view&&activity.assigned_to.length>0" class="mt-2">
				<div class="text-center" style="">
					<div><v-icon small class="mr-2">fas fa-users</v-icon>Assigned to: <b>{{assigned_student_count}} Student{{assigned_student_count==1?'':'s'}}</b><v-btn x-small color="#ddd" class="ml-2 elevation-0" @click="show_all_assignees=!show_all_assignees">Details</v-btn></div>
					<div class="mt-2" v-show="show_all_assignees"><div class="ma-1" v-for="(assignee) in activity.assigned_to" :key="assignee.activity_student_mapping_id" color="#ddd" v-html="assignee.toString(null,true)"></div></div>
				</div>
			</div>
		</div>
		<div v-if="!student_view">
			<div v-if="activity.activity_description" class="fr-view mt-5 text-center" style="font-size:16px; font-weight:bold;"><v-icon style="margin-top:-4px" small class="mr-2">fas fa-star</v-icon><span v-html="activity.activity_description"></span></div>

			<div v-if="activity.activity_instructions||standards.length>0" class="k-activity-instructions mt-4 pb-2 px-1">
				<div v-if="standards.length>0" class="d-flex pt-2" @click="toggle_standards" style="cursor:pointer">
					<v-btn icon x-small color="light-blue darken-2" class="mr-1" @click.stop="toggle_standards"><v-icon>fas {{activity.standards_showing?'fa-caret-down':'fa-caret-right'}}</v-icon></v-btn>
					<div class="k-lesson-title-intro light-blue--text text--darken-2"><b>Learning Standards</b></div>
				</div>
				<v-expand-transition><div v-show="standards.length>0&&activity.standards_showing"><div class="d-flex flex-wrap my-1 ml-5 mr-2"><CASEItemBtn v-for="(s) in standards" :key="s.identifier" :item="s" satchel_determines_framework_id="yes" :selected_items="standards" small :outer_class="'ma-1'" /></div></div></v-expand-transition>

				<div v-if="activity.activity_instructions" class="d-flex pt-2" @click="toggle_activity_instructions" style="cursor:pointer">
					<v-btn icon x-small color="light-blue darken-2" class="mr-1" @click.stop="toggle_activity_instructions"><v-icon>fas {{activity.activity_instructions_showing?'fa-caret-down':'fa-caret-right'}}</v-icon></v-btn>
					<div class="k-lesson-title-intro light-blue--text text--darken-2"><b>Student Instructions</b></div>
				</div>
				<v-expand-transition><div v-show="activity.activity_instructions&&activity.activity_instructions_showing" class="fr-view mt-2">
					<div class="k-activity-instructions-body">
						<v-icon small color="light-blue darken-1">fas fa-info-circle</v-icon>
						<div v-html="activity_instructions_formatted"></div>
					</div>
				</div></v-expand-transition>
			</div>

			<div class="text-center mt-2 mb-0">
				<v-btn small color="primary" outlined text class="k-tight-btn k-nocaps-btn my-1 mx-1" @click="show_student_activity"><v-icon small class="mr-1">fas fa-rocket</v-icon><b class="mx-1">ADMINISTER</b> <span style="font-weight:normal">Student Activity</span></v-btn>
				<v-btn small text class="k-tight-btn k-nocaps-btn my-1 mx-1" color="primary" outlined @click="show_student_preview"><v-icon small class="mr-2">fas fa-glasses</v-icon><span style="font-weight:normal">Student</span> <b class="ml-1">PREVIEW</b></v-btn>
				<v-btn :style="!can_edit_assignment?'opacity:0.5':''" small text class="k-tight-btn k-nocaps-btn my-1 mx-1" color="primary" outlined @click="edit_assignment"><v-icon class="mr-1" small>fas fa-edit</v-icon> <b class="mr-1">EDIT</b> <span style="font-weight:normal">Assignment</span></v-btn>
				<v-btn :style="!can_edit_assignment?'opacity:0.5':''" small text class="k-tight-btn k-nocaps-btn my-1 mx-1" color="primary" outlined @click="create_message"><v-icon class="mr-1" small>fas fa-comment</v-icon><span style="font-weight:normal">Create</span> <b class="ml-1">MESSAGE</b></v-btn>
			</div>
		</div>

		<!-- <div v-if="sparkl_loaded" v-show="sparkl_showing" class="mt-2"> -->
			<!-- Note: we used to force_maximize only for student view -->
			<!-- <SparklEmbed :activity="activity" :force_maximize="true" :role_override="sparkl_role_override" :coteacher_role="coteacher_role" :key="sparkl_embed_key" @close_sparkl="hide_sparkl" @student_results_update="student_results_update" @activity_saved="sparkl_activity_saved"  />
		</div> -->

		<!-- EMBEDDED GOOGLE ASSIGNMENT TOOL -->
		<!-- <GoogleEmbed v-if="google_showing" ref="google_embed" :force_maximize="true" :activity="activity" @close_google="close_google_from_embed" /> -->

	</v-card-text>
</template>

<script>
import goTo from 'vuetify/lib/services/goto'
import { mapState, mapGetters } from 'vuex'
// import SparklEmbed from '../lessons/SparklEmbed'
// import GoogleEmbed from '../lessons/GoogleEmbed'
import CASEItemBtn from '../standards/CASEItemBtn'

export default {
	name: 'DirectiveActivityView',
	components: {
		// have to this this way because of recursion in the components...
		ResourceCollectionItem: () => import('../resources/ResourceCollectionItem')
		, CASEItemBtn			// , SparklEmbed, GoogleEmbed
	},
	props: {
		activity: { required: true },
		print_lesson_dom_id: { type: String, required: false, default() { return U.new_uuid() } },
	},
	data() { return {
		standard_children_showing: {},
		google_showing: false,
		show_all_assignees: false,
		sparkl_closed_from_embed: false,
	}},
	computed: {
		...mapState(['user_info', 'my_activity_results', 'single_item', 'single_item_mode', 'my_coteaching_courses']),
		...mapGetters(['role', 'system_role', 'simulating_user']),
		student_view() { 
			if (this.single_item_mode == 'studentpreview') return true
			return this.role != 'staff' && this.role != 'admin' 
		},
		is_activity_creator() { return this.activity.creator_user_id == this.user_info.user_id },
		origin_teacher() {
			// PW: is this really sufficient?
			const o = this.my_coteaching_courses.find(c => c.origin_teacher_user_id == this.activity.creator_user_id && c.course_code == this.activity.course_code)
			if (o) return o
			else return null
		},
		activity_creator_name() {
			// If this activity is being viewed by a coteacher, we want to list the name of the activity creator
			if (!this.origin_teacher) return '???'	// we shouldn't be calling this if there isn't an origin teacher
			else return (`${this.origin_teacher.origin_teacher_first_name} ${this.origin_teacher.origin_teacher_last_name}`)
		},
		coteacher_role() {
			if (!this.student_view && !this.is_activity_creator) {
				if (this.origin_teacher.permission_level == 2) return 'edit'
				if (this.origin_teacher.permission_level == 1) return 'grade'
				return 'view'
			}
			return ''
		},
		can_edit_assignment() {
			if (this.is_activity_creator) return true
			if (this.coteacher_role == 'edit') return true
			return false
		},
		activity_type() { return this.activity.activity_type },
		directive_type_icon() { return U.directive_type_icon(this.activity_type) },
		directive_type_label() { return U.directive_type_label(this.activity_type) },
		available_to_students() { return this.activity.available_to_students() },
		activity_instructions_formatted() {
			let s = this.activity.activity_instructions
			if (empty(s)) return ''
			s = s.replace(/<a /g, '<a target="_blank" ')
			return s
		},
		sparkl_role_override() { 
			// if this is a teacher simulating a student, we have to send a role_override of 'student'
			if (this.student_view && (this.system_role == 'staff' || this.system_role == 'admin')) return 'student'
			return ''
		},
		activity_result() {
			// if we don't already have an activity_result, create one here
			if (empty(this.my_activity_results[this.activity.activity_id])) {
				// note that we have to cast the activity_id as a string when we do the set commit, because my_activity_results is an object
				this.$store.commit('set', [this.my_activity_results, this.activity.activity_id+'', new Activity_Result(null, this.activity, this.user_info)])
			}
			return this.my_activity_results[this.activity.activity_id]
		},
		student_status() {
			let score = ''
			if (this.activity_result.started()) {
				score = sr('<b>$1%</b>', Math.round(this.activity_result.score * 100))
			}
			
			if (this.activity.due_date_has_passed() && !this.activity_result.complete()) {
				if (!this.activity_result.started()) return '<div class="k-status k-status-past-due">Past Due / Not Started</div>'
				return sr('<div class="k-status k-status-past-due">Past Due / Started</div> Current Score: $1', score)
			}
			if (!this.activity_result.started()) return '<div class="k-status k-status-not-started">Not Started</div>'
			if (this.activity_result.complete()) return sr('<div class="k-status k-status-complete mr-2">Complete</div> Current Score: $1', score)
			return sr('<div class="k-status k-status-started mr-2">Started</div> Current Score: $1', score)
		},
		assigned_student_count() {
			let students = []
			for (let assignee of this.activity.assigned_to) {
				let astudents = assignee.get_students()
				for (let student of astudents) {
					if (!students.includes(student)) students.push(student)
				}
			}
			return students.length
		},
		gradebook_display() {
			if (this.activity.assigned_to.length == 0) return ''

			if (!this.activity.added_to_gradebook) return 'Not added to gradebook'
			// if added to the gradebook and we have lineitems, show count of sections (you see the exact sections when you edit the assignment
			let count = 0
			for (let class_sourcedId in this.activity.lineItemSourcedId) ++count
			if (count == 0) return 'Added to gradebook, <nobr>no SIS columns configured</nobr>'	// this probably won't happen
			else return `<b class="pink--text text--darken-2">Sending grades to SIS</b> for ${U.ps('1 section', count, `${count} sections`)}`
		},
		disable_student_activity() {
			if (this.role == 'parent') return true
			if (this.simulating_user) return true
		},
		standards() {
			if (!this.activity?.resource?.standards) return []
			return this.activity.resource.standards
		},
	},
	watch: {
	},
	created() {
		vapp.activity_view_component = this
	},
	mounted() {
		// if we're show a single activity in 'admin' mode, open sparkl right away
		if (this.single_item_mode == 'admin') {
			this.show_student_activity()
		}
	},
	methods: {
		toggle_standard_children(identifier) {
			if (!this.standard_children_showing[identifier]) {
				this.$set(this.standard_children_showing, identifier, true)
			} else {
				this.standard_children_showing[identifier] = false
			}
		},

		toggle_standards(val) {
			if (typeof(val) !== 'boolean') val = !this.activity.standards_showing
			this.$store.commit('set', [this.activity, 'standards_showing', val])
		},

		toggle_activity_instructions(val) {
			if (typeof(val) !== 'boolean') val = !this.activity.activity_instructions_showing
			this.$store.commit('set', [this.activity, 'activity_instructions_showing', val])
		},

		student_results_update(data) {
			// this will be called when Sparkl sends data back about a student's activity status. data may include started (Boolean), complete (Boolean), time_spent (int), score (float), and teacher_grading_required (boolean)
			// note that this same fn is called from both the student view and the teacher view
			if (this.student_view) {
				// for a student viewing the activity, the update is for that student's results
				let send_update = false

				// score
				if (!empty(data.score) && data.score != this.activity_result.score) {
					this.$store.commit('set', [this.activity_result, 'score', data.score])
					send_update = true
				}

				// status
				let status = ''
				if (data.complete) status = 'complete'
				else if (data.started) status = 'started'
				else status = 'viewed'
				if (status != this.activity_result.status) {
					this.$store.commit('set', [this.activity_result, 'status', status])
					send_update = true
				}

				// time spent
				if (data.time_spent) {
					// only send update to the server if time_spent has changed by more than 15 seconds (unless we update the score or status)
					if (data.time_spent*1 > this.activity_result.time_spent*1 + 15) send_update = true
					this.$store.commit('set', [this.activity_result, 'time_spent', data.time_spent])
				}

				// teacher_grading_required
				if (!empty(data.teacher_grading_required)) {
					// only send update to the server if value has changed
					if (data.teacher_grading_required != this.activity_result.teacher_grading_required) send_update = true
					this.$store.commit('set', [this.activity_result, 'teacher_grading_required', data.teacher_grading_required])
				}

				// if anything changed, save this result to the DB, which will in turn pass the updated grade to IC if necessary
				if (send_update) {
					this.$store.dispatch('save_my_activity_result', this.activity.activity_id).then(()=>{
						// nothing to do on completion
					})
				}

			} else {
				// for a teacher viewing the activity, the update data will include the student_email; look the student up from there
				let student_sourcedId
				for (let sid in this.$store.state.all_students) {
					if (this.$store.state.all_students[sid].email == data.student_email) {
						student_sourcedId = sid
						break
					}
				}
				if (!student_sourcedId) {
					console.log('COULDN’T FIND STUDENT TO UPDATE: ' + data.student_email)
					return
				}

				let status = ''
				if (data.complete) status = 'complete'
				else if (data.started) status = 'started'
				else status = 'viewed'	// note: if the teacher resets the student's results, we will record the status as 'viewed'

				let ar = new Activity_Result({
					activity_id: this.activity.activity_id,
					// student_user_id: ???		// we don't know the student's user_id; that should be OK though
					student_sourcedId: student_sourcedId,
					time_spent: data.time_spent,
					status: status,
					score: data.score,
					teacher_grading_required: data.teacher_grading_required
				})

				let payload = {
					user_id: this.user_info.user_id,
					activity_result_data: JSON.stringify(ar.copy_for_save())
				}

				// determine if this student's results need to go into the IC gradebook.
				// if the activity is added_to_gradebook and we have lineItemSourcedId(s)...
				if (this.activity.added_to_gradebook && this.activity.lineItemSourcedId) {
					// go through each of the activity's class_sourcedIds that we have lineItemSourcedIds for...
					for (let class_sourcedId in this.activity.lineItemSourcedId) {
						// if we find this class_sourcedId in one of the teacher's sis_classes...
						for (let sis_course of this.$store.state.sis_classes) {
							let i = sis_course.class_sourcedIds.indexOf(class_sourcedId)
							if (i > -1) {
								// then if this student is in this class...
								if (sis_course.students[i].find(x=>x.sourcedId == student_sourcedId)) {
									// add this lineItemSourcedId, along with the student_sourcedId, to the payload; the service will send the score to the gradebook if it has changed
									payload.lineItemSourcedId = this.activity.lineItemSourcedId[class_sourcedId]
									payload.student_sourcedId = student_sourcedId
									break
								}
							}
						}
					}
				}

				// from the teacher side, Sparkl only sends this message back if something has changed (and therefore needs to be sent to the DB)
				U.ajax('save_activity_result_update_from_teacher', payload, result=>{
					if (result.status != 'ok') {
						console.log('Error in save_activity_result_update_from_teacher call'); vapp.ping();
					}
				});

			}
		},

		show_sparkl(force_reload) {
			// // embed_mode can be 'edit' or 'view'. if we receive a value, use it
			// if (typeof(embed_mode) != 'string') {
			// 	// else if the user has the ability to edit here, use 'edit'
			// 	if (this.option_availability.edit) embed_mode = 'edit'
			// 	// else use 'view'
			// 	else embed_mode = 'view'
			// 	// (note that the embed mode doesn't do much more than affect what options are shown in the TeacherStart component)
			// }

			vapp.$refs.sparkl_embed.show_activity({
				activity_record: this.activity,
				// activity_record: {
				// 	tool_activity_id: this.item_to_show.url,
				// 	lti_resource_link_id: this.item_to_show.resource_id,
				// 	activity_title: this.item_to_show.description,
				// 	creator_user_id: this.item_to_show.creator,
				// },
				force_reload: force_reload,
				// embed_mode: embed_mode,

				// if this is a teacher simulating a student, we have to send a role_override of 'student'
				role_override: (this.student_view && (this.system_role == 'staff' || this.system_role == 'admin')) ? 'student': '',
				
				// FROM ResourceCollectionItem if we're launching from the unit editor interface, launch with a flag that allows the original activity to be edited
				// force_allow_original_to_be_edited: this.in_unit_editor ? 'yes' : 'no',

				// if we're simulating another user, don't allow the user to edit (see below also)
				force_prevent_original_to_be_edited: (this.simulating_user) ? 'yes' : 'no',

				show_copy_for_my_use_btn: false,
				controller_component: this,
			})

			// // hide active dialogs/overlays while the activity shows (e.g. the unit editor might be showing)
			// $('.v-dialog__content--active, .v-overlay--active').hide()
		},

		copy_to_my_content() {
			// if we're simulating a user, this will be be called if the user tries to edit anything in the assignment (because force_prevent_original_to_be_edited is sent as 'yes'); tell the user they can't do so
			if (this.simulating_user) {
				this.$alert('You cannot edit an activity while simulating another user.')
			}
		},

		sparkl_activity_saved(data) {
			// if we're simulating a user, this will be called when the activity first opens, then again when it is closed
			if (this.simulating_user) {
				if (this.sparkl_closed_from_embed) this.close_sparkl_finish()
				return
			}

			// this is called when Sparkl issues a 'sparkl_activity_saved' message; see also analogous fn in DirectiveActivityEditor
			let changed = false
			let reload = false
			let activity_data = {activity_id: this.activity.activity_id}	// we only have to send updated data through to save_activity
			// if tool_activity_id is changed, set it (I don't think this should happen anymore, but leave it just in case)
			if (data.sparkl_activity_id && data.sparkl_activity_id != this.activity.tool_activity_id) {
				console.log('sparkl_activity_id updated...')
				// if the tool_activity_id wasn't empty and has been updated, we need to reload the iframe
				if (this.activity.tool_activity_id) reload = true

				this.$store.commit('set', [this.activity, 'tool_activity_id', data.sparkl_activity_id])
				activity_data.tool_activity_id = data.sparkl_activity_id
				changed = true
			}

			// build description from the rest of the data; if changed, set it
			if (data.stars_available) {
				let d = Activity.standard_activity_description(data.stars_available)
				
				if (this.activity.activity_description != d) {
					this.activity.activity_description = d
					changed = true
				}
			}

			// update activity_instructions and title if they change
			if (this.activity.activity_instructions != data.activity_instructions) {
				this.$store.commit('set', [this.activity, 'activity_instructions', data.activity_instructions])
				activity_data.activity_instructions = data.activity_instructions
				changed = true
			}
			if (this.activity.activity_title != data.activity_title) {
				this.$store.commit('set', [this.activity, 'activity_title', data.activity_title])
				activity_data.activity_title = data.activity_title
				changed = true
			}

			// if we changed something here, save the activity
			if (changed) {
				// NOTE: even if a student launched the activity, it is possible (though not probable) that something (e.g. the title) about the activity was changed by the teacher outside of the context of Cureum, 
				// in which case we *would* want to save the activity
				let payload = { 
					activity_data: activity_data,
					// by sending the tool_update_only flag, save_activity won't do any of the IC updating
					tool_update_only: 'yes',
				}

				console.log('saving activity in HC (from DirectiveActivityView)  ', payload)
				this.$store.dispatch('save_activity', payload).then((result)=>{
					// reload sparkl if necessary
					if (reload) this.show_sparkl(true)

					// else once activity is saved, if sparkl_closed_from_embed is true, finish closing sparkl
					else if (this.sparkl_closed_from_embed) this.$nextTick(()=>this.close_sparkl_finish())

				}).catch((result)=>{
					console.log(result)
					this.$alert('An error occurred...')
				})

				// return here so we don't call close_sparkl_finish too quickly below 
				return
			}

			// reload sparkl if necessary
			if (reload) this.show_sparkl(true)

			// else if we didn't have to save, and sparkl_closed_from_embed is true, finish closing sparkl here
			else if (this.sparkl_closed_from_embed) this.close_sparkl_finish()

		},

		close_sparkl() {
			U.loading_start()
			// send the host_activity_saved message TO sparkl, so that Sparkl saves anything that might have been edited there
			vapp.$refs.sparkl_embed.execute('host_activity_saved', {})

			this.sparkl_closed_from_embed = true
			// ... then once sparkl is done saving, sparkl_activity_saved will be called, and since sparkl_closed_from_embed is true, sparkl_activity_saved will call close_sparkl_finish
		},

		close_sparkl_finish() {
			U.loading_stop()
			this.sparkl_closed_from_embed = false
			vapp.$refs.sparkl_embed.hide_activity()

			// // re-show active dialogs/overlays
			// $('.v-dialog__content--active, .v-overlay--active').show()
		},

		show_student_activity() {
			if (this.role == 'parent') {
				this.$alert('Please ask your student to sign in and show you their work on their assignments.')
				return
			}

			// note that if user A is simulating user B, A will be able to view, but not edit, the activity
			if (this.activity.tool_id == 'google') {
				this.google_showing = true
			} else {
				this.show_sparkl(false)
			}
		},

		edit_assignment() {
			if (!this.can_edit_assignment) { this.$alert('This is an activity created by a coteacher. You do not have the ability to edit the activity assignment.'); return; }
			if (this.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			this.$emit('edit')
		},

		create_message() {
			if (this.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }
			if (!this.student_view && !this.can_edit_assignment) {
				this.$alert('This is an activity created by a coteacher. You do not have the ability to create activity messages.');
				return;
			}

			if (this.student_view)
			{
				// If student, we want to only message the teacher of this activity
				// To do so, we need to get the sis_user_sourcedId
				const payload = {
					user_id: this.user_info.user_id,
					creator_user_id: this.activity.creator_user_id,
				}
				U.loading_start()
				U.ajax('get_sourcedId_from_creator_user_id', payload, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						this.$alert('Error in ajax call: ' + result.status); vapp.ping(); return;
					}
					this.$emit('create_message', result.sis_user_sourcedId)
				})
			} else {
				this.$emit('create_message')
			}
		},

		close_google_from_embed() {
			this.google_showing = false
		},

		show_student_preview() {
			let url = window.location.origin + '/activity/' + this.activity.activity_id + '?studentpreview'
			window.open(url, 'preview_tab-'+this.activity.activity_id)
		},
	}
}
</script>

<style lang="scss">
// css currently re-used from LessonView
.k-activity-header {
	padding:12px 12px 0 12px;
	margin-top:8px;
	border-top:1px solid #ccc;
	border-bottom:0 solid #ccc;
	font-size:14px;
	line-height:18px;
	.fas { margin-top:-3px; }
}

.k-activity-instructions {
	max-width:680px;
	margin-left:auto;
	margin-right:auto;
	background-color:#e4e4e4;
	color:#000;
	border-radius:8px;
}

.k-activity-instructions-header {
	font-weight:900;
	font-size:14px;
	line-height:18px;
	text-transform: uppercase;
	padding:0 0 4px 12px;
	color:$v-light-blue-darken-2;
}

.k-activity-instructions-body {
	position:relative;
	padding:4px 12px 4px 32px;
	font-family:Roboto;
	font-size: 16px;
	line-height: 21px;

	.fa-info-circle {
		font-size:20px!important;
		position:absolute;
		left:8px;
		top:5px;
	}

	a {
		background-color:$v-indigo-lighten-4;
		color:$v-indigo-darken-3!important;
		border-radius:4px;
		padding:1px 5px;
		font-weight:bold;
		text-decoration:none;
		font-family:$sans-serif-font;
		// font-size:18px;
		text-transform:uppercase;
	}

	// match these to Sparkl
	p, ul, ol {
		margin-top:0!important;
		margin-bottom:16px!important;
	}
}

</style>
