<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
	<v-card-text :id="print_lesson_dom_id" class="k-lesson-card-text">
		<!-- LESSON_VARIANT B ("ENHANCED") -->
		<div v-if="lesson.lp_variant=='B'&&!show_print_dialog" class="k-lpe-alba-outer-outer">
			<!-- MENU FOR LESSON COMPONENTS/STAGES -->
			<div class="k-lpe-alba-menu-stages" style="margin-bottom:-1px">
				<div v-if="lesson.standards.length>0"><v-hover v-slot:default="{hover}"><div class="k-lpe-alba-menu-stage-outer k-lpe-alba-menu-stage-complete" :style="hover?'opacity:1':''">
					<div class="k-lpe-alba-menu-stage amber lighten-4" @click="go_to_stage('standards')">
						<v-icon style="width:20px" color="amber darken-4" small class="mr-2">fas fa-map</v-icon>
						<div class="k-lpe-alba-menu-stage-label">Learning Standards</div>
						<v-spacer/>
						<v-icon v-show="hover" color="amber darken-4" small>fas fa-circle-arrow-right</v-icon>
					</div>
				</div></v-hover></div>

				<div v-for="(component, ci) in lesson.lesson_plan" :key="component.lc_uuid" v-if="component.lc_content"><v-hover v-slot:default="{hover}"><div class="k-lpe-alba-menu-stage-outer k-lpe-alba-menu-stage-complete k-lpe-alba-menu-stage-hoveredx" :class="hover?'k-lpe-alba-menu-stage-hovered':''">
					<div class="k-lpe-alba-menu-stage" :class="`${mdlph[component.lc_uuid].lc_color} lighten-4`" @click="go_to_stage(component)">
						<v-icon style="width:20px" :color="`${mdlph[component.lc_uuid].lc_color} darken-4`" small class="mr-2">{{mdlph[component.lc_uuid].lc_icon}}</v-icon>
						<div class="k-lpe-alba-menu-stage-label">{{mdlph[component.lc_uuid].lc_title}}</div>
						<v-spacer/>
						<v-icon v-show="hover" :color="`${mdlph[component.lc_uuid].lc_color} darken-4`" small>fas fa-circle-arrow-right</v-icon>
					</div>
				</div></v-hover></div>

				<div v-if="lesson.sparkl_activity_id"><v-hover v-slot:default="{hover}"><div class="k-lpe-alba-menu-stage-outer k-lpe-alba-menu-stage-complete" :style="hover?'opacity:1':''">
					<div class="k-lpe-alba-menu-stage purple lighten-4" @click="go_to_stage('sparkl')">
						<v-icon style="width:20px" color="purple darken-4" small class="mr-2">fas fa-star</v-icon>
						<div class="k-lpe-alba-menu-stage-label">{{site_config.sparkl_app_name}} Student Activity</div>
						<v-spacer/>
						<v-icon v-show="hover" color="purple darken-4" small>fas fa-circle-arrow-right</v-icon>
					</div>
				</div></v-hover></div>
			</div>

			<!-- HOLDER FOR EACH STAGE, INCLUDING TITLE, STANDARDS, SPARKL ACTIVITY, AND RESOURCES -->
			<div class="k-lpe-alba-content"><div class="k-lpe-alba-content-inner">
				<!-- STANDARDS -->
				<div v-if="lesson.standards.length>0" class="k-lpe-alba-content-section k-lpe-alba-content-section-standards amber lighten-4" style="display:block; border-bottom:1px solid #ccc!important">
					<div class="d-flex align-center">
						<v-icon class="k-lpe-alba-section-header-icon" color="amber darken-4" @click="lesson.standards_showing=!lesson.standards_showing">fas fa-map</v-icon>
						<div class="k-lpe-alba-section-header-text mr-2 my-3" @click="lesson.standards_showing=!lesson.standards_showing">Learning Standards</div>
						<v-btn small icon class="mr-2" color="amber darken-4" @click="lesson.standards_showing=!lesson.standards_showing"><v-icon style="font-size:20px">fas {{lesson.standards_showing?'fa-angles-up':'fa-angles-down'}}</v-icon></v-btn>
						<div v-show="!lesson.standards_showing" style="display:flex" class="flex-wrap my-1">
							<CASEItemBtn @click="show_standard_in_satchel(s)" v-for="(s) in lesson.standards" :key="s.identifier" btn_color="amber darken-4" :item="s" small :outer_class="'ma-1'" />
						</div>
					</div>
					<v-expand-transition><div v-show="lesson.standards_showing" class="mt-2">
						<div v-for="(standard) in lesson.standards" class="d-flex">
							<CASEItemBtn @click="show_standard_in_satchel(s)" btn_color="amber darken-4" :item="standard" small :outer_class="'mr-2'" />
							<div v-html="standard.fullStatement"></div>
						</div>
					</div></v-expand-transition>
				</div>
				
				<!-- LESSON COMPONENTS -->
				<div v-for="(component, ci) in lesson.lesson_plan" :key="component.lc_uuid" v-if="show_component(component)" class="k-lpe-alba-content-section k-lpe-alba-section-stage-complete" :class="section_stage_css(component)">
					<div class="d-flex align-center">
						<v-icon class="k-lpe-alba-section-header-icon" @click="toggle_stage(component)" :color="`${mdlph[component.lc_uuid].lc_color} darken-4`">{{mdlph[component.lc_uuid].lc_icon}}</v-icon>
						<div class="k-lpe-alba-section-header-text" :class="`${mdlph[component.lc_uuid].lc_color}--text text--darken-4`" @click="toggle_stage(component)">{{mdlph[component.lc_uuid].lc_title}}</div>
					</div>
					<div v-show="component.lc_content" class="k-lpe-alba-section-summary my-2" v-html="section_text(component)"></div>

					<!-- MODEL RESPONSES FOR QUESTIONS -->
					<div v-if="component.lc_sparkl_model_response" class="mt-2 ml-5">
						<div style="font-size:14px"><b>Model response for question:</b></div>
						<div class="mt-1" v-html="component.lc_sparkl_model_response"></div>
					</div>

					<!-- RESOURCES FOR MATERIALS -->
					<div v-if="component.lc_uuid=='materials'" class="k-lesson-resource-collection mt-3 mb-2" xstyle="background-color:#fff; padding:8px 8px; border-radius:6px; margin-top:12px;">
						<div v-for="(resource) in lesson.resources" :key="resource.resource_id" class="k-resource-collection-activity-wrapper">
							<ResourceCollectionItem :item="resource" />
						</div>
					</div>
				</div>

				<!-- SPARKL ACTIVITY -->
				<div v-if="lesson.sparkl_activity_id!=0" class="k-lpe-alba-content-section k-lpe-alba-content-section-sparkl purple lighten-5" style="display:block; border-bottom:1px solid #ccc!important">
					<div class="d-flex align-center">
						<v-icon class="k-lpe-alba-section-header-icon" color="purple darken-4" @click="lesson.sparkl_showing=!lesson.sparkl_showing;scroll_to_stage('sparkl')">fas fa-star</v-icon>
						<div class="k-lpe-alba-section-header-text purple--text text--darken-4 mr-2 py-2" @click="lesson.sparkl_showing=!lesson.sparkl_showing;scroll_to_stage('sparkl')">{{site_config.sparkl_app_name}} Student Activity</div>
						<!-- <v-btn small icon class="mr-2" color="purple darken-4" @click="lesson.sparkl_showing=!lesson.sparkl_showing;scroll_to_stage('sparkl')"><v-icon style="font-size:20px">fas {{lesson.sparkl_showing?'fa-angles-up':'fa-angles-down'}}</v-icon></v-btn> -->
						<div class="ml-3"><b>Created:</b> Activity ID {{lesson.sparkl_activity_id}}</div>
						<v-spacer/>
						<v-btn small color="#444" class="mr-2" dark @click="open_sparkl_activity"><v-icon small class="mr-2">fas fa-up-right-from-square</v-icon> Open Activity</v-btn>
					</div>
				</div>
			</div></div>

		</div>

		<!-- LESSON VARIANT "A" ("TRADITIONAL") -->
		<div v-else class="px-1 pt-1 pb-3">
			<div class="k-lesson-grouping elevation-2" v-if="lesson.standards.length>0">
				<div class="d-flex" @click="toggle_standards" style="cursor:pointer">
					<v-btn icon x-small color="indigo darken-2" class="mr-2" @click.stop="toggle_standards"><v-icon>fas {{lesson.standards_showing?'fa-caret-down':'fa-caret-right'}}</v-icon></v-btn>
					<div class="k-lesson-title-intro"><b>Learning Standards</b></div>
				</div>
				<v-expand-transition><div v-show="lesson.standards_showing"><div class="d-flex flex-wrap my-1"><CASEItemBtn v-for="(s) in lesson.standards" :key="s.identifier" :item="s" :framework_identifier="case_framework_identifier(s)" :selected_items="lesson.standards" small :outer_class="'ma-1'" /></div></div></v-expand-transition>
			</div>

			<div class="k-lesson-grouping elevation-2" v-if="false&&lesson.student_description">
				<div class="d-flex" @click="toggle_student_description" style="cursor:pointer">
					<v-btn icon x-small color="indigo darken-2" class="mr-2" @click.stop="toggle_student_description"><v-icon>fas {{lesson.student_description_showing?'fa-caret-down':'fa-caret-right'}}</v-icon></v-btn>
					<div class="k-lesson-title-intro"><b>Student-Facing Lesson Overview</b></div>
				</div>
				<v-expand-transition><div v-show="lesson.student_description_showing" style="background-color:#fff;border-radius:6px;padding:6px;margin-top:4px;">
					<div class="fr-view" v-html="U.render_latex(lesson.student_description)"></div>
				</div></v-expand-transition>
			</div>

			<div v-for="(component, ci) in lesson.lesson_plan" :key="component.uuid" v-if="!component.is_empty()" class="k-lesson-component elevation-2" :class="component.lc_showing?'k-lesson-component-showing':''">
				<div class="k-lesson-component-title" @click="toggle_component(ci)">
					<div class="k-lesson-component-toggle-holder"><v-btn icon x-small color="indigo darken-2" @click.stop="toggle_component(ci)"><v-icon>fas {{component.lc_showing?'fa-caret-down':'fa-caret-right'}}</v-icon></v-btn></div>
					<div class="k-lesson-component-title-text" :class="'k-lesson-component-title-text-'+lesson_master.lesson_plan[ci].lc_weight">{{(lesson_master.lesson_plan[ci].lc_title)?lesson_master.lesson_plan[ci].lc_title:'Part '+(ci+1)}}</div>
					<div v-if="lesson_master.lesson_plan[ci].lc_weight" class="k-lesson-component-title-weight" :style="'flex-basis:' + lesson_master.lesson_plan[ci].lc_weight + '%'">{{lesson_master.lesson_plan[ci].lc_weight}}%</div>
				</div>
				<v-expand-transition><div v-show="component.lc_showing">
					<div v-if="lesson.lp_variant=='B'">
						<div class="k-lesson-component-content" v-html="section_text(component)"></div>
					</div>
					<div v-else>
						<div v-if="lesson_master.lesson_plan[ci].lc_guidance" class="k-lesson-component-guidance" v-html="lesson_master.lesson_plan[ci].lc_guidance"></div>
						<div class="k-lesson-component-content">
							<div v-for="(component_part, cpi) in component.lc_parts" :key="cpi" class="k-lesson-component-part-content">
								<div v-if="component.lc_parts.length>1" class="k-lesson-component-part-header">
									<div v-if="!component_part.lcp_title" class="mr-2">Part {{cpi+1}}</div>
									<div v-if="component_part.lcp_title" v-html="component_part.lcp_title"></div>
								</div>
								<div class="fr-view" v-html="U.render_latex(component_part.lcp_teacher_content)"></div>
							</div>
						</div>
						<!-- show lc_default_content from master if we don't have any teacher content -->
						<div v-if="component.lc_parts.length>0&&component.lc_parts[0].lcp_teacher_content==''&&lesson_master.lesson_plan[ci].lc_default_content" class="k-lesson-component-default-content">
							<div class="float-left mr-2"><b>“Looks like…”</b></div>
							<div class="fr-view" v-html="lesson_master.lesson_plan[ci].lc_default_content"></div>
						</div>
					</div>
				</div></v-expand-transition>
			</div>

			<div class="k-lesson-grouping elevation-2" v-if="lesson.resources.length>0">
				<div class="d-flex" @click="toggle_resources" style="cursor:pointer">
					<v-btn icon x-small color="indigo darken-2" class="mr-2" @click.stop="toggle_resources"><v-icon>fas {{lesson.resources_showing?'fa-caret-down':'fa-caret-right'}}</v-icon></v-btn>
					<div class="k-lesson-title-intro"><b>Lesson Resources</b></div>
				</div>
				<v-expand-transition><div v-show="lesson.resources_showing"><div class="k-lesson-resource-collection mt-2">
					<div v-for="(resource) in lesson.resources" :key="resource.resource_id" class="k-resource-collection-activity-wrapper">
						<ResourceCollectionItem :item="resource" />
					</div>
				</div></div></v-expand-transition>
			</div>
		</div>

	</v-card-text>
</template>

<script>
import goTo from 'vuetify/lib/services/goto'
import { mapState, mapGetters } from 'vuex'
import CASEItemBtn from '../standards/CASEItemBtn'

export default {
	name: 'LessonView',
	components: {
		// have to this this way because of recursion in the components...
		ResourceCollectionItem: () => import('../resources/ResourceCollectionItem')
		, CASEItemBtn
	},
	props: {
		lesson: { required: true },
		print_lesson_dom_id: { type: String, required: false, default() { return U.new_uuid() } },
		show_print_dialog: { type: Boolean, required: false, default() { return false } },
	},
	data() { return {
		standard_children_showing: {},
		sparkl_closed_from_embed: false,
		last_clicked_component: null,
	}},
	computed: {
		...mapState(['user_info', 'lesson_masters', 'default_lesson_master', 'site_config']),
		...mapGetters([]),
		lesson_master() {
			// TEMP: hard code master for variant B
			if (this.lesson.lp_variant == 'B') return window.variant_b_lesson_master_data
			
			// if we have a lesson_master_id, return the lesson master
			if (this.lesson.lesson_master_id) {
				let l = this.lesson_masters.find(x=>x.lesson_id==this.lesson.lesson_master_id)
				if (l) return l
			}
			// otherwise the lesson is its own master
			return this.lesson
		},
		mdlph() {
			// master data lesson plan hash: convenience object for getting properties of the master data for each component
			let o = {}
			for (let c of this.lesson_master.lesson_plan) {
				o[c.lc_uuid] = c
			}
			return o
		},
	},
	watch: {
	},
	created() {
		// get lesson_masters if we haven't already done so
		this.$store.dispatch('get_lesson_masters').then(x=>{
			// then get the lesson itself if it isn't yet fully loaded
			if (!this.lesson.lesson_fully_loaded) {
				this.$store.dispatch('load_lesson', this.lesson).then(x=>{
					// console.warn('loaded lesson', object_copy(this.lesson))
				})
			}
		})
	},
	mounted() {
		// setTimeout(x=>console.log(this.lesson), 2000)
		// console.warn(object_copy(this.lesson.standards))
		vapp.lesson_view_component = this
	},
	methods: {
		lesson_component_not_empty(component) {
			
		},

		toggle_component(ci, val) {
			if (typeof(val) != 'boolean') val = !this.lesson.lesson_plan[ci].lc_showing
			this.$store.commit('set', [this.lesson.lesson_plan[ci], 'lc_showing', val])
		},

		toggle_standard_children(identifier) {
			if (!this.standard_children_showing[identifier]) {
				this.$set(this.standard_children_showing, identifier, true)
			} else {
				this.standard_children_showing[identifier] = false
			}
		},

		toggle_resources(val) {
			if (typeof(val) !== 'boolean') val = !this.lesson.resources_showing
			this.$store.commit('set', [this.lesson, 'resources_showing', val])
		},

		toggle_student_description(val) {
			if (typeof(val) !== 'boolean') val = !this.lesson.student_description_showing
			this.$store.commit('set', [this.lesson, 'student_description_showing', val])
		},

		toggle_standards(val) {
			if (typeof(val) !== 'boolean') val = !this.lesson.standards_showing
			this.$store.commit('set', [this.lesson, 'standards_showing', val])
		},

		case_framework_identifier(item) {
			// if the item has a framework_identifier, send it
			if (item.framework_identifier) return item.framework_identifier

			// HACK: trace up through parents looking for a case_framework_identifier value (e.g. in CourseView); if found, use it
			// for newly-generated lessons, added standards will include the framework_identifier
			let parent = this.$parent
			while (parent) {
				if (parent.case_framework_identifier) {
					return parent.case_framework_identifier
				}
				parent = parent.$parent
			}

			// TODO: if we get to here, look in loaded frameworks, then use a service (or the item uri?) to look up the document
			return ''
		},

		/////////////////////////////////////////
		master_component(component) {
			return this.mdlph[component.lc_uuid]
		},

		show_component(component) {
			if (!empty(component.lc_content)) return true
			if (component.lc_uuid == 'materials' && this.lesson.resources.length > 0) return true
			return false
		},

		section_stage_css(component) {
			let mdc = this.master_component(component)
			let s = `k-lpe-alba-content-section-${component.lc_uuid}`
			// if (this.last_clicked_component == component) s += ` ${mdc?.lc_color} lighten-5 k-lpe-alba-section-stage-open_for_editing`
			if (this.last_clicked_component == component) s += ` ${mdc?.lc_color} lighten-5`
			return s
		},

		go_to_stage(component) {
			if (component == 'standards') this.lesson.standards_showing = !this.lesson.standards_showing
			if (component == 'resources') this.lesson.resources_showing = !this.lesson.resources_showing
			if (component == 'sparkl') this.lesson.sparkl_showing = !this.lesson.sparkl_showing

			if (this.last_clicked_component == component) this.last_clicked_component = null
			else this.last_clicked_component = component

			this.scroll_to_stage(component)
		},

		scroll_to_stage(component) {
			// scroll to stage; note that target selector logic handles whether component is an actual component or a stage name (e.g. 'title')
			let target = $(this.$el).find(`.k-lpe-alba-content-section-${component?.lc_uuid ?? component}`)[0]
			if (!empty(target)) {
				let container = $(this.$el).find('.k-lpe-alba-content-inner')[0]
				// let container = $('.v-dialog').find('.v-card__text')[0]
				this.$vuetify.goTo(target, {container:container, offset:0})
			}
		},

		toggle_stage(component) {
			if (this.last_clicked_component == component) {
				this.last_clicked_component = null
			} else {
				this.last_clicked_component = component
				this.scroll_to_stage(component)
			}
		},

		section_text(component) {
			let content = U.render_latex(component.lc_content)
			let mdc = this.master_component(component)
			if (empty(mdc)) return content

			if (empty(mdc?.lc_text_format) || mdc.lc_text_format.includes('paragraphs')) {
				// for 'paragraphs' style, or if we don't have a master component or lc_text_format is empty, just return the plain content
				return content

			} else if (mdc.lc_text_format.includes('line')) {
				// for single-line style (e.g. questions), wrap in a paragraph
				return `<p>${content}</p>`

			} else if (mdc.lc_text_format.includes('list')) {
				// for lists, use unordered list
				let lines = content.split('<br>')
				let s = `<ul>`
				for (let line of lines) s += `<li>${line}</li>`
				s += `</ul>`
				return s
			}
		},

		show_standard_in_satchel(start_item) {
			let data = { framework_identifier: '', item_identifier: '' }

			if (start_item?.framework_identifier) {
				data.framework_identifier = start_item.framework_identifier
			} else {
				// if the lesson has a case_framework_identifier, send it
				if (this.lesson.case_framework_identifier) data.framework_identifier = this.lesson.case_framework_identifier

				// HACK: trace up through parents looking for a case_framework_identifier value (e.g. in CourseView); if found, use it
				let parent = this.$parent
				while (parent) {
					if (parent.case_framework_identifier) { data.framework_identifier = parent.case_framework_identifier; break; }
					parent = parent.$parent
				}
			}

			if (start_item?.identifier) {
				data.item_identifier = start_item.identifier
			} else {
				// if the lesson has a course_case_identifier, send it
				if (this.lesson.course_case_identifier) data.item_identifier = this.lesson.course_case_identifier

				// HACK: trace up through parents looking for a course_case_identifier value (e.g. in CourseView); if found, use it as item_identifier
				let parent = this.$parent
				while (parent) {
					if (parent.course_case_identifier) { data.item_identifier = parent.course_case_identifier; break; }
					parent = parent.$parent
				}
			}

			// add current lesson standards as selected items
			if (this.lesson.standards.length > 0) {
				data.selected_items = []
				for (let standard of this.lesson.standards) data.selected_items.push(standard.identifier)
			}

			// set hide_fn to hide the standards chooser if/when the editor is no longer visible
			let show_data = { hide_fn: ()=>{ return ($(vapp.lesson_view_component?.$el).is(':visible') == false) } }

			vapp.$refs.satchel.execute('show', show_data).then(()=>{
				vapp.$refs.satchel.execute('load_framework', data).then(()=>{
				})
			})
		},

		////////////////////////////////////////////
		open_sparkl_activity() {
			if (this.lesson.sparkl_activity_id == 0) return
			vapp.$refs.sparkl_embed.show_activity({
				activity_record: {
					tool_activity_id: this.lesson.sparkl_activity_id,
					lti_resource_link_id: 'sparkl_for_lesson_' + this.lesson.lesson_id,
					activity_title: this.lesson.lesson_title,
					creator_user_id: this.user_info.user_id,
				},
				// force_reload: force_reload,
				embed_mode: 'view',

				// the following two probably aren't really needed
				force_allow_original_to_be_edited: 'yes',
				force_prevent_original_to_be_edited: 'no',

				show_copy_for_my_use_btn: false,
				viewing_original_of_in_my_collections: false,
				controller_component: this,

				sparkl_origin_override: this.sparkl_origin_override,	// could be null
			})

			// hide the edit lesson dialog while the activity shows
			$('.v-dialog__content--active, .v-overlay--active').hide()
		},

		sparkl_activity_saved(activity_data_from_sparkl) {
			console.log(`sparkl_activity_saved in LessonEditorEnhanced`, activity_data_from_sparkl)
			// this is called when Sparkl issues a 'sparkl_activity_saved' message
			// activity_data_from_sparkl should include sparkl_activity_id, stars_available, activity_instructions, activity_title, activity_editors
			// currently we're not doing much here; see other instantiations of sparkl_activity_saved for other things we might want to do

			// if sparkl_closed_from_embed is true, finish closing sparkl here
			if (this.sparkl_closed_from_embed) this.close_sparkl_finish()
		},

		close_sparkl() {
			// send the host_activity_saved message TO sparkl, so that Sparkl saves anything that might have been edited there
			console.log(`close_sparkl in LessonEditorEnhanced; host_activity_saved being called`)
			U.loading_start()
			vapp.$refs.sparkl_embed.execute('host_activity_saved', {})

			this.sparkl_closed_from_embed = true
			// ... then once sparkl is done saving, sparkl_activity_saved will be called, and since sparkl_closed_from_embed is true, sparkl_activity_saved will call close_sparkl_finish
		},

		close_sparkl_finish() {
			U.loading_stop()
			this.sparkl_closed_from_embed = false
			vapp.$refs.sparkl_embed.hide_activity()

			// re-show active dialogs/overlays
			$('.v-dialog__content--active, .v-overlay--active').show()
		},

	}
}
</script>

<style lang="scss">
// enclosing item should apply an appropriate margin; we only add 4px padding here to make sure shadows show properly
.k-lesson-card-text {
	font-size:16px;
	line-height:20px;
	padding:0!important;
	// padding:4px 0px 12px 0px!important;

	.k-lesson-standard {
		border-top:1px solid #ccc;
		padding-top:5px;
		margin:8px 16px 8px 40px;
		font-size:14px;
		line-height:19px;
		position:relative;
		min-height:24px;
	}

	.k-lesson-standard-child {
		margin-left:24px;
		min-height: none;
	}

	.k-lesson-standard-statement-toggler {
		position:absolute;
		left:-32px;
		top:0px;
	}

	.k-lesson-standard-statement-wrapper {
		// display:flex;
		// align-items: flex-start;
	}

	.k-lesson-standard-statement {
		cursor:pointer;
		font-size:14px;
		line-height: 18px;
	}
	.k-lesson-standard-statement:hover {
		text-decoration:underline;
	}
}

.k-lesson-title {
	font-size:16px;
	line-height:20px;
	// margin-bottom:16px;
}

.v-dialog .k-lesson-title {
	font-size:20px;
}

// PW: SF removed this for some reason; putting it back, at least for now
.k-lesson-course-unit-header {
	font-size:12px;
	line-height:15px;
	font-weight:bold;
	color:#999;
	margin-left:8px;
	text-align:center;
}

.k-lesson-course-unit-header-inner {
	font-size:9px;
	line-height:12px;
	font-weight:bold;
	border-radius:6px;
	padding:2px 3px;
	background-color:#fff;
	color:#000;
	border:1px solid #999;
}

.k-mycontent-item-outer-showing {
	.k-lesson-course-unit-header-inner {
		font-size:12px;
		line-height:15px;
		padding:4px 6px;
	}
}

.k-lesson-date-header {
	display:inline-block;
	white-space:nowrap;
	font-size:13px;
	line-height:15px;
	font-weight:bold;
	background-color:#999;	// this will get overwritten
	border-radius:6px;
	padding:4px 6px;
	color:#fff;
	text-align:center;
}
.k-lesson-date-header-date {
	// letter-spacing: 1px;
}

.k-lesson-date-header-grey { background-color: $v-grey-darken-2; }
.k-lesson-date-header-teal { background-color: $v-teal-darken-2; }
.k-lesson-date-header-amber { background-color: $v-amber-darken-4; }
.k-lesson-date-header-red { background-color: $v-red-darken-2; }


// .k-lesson-date-header {
// 	white-space:nowrap;
// 	font-size:14px;
// 	line-height:15px;
// 	font-weight:900;
// 	background-color:#999;	// this will get overwritten
// 	border-radius:6px;
// 	padding:4px 6px;
// 	color:#fff;
// 	margin-left:8px;
// 	text-align:center;
// }

.k-lesson-title-intro {
	font-weight:bold;
	font-size:14px;
	text-transform: uppercase;
	// font-variant: small-caps;
	color:$v-indigo-darken-2;
	margin-right:8px;
}

.k-lesson-component {
	border:1px solid $v-amber-darken-2;
	border-radius:7px;
	margin-top: 8px;
	color:#222;
	background-color:#fff;
}

.k-lesson-component-title {
	display:flex;
	cursor:pointer;
	font-size:16px;
	line-height:20px;
	.k-lesson-component-toggle-holder {
		border-radius:6px 0 0 6px;
		background-color:$v-amber-lighten-3;
		flex:0 1 auto;
		padding:4px 0 3px 4px;
	}
	.k-lesson-component-title-text {
		font-weight:bold;
		background-color:$v-amber-lighten-3;
		color:#222;
		padding:6px 6px;
		flex: 1 0 auto;
	}
	.k-lesson-component-title-text-0 {
		border-radius:0 6px 6px 0;
	}
	.k-lesson-component-title-weight {
		border-radius:0 6px 6px 0;
		background-color:$v-orange-darken-3;
		color:#fff;
		padding:6px 6px;
		flex:0 1 auto;
		text-align:right;
	}
}

.k-lesson-component-guidance, .fr-view.k-lesson-component-guidance {
	color:#555;
	font-size:12px;
	line-height:15px;
	padding: 0 8px;
}

.k-lesson-component-content {
	color:#222;
	font-size:14px;
	line-height:19px;
	margin-top:12px;
	padding: 0 8px 8px 8px;

	li {
		margin-bottom:6px;
	}
}

.k-lesson-component-part-header {
	display:flex;
	align-items: center;
	font-weight:900;
	margin:12px 0 8px 0;
	background-color:$v-amber-accent-1;
	border-radius:6px;
	padding:3px 5px 3px 8px;
}

.k-lesson-component-part-content {
	padding:0 4px;
}

// .k-lesson-component-part-content:last-of-type {
// 	border-bottom:0;
// 	margin-bottom:0;
// }

.k-lesson-component-default-content {
	color:#555;
	font-size:12px;
	line-height:15px;
	margin-top:12px;
	padding: 0 8px;
}

.k-lesson-component-showing {
	.k-lesson-component-title {
		.k-lesson-component-toggle-holder {
			border-radius:6px 0 0 0;
		}
		.k-lesson-component-title-text-0 {
			border-radius:0 6px 0 0;
		}
		.k-lesson-component-title-weight {
			border-radius:0 6px 0 0;
		}
	}
}

.k-lesson-component-part-activity {
	flex:0 0 auto;
	// max-width:200px;
	border:2px solid $v-pink-darken-2;
	border-radius:10px;
	margin:4px;
	background-color:$v-pink-lighten-5;
}

.k-lesson-component-part-activity-expanded {
	flex:1 0 100%;
	margin:4px 0;
	max-width:none;
	// width:calc(100% - 8px);
}

.k-lesson-component-resource-link, .k-lesson-component-resource-link-placeholder {
	background-color:#555;
	color:#fff;
	padding:2px 8px;
	border-radius:5px!important;
	margin:0 4px!important;
	display:inline-block;
	height:20px;
	font-size:16px;
	cursor:pointer;
}

.k-lesson-grouping {
	border-radius:6px;
	// border:1px solid #ccc;
	background-color:$v-amber-lighten-5;
	padding:8px;
	margin:12px 0 0 0;
}

.k-lesson-resource-collection {
	display:flex;
	flex-wrap: wrap;
	align-items: stretch;

	.k-resource-collection-activity-wrapper {
		min-width:25%;
		max-width:25%;
	}
}
</style>
