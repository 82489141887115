<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div v-show="sparkl_showing">
	<div class="k-sparkl-overlay-scrim"></div>
	<div class="k-sparkl-maximized">
		<div v-if="sparkl_loading_status" v-show="sparkl_frame_showing" class="k-sparkl-inline-outer" :data-sparkl_embed_id="sparkl_embed_id">
			<iframe :name="'sparkl_iframe-'+sparkl_embed_id" class="k-sparkl-inline-iframe" :class="signed_in?'':'k-sparkl-inline-iframe-student-demo'" allow="camera *;microphone *;clipboard-read *;clipboard-write *"></iframe>
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	// components: { TemplateComponent },
	props: {
	},
	data() { return {
		sparkl_showing: false,

		activity: {},
		embed_mode: 'view',
		show_copy_for_my_use_btn: false,
		viewing_original_of_in_my_collections: false,
		force_allow_original_to_be_edited: false,
		force_prevent_original_to_be_edited: false,
		controller_component: null,
		sparkl_origin_override: null,
		sparkl_activity_data_record: {},
		role_override: '',

		sparkl_embed_id: U.new_uuid(),
		sparkl_loading_status: false,
		sparkl_frame_showing: false,
		establish_connection_tries: 0,
		connection_established: false,
		initialize_params: {},

		// set pm_verbose to true for debugging
		pm_verbose: true,
	}},
	computed: {
		...mapState(['user_info', 'pm_event_listener_created', 'sparkl_embed_components', 'current_sparkl_embed_component', 'site_config']),
		...mapGetters(['signed_in', 'role', 'studentish_role']),
		activity_owned_by_user() { 
			// check here to see if the sparkl_activity_data_record's editors field includes the signed-in user's email
			if (!this.signed_in) return false
			if (empty(this.sparkl_activity_data_record.editors)) return false
			return this.sparkl_activity_data_record.editors.includes(this.user_info.email)
		},
		sparkl_activity_id() { return this.activity.tool_activity_id },
		lti_resource_link_id() { return this.activity.lti_resource_link_id },
		activity_title() { return this.activity.activity_title },
		// currently we don't have an open in new window option...
		openable_in_new_window() {
			// if we're already open in single_item mode, don't show the button again
			if (this.single_item) return false

			// in edit embed_mode, we need to communicate with the Inspire window, so don't allow this
			if (this.embed_mode == 'edit') return false

			// keep students focused on a single activity; also it's crucial that Sparkl communicate with HC here (left over from HC)
			if (!this.signed_in || this.studentish_role) return false

			// if we get to here allow to be opened in a new tab
			return true
		},
		copy_to_my_content_btn_text() {
			let s = ''
			if (this.controller_component?.option_availability?.import_to_district) s += 'Import To HenryConnects'
			else s += 'Copy Activity For My Use'
			return s
		},
		sparkl_origin() {
			// If last argument below is 'true', we serve sparkl from localhost in local development mode
			if (window.location.origin.indexOf('localhost') > -1 && false) {
				// return 'https://dev.sparkl-ed.com'
				// for localhost, we have a different origin for the studentapp and the teacherapp
				if (this.studentish_role) return 'http://localhost:8071'
				else return 'http://localhost:8070'
			// for the real server, it's the same origin for both
			} else {
				// allow for a sparkl_origin_override
				if (this.sparkl_origin_override) return this.sparkl_origin_override
				else return this.site_config.sparkl_origin
			}
		},
	},
	watch: {
	},
	created() {
		// save this component in state.sparkl_embed_components (see below -- even though for now, at least, we only have one sparkl embed in the entire app)
		this.$store.commit('set', [this.sparkl_embed_components, this.sparkl_embed_id, this])
	},
	mounted() {
	},
	methods: {
		show_activity(params) {
			// always set controller_component
			this.controller_component = params.controller_component

			// always set sparkl_origin_override, which may be null
			this.sparkl_origin_override = params.sparkl_origin_override

			// this triggers the control bar options to show at the top of the window
			this.$store.commit('set', ['current_sparkl_embed_component', this])

			// if the following conditions are met, just show the previously-loaded activity:
			// - the force_reload parameter is false
			// - we're being asked to show the same activity that was already showing
			// - the lti_resource_link_id hasn't changed
			// - the values for force_allow_original_to_be_edited and force_prevent_original_to_be_edited haven't changed (if either did, the user probably switched between viewing in the unit editor and viewing in the "normal" unit interface)
			if (!params.force_reload && (params.activity_record.tool_activity_id == this.activity.tool_activity_id) && (params.activity_record.lti_resource_link_id == this.activity.lti_resource_link_id) && (params.force_allow_original_to_be_edited == this.force_allow_original_to_be_edited) && (params.force_prevent_original_to_be_edited == this.force_prevent_original_to_be_edited) && (params.role_override == this.role_override)) {
				this.sparkl_showing = true
				console.log('showing already-loaded activity')
				return
			}

			// if we get to here, we need to load the activity, or reload it if it was already showing

			this.activity = params.activity_record
			this.embed_mode = params.embed_mode ?? 'view'
			this.show_copy_for_my_use_btn = params.show_copy_for_my_use_btn
			this.viewing_original_of_in_my_collections = params.viewing_original_of_in_my_collections

			// these may come in, e.g., if we're managing assignments
			this.initial_content = params.initial_content
			this.coteacher_role = params.coteacher_role

			// send in 'student' to force the student view
			this.role_override = params.role_override

			// this param (which should be sent through as 'yes'/'no') will tell lti launch to allow the user to edit the activity if they aren't the original creator
			this.force_allow_original_to_be_edited = params.force_allow_original_to_be_edited

			// this param (which should be sent through as 'yes'/'no') will tell lti launch to prevent the user from editing the activity if they shouldn't be editing in this context
			this.force_prevent_original_to_be_edited = params.force_prevent_original_to_be_edited

			this.sparkl_showing = true

			// reset sparkl_activity_data_record; it will be filled in once sparkl is finished initializing
			this.sparkl_activity_data_record = {}

			// make sure the frame reloads
			this.sparkl_loading_status = false
			this.connection_established = false

			this.$nextTick(()=>{
				console.log(`Showing sparkl: sparkl_activity_id=${this.sparkl_activity_id}`)
				this.execute('show')
				this.sparkl_frame_showing = true
			})
		},

		hide_activity() {
			this.sparkl_showing = false
			this.$store.commit('set', ['current_sparkl_embed_component', null])
		},

		// this can be called to force the activity to reload if/when it is reopened
		force_reload_when_reopened() {
			this.activity = {}
		},

		execute(cmd, data) {
			if (this.pm_verbose) console.log(sr('PMX HOST ==> execute cmd $1: $2', cmd, JSON.stringify(data)))
			return new Promise((resolve, reject)=>{
				// if the sparkl iframe hasn't finished loading...
				if (this.sparkl_loading_status != 'loaded') {
					// if we haven't even started loading...
					if (!this.sparkl_loading_status) {
						// setting sparkl_loading_status to a non-falsey value renders the iframe
						this.sparkl_loading_status = 'loading'

						// execute an LTI launch to sparkl
						this.lti_launch()

						// initialize postMessage; once loading is done, sparkl_loading_status will be set to 'loaded', 
						// then pm_execute_from_host will eventually call execute_cmd, sending through the initialize_params we store here (including resolve and reject)
						this.initialize_params = {cmd:cmd, data:data, resolve:resolve, reject:reject}
						this.pm_initialize()
					}

				} else {
					// else we're already loaded, so send the command
					this.execute_cmd(cmd, data, resolve, reject)
				}
			})
		}, 

		execute_cmd(cmd, data, resolve, reject) {
			if (cmd == 'show') {
				this.sparkl_frame_showing = true
				if (resolve) resolve('ok')

			} else if (cmd == 'hide') {
				// TODO: send a msg to sparkl telling it to suspend activity (i.e. stop checking the server for updates); and/or close the window altogether after a few minutes of inactivity
				this.sparkl_frame_showing = false
				if (resolve) resolve('ok')

			} else if (cmd == 'host_activity_saved') {
				// send a message TO sparkl telling it that the activity has been saved
				this.pm_send('host_activity_saved', data, resolve, reject)

			} else if (cmd == 'duplicate_shared_activity_finish') {
				this.pm_send('duplicate_shared_activity_finish', data, resolve, reject)
			
			// one example of a non-show/hide cmd from Satchel; see SatchelEmbed for more examples
			// } else if (cmd == 'search') {
			// 	this.pm_send('search', {search_terms: data.search_terms, limit_to: data.limit_to}, resolve, reject)
			// 	if (resolve) resolve('ok')
			}
		},

		lti_launch() {
			// exception for sparkl_origin: if we're using localhost and role_override is student, use the student origin
			let sparkl_origin = this.sparkl_origin
			if (sparkl_origin.indexOf('localhost') > -1 && this.role_override == 'student') sparkl_origin = 'http://localhost:8071'

			let payload = { 
				lti_resource_link_id: this.lti_resource_link_id,
				sparkl_activity_id: this.sparkl_activity_id,
				activity_title: this.activity_title,
				embedded_flag: this.embed_mode,
				role_override: this.role_override,
				demo_mode: this.role_override ? 'on' : '',
				force_allow_original_to_be_edited: this.force_allow_original_to_be_edited,
				force_prevent_original_to_be_edited: this.force_prevent_original_to_be_edited,
				sparkl_origin: sparkl_origin,
			}
			if (this.initial_content) payload.initial_content = this.initial_content
			if (this.coteacher_role) payload.coteacher_role = this.coteacher_role
			if (this.sparkl_origin.indexOf('localhost') > -1) payload.localhost = 'true'

			// if user isn't signed in...
			if (!this.signed_in) {
				// create, or get from lst, a demo email account
				let demo_email = this.$store.state.lst.sparkl_demo_email
				if (empty(demo_email)) {
					// demo_email = 'bcdfgklmnprstvz'[U.random_int(17)] + 'aeiou'[U.random_int(4)] + 'bcdfgklmnprstvz'[U.random_int(14)] + Math.round(new Date().getTime()/1000) + '@cglt.com'
					demo_email = 'bcdfgklmnprstvz'[U.random_int(15)] + 'bcdfgklmnprstvz'[U.random_int(14)] + Math.round(new Date().getTime()/1000) + '@cglt.com'
					this.$store.commit('lst_set', ['sparkl_demo_email', demo_email])
				}

				payload.email = demo_email
				payload.first_name = demo_email[0].toUpperCase() + demo_email[1].toUpperCase()
				payload.last_name = 'Demonte'
			}

			// add lti_section_collections if we have a course_code for the activity and we have sis_classes -- this should be the case if we're managing assignments and this is an assigned activity...
			if (this.activity.course_code && this.$store.state.sis_classes && this.$store.state.sis_classes.length > 0) {
				// for staff, don't send if role_override is student
				if (this.role == 'staff') {
					if (this.role_override != 'student') {
						// and for staff, we send all assigned sections, and all students in all assigned sections
						let lti_section_collections
						// note that we might have multiple sis_classes records if teaching block and non-block courses
						let sis_classes = this.$store.state.sis_classes.filter(x=>x.course_code==this.activity.course_code)
						if (sis_classes.length > 0) for (let assignee of this.activity.assigned_to) {
							if (empty(assignee.user_sourcedId)) {
								for (let c of sis_classes) {
									let index = c.class_sourcedIds.findIndex(x=>x==assignee.class_sourcedId)
									// don't send section data for sections with no students
									if (index > -1 && $.isArray(c.students[index])) {
										console.log('send section data for', assignee.class_sourcedId, index)
										if (!lti_section_collections) lti_section_collections = {}
										lti_section_collections[assignee.class_sourcedId] = {
											title: c.section_title(index, {term:0, title:true}),
											students: {},
										}
										for (let student of c.students[index]) {
											lti_section_collections[assignee.class_sourcedId].students[student.email] = {g: student.givenName, f: student.familyName}
										}
									}
								}
							}
						}

						// we got any lti_section_collections, send them, stringified, with the payload
						// if (lti_section_collections) console.log('lti_section_collections', JSON.stringify(lti_section_collections).length, lti_section_collections)
						if (lti_section_collections) payload.lti_section_collections = JSON.stringify(lti_section_collections)
					}

				// for studentish, send only sections the student is a member of, and only send their email
				} else if (this.studentish_role) {
					let lti_section_collections
					let sis_classes = this.$store.state.sis_classes.filter(x=>x.course_code==this.activity.course_code)
					if (sis_classes.length > 0) for (let assignee of this.activity.assigned_to) {
						if (empty(assignee.user_sourcedId)) {
							for (let c of sis_classes) {
								let index = c.class_sourcedIds.findIndex(x=>x==assignee.class_sourcedId)
								if (index > -1) {
									// if we find the class_sourcedId in the student's sis_classes records, the student is definitionally in that class
									console.log('send section data for', assignee.class_sourcedId)
									if (!lti_section_collections) lti_section_collections = {}
									lti_section_collections[assignee.class_sourcedId] = {
										title: c.section_title(index, {term:0, title:true}),
										students: {},
									}
									lti_section_collections[assignee.class_sourcedId].students[this.user_info.email] = {g: this.user_info.first_name, f: this.user_info.last_name}
								}
							}
						}
					}

					// we got any lti_section_collections, send them, stringified, with the payload
					// if (lti_section_collections) console.log('lti_section_collections', JSON.stringify(lti_section_collections).length, lti_section_collections)
					if (lti_section_collections) payload.lti_section_collections = JSON.stringify(lti_section_collections)
				}
			}

			// debug
			// payload.debug_sparkl_resource_launches = 'yes'

			U.ajax('get_sparkl_lti_launch', payload, result=>{
				if (result.status != 'ok') {
					vapp.ping()		// call ping to check if the session is expired
					vapp.$alert('An error occurred when attempting to open the Sparkl activity.')
					return
				}

				// console.log('got lti_form:', result.lti_form)
				// we should return back the lti launch form; write it out to the iframe
				window['sparkl_iframe-'+this.sparkl_embed_id].document.write(result.lti_form)
			});
		},

		// this is the initialize fn for the host window that embeds/opens sparkl in an iframe window
		// here, we establish the eventListener for postMessages (if it hasn't already been established), then send a message to sparkl establishing the connection.
		pm_initialize() {
			// set up eventListener for postMessages -- BUT we only set up one listener for the window, no matter how many Sparkl iframes we embed
			if (!this.pm_event_listener_created) window.addEventListener('message', (event) => {
				// event.source is the window that sent the message (the window that included the iframe)
				// event.origin is the origin that sent it; make sure we trust it
				// event.data is what was sent

				// Do we trust the sender of this message?
				if (event.origin !== this.sparkl_origin) {
					if (this.pm_verbose == 'show_untrusted') {
						if (event.origin == window.location.origin) console.log('   PMX HOST    !-- message in host from self')
						else console.log('   PMX HOST    !-- message in host from untrusted origin: ' + event.origin, event.data)
					}
					return
				}

				// messages should all have the form {msg: 'xxx', data: ...}
				if (typeof(event.data) != 'object' || empty(event.data.msg)) {
					// Sparkl sends the following messages to make Canvas work better, which we don't need to report about
					if (!(typeof(event.data) == 'object' && ['lti.hideRightSideWrapper','lti.frameResize','lti.scrollToTop'].includes(event.data.subject))) {
						console.log('   PMX HOST    !-- bad message received', event.data)
					}
					return
				}

				// Remember: we only set this event listener once, for the first-loaded SparklEmbed component; that listener then handles all events from any SparklEmbed components we open later
				// Get sparkl_embed_id from event.data. That indicates the SparklEmbed component this event is directed at. Direct the event to that component
				let sparkl_embed_id = event.data.sparkl_embed_id
				if (this.pm_verbose) console.log(sr('   PMX HOST    <-- Message from sparkl_embed_id $1', this.sparkl_embed_id))
				this.sparkl_embed_components[sparkl_embed_id].pm_execute_from_host(event)
			}, false);
			this.$store.commit('set', ['pm_event_listener_created', true])

			// show loader, then start trying to establish the connection
			U.loading_start()
			setTimeout(x=>{ this.pm_establish_connection() }, 100)
		},

		pm_establish_connection() {
			// sparkl may not be ready to receive messages right away, so keep sending this message until it succeeds
			if (!this.connection_established) {
				if (this.establish_connection_tries > 600) {
					console.log('   PMX HOST    !-- giving up on establishing connection')
					U.loading_stop()
					// vapp.$alert('The connection to Sparkl could not be opened.')
					return
				}
				++this.establish_connection_tries
				if (this.pm_verbose) console.log('   PMX HOST    --> queuing establish_connection message (' + this.establish_connection_tries + ')')

				// we send embedder_origin and satchel_origin in to sparkl in the establish_connection message
				this.pm_send('establish_connection', {embedder_origin: this.site_config.sparkl_embedder_origin, satchel_origin: this.site_config.satchel_origin })

				setTimeout(x=>{ this.pm_establish_connection() }, 50)
			}
		},

		pm_send(msg, data, resolve, reject) {
			if (this.pm_verbose && msg != 'establish_connection') console.log('   PMX HOST    --> pm_send: ' + msg)

			// queue a message to be sent
			// second param specifies what the origin of the target window must be for the event to be dispatched
			try {
				// note that we send in sparkl_embed_id; sparkl sends it back with its messages so the host's event listener will knows which embedded iframe should deal with the message
				window['sparkl_iframe-'+this.sparkl_embed_id].postMessage({msg: msg, data: data, sparkl_embed_id:this.sparkl_embed_id}, this.sparkl_origin)
				if (resolve) resolve('ok')

			} catch(e) {
				console.log('   PMX HOST    !-- pm_send error caught: ', e)
				if (reject) reject(e)
			}
		},

		// this fn acts on the messages sent back from Sparkl
		pm_execute_from_host(event) {
			let msg = event.data.msg
			let data = event.data.data

			if (msg == 'received_establish_connection') {
				if (this.pm_verbose) console.log('   PMX HOST    <-- CONNECTION ESTABLISHED WITH SPARKL')
				this.sparkl_loading_status = 'loaded'
				this.connection_established = true
				U.loading_stop()

				// if the original execute command wasn't 'show', assume that the user wants the iframe to appear first
				// (if the original command *was* show, go ahead and execute it; then we'll go on to execute whatever was in resolve)
				if (this.initialize_params.cmd != 'show') this.sparkl_frame_showing = true

				// execute the command that sent to the original execute call (wait a tick in case we just showed)
				this.$nextTick(()=>this.execute_cmd(this.initialize_params.cmd, this.initialize_params.cmd_data, this.initialize_params.resolve, this.initialize_params.reject))
				
			///////////////////////////////////////////
			// HERE ARE THE MESSAGES THAT SPARKL MAY SEND BACK TO US
			} else if (msg == 'activity_initialized') {
				if (this.pm_verbose) console.log('   PMX HOST    <-- activity_initialized: ' + JSON.stringify(data))

				// data will be the sparkl activity_data record (sparkl vapp.ad); store it here, as we use it for some things here; and also send to controller_component in case it needs it
				this.sparkl_activity_data_record = data
				console.log('sparkl_activity_data_record received: ' + JSON.stringify(this.sparkl_activity_data_record))
				if (this.controller_component.sparkl_activity_initialized) this.controller_component.sparkl_activity_initialized(data)

			} else if (msg == 'window_size_inform') {
				if (this.pm_verbose) console.log('   PMX HOST    <-- window_size_inform: ' + JSON.stringify(data))

				// since we always run with the screen maximized, we don't change the iframe height

			} else if (msg == 'import_to_my_collections') {
				if (this.pm_verbose) console.log('   PMX HOST    <-- import_to_my_collections: ' + JSON.stringify(data))
				this.copy_to_my_content()

			} else if (msg == 'sparkl_activity_saved') {
				if (this.pm_verbose) console.log('   PMX HOST    <-- sparkl_activity_saved: ' + JSON.stringify(data))
				// this.$emit('sparkl_activity_saved', data)
				if (this.controller_component.sparkl_activity_saved) this.controller_component.sparkl_activity_saved(data)

			} else if (msg == 'sparkl_student_results_update') {
				if (this.pm_verbose) console.log('   PMX HOST    <-- student_results_update: ' + JSON.stringify(data))
				// this.$emit('student_results_update', data)
				if (this.controller_component.sparkl_activity_saved) this.controller_component.student_results_update(data)

			} else if (msg == 'open_resource_link') {
				vapp.open_resource_link(data.resource_id)

			} else if (msg == 'reload_activity') {
				this.reload_iframe()

			// more messages here...
			} else {
				if (this.pm_verbose) console.log('   PMX HOST    !-- received unprocessed message: ', msg, data)
			}
		},

		reload_iframe() {
			// to reload the iframe, we have to first set sparkl_loading_status to false so that the iframe is removed from the DOM; 
			// then on next tick we set status to 'loading' and redo some of what we do in execute when the frame hasn't yet been loaded
			this.sparkl_loading_status = false
			this.$nextTick(()=>{
				this.sparkl_loading_status = 'loading'
				this.connection_established = false
				this.lti_launch()
				this.pm_initialize()
			})
		},

		open_in_new_window() {
			// we don't do this in Inspire
			console.log('open_in_new_window -- not supported')
			return
			let url = window.location.origin + '/activity/' + this.activity.activity_id + '?admin'
			window.open(url, 'sparkl_tab-'+this.activity.activity_id)
		},

		copy_to_my_content($event) {
			if (this.controller_component.copy_to_my_content) this.controller_component.copy_to_my_content($event)
			else console.log('copy_to_my_content called (from sparkl iframe?), but `this.controller_component.copy_to_my_content` is null')
		},

		use_with_students() {
			// when the user clicks this btn, ask sparkl to show the activity link
			this.pm_send('show_activity_link')
		},

		close_btn_clicked() {
			this.controller_component.close_sparkl()
		},
	},
}
</script>

<style lang="scss">
.k-sparkl-inline-outer {
	width:100%;
	// width:600px;
	height:220px;
	// background-color:#999;
}

.k-sparkl-inline-iframe {
	width:100%;
	height:100%;
	border:0px;
	background-color:transparent;
}

.k-sparkl-inline-iframe-student-demo {
	border-top:20px solid #999;
}

.k-sparkl-maximized {
	position:fixed;
	z-index:4;
	left:0;
	top:48px;	// below HenryConnects banner
	width:100vw;
	height:calc(100vh - 48px);
	background-color:#fff;

	.k-sparkl-inline-iframe {
		height:calc(100vh - 48px);
	}
}

.k-sparkl-overlay-scrim {
	// display:none;
	position:fixed;
	z-index: 3;	// we previously had this at 0; but then the welcome "tabs" would show through...
	left:0;
	top:0;
	width:100vw;
	height:100vh;
	background-color:#fff;
}

.k-sparkl-close-btn {
	position:fixed;
	z-index:10000001;
	right:2px;
	top:2px;
	background-color:#fff;
	border-radius:50px;
}

.k-sparkl-done-editing-btns {
	position:absolute;
	width:100%;
	z-index:10000001;
	left:0;
	bottom:0px;
	display:flex;
	align-items: center;
	background-color:#eee;
	border-top:1px solid $v-amber-accent-4;
	padding:6px 0;
	// border-radius:0 0 12px 12px;
}
</style>