<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-page-wrapper">
	<h2 class="k-page-title">
		<v-icon @click="return_to_admin_main" large color="primary" class="mr-2">fas fa-cog</v-icon>
		Content Collections
	</h2>

	<p v-show="initialized&&!new_lp">
		<!-- <v-btn @click="create_lp" class="k-tight-btn" color="primary"><v-icon class="mr-2" small>fas fa-plus</v-icon> Create a New Course</v-btn> -->
		<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-on="on" class="k-tight-btn" color="primary"><v-icon class="mr-2" small>fas fa-plus</v-icon> Create a New Collection</v-btn></template>
			<v-list min-width="250">
				<v-list-item @click="create_lp('course')"><v-list-item-icon><v-icon small>fas fa-chalkboard</v-icon></v-list-item-icon><v-list-item-title>Course</v-list-item-title></v-list-item>
				<v-list-item @click="create_lp('repo')"><v-list-item-icon><v-icon small>fas fa-diagram-project</v-icon></v-list-item-icon><v-list-item-title>Repository</v-list-item-title></v-list-item>
				<v-list-item @click="create_lp('pd')"><v-list-item-icon><v-icon small>fas fa-user-graduate</v-icon></v-list-item-icon><v-list-item-title>PD Collection</v-list-item-title></v-list-item>
			</v-list>
		</v-menu>

		<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-on="on" class="k-tight-btn ml-2" color="primary"><v-icon class="mr-2" small>fas fa-gear</v-icon> More Options...</v-btn></template>
			<v-list min-width="250">
				<v-list-item @click="duplicate_lp"><v-list-item-icon><v-icon small>fas fa-copy</v-icon></v-list-item-icon><v-list-item-title>Duplicate an Existing Collection</v-list-item-title></v-list-item>
				<v-list-item @click="delete_lp_clicked"><v-list-item-icon><v-icon small>fas fa-trash-alt</v-icon></v-list-item-icon><v-list-item-title>Delete an Existing Collection</v-list-item-title></v-list-item>
				<v-list-item @click="show_change_collection_type_dialog"><v-list-item-icon><v-icon small>fas fa-shuffle</v-icon></v-list-item-icon><v-list-item-title>Change a Collection’s Type</v-list-item-title></v-list-item>
				<v-list-item @click="show_subject_color_changer = true"><v-list-item-icon><v-icon small>fas fa-pen-to-square</v-icon></v-list-item-icon><v-list-item-title>Update Subject Colors</v-list-item-title></v-list-item>
			</v-list>
		</v-menu>

		<v-dialog v-model="change_collection_type_dialog_showing" max-width="680" persistent scrollable>
			<v-card>
				<v-card-title><b>Change Collection Type</b></v-card-title>
				<v-card-text class="pt-2 pb-4" style="border-top:1px solid #999;">
					<div class="pt-2">
						<v-autocomplete clearable v-model="selected_collection" :items="course_selection_array" label="Choose a Collection" outlined hide-details dense background-color="#fff" @change="">
						</v-autocomplete>
					</div>
					<v-expand-transition>
						<div class="mt-4" v-if="selected_collection">
							<!-- <pre>{{ JSON.stringify(selected_collection, false, 2) }}</pre> -->
							<v-autocomplete :items="collection_types" v-model="updated_collection_type" return-object :label="collection_select_label" outlined hide-details dense background-color="#fff"></v-autocomplete>
						</div>
					</v-expand-transition>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4 pb-4" >
					<v-spacer/>
					<v-btn color="primary" @click="save_collection_type_change" :disabled="!change_collection_type_saveable"><v-icon small class="mr-2">fas fa-save</v-icon>Save</v-btn>
					<v-btn color="secondary" @click="change_collection_type_dialog_showing=false"><v-icon small class="mr-2">fas fa-times</v-icon>Cancel</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</p>

	<CollectionEdit v-if="new_lp" :learning_progression="new_lp" @editor_cancel="new_lp=null"></CollectionEdit>
	<CollectionEdit v-if="show_editor_for_changed_collection" :learning_progression="selected_collection" @editor_cancel="close_editor_for_changed_collection"></CollectionEdit>
	<AdminSubjectChanger v-if="show_subject_color_changer" @dialog_cancel="show_subject_color_changer=false"/>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CollectionEdit from '../collections/CollectionEdit'
import AdminSubjectChanger from './AdminSubjectChanger.vue';

export default {
	components: { CollectionEdit, AdminSubjectChanger },
	props: {
	},
	data() { return {
		initialized: false,
		new_lp: null,
		selected_collection: '',
		change_collection_type_dialog_showing: null,
		updated_collection_type: null,
		show_editor_for_changed_collection: false,
		collection_types: [
			{ text: 'Course Collection', value: 'course'},
			{ text: 'Resource Repository', value: 'repo' },
			{ text: 'PD Collection', value: 'pd' },
			{ text: 'User Collection', value: 'my'},
		],
		show_subject_color_changer: false
	}},
	computed: {
		...mapState(['user_info', 'site_config', 'all_courses_loaded', 'all_courses', 'added_my_courses', 'lst']),
		...mapGetters([]),

		collection_select_label() {
			if (!this.change_collection_type_dialog_showing || !this.updated_collection_type) return ''
			return this.updated_collection_type.value === this.selected_collection.collection_type ? 'Current collection type' : 'Update collection type to'
		},

		course_selection_array() {
			let arr = []
			for (let lp of this.all_courses) {
				// skip inactive courses if user isn't an admin for the course
				if (lp.active != 'yes' && !(lp.user_can_view_lp && lp.user_can_view_lp())) continue

				// Skip the My Content Sandbox collection
				if (lp.course_code === 'default') continue

				arr.push({value:lp, text: lp.title})
			}

			// Sort on various parameters
			let priority_collections = []
			let added_courses = new Set()
			// 1st priority is the last viewed collections
			priority_collections.push(...arr.filter( ({value}) => {
				const exists = this.lst.last_collections_viewed.includes(`${value.course_code}`)
				if (exists && !added_courses.has(value.course_code)) {
					added_courses.add(value.course_code)
					return exists
				}
			}))
			// 2nd priority is the user created
			priority_collections.push(...arr.filter( ({value}) => {
				const exists = this.user_info.admin_rights.includes(`lp.course.${value.course_code}`)
				if (exists && !added_courses.has(value.course_code)) {
					added_courses.add(value.course_code)
					return exists
				}
			}))
			// 3rd priority is the added my courses
			priority_collections.push(...arr.filter( ({value}) => {
				const exists = this.added_my_courses.includes(`${value.course_code}`)
				if (exists && !added_courses.has(value.course_code)) {
					added_courses.add(value.course_code)
					return exists
				}
			}))
			const non_priority = arr.filter((e) => !priority_collections.includes(e))
			// sort alphabetically
			non_priority.sort((a,b)=>U.natural_sort(a.value.title, b.value.title))

			return [...priority_collections, ...non_priority]
		},

		selected_collection_title() {
			return this.selected_collection?.title
		},

		change_collection_type_saveable() {
			if (!this.selected_collection) return false
			return this.selected_collection.collection_type !== this.updated_collection_type.value
		},
	},
	created() {
		if (!this.all_courses_loaded) {
			this.$store.dispatch('get_all_courses').then(()=>{
				this.$nextTick(()=>{
					this.initialized = true
				})
			}).catch(()=>{
				this.$alert('error in get_all_courses')
				this.return_to_admin_main()
			})
		} else {
			this.initialized = true
		}
	},
	mounted() {
	},
	watch: {
		selected_collection(new_val) {
			const collection_type = new_val?.collection_type ?? null
			if (!collection_type) return
			this.updated_collection_type = this.collection_types.find(item => item.value === collection_type)
		}
	},
	methods: {
		create_lp(collection_type) {
			// lps created from here are definitionally agency_sanctioned
			this.new_lp = new Learning_Progression({agency_sanctioned: true, collection_type: collection_type})
			return
		},

		duplicate_lp() {
			this.$prompt({
				title: 'Duplicate Collection',
				text: 'Enter the “course code(s)” for the collection (course or repository) you’d like to duplicate, along with the new course codes for the duplicates. Separate each original and duplicate course code with a :, and separate multiple course code pairs with a line break, like this:<br><br>1111: 1112<br>3333: 3334<br>5555: 5556<br><br>',
				promptType: 'textarea',
				acceptText: 'Duplicate',
			}).then(course_code_lines => {
				if (empty(course_code_lines)) return
				course_code_lines = $.trim(course_code_lines).split(/\n+/)

				// parse lines and check for errors
				let course_codes = []
				let bad_origin_course_codes = []
				let bad_destination_course_codes = []
				for (let line of course_code_lines) {
					let arr = $.trim(line).split(/[\s\:]+/)
					if (arr.length != 2) {
						this.$alert('Bad line in input:<br><br>' + line)
						return
					}

					// if the first code doesn't exist, error
					if (empty(this.$store.state.all_courses.find((o)=>o.course_code==arr[0]))) {
						bad_origin_course_codes.push(arr[0])
					}

					// if the second code does exist, error
					if (!empty(this.$store.state.all_courses.find((o)=>o.course_code==arr[1]))) {
						bad_destination_course_codes.push(arr[1])
					}

					course_codes.push(arr)
				}

				if (bad_origin_course_codes.length > 0) {
					this.$alert(sr('We don’t have collection(s) for course code(s) $1. Operation cancelled.', bad_origin_course_codes.join(', ')))
					return
				}
				if (bad_destination_course_codes.length > 0) {
					this.$alert(sr('We already have collection(s) for course code(s) $1. Operation cancelled.', bad_destination_course_codes.join(', ')))
					return
				}

				// if we get to here, process the duplicates
				this.$store.dispatch('duplicate_learning_progressions', course_codes).then(()=>{
					this.$alert('Collection(s) duplicated.')
				})
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		delete_lp_clicked() {
			this.$prompt({
				title: 'Delete Collection',
				text: 'Enter the “course code” for the collection you’d like to delete.',
				acceptText: 'Delete',
				acceptColor: 'red',
			}).then(course_code => {
				if (!empty(course_code)) {
					this.delete_lp(course_code)
				}
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		delete_lp(course_code) {
			let lp = this.$store.state.all_courses.find((o)=>o.course_code==course_code)
			if (empty(lp)) {
				this.$alert('We don’t have a collection for that course code in the system.')
				return
			}

			this.$confirm({
			    title: 'Are you sure?',
			    text: sr('Are you sure you want to delete the collection for course code $1 (“$2”)?', lp.course_code, lp.title),
			    acceptText: 'Delete',
				acceptColor: 'red',
			}).then(y => {
				this.$store.dispatch('delete_learning_progression', lp).then(()=>{
					this.$alert('Collection deleted.')
				})
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		return_to_admin_main() {
			this.$router.push({ path: '/' })
		},

		show_change_collection_type_dialog() {
			this.change_collection_type_dialog_showing = true
			this.selected_collection = null
		},

		save_collection_type_change() {
			const collection_type = this.selected_collection?.collection_type
			const previous_collection_type = this.collection_types.find(item => item.value === collection_type).text || `${collection_type} collection`

			let confirm_prompt = `Are you sure you want to change the collection type for "${this.selected_collection.title}" from ${previous_collection_type} to ${this.updated_collection_type.text}?`

			// Switching from another collection type to a 'My' collection is worth an extra warning
			if (this.updated_collection_type.value === 'my') confirm_prompt += ` Switching from a ${previous_collection_type} to a User Collection might have unintended consequences.`

			this.$confirm({
			    title: 'Are you sure?',
			    text: confirm_prompt,
			}).then(y => {
				const payload = {
					course_code: this.selected_collection.course_code,
					updated_collection_type: this.updated_collection_type.value
				}
				this.$store.dispatch('admin_change_collection_type', payload).then(()=>{
					this.change_collection_type_dialog_showing = false

					// If we have changed to a Course Collection, we need to add required course data (subject & grade)
					if (this.updated_collection_type.value === 'course') {
						this.show_editor_for_changed_collection = true
						this.$inform('Please set course subject and grade range')
					} else {
						this.selected_collection = null
						this.$alert('Collection type changed.')
					}

				})
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		close_editor_for_changed_collection() {
			this.selected_collection = null
			this.show_editor_for_changed_collection = false
		},
	}
}
</script>

<style lang="scss">
</style>
