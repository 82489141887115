<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-main-division-wrapper k-main-classes">
	<AdminList />
	<router-view></router-view>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import AdminList from './AdminList'

export default {
	components: { AdminList },
	data() { return {
	}},
	computed: {
		...mapState([]),
	},
	created() {
		// keep non-admins out of admin tools
		if (!vapp.has_admin_right('su')) {
			this.$router.push({ path: '/home' })
		}
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
.k-main-classes {
	position:relative;
}
</style>
