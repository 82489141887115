<template>
	<div>
		<v-menu :close-on-content-click="true" transition="scale-transition" offset-y min-width="290px">
			<template v-slot:activator="{ on }">
				<v-text-field background-color="#f8f8f8" outlined dense hide-details :value="value" :label="label"
					prepend-inner-icon="fas fa-calendar-week" readonly v-on="on">
				</v-text-field>
			</template>
			<v-date-picker :value="value" @input="$emit('input', $event)" no-title scrollable>
			</v-date-picker>
		</v-menu>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			default: '',
		},
	},
}
</script>

<style lang="scss" scoped>
</style>
