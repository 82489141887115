class Message {
	constructor(data) {
		if (empty(data)) {
			data = {}
		}
		sdp(this, data, 'message_id', 0)
		sdp(this, data, 'course_code', '')
		sdp(this, data, 'author_user_id', 0)
		sdp(this, data, 'author_sourcedId', '')
		sdp(this, data, 'parent_message_id', 0)
		sdp(this, data, 'message_level', 0)
		sdp(this, data, 'send_to', 'Student')
		sdp(this, data, 'subject', '')
		sdp(this, data, 'body', '')
		sdp(this, data, 'replies', [])
		sdp(this, data, 'recipients', [])
		sdp(this, data, 'activity_id', 0)
		sdp(this, data, 'is_read', false)
		sdp(this, data, 'first_name', '')
		sdp(this, data, 'last_name', '')

		// convert created at from mysql to timestamp
		const tzo = new Date().getTimezoneOffset() * 60 * 1000 // timezone offset in milliseconds

		const convert_to_local_tz = (timestamp) => {
			return (date.parse(timestamp, 'YYYY-MM-DD HH:mm:ss').getTime() - tzo) / 1000
		}

		if (!isNaN(data.created_at * 1)) this.created_at = data.created_at * 1
		else this.created_at = empty(data.created_at) ? 0 : convert_to_local_tz(data.created_at)

		if (!isNaN(data.send_at * 1)) this.send_at = data.send_at * 1
		else this.send_at = empty(data.send_at) ? 0 : convert_to_local_tz(data.send_at)

		// If there are replies, set the initial (level one) reply send-at to local tz
		if (this.replies && this.replies.length > 0) {
			this.replies.forEach((reply) => {
				if (!isNaN(reply.send_at * 1)) reply.send_at = reply.send_at * 1
				else reply.send_at = empty(reply.send_at) ? 0 : convert_to_local_tz(reply.send_at)

				// Do the same for replies to replies (aka level 2 replies)
				if (reply.replies && reply.replies.length > 0) {
					reply.replies.forEach((reply_to_reply) => {
						if (!isNaN(reply_to_reply.send_at * 1)) reply_to_reply.send_at = reply_to_reply.send_at * 1
						else reply_to_reply.send_at = empty(reply_to_reply.send_at) ? 0 : convert_to_local_tz(reply_to_reply.send_at)
					})
				}
			})
		}
	}

	course_header() {
		// adapted from course_unit_header() in src/js/activities.js
		if (!this.course_code) return ''
		let lp = vapp.$store.state.all_courses.find((x) => x.course_code == this.course_code)
		if (!lp) lp = vapp.$store.state.sis_classes.find((x) => x.course_code == this.course_code)
		if (!lp) return this.course_code
		let s = lp.title

		let style = U.collection_color_style(lp)
		s = `<div class="k-lesson-course-unit-header-inner" style="border-color:${style['background-color']}; color:${style['background-color']}">${s}</div>`

		return s
	}
}

window.Message = Message

class Recipient {
	constructor(data) {
		if (empty(data)) {
			data = {}
		}
		sdp(this, data, 'message_recipient_mapping_id', 0)
		sdp(this, data, 'class_sourcedId', '')
		sdp(this, data, 'user_sourcedId', '')
	}

	// get a list of the student records of all students included in this Recipient group
	get_students() {
		let arr = []
		let all_students = vapp.$store.state.all_students
		if (!all_students) {
			console.log('get students: no all_students')
		}
		if (this.user_sourcedId) {
			if (all_students[this.user_sourcedId]) arr.push(all_students[this.user_sourcedId])
		} else {
			for (let key in all_students) {
				let student = all_students[key]
				if (student.class_sourcedIds.includes(this.class_sourcedId)) arr.push(student)
			}
		}
		return arr
	}

	to_string(sis_classes) {
		// mostly copied from src/js/activities.js
		if (empty(sis_classes)) sis_classes = vapp.$store.state.sis_classes
		if (sis_classes)
			for (let my_class of sis_classes) {
				for (let i = 0; i < my_class.class_sourcedIds.length; ++i) {
					if (my_class.class_sourcedIds[i] == this.class_sourcedId) {
						// found the class_sourcedid
						// if the assignment is for the whole class, show that
						if (this.user_sourcedId == '') {
							let n_students = 0
							if (!empty(my_class.students[i])) n_students = my_class.students[i].length
							if (n_students == 0) continue
							return my_class.section_title(i, {
								student_count: true,
								teacher: false,
								title: false,
								term: 0,
							})

							// else try to find the student
						} else {
							if (!empty(my_class.students[i]))
								for (let student of my_class.students[i]) {
									if (student.sourcedId == this.user_sourcedId) {
										// found the student
										let name = student.familyName + ', ' + student.givenName
										if (!empty(student.middleName)) name += ' ' + student.middleName[0] + '.'
										return name
									}
								}
						}
					}
				}
			}
		// if we get to here we didn't find a valid string; this shouldn't, in theory, happen
		return sr('[unknown recipient $1]', this.message_recipient_mapping_id)
	}
}

window.Recipient = Recipient
