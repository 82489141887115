<template>
	<div class="k-page-wrapper">
		<div v-if="!course_has_coteachers" class="mt-4">
			You have not added any co-teachers to this course.
		</div>
		<div v-if="course_has_coteachers">
			<v-data-table light :headers="headers" :items="rows" hide-default-footer>
				<template v-slot:item="{ item }">
					<tr>
						<td style="width:500px">
							<v-btn icon x-small class="mr-2" @click="edit_coteacher(item.id)">
								<v-icon>fas fa-edit</v-icon>
							</v-btn>
							<span v-html="item.user"></span>
						</td>
						<td style="width:100px" class="k-td-icon">
							<v-icon small v-if="item.can_view_results">fas fa-check</v-icon>
						</td>
						<td style="width:100px" class="k-td-icon">
							<v-icon small v-if="item.can_edit_results">fas fa-check</v-icon>
						</td>
						<td style="width:100px" class="k-td-icon">
							<v-icon small v-if="item.can_edit_activity">fas fa-check</v-icon>
						</td>
					</tr>
				</template>
			</v-data-table>
		</div>
		<CoteacherEdit v-if="user_being_edited" :coteacher="user_being_edited"
			@editor_cancel="user_being_edited = null" />
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import CoteacherEdit from './CoteacherEdit';

export default {
	components: { CoteacherEdit },
	props: {
		course_code: { type: String, required: false, default() { return '' } },
	},
	data() {
		return {
			user_being_edited: null,
			headers: [
				{ text: 'User', align: 'left', sortable: false, value: 'user' },
				{ text: 'Can View Student Results?', align: 'center', sortable: false, value: 'can_view_results' },
				{ text: 'Can Edit Student Results?', align: 'center', sortable: false, value: 'can_edit_results' },
				{ text: 'Can Edit Activity Content?', align: 'center', sortable: false, value: 'can_edit_activity' },
			],
		};
	},
	computed: {
		...mapState(['my_coteachers', 'user_info']),
		...mapGetters([]),
		coteachers_for_course() {
			return this.my_coteachers
				.filter(ct => ct.coteacher_user_id !== this.user_info.user_id) // coteachers shouldn't see themselves in list
				.filter(ct => ct.course_code == this.course_code)
				.sort((a, b) => a.last_name.localeCompare(b.last_name))
		},
		course_has_coteachers() {
			return this.coteachers_for_course.length > 0
		},
		rows() {
			let arr = []
			this.coteachers_for_course.forEach((ct) => {
				arr.push({
					id: ct.coteacher_id,
					user: `${ct.last_name}, ${ct.first_name} (${ct.email})`,
					can_view_results: ct.permission_level >= 0 ? true : false,
					can_edit_results: ct.permission_level >= 1 ? true : false,
					can_edit_activity: ct.permission_level >= 2 ? true : false,
				})
			})
			return arr
		},
	},
	methods: {
		edit_coteacher(coteacher_id) {
			this.user_being_edited = this.coteachers_for_course.find(ct => ct.coteacher_id == coteacher_id)
		},
	},
	created() { },
	mounted() { },
}
</script>

<style lang="scss">
.k-td-icon {
	text-align: center;
}
</style>
