<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
	<div class="k-mymessages-view-outer">
		<div class="k-mymessages-view-header pr-2">
			<div>{{ header_text }}</div>
			<v-spacer />
			<v-btn small color="secondary" class="ml-3 k-tight-btn" @click="show_preferences_dialog">
				<v-icon small class="mr-2">fas fa-gear</v-icon>
				Edit Preferences
			</v-btn>
			<v-btn v-if="!is_in_my_courses_view && is_staff" small color="primary" class="ml-3 k-tight-btn"
				@click="create_new_message">
				<v-icon small class="mr-2">fas fa-plus</v-icon>
				{{ new_message_btn_text }}
				<v-icon small class="ml-2">far fa-comment</v-icon>
			</v-btn>
		</div>

		<div v-if="preferences_dialog_open" class="mx-auto k-mymessages-prefs">
			<MessagePreferences :message_preferences="message_preferences"
				@close_preferences_dialog="close_preferences_dialog" />
		</div>

		<div class="mx-auto k-mymessages-list">
			<div v-if="show_message_editor && new_message" class="mb-4 k-new-message-component">
				<div class="k-message-outer k-message-outer-edited elevation-2">
					<div class="k-message-title">
						<v-icon class="mr-2" color="primary">fas fa-comment</v-icon>
						<div><b style="font-weight: 900">New Message</b></div>
						<v-spacer />
					</div>
					<div class="k-message-edit-outer pt-3">
						<MessageEditor v-if="show_message_editor && new_message" :message="new_message"
							:course_code="this.course_code" @editor_cancel="show_message_editor = false" />
					</div>
				</div>
			</div>
			<MessageList v-if="has_messages" :course_code="course_code"
				:notification_message_id="notification_message_id" />
			<div v-if="!has_messages" class="k-mymessages-no-messages elevation-2">No messages</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import MessageList from './MessageList'
import MessageEditor from './MessageEditor'
import MessagePreferences from './MessagePreferences'

export default {
	components: { MessageList, MessageEditor, MessagePreferences },
	props: {
		// req: { type: String, required: true },
		course_code: {
			type: String,
			required: false,
			default() {
				return ''
			},
		},
		notification_message_id: {
			type: Number,
			required: false,
			default() {
				return null
			},
		},
	},
	data() {
		return {
			new_message: null,
			new_message_is_saved: false,
			show_message_editor: false,
			preferences_dialog_open: false,
		}
	},
	computed: {
		...mapState(['messages', 'user_info', 'all_courses']),
		...mapGetters(['role', 'system_role', 'threaded_messages', 'messages_for_student']),
		is_staff() {
			return this.role == 'staff' || this.role == 'admin'
		},
		header_text() {
			let s = 'Message Center'
			if (this.role == 'parent') s = 'Messages About My Child'
			if (this.role == 'student') s = 'My Messages'
			if (this.max_context == 0) s += ': All Courses'
			return s
		},
		new_message_btn_text() {
			let s = 'New Message'
			if (this.role === 'parent') s = "Message My Child's Instructor"
			if (this.role === 'student') s = 'Message My Instructor'
			return s
		},
		has_messages() {
			let ms = this.role !== 'parent' ? this.threaded_messages : this.messages_for_student
			const root_messages =
				ms &&
				ms.filter((m) => {
					let rv = m.message_id !== 0
					if (this.course_code !== '') rv = rv && m.course_code === this.course_code
					return rv
				})
			return root_messages?.length > 0
		},
		message_preferences() {
			const prefs = this.user_info.message_preferences
			if (!prefs) return {}
			return prefs
		},
		is_in_my_courses_view() {
			return this.course_code === ''
		},
	},
	watch: {},
	created() {
		// // Commented this out... Was in Henry, but didn't seemed to be used anywhere
		// vapp.my_messages_view = this
	},
	mounted() {
		let payload = {
			course_code: this.course_code,
		}
		this.$store.dispatch('get_messages', payload)
	},
	methods: {
		show_preferences_dialog() {
			this.preferences_dialog_open = true
		},
		close_preferences_dialog() {
			this.preferences_dialog_open = false
		},
		create_new_message() {
			let m = {
				author_user_id: this.user_info.user_id,
				author_sourcedId: this.user_info.sis_user_sourcedId,
				course_code: this.course_code,
				message_id: 0,
				message_level: 0,
				parent_message_id: 0,
				first_name: this.user_info.first_name,
				last_name: this.user_info.last_name,
			}
			console.log(m)
			this.new_message = new window.Message(m)
			this.$store.commit('set', [this.messages, 'PUSH', this.new_message])
			this.show_message_editor = true
		},
	},
}
</script>

<style lang="scss">
.k-mymessages-view-outer {
	max-width: 100vw;
	margin: 0 auto;
	text-align: left;
	padding-top: 12px;

	.k-mymessages-view-header {
		max-width: 800px;
		margin: 0 auto;
		padding: 0 0 0 4px;
		margin-bottom: 8px;
		text-align: left;
		font-size: 18px;
		font-weight: bold;
		color: #666;
		display: flex;
		align-items: center;
	}

	.k-mymessages-list {
		width: 820px;

		.k-mymessages-no-messages {
			font-style: italic;
			font-size: 16px;
			background-color: #fff;
			border-radius: 12px;
			margin-top: 12px;
			padding: 12px;
		}
	}

	.k-mymessages-prefs {
		width: 820px;
	}
}

.k-new-message-component {
	max-width: 860px;
	margin-left: auto;
	margin-right: auto;
}

.k-message-outer {
	background-color: #f5f5f5;
	border-radius: 8px;
	padding: 8px;
	margin-top: 0;
	margin-bottom: 4px;
}

.k-message-outer-edited {
	background-color: #fff;
	border: 2px solid #666;
	position: relative; // needed for positioning of close/save btns
}

.k-message-title {
	display: flex;
	align-items: center;
}
</style>
