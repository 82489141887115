<template><div>
	<router-view></router-view>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	// components: { , },
	data() { return {
	}},
	computed: {
		...mapState([]),
	},
	created() {
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
</style>
