<template>
	<div>
		<v-hover v-slot:default="{ hover }">
			<div @click="toggle_message">
				<div
					:class="{ 'd-flex': true, 'k-message-reply-header': true, 'k-message-reply-header--hover': hover, 'k-message-reply-header--expanded': expanded }">
					<div class="d-flex k-message-reply-sender">
						<v-icon class="mr-2" small>{{ expansion_caret }}</v-icon>
						<p>
							<v-icon v-if="show_unread_message_icon" class="k-unread-message-icon"> fa-light fa-comment
							</v-icon>
							{{ message.first_name }} {{ message.last_name }}
							<!-- <span v-if="thread_length > 0" class="k-message-reply-response-count">{{ thread_length }}</span> -->
						</p>
					</div>
					<div v-if="!expanded" class="k-message-reply-body">
						<p>{{ truncated_message }}</p>
					</div>
					<div class="d-flex k-message-reply-date">
						<p>{{ message_date }}</p>
					</div>
				</div>
			</div>
		</v-hover>
		<v-expand-transition>
			<div v-if="expanded" class="k-message-reply-expanded">
				<v-divider />
				<div class="k-message-body">
					<div class="k-message-body-text" v-html="message.body"></div>
				</div>
			</div>
		</v-expand-transition>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	name: 'MessageReply',
	props: {
		message: { type: Object, required: true },
		is_first_level_reply: { type: Boolean, required: false, default: false },
		thread_length: { type: Number, required: false, default: 0 },
	},
	data() {
		return {
			show_message_reply_editor: false,
			message_reply: null,
			expanded: false,
		}
	},
	computed: {
		...mapState(['user_info', 'messages']),
		...mapGetters(['threaded_messages']),
		reply_thread() {
			let arr = this.messages.filter((message) => {
				if (message.parent_message_id === this.message.message_id) return true
				return false
			})
			arr.sort((a, b) => {
				return a.created_at - b.created_at
			})
			return arr
		},
		show_unread_message_icon() {
			return (this.message.is_read == false || this.has_unread_replies) && !this.expanded
		},
		has_unread_replies() {
			let unread = false
			this.reply_thread.forEach((message) => {
				if (message.is_read == false) unread = true
			})
			return unread
		},
		show_reply_thread() {
			return this.reply_thread.length > 0
		},
		truncated_message() {
			return U.html_to_text(this.message.body).substring(0, 200)
		},
		is_message_author() {
			return this.message.author_user_id == this.user_info.user_id
		},
		expansion_caret() {
			return `fas ${this.expanded ? 'fa-caret-down' : 'fa-caret-right'}`
		},
		message_date() {
			if (!this.message.send_at) return ''
			return date.format(new Date(this.message.send_at * 1000), 'MMM D, YYYY h:mm A')
		},
	},
	watch: {},
	created() { },
	mounted() { },
	methods: {
		toggle_message() {
			this.expanded = !this.expanded
			if (this.is_first_level_reply) {
				this.$emit('set_expanded', this.expanded)
			}
			if (this.expanded && !this.message.is_read) {
				this.$store.dispatch('mark_message_as_read', this.message)
			}
		},
	},
}
</script>

<style scoped lang="scss">
.k-message-reply-header {
	padding: 0 10px;
	background-color: #fefcf6;

	&--hover {
		cursor: pointer;
	}

	&--hover,
	&--expanded {
		background-color: #fdf7e4;
		border-radius: 0 6px 0 0;
	}

	.k-message-reply-sender {
		font-weight: bold;
		flex: 0 0 28%;

		.k-unread-message-icon {
			font-weight: normal;
			font-size: 15px;
			padding-bottom: 2px;
			// transform:scaleX(0.85);

			.k-unread-message-icon::after {
				content: '\00a0';
			}
		}
	}

	.k-message-reply-response-count {
		font-weight: normal;
	}

	.k-message-reply-response-count::before {
		content: '\00a0(';
	}

	.k-message-reply-response-count::after {
		content: ')';
	}

	.k-message-reply-body {
		flex: 0 1 auto;
		width: 40%;
		min-width: 0;

		p {
			white-space: nowrap;
			width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.k-message-reply-date {
		margin-left: auto;
	}
}

.k-message-reply-expanded {
	background-color: #fdf7e4;
	padding: 0 10px;
}
</style>
