<template><div class="mt-7">
	<div class="d-flex justify-center align-center mx-3">
		<MiniNav :current_section="collection?collection.collection_type:''" />
		<div class="k-collection-base-tiles">

			<div v-if="user_info.role=='parent'&&child_data_showing&&child_data_showing.first_name" class="k-course-list-child-switcher">
				<v-menu bottom left>
					<template v-slot:activator="{on}">
						<div v-on="on" class="mx-2 d-flex align-center text-center elevation-2" style="cursor:pointer; font-size:14px; background-color:rgba(255,255,255,0.5); padding:4px 12px 4px 4px; border-radius:6px;" :style="child_count==0?'padding-left:12px':''">
							<v-btn v-if="child_count>1" small icon color="#999"><v-icon small>fas fa-arrow-right-arrow-left</v-icon></v-btn>
							<div style="font-weight:900">{{child_data_showing.first_name}}<br>{{child_data_showing.last_name}}</div>
						</div>
					</template>
					<v-list dense>
						<v-list-item v-for="(child_data, child_email) in user_info.child_data" :key="child_email" @click="switch_child_data_showing(child_email)"><v-list-item-title>{{child_data.first_name}} {{child_data.last_name}} ({{child_email}})</v-list-item-title></v-list-item>
					</v-list>
				</v-menu>
			</div>

			<CollectionTile v-for="(list_collection) in collections_list" :key="list_collection.course_code"
				:tile_collection="list_collection" :active_collection="collection" />
		</div>
	</div>
	<Collection :course_code="course_code" :unit_id="unit_id" :collections_list="collections_list" />
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import MiniNav from '../main/MiniNav'
import Collection from '../collections/Collection'
import CollectionTile from './CollectionTile'

export default {
	components: { MiniNav, Collection, CollectionTile },
	props: {
		// note that these two props come from vuerouter
		course_code: { type: String, required: true },
		unit_id: { type: String, required: true },
	},
	data() { return {
		num_hidden_classes: 0,
		show_hidden_classes: false,
		classes_initialized: false,
		collection_body_style: '',
	}},
	computed: {
		...mapState(['user_info', 'all_courses', 'course_update_trigger', 'sis_classes', 'added_my_courses', 'removed_my_courses']),
		...mapGetters(['signed_in', 'role', 'my_default_collection', 'child_count', 'child_data_showing']),
		child_email_showing: {
			get() { return this.$store.state.lst.child_email_showing },
			set(val) { this.$store.commit('lst_set', ['child_email_showing', val]) }
		},
		last_collections_viewed: {
			get() { return this.$store.state.lst.last_collections_viewed },
			set(val) { this.$store.commit('lst_set', ['last_collections_viewed', val]) }
		},
		collection() { return this.all_courses.find(x=>x.course_code == this.course_code) },
		header_text() {
			if (this.user_info.role == 'parent') return 'My Child’s Courses'
			if (this.user_info.role == 'student') return 'My Courses'
			return 'My Courses'
		},
		collections_list() {
			if (!this.collection) return []

			// console.log('-------- LOADING collections_list --------')
			let arr = []

			// include the last 5 collections the user has viewed at the start of the queue (except parents, who always see their selected child's courses)
			if (this.user_info.role !== 'parent') {
				for (let course_code of this.last_collections_viewed) {
					if (!this.my_default_collection || course_code != this.my_default_collection.course_code) {		// but not the default collection
						let c = this.all_courses.find(x=>x.course_code == course_code)
						if (empty(c)) continue
						if (c.active == 'no' && !c.user_can_view_lp()) {
							// console.warn('user can’t view ' + c.title)
							continue
						}
						arr.push(c)
					}
				}
			}

			// now "sis_classes" -- courses that cureum has been told by an outside system the user is teaching
			for (let sis_class of this.sis_classes) {
				// filter out classes with code 'xxx'
				if (sis_class.course_code == 'xxx') continue

				// skip courses we've already included
				if (arr.find(x=>x.course_code == sis_class.course_code)) continue

				// also filter out classes in removed_my_courses
				if (this.removed_my_courses.find(x=>x == sis_class.course_code)) continue

				// If viewing as parent, only show courses for the selected child
				if (this.user_info.role === 'parent') {
					const child_in_course = this.child_data_showing.sis_student_classes?.find(x => x.course_code == sis_class.course_code)
					if (!child_in_course) continue
				}

				// for inactive collections, only show to people explicitly authorized as viewers (this includes admins)
				let c = this.all_courses.find(x=>x.course_code == sis_class.course_code)
				if (!empty(c) && c.active == 'no') {
					if (!c.user_can_view_lp()) continue
				}

				// store the all_courses entry if we have one (that makes the collection type icon show up properly); otherwise show the sis_class
				if (c) arr.push(c)
				else arr.push(sis_class)
			}

			// We will skip the remaining steps if the user is a parent, as they should only see their selected child's courses
			if (this.user_info.role === 'parent') return arr

			// add favorites -- collections whose course_codes are in added_my_courses (and which we haven't yet processed above)
			for (let course_code of this.added_my_courses) {
				// but don't include more than X total collections
				if (arr.length >= this.$store.state.n_collections_to_show_in_banner) continue

				// skip if we already processed the collection above -- the collection might, e.g., have been added to the teacher's sis_classes after they explicitly added it
				if (arr.find(x=>x.course_code==course_code)) continue

				let c = this.all_courses.find(x=>x.course_code == course_code)
				if (!empty(c)) arr.push(c)
			}

			// always include my default collection at the end
			if (this.my_default_collection) arr.push(this.my_default_collection)

			// console.log('collections_list', arr)
			return arr

			// NOTE: sis_classes will appear first, in alpha order, followed by added collections, followed by recently-visited collections, with the default collection at the end
		},
	},
	created() {
		// parent has to select a child before going here...
		if (this.role == 'parent' && empty(this.child_data_showing?.sis_student_classes)) {
			this.$store.commit('lst_set', ['welcome_section_showing', 'family_home'])
			vapp.go_to_home('home')
		}

		// console.log('CollectionBase created: ' + this.course_code)
		if (window.location.pathname.match(/\/courses\/([^\/]+)(\/(.*))?/)) {
			this.show_all_lps()
		} else {
			// if (!this.course_showing) {
			// 	// if no course is showing, make sure we don't have a subject/subcat displaying
			// 	window.history.replaceState(null, '', '/courses')
			// }

			if (this.$store.state.loaded_classes) {
				this.classes_initialized = true
			} else if (!this.classes_initialized) {
				this.get_classes()
			}
		}
	},
	watch: {
	},
	mounted() {
		this.resize_course_tiles()
	},
	methods: {
		resize_course_tiles() {
			// // for some reason we need to explicitly specify the width of this div to make overflow scrolling work, and we have to use this hackish mechanism to get the width right
			// // TODO: revisit this...
			// clearTimeout(this.resize_course_tiles_timeout)

			// // the 20 below is padding on k-collection-base-tiles
			// this.collection_body_style = 'width:' + (120 + this.collections_list.length * $(this.$el).find('.k-collection-base-tile').first().outerWidth(true)) + 'px;'
			// console.log('this.collection_body_style', this.collection_body_style)

			// this.resize_course_tiles_timeout = setTimeout(x=>this.resize_course_tiles(), 200)
		},

		get_classes() {
			this.$store.dispatch('get_classes').then(()=>{
				this.$nextTick(()=>{
					this.classes_initialized = true
				})
			})
		},

		collection_tile_title(tile_collection) {
			let s = ''
			// start with icon indicating collection type
			if (tile_collection.collection_type == 'course') s += '<i class="fas fa-chalkboard"></i>'
			else if (tile_collection.collection_type == 'repo') s += '<i class="fas fa-diagram-project"></i>'
			else if (tile_collection.collection_type == 'pd') s += '<i class="fas fa-user-graduate"></i>'
			else s += '<i class="fas fa-cubes-stacked"></i>'
			// add <wbr> tags after slashes
			s += tile_collection.title.replace(/\//g, '/<wbr>')
			// if (tile_collection.active == 'no') {
			// 	s += '<br><div class="red mt-1 mx-auto" style="padding:2px; display:inline-block; font-weight:normal">Inactive</div>'
			// }

			return s
		},

		show_all_lps() {
			if (!this.signed_in) this.$store.commit('lst_set', ['unsigned_index_view_flavor', 'lpindex'])
			else if (this.collection.collection_type == 'course') this.$store.commit('lst_set', ['course_index_view_flavor', 'lpindex'])
			else if (this.collection.collection_type == 'my') this.$store.commit('lst_set', ['my_index_view_flavor', 'lpindex'])
			else this.$store.commit('lst_set', ['repo_index_view_flavor', 'lpindex'])
		},

		switch_child_data_showing(child_email) {
			this.child_email_showing = child_email
		},
	}
}
</script>

<style lang="scss">
.k-collection-base-tiles {
	// flex:0 1 auto;
	// flex:1 0 calc(100vw - 100px);
	overflow:auto;
	background-color:$page-background;
    border-radius: 0 16px 16px 0;
	display:flex;
	height:160px;
	align-items:center;
	flex-wrap:nowrap;
	padding:0 6px 0 0px;
}

</style>
