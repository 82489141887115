<template>
	<v-btn  :x-small="size=='small'" :small="size!='small'&&size!='large'" :color="color" @click.stop="copy" icon>
		<v-icon :x-small="size=='small'" :small="size!='small'&&size!='large'">fas fa-copy</v-icon>
		<textarea style="display:none" class="k-copy-hidden-input"></textarea>
	</v-btn>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	// components: { TemplateComponent },
	props: {
		val: { type: String, required: true },
		size: { type: String, required: false, default() { return ''} },
		color: { type: String, required: false, default() { return 'secondary'} },
	},
	data() { return {
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		copy() {
			let jq = $(this.$el).find('.k-copy-hidden-input')
			jq.val(this.val)
			jq.show()

			// select the input value and copy to clipboard
			jq[0].select()
			document.execCommand("copy")

			// re-hide the input
			jq.hide()

			this.$inform({
				text: 'Copied to clipboard',
				// color: 'orange darken-2',
				// snackbarTimeout:5000
			})
		},
	}
}
</script>

<style lang="scss">
</style>
