<!-- Copyright 2023, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="1000" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999">
				<h3>Weekly Lesson Plans</h3>
				<v-spacer />
				<v-text-field
					v-model="search"
					prepend-inner-icon="fa fa-search" clearable rounded clear-icon="fa fa-times-circle"
					label="Search" single-line hide-details outlined dense background-color="#fff" style="flex:0 1 340px"
				></v-text-field>
				<v-spacer/>
				<v-btn color="primary" class="mr-3 k-tight-btn" @click="create_lesson_report"><v-icon small class="mr-2">fas fa-plus</v-icon>New Weekly Lesson Plan</v-btn>
				<v-btn color="secondary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-check</v-icon>Done</v-btn>
			</v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">
				<div class="mb-3 text-center" style="font-size:18px" v-html="user_for_reports_display"></div>
				<v-data-table light dense
					:headers="headers"
					:sort-by="['created_at']"
					:sort-desc="[true]"
					:items="rows"
					:search="search"
					:footer-props="footer_options"
					:items-per-page="100"
					class="k-weekly-lesson-plans-table"
				>
					<template v-slot:item="{ item }"><tr>
						<td><div class="d-flex">
							<!-- <v-btn small color="primary" text @click="show_lesson_report(item.lesson_report_id)"><v-icon small class="mr-1">fas fa-eye</v-icon> View</v-btn> -->
							<a @click="show_lesson_report(item.lesson_report_id)" v-html="item.description"></a>
						</div></td>
						<td class="text-center"><nobr style="font-size:12px">{{item.role}}</nobr></td>
						<td class="text-center"><nobr style="font-size:12px">{{item.created_at_display}}</nobr></td>
						<td class="text-center"><v-btn small color="primary" text @click="show_lesson_report(item.lesson_report_id)"><v-icon small class="mr-1">fas fa-eye</v-icon> View</v-btn></td>
						<td v-if="any_viewed" class="text-center" style="width:90px"><v-icon v-visible="item.viewed" color="green darken-2">fas fa-check</v-icon></td>
					</tr></template>
				</v-data-table>

			</v-card-text>
		</v-card>

		<LessonReport v-if="lesson_report_id!==null" :lesson_report_id="lesson_report_id"  @dialog_cancel="lesson_report_id=null" />
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import LessonReport from '../lessons/LessonReport'

export default {
	components: { LessonReport, },
	props: {
		user_id_for_reports: { type: Number, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		footer_options: {
			itemsPerPageOptions: [10,50,100,-1],
		},
		search: '',
		lesson_report_id: null,
		any_viewed: false,
	}},
	computed: {
		...mapState(['user_info', 'lesson_reports', 'user_records']),
		...mapGetters([]),
		lesson_reports_url() {
			return window.location.origin
			// not implementing this for now, but could later...
			// return `${window.location.origin}/lesson_reports/${this.user_id_for_reports}`
		},
		user_record_for_reports() {
			// if we don't already have this record, we'll get it below
			if (this.user_id_for_reports == this.user_info.user_id) return this.user_info
			return this.user_records.find(x=>x.user_id == this.user_id_for_reports)
		},
		user_for_reports_display() {
			if (!this.user_record_for_reports) return 'Loading user...'
			return `<b>${this.user_record_for_reports.first_name} ${this.user_record_for_reports.last_name}</b>  <a href="mailto:${this.user_record_for_reports.email}?subject=Weekly%20Lesson%20Plans&body=${this.lesson_reports_url}">${this.user_record_for_reports.email}</a>`
		},
		headers() {
			let arr = []
			arr.push({ text: 'Weekly Lesson Plan', align: 'left', sortable: true, value: 'description' })
			arr.push({ text: 'Role', align: 'center', sortable: true, value: 'role' })
			arr.push({ text: 'Date Created', align: 'center', sortable: true, value: 'created_at' })
			arr.push({ text: '', align: 'center', sortable: false, value: 'viewed' })
			if (this.any_viewed) arr.push({ text: 'Viewed', align: 'center', sortable: true, value: 'viewed' })
			return arr
		},
		rows() {
			let arr = []
			for (let lr of this.lesson_reports) {
				if (lr.collaborator_user_ids.includes(this.user_id_for_reports) || lr.reviewer_user_ids.includes(this.user_id_for_reports) || lr.owner_user_id == this.user_id_for_reports) {

					let o = {}
					o.lesson_report_id = lr.lesson_report_id
					o.description = lr.description.replace(/(.*) \[(.*)\]/, '$1<b style="font-size:0.8em; color:#444; margin-left:8px; white-space:nowrap;">$2</b>')
					o.viewed = lr.viewed
					// note if any of them have been viewed
					if (o.viewed) this.any_viewed = true

					o.created_at = lr.created_at
					if (!lr.created_at) o.created_at_display = ''
					else o.created_at_display = date.format(new Date(lr.created_at*1000), 'M-D-YY h:mm A')

					// note that the role we show is the signed-in user's role
					if (lr.owner_user_id == this.user_info.user_id) o.role = 'Owner'
					else if (lr.collaborator_user_ids.includes(this.user_info.user_id)) o.role = 'Collaborator'
					else if (true || lr.reviewer_user_ids.includes(this.user_info.user_id)) o.role = 'Reviewer'	// if we have the record and you're not the owner or collaborator, you must be a reviewer

					o.lesson_report = lr
					arr.push(o)
				}
			}
			return arr
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
		// get reduced version (description only) of lesson reports for the given user
		U.ajax('get_lesson_reports', {user_id: this.user_info.user_id, user_id_for_reports: this.user_id_for_reports}, result=>{
			if (result.status != 'ok') {
				this.$alert('An error occurred when attempting to retrieve Weekly Lesson Plans.')
				return
			}
			// note that we might get multiple entries for the same user, if the user has multiple roles
			for (let lr_data of result.lesson_reports) {
				let lri = this.lesson_reports.findIndex(x=>x.lesson_report_id==lr_data.lesson_report_id)
				let lr
				if (lri == -1) {
					lr = new Lesson_Report(lr_data)
					lr.fully_loaded = false
					this.$store.commit('set', [this.lesson_reports, 'PUSH', lr])
				} else {
					lr = this.lesson_reports[lri]
				}

				// if not fully_loaded, deal with role -- this.user_id_for_reports goes in owner, collaborators, and/or reviewers
				if (!lr.fully_loaded) {
					if (lr_data.role == 'owner') this.$store.commit('set', [lr, 'owner_user_id', this.user_id_for_reports])
					if (lr_data.role == 'collaborator' && !lr.collaborator_user_ids.includes(this.user_id_for_reports)) this.$store.commit('set', [lr.collaborator_user_ids, 'PUSH', this.user_id_for_reports])
					if (lr_data.role == 'reviewer' && !lr.reviewer_user_ids.includes(this.user_id_for_reports)) this.$store.commit('set', [lr.reviewer_user_ids, 'PUSH', this.user_id_for_reports])
				}
			}
		})

		// if we don't have user_id_for_reports's record, get it
		if (!this.user_record_for_reports) {
			this.$store.dispatch('get_user_records', {user_ids: [this.user_id_for_reports]})
		}
	},
	methods: {
		show_lesson_report(lesson_report_id) {
			this.lesson_report_id = lesson_report_id
		},

		create_lesson_report() {
			this.lesson_report_id = ''
		},
	}
}
</script>

<style lang="scss">
.k-weekly-lesson-plans-table {
	td {
		// font-size:14px!important;
		line-height: 18px;
	}
	a:hover { text-decoration:underline; }
}
</style>