// https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Objects/Inheritance

class User_Info {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'user_id', 0)
		sdp(this, data, 'first_name', '')
		sdp(this, data, 'last_name', '')
		sdp(this, data, 'email', '')
		sdp(this, data, 'system_role', 'student', ['student', 'staff', 'parent', 'admin', 'unknown'])
		sdp(this, data, 'role', this.system_role)
		sdp(this, data, 'district_role', [])
		sdp(this, data, 'district_department', [])
		sdp(this, data, 'oidc_data', {})
		sdp(this, data, 'sis_identities', [])
		sdp(this, data, 'sis_user_sourcedId', '')
		sdp(this, data, 'sis_class_sourcedIds', [])
		sdp(this, data, 'child_data', {})
		sdp(this, data, 'admin_rights', [])
		sdp(this, data, 'academic_year', '')
		sdp(this, data, 'todo_user_group', '')

		// for resources marked as todos; format is resource_id: timestamp of completion
		this.todo_status = {}
		if (!empty(data.todo_status)) {
			for (let resource_id in data.todo_status) {
				// note that the todo_status for a video may be 5-95, indicating partial completion
				if (data.todo_status[resource_id] * 1 > 0) {
					this.todo_status[resource_id] = data.todo_status[resource_id]*1
				}
			}
		}

		// fiddle with oidc_data for testing purposes
		if (this.email.includes('commongoodlt.com')) {
			this.oidc_data = {
				'school_id': '3050',
				'school_name': 'North Jackson Elementary School',
				'system_id': '678',
				'system_name': 'Jackson County',
				'user_role': 'Teacher',
				'course_codes': '23.1050000,23.0050000,00.0000009,00.0000013,27.1150000,27.0150000,23.1016000,23.0016000,41.0150000,45.0050000',
			}
		}
	}
}
window.User_Info = User_Info

/* Admin rights values
su: “Superuser” admin rights
Editing Courses
	lp.level.all: All courses
	lp.level.Elementary: All Elementary
	lp.level.Middle School: All Middle
	lp.level.High School: All High School
	lp.subject.Science: All Science
	lp.subject.Mathematics: All Math
	lp.subject.English: All English
	lp.subject.Social Studies: All Social Studies
	lp.course.1345: Specific course
Viewing Courses: same as above, but prefix is 'view_lp'
*/
