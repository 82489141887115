<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-lesson-edit">

	<div style="font-size:16px; padding-bottom:56px;">
		<div>
			<v-text-field background-color="#fff" dense hide-details outlined label="Lesson Master Title" v-model="edited_lesson.lesson_title"></v-text-field>
		</div>

		<div class="mt-3">
			<b class="k-lesson-title-intro">Lesson Components:</b>

			<!-- COMPONENTS -->
			<div v-for="(component, ci) in edited_lesson.lesson_plan" :key="component.uuid" class="k-lesson-editor-component-master">
				<div class="d-flex">
					<v-text-field background-color="#fff" class="mr-2" hide-details outlined label="Component Title" style="flex-basis:80%" v-model="component.lc_title"></v-text-field>
					<v-text-field background-color="#fff" hide-details outlined label="Weight (%)" style="flex-basis:20%" v-model="component.lc_weight"></v-text-field>
				</div>
				<div class="mt-2">
					<div class="mb-1"><b>Component Guidance:</b></div>
					<froala-wrapper v-model="component.lc_guidance" />
				</div>

				<div class="mt-2 mb-2">
					<div class="mb-1"><b>Component Template Content</b></div>
					<froala-wrapper v-model="component.lc_default_content" />
				</div>

				<div class="mt-2 text-right"><v-btn small color="red" dark class="k-tight-btn" @click="delete_component(component.uuid)"><v-icon small class="mr-2">fas fa-trash-alt</v-icon> Delete Component</v-btn></div>
			</div>

			<div class="mt-2"><v-btn small color="primary" @click="add_component"><v-icon small class="mr-2">fas fa-plus</v-icon> New Lesson Component</v-btn></div>
		</div>
	</div>
	<div class="px-3 pt-2 k-lesson-editor-buttons">
		<v-btn color="secondary" class="k-tight-btn" @click="edit_lesson_cancel"><v-icon small class="mr-2">fas fa-times</v-icon>{{is_new_lesson?'Cancel':'Close'}}&nbsp;</v-btn>
		<v-spacer/>
		<v-btn color="primary" class="ml-3 k-tight-btn" @click="save_lesson"><v-icon small class="mr-2">fas fa-save</v-icon>Save&nbsp;</v-btn>
		<v-btn color="primary" class="ml-3 k-tight-btn" @click="save_and_close"><v-icon small class="mr-2">fas fa-check</v-icon>Save and Close</v-btn>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import ResourceCollectionItem from '../resources/ResourceCollectionItem'
import ResourceEditor from '../resources/ResourceEditor'

export default {
	components: { ResourceCollectionItem, ResourceEditor },
	props: {
		original_lesson: { required: true },	// if 'new' we're creating a new lesson
	},
	data() { return {
		edited_lesson: {},
		selected_master_id: -1,
		lesson_master: null,
	}},
	computed: {
		...mapState(['user_info', 'lesson_masters', 'default_lesson_master']),
		...mapGetters([]),
		is_new_lesson() { return this.original_lesson == 'new' },
	},
	watch: {
	},
	created() {
	},
	mounted() {
		// for an existing lesson, create edited_lesson now
		if (!this.is_new_lesson) {
			this.edited_lesson = new Lesson(this.original_lesson)
		} else {
			this.edited_lesson = new Lesson()
		}
	},
	methods: {
		add_component() {
			this.edited_lesson.lesson_plan.push(new Lesson_Component())
		},

		delete_component(uuid) {
			this.edited_lesson.lesson_plan.splice(this.edited_lesson.lesson_plan.findIndex(x=>x.uuid==uuid), 1)
		},

		///////////////////////////////////
		save_and_close() {
			this.save_lesson('and_close')
		},

		save_lesson(flag) {
			this.edited_lesson.lesson_title = $.trim(this.edited_lesson.lesson_title)
			if (empty(this.edited_lesson.lesson_title)) {
				this.$alert('You must enter a title.')
				return
			}

			// for masters do some additional checks
			if (this.edited_lesson.lesson_plan.length == 0) {
				this.$alert('The lesson must include at least one Lesson Component.')
				return
			}

			// make sure each component has a title, and that weights are integers (if not empty)
			let sum = 0
			for (let c of this.edited_lesson.lesson_plan) {
				c.lc_title = $.trim(c.lc_title)
				if (empty(c.lc_title)) {
					this.$alert('Each component must have a title.')
					return
				}

				if (c.lc_weight && isNaN(c.lc_weight*1)) {
					this.$alert('Component weights must be integers.')
					return
				}

				// trim froala text
				c.lc_guidance = window.trim_froala_text(c.lc_guidance)
				c.lc_default_content = window.trim_froala_text(c.lc_default_content)
			}

			U.loading_start()
			U.ajax('save_lesson', {
				user_id: this.user_info.user_id,
				lesson_class: 'master',
				lesson_data: JSON.stringify(this.edited_lesson.copy_for_save('master'))
			}, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error in ajax call'); vapp.ping(); return;
				}

				// updated data will be returned; emit it to the parent component
				this.$emit('edit_lesson_saved', result.updated_lesson)

				// then if flag is 'and_close', emit edit_lesson_cancel
				if (flag == 'and_close') this.edit_lesson_cancel()
			});
		},

		edit_lesson_cancel() {
			this.$emit('edit_lesson_cancel')
		},
	}
}
</script>

<style lang="scss">
// styles are in LessonEditor
</style>
