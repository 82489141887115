<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999">
				<div>
					<b>Resource Completion Report:</b>
					&nbsp;
					<span>{{user.last_name + ', ' + user.first_name}}</span>
					<div class="ml-4" style="font-size:14px">
						<b>Division / School:</b>
						<span v-html="todo_user_group_display"></span>
						<v-btn v-if="allow_to_change_department" x-small color="secondary" class="ml-2" @click="update_todo_user_group">Change</v-btn>
						<span v-if="!allow_to_change_department" class="ml-2 grey--text text--darken-1">(Division/school is automatically refreshed from Active Directory.)</span>
					</div>
				</div>
				<v-spacer/>
				<v-btn small color="secondary" @click="download_report"><v-icon small class="mr-1">fas fa-download</v-icon> Download Report</v-btn>
			</v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">
				<v-data-table light dense
					:headers="headers"
					:items="rows"
					:sort-by="['user']"
					:sort-desc="[false]"
					:must-sort="true"
					:search="search"
					hide-default-footer
					:items-per-page="-1"
					class="k-todo-report-table"
				>
					<template v-slot:item="{ item }"><tr>
						<td :colspan="item.type=='unit'?2:1">
							<b v-if="item.type=='unit'" v-html="item.title"></b>
							<div class="ml-5" v-if="item.type=='resource'" v-html="item.title"></div>
						</td>
						<td class="text-center" v-if="item.type=='resource'">
							<i v-if="!item.complete">not complete</i>
							<span v-if="item.complete"><v-icon small color="green" class="mr-2">fas fa-check</v-icon> {{item.complete}}</span>
						</td>
					</tr></template>
				</v-data-table>

			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-spacer/>
				<v-btn color="primary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-arrow-left</v-icon> Back</v-btn></v-btn>
			</v-card-actions>
		</v-card>
		<TodoUserGroupChooser v-if="show_tugc_dialog" :user_info="user" @tug_updated="tug_updated" @dialog_cancel="show_tugc_dialog=false" />
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import TodoUserGroupChooser from '../resources/TodoUserGroupChooser'

export default {
	components: { TodoUserGroupChooser },
	props: {
		collection: { type: Object, required: true },
		user_id: { type: Number, required: true },
	},
	data() { return {
		dialog_open: true,
		search: '',
		resource_ids: [],
		headers:[
			{ text: 'Resource', align: 'left', value:'title' },
			{ text: 'Completion Date', align: 'center', value:'complete' },
		],
		show_tugc_dialog:false,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		// by definition a group's data must be showing in order to get to a user report
		todo_report_group_showing() { return this.$store.state.todo_report_group_showing },
		todo_report_department_showing() {
			if (this.todo_report_group_showing == 'all') return 'all'
			let o = this.groups.find(x=>x.value == this.todo_report_group_showing)
			if (!o) return '???'	// shouldn't happen
			return o.text
		},
		todo_report_group_data() {
			// if the user hasn't yet chosen a group, or if that group's data hasn't been loaded, return an empty array; otherwise return the group's data array
			if (empty(this.todo_report_group_showing)) return []
			let arr = this.$store.state.todo_report_group_data[this.todo_report_group_showing]
			if (empty(arr)) return []
			else return arr
		},
		user() {
			let o = this.todo_report_group_data.find(x=>x.user_id == this.user_id)
			if (empty(o)) {
				this.$alert('This user’s data could not be found.')
				return {}
			}
			return o
		},
		allow_to_change_department() {
			// only allow the department to be edited if the user doesn't have AD district_department data
			return (!this.user.district_department || this.user.district_department.length == 0)
		},
		todo_user_group_display() {
			// get todo_user_group_display: first try using district_department values; use todo_user_group value as a backup
			let todo_user_group_display = '<b style="color:#999">Not chosen</b>'
			if (this.user.district_department && this.user.district_department.length > 0) {
				todo_user_group_display = this.user.district_department.join(', ')
			} else if (this.user.todo_user_group) {
				if (this.$store.state.todo_user_group_divisions[this.user.todo_user_group]) {
					todo_user_group_display = this.$store.state.todo_user_group_divisions[this.user.todo_user_group] + '*'
				} else if (this.$store.state.todo_user_group_schools[this.user.todo_user_group]) {
					todo_user_group_display = this.$store.state.todo_user_group_schools[this.user.todo_user_group] + '*'
				} else {
					todo_user_group_display = '???'
				}
			}
			return todo_user_group_display
		},
		rows() {
			let carr = []

			// first get a list of all todo resources in the collection

			// for each unit
			for (let unit of this.collection.units) {
				const add_items_from_folder = (folder_id) => {
					let arr = []
					let top_folder_item_seq = 10000
					// for each resource...
					for (let resource of unit.resources) {
						// skip resources that aren't marked todo
						if (!resource.todo) continue

						// look for a folder_assignment for the resource
						let folder_assignment = unit.resource_tree.folder_assignments.find(x=>x.resource_id==resource.resource_id)

						// if not found, assume it's a top-level item, use top_folder_item_seq for the array sequence value (putting it at the end of the top folder)
						if (empty(folder_assignment)) {
							folder_assignment = {
								parent_folder_id: 'top',
								seq: top_folder_item_seq
							}
							++top_folder_item_seq
						}

						// if this resource goes in this folder, add it in seq order
						if (oprop(folder_assignment, 'parent_folder_id') == folder_id) {
							let title = resource.description.substr(0, 12)
							if (title != resource.description) title += '…'
							arr[folder_assignment.seq] = resource
						}
					}

					// now deal with sub-folders, recursing to add an array of items for each sub-folder
					for (let folder of unit.resource_tree.folders) {
						if (folder.parent_folder_id == folder_id) {
							arr[folder.seq] = add_items_from_folder(folder.folder_id)
						}
					}

					return arr
				}

				// get the initial structure, which will be a tree
				let tree = add_items_from_folder('top')

				// add a row for the unit
				carr.push({type: 'unit', title: unit.title})

				// now flatten the tree into an array with the items in the same order as shown in the unit folder structure, find the user's data, and put everything in carr
				const flatten_tree = (tarr) => {
					for (let resource of tarr) {
						if (empty(resource)) continue	// skip empty index values

						if ($.isArray(resource)) {
							flatten_tree(resource)
						} else {
							let o = {type: 'resource', title: resource.description, complete: ''}
							// note that the todo_status for a video may be 5-95, indicating partial completion
							if (this.user.todo_status[resource.resource_id] > 100) {
								o.complete = date.format(new Date(this.user.todo_status[resource.resource_id]*1000), 'M/D/Y')
							}
							carr.push(o)
						}
					}
				}
				flatten_tree(tree)
			}

			return carr
		},
	},
	watch: {
		todo_report_group_showing: { immediate: true, handler() {
			if (!empty(this.todo_report_group_showing) && this.todo_report_group_data.length == 0) {
				this.get_todo_report_data()
			}
		}},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		get_todo_report_data() {
			let payload = {
				todo_user_group: this.todo_report_group_showing
			}
			if (this.todo_report_department_showing != 'all') {
				payload.department = this.todo_report_department_showing
			}

			this.$store.dispatch('get_todo_report_data', payload)
		},

		download_report() {
			let filename = sr('Resource Completion Report - $1, $2.csv', this.user.last_name, this.user.first_name)

			let table = [['Resource', 'Completion Date']]
			for (let row of this.rows) {
				if (row.type == 'unit') {
					table.push([row.title, ''])
				} else {
					table.push(['  ' + row.title, (row.complete ? row.complete : 'not complete')])
				}
			}

			U.download_file(CSV.stringify(table), filename)
		},

		update_todo_user_group() {
			this.show_tugc_dialog = true
		},

		tug_updated(user_id, new_todo_user_group) {
			this.$store.commit('set', [this.user, 'todo_user_group', new_todo_user_group])
		},
	}
}
</script>

<style lang="scss">
</style>
