<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
	<v-dialog v-model="dialog_open" max-width="800px" scrollable>
	<v-card>
		<v-card-title style="border-bottom:1px solid #999">
			<b>Subject Colors</b>
			<v-spacer></v-spacer>
			<v-btn color="secondary" @click="$emit('dialog_cancel')" class="ml-4 mr-1"><v-icon small class="mr-2">fas fa-times</v-icon>Cancel</v-btn>
			<v-btn color="primary" @click="save" class="ml-2 mr-1"><v-icon small class="mr-2">fas fa-save</v-icon>Save</v-btn>
		</v-card-title>
		<v-card-text class="mt-3" style="font-size:16px; color:#000;">
			<div v-for="([subject, hex], index) in Object.entries(new_subject_colors)"
					:key="index"
					class="d-flex align-center justify-space-between position-relative"
					style="border:1px solid #000;
					border-radius:8px; padding:8px; margin:8px 0"
					:style="{'background-color': hex, 'color': U.get_contrast_color(hex)}"
				>
					<div>{{ subject }}</div>
					<ColorPicker :subject="subject" :color="hex" @update_color="update_color" />
			</div>
		</v-card-text>
	</v-card>
	</v-dialog>

</template>

<script>
import { mapState } from 'vuex'
import ColorPicker from '../utilities/ColorPicker.vue'

export default {
	components: { ColorPicker },
	props: {
	},
	data() { return {
		dialog_open: true,
		selected_subject: "",
		new_subject_colors: {}
	}},
	computed: {
		...mapState(['site_config', 'subjects', 'user_info']),
		max_width() {
			if (this.$vuetify.breakpoint.xs) return '100vw'
			return 500
		},
	},
	created() {
	},
	mounted() {
		this.new_subject_colors = Object.entries(this.subjects).reduce((acc, [key, value]) => {
			if (value.color) {
				acc[key] = value.color;
			}
			return acc;
		}, {})
	},
	methods: {
		save() {
			if (JSON.stringify(this.new_subject_colors) !== JSON.stringify(this.subjects)) {
				U.ajax('save_subject_color', {user_id: this.user_info.user_id, subjects: this.new_subject_colors}, result => {
					for (const [subject, color] of Object.entries(this.new_subject_colors)) {
						if (subject in this.subjects) this.$store.commit('set', [this.subjects, [subject, 'color'], color])
					}
					this.$emit('dialog_cancel')
				})
			} else this.$emit('dialog_cancel')
		},
		update_color(new_color, subject) {
			if (subject) this.new_subject_colors[subject] = new_color
		},
	}
}
</script>

<style lang="scss">
</style>
