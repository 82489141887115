<template>
	<v-dialog v-model="dialog_open" max-width="700" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b>{{user_info.first_name}} {{user_info.last_name}}:</b>&nbsp;Choose Your School or Division</v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">
				<div class="mb-3">Please choose the school or division where you will be primarily working during the upcoming school year. We need this information so that your supervisor can verify that you’ve completed the required training materials.</div>
				<div class="mb-3">If you’re not sure what school or division you’ll be working in, you can leave the selection as “Unknown” for now, and you’ll be asked again to enter this information the next time you sign in to HenryConnects.</div>
				<v-select outlined dense background-color="#fff" hide-details class="" label="" placeholder="" :items="groups" v-model="group"></v-select>
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="$emit('dialog_cancel')">Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		// req: { type: String, required: true },
		user_info: { type: Object, required: false, default() { return this.$store.state.user_info} },
	},
	data() { return {
		dialog_open: true,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		group: {
			get() { return this.user_info.todo_user_group },
			set(val) {
				this.$store.dispatch('save_todo_user_group', {todo_user_id: this.user_info.user_id, todo_user_group: val})
				this.$emit('tug_updated', this.user_info.user_id, val)
			}
		},
		groups() {
			let arr = [
				{value: '', text: 'Unknown at this time'},
				{header: '--- DIVISIONS ---'},
			]
			for (let uuid in this.$store.state.todo_user_group_divisions) {
				arr.push({value: uuid, text: this.$store.state.todo_user_group_divisions[uuid]})
			}
			arr.push({header: '--- SCHOOLS ---'})
			for (let uuid in this.$store.state.todo_user_group_schools) {
				arr.push({value: uuid, text: this.$store.state.todo_user_group_schools[uuid]})
			}
			return arr
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
</style>
