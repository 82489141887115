<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<vue-draggable-resizable :drag-handle="'.k-case-tree-title'" :resizable="!small_screen" :draggable="!small_screen" class-name="k-case-tree-outer-wrapper k-rc-tree-outer-wrapper" :h="560" :w="560" :minWidth="350" :minHeight="250" :active="true" :preventDeactivation="true" :handles="['br']" class-name-handle="k-resizable-handle">
		<div class="k-case-tree-top k-rc-tree-top">
			<div class="k-case-tree-title">
				<v-icon v-if="!small_screen" color="#fff" class="mr-2" style="margin-left:-5px">fas fa-arrows-alt</v-icon>
				<div v-html="resource_collection.description"></div>
				<v-spacer/>
				<v-btn icon color="#fff" @click="$emit('dialog_cancel')"><v-icon>fas fa-times-circle</v-icon></v-btn>
			</div>
			<v-text-field light background-color="#fff" class="ma-1" solo hide-details clearable dense
				placeholder="Search"
				v-model="search_terms"
				prepend-inner-icon="fas fa-search" @click:prepend-inner="execute_search_start"
				@click:clear="execute_search_clear"
				@keyup="search_field_keyup"
				autocomplete="new-password"
			></v-text-field>
		</div>
		<div class="k-case-tree-main">
			<div class="k-case-tree-inner-wrapper">
				<div :style="open_items.length>0?'visibility:visible':'visibility:hidden'" class="text-right" style="margin-top:-2px"><v-btn @click="collapse_all" x-small text color="indigo"><b>Collapse all</b></v-btn></div>
				<v-treeview open-on-click light
					:open="open_items"
					:items="rc_tree"
					item-key="resource_id"
					item-children="children"
					@update:open="open_updated"
				>
				<template v-slot:label="{ item }">
						<ResourceCollectionItem v-if="item.resource&&node_search_match(item.resource_id)!='no'" class="mb-1"
							small
							:item="item.resource"
							:from_pd_resource_popup_collection="false"
						/>
						<div v-if="!item.resource" class="k-case-tree_case_item" :class="search_css(item.resource_id)">
							<span style="cursor:pointer" v-html="highlight_search_terms(item.description)"></span>
						</div>
					</template>
				</v-treeview>
			</div>
		</div>
	</vue-draggable-resizable>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import '../../js/search_fns.js'
import ResourceCollectionItem from '../resources/ResourceCollectionItem.vue'

export default {
	components: { ResourceCollectionItem },
	props: {
		resource_collection: { type: Object, required: true },
	},
	data() { return {
		active_items: [],
		open_items: [],
		search_terms: '',
		search_term_res: [],
		stop_words: [],
		search_results: [],
		total_resource_count: 0,
	}},
	computed: {
		...mapState(['user_info']),
		...mapGetters(['role', 'small_screen']),
		rc_tree() {
			function traverse(node, role) {
				// each node has a title 't' (except the top node); each node should have either a resource_id 'r' or a child array 'c'
				let nn = {
					description: node.t,	// use "description" to keep things consistent with LPUnitResourceCollectionTree
					resource_count: 0,
					resource_id: U.new_uuid(),	// use "resource_id" to keep things consistent with LPUnitResourceCollectionTree
				}

				if (!empty(node.r)) {
					// NOTE: the code below is also mostly reproduced in LPUnitResourceCollectionTree.vue
					// teacher_facing is normally based on the "node.i" value...
					let teacher_facing = (node.i == 1)

					// ...but make sure that anything that has "assessment" or "teacher ebook" or "teacher edition" in the title is teacher facing
					if (node.t.search(/(assessment)|(teacher ebook)|(teacher edition)|(answer key)/i) > -1) teacher_facing = true

					// only show teacher resources to staff and admins
					let include_resource = (role == 'staff' || role == 'admin') || !teacher_facing

					if (include_resource) {
						nn.resource = new Resource({
							resource_id: node.r,
							type: 'collection_item',
							teacher_facing: teacher_facing,
							district_sanctioned: true,
							description: node.t,
						})
					}
				}

				if (!empty(node.c)) {
					nn.children = []
					for (let child of node.c) {
						child = traverse(child, role)

						// don't push empty nodes for parents and students (for staff or admins, they might be creating the nodes)
						if (role == 'staff' || role == 'admin' || child.resource_count > 0 || child.resource) {
							// deal with resource counts
							if (!empty(child.resource)) nn.resource_count += 1
							nn.resource_count += child.resource_count	// this will likely be 0 if the child is a resource, or a number if it's a "folder"

							nn.children.push(child)
						}
					}
					nn.searchable_description = nn.description
					nn.description += sr(' ($1)', nn.resource_count)
				}

				return nn
			}

			let tree = []
			this.total_resource_count = 0
			for (let child of this.resource_collection.collection_json.c) {
				child = traverse(child, this.role)
				// don't push empty nodes for parents and students (for staff or admins, they might be creating the nodes)
				if (this.role == 'staff' || this.role == 'admin' || child.resource_count > 0 || child.resource) {
					tree.push(child)
					this.total_resource_count += child.resource_count
				}
			}
			return tree
			// note that rc_tree.resource_count will have the total # of items in the collection

			// return this.resource_collection.collection_json.c
		},
	},
	created() {
	},
	mounted() {
	},
	watch: {
		// use this hack to make the vue-draggable-resizable component shift more-or-less-properly to full screen when the window is narrow
		'$vuetify.breakpoint.width': { immediate: true, handler(val) {
			this.accommodate_narrow_window()
		}},
	},
	methods: {
		accommodate_narrow_window() {
			setTimeout(x=>{
				if (this.small_screen) {
					$('.k-case-tree-outer-wrapper').css({width:'100vw', height:'100vh', transform:'none'})
				} else {
					$('.k-case-tree-outer-wrapper').css({width:'560px', height:'560px'})
				}
			}, 10)
		},
		// send issue report requests to vapp
		report_issue(issue_params) {
			// issue_params comes in with resource specified; nothing to add here for now
			vapp.report_issue(issue_params)
		},

		open_updated(arr) {
			this.open_items = arr
		},

		execute_search(node) {
			if (empty(node)) return false

			// by default return false (item doesn't meet criteria)
			let rv = false

			// if the node has children, search the children
			if (!empty(node.children) && node.children.length > 0) {
				for (let child of node.children) {
					if (this.execute_search(child)) {
						if (!this.open_items.find(x=>x==node.resource_id)) this.open_items.push(node.resource_id)
						rv = true
					}
				}
			}

			// if the node has resources, search the resources
			if (!empty(node.resources) && node.resources.length > 0) {
				for (let child of node.resources) {
					if (this.execute_search(child)) {
						if (!this.open_items.find(x=>x==node.resource_id)) this.open_items.push(node.resource_id)
						rv = true
					}
				}
			}

			// assuming the node has a description (title), determine if it should be highlighted as a search result
			let description = node.description
			// use searchable_description if we have one
			if (!empty(node.searchable_description)) description = node.searchable_description
			if (!empty(description)) {
				// if the description includes a stop word, no
				if (!U.string_includes_stop_word(this.stop_words, description)) {
					// check description; could also check long_description at some point
					let arr = [description]

					if (U.strings_match_search_term_res(this.search_term_res, arr)) {
						this.search_results.push(node.resource_id)
						if (!this.open_items.find(x=>x==node.resource_id)) this.open_items.push(node.resource_id)
						rv = true
					}
				}
			}

			return rv
		},

		node_search_match(resource_id) {
			if (this.search_results.length == 0) return '-'
			if (this.search_results.findIndex(x=>x==resource_id) > -1) return 'yes'
			return 'no'
		},

		execute_search_start() {
			U.loading_start()
			setTimeout(()=>U.loading_stop(), 100)
			this.execute_search_clear()

			let arr = U.create_search_re(this.search_terms)
			this.search_term_res = arr[0]
			this.stop_words = arr[1]

			for (let node of this.rc_tree) {
				this.execute_search(node)
			}
		},

		execute_search_clear() {
			// this.collapse_all()
			this.search_results = []
		},

		search_field_keyup(evt) {
			if (evt.key == 'Enter' || evt.keyCode == 13) {
				this.execute_search_start()
			}
		},

		node_found_by_search(tree_key) {
			return (!empty(this.search_terms) && !empty(this.search_results.find(o=>o==tree_key)))
		},

		search_css(tree_key) {
			if (this.node_found_by_search(tree_key)) return 'k-rc-tree-search-match'
			return ''
		},

		highlight_search_terms(s) {
			return s

			// highlight search terms if we have them
			if (!empty(this.search_term_res)) {
				for (let res of this.search_term_res) {
					for (let re of res) {
						s = s.replace(re, '<span class="k-rc-tree-searched-term">$1</span>')
					}
				}
			}
			return s
		},

		collapse_all() {
			this.open_items = []
			this.active_items = []
		},

		assign_resource(standard, resource) {
			let case_identifier = ''
			if (!empty(standard)) case_identifier = standard.identifier
			this.$emit('assign_resource', case_identifier, resource)
			this.$emit('show_assignments')
		},
	}
}
</script>

<style lang="scss">
.k-rc-tree-outer-wrapper {
	right:5px;
	border:5px solid $v-indigo-darken-3;
	background-color:#fff;
	z-index:8!important;	// so the resource tree floats above resource btns in the background

	.k-rc-tree-top {
		background-color: $v-indigo-darken-3;
		color:#fff;
		border-radius:2px 2px 0 0;
	}

	.k-resizable-handle-br {
		background-color:#fff;
		border:1px solid #000;
		position:absolute;
		right:-10px;
		bottom:-10px;
		width:16px;
		height:16px;
		cursor: se-resize;
	}

	.k-case-tree-main .v-treeview-node__root {
		min-height:0!important;
	}
}

</style>
