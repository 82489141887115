window.sample_data = {
	teacher_google_classroom_classes: [
		new Google_Classroom_Class({
			title: 'Ms. Smith 3rd Grade Social Studies',
			url: 'https://classroom.google.com/c/NjAzMzI2OTgxMTJa',
			id: '60332698112',
			created_at: '2020-01-30T02:06:18.171Z',
			hidden: false,
		}),
		new Google_Classroom_Class({
			title: 'Ms. Smith 3rd Grade Social Studies 2018-2019',
			url: 'https://classroom.google.com/c/MTE2NDMwNjIyODBa',
			id: '11643062280',
			created_at: '2018-02-22T22:42:57.787Z',
			hidden: true,
		})
	],

	teacher_schoology_classes: [
		// new Schoology_Class({
		// 	// title: 'Ms. Smith 3rd Grade Math/Science Class with a really long name that won’t fit well',
		// 	title: 'Ms. Smith 3rd Grade Math/Science',
		// 	url: 'https://app.schoology.com/course/196496428/materials',
		// 	id: 'sch123',
		// 	created_at: '2019-01-30T02:06:18.171Z',
		// 	hidden: false,
		// })
	],

	student_google_classroom_classes: [
		new Google_Classroom_Class({
			title: 'Mr. Franklin 2nd Grade',
			url: 'https://classroom.google.com/c/NjAzMzI2OTgxMTJa',
			id: '60332698112',
			created_at: '2020-01-30T02:06:18.171Z',
			hidden: false,
		}),
	],
}
