<template>
	<v-dialog v-model="dialog_open" persistent max-width="600px">
		<v-card>
			<v-card-title><b>Edit Instance Unit User Rights</b></v-card-title>
			<v-card-text>
				<div style="font-size: 1.2em"><b>{{ user.user }}</b></div>
				<div class="d-flex">
					<v-checkbox :label="'Admin'" :disabled="is_domain" class="mr-6" v-model="admin" @change="admin_changed" hide-details></v-checkbox>
					<v-checkbox :label="'Edit'" :disabled="admin || is_domain" class="mr-6" v-model="edit" hide-details></v-checkbox>
					<v-checkbox :label="'View'" disabled v-model="view" hide-details></v-checkbox>
				</div>
			</v-card-text>

			<v-card-actions class="pl-4 pr-4 pb-4">
				<v-spacer></v-spacer>
				<v-btn color="secondary" @click="$emit('user_editor_cancel')" class="mr-1"><v-icon small
					class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
				<v-btn color="primary" @click="save_edits"><v-icon small class="mr-2">fas fa-check</v-icon> Save</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
	components: {},
	props: {
		user: { type: Object, required: true },
		lp_unit_id: { type: Number, required: true },
	},
	data() {
		return {
			dialog_open: true,
			view: false,
			edit: false,
			admin: false,
		}
	},
	computed: {
		...mapState(['user_info']),
		is_domain() {
			return this.user.identifier.startsWith('*@');
		},
	},
	created() {
	},
	mounted() {
		this.view = this.user.view
		this.edit = this.user.edit
		this.admin = this.user.admin
	},
	methods: {
		save_edits() {
			const rights_level = this.admin ? 'admin' : this.edit ? 'edit' : 'view'
			const payload = {
				lp_unit_id: this.lp_unit_id,
				admin_rights_id: this.user.admin_rights_id,
				rights_level: rights_level,
				user_id: this.user_info.user_id,
			}
			U.ajax('edit_instance_unit_rights', payload, result => {
				this.$emit('user_edit_saved')
				this.dialog_open = false
			})
		},
		admin_changed() {
			if (this.admin) this.edit = true
		},
	},
}
</script>

<style lang="scss"></style>
