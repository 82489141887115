// Data structures to support exercises, exercise parts, and queries within exercise parts
// Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams

// https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Objects/Inheritance

window.exercise_iframe_preview_font_family = 'Roboto, Arial, sans-serif'

class Exercise_Record {
	constructor(data) {
		if (empty(data)) data = {}
		if (empty(data.exercise_id)) {
			throw new Error('Attempt to create exercise with no exercise_id; use “new” to create a new exercise_id.')
		}
		this.exercise_id = data.exercise_id

		// This will start being saved ...
		sdp(this, data, 'original_activity_id', 0)
		sdp(this, data, 'copied_from_exercise_id', '')

		sdp(this, data, 'exercise_title', '')
		// the php service may send images in as an empty *array*, but we need it to be an object
		if ($.isArray(data.images)) {
			if (data.images.length > 0) console.log('WARNING: incoming data.images is a non-empty array') // shouldn't happen
			this.images = {}
		} else {
			sdp(this, data, 'images', {})
		}

		let legacy_exercise_type = data.exercise_type
		this.legacy_fix_exercise_types(data)
		sdp(this, data, 'exercise_type', 'freeform')

		// exercise types can have extra fields
		if (this.exercise_type == 'quiz') {
			sdp(this, data, 'queries_required', -1)
			sdp(this, data, 'shuffle_question_order', false)

		} else if (this.exercise_type == 'sb_game') {
			sdp(this, data, 'end_date_timestamp', 0)
			sdp(this, data, 'open_for_responses', true)
			sdp(this, data, 'final_revision_seconds', 0)

		} else if (this.exercise_type == 'ir_game') {
			sdp(this, data, 'reading_time', 300)
			sdp(this, data, 'answering_time', 120)	// 120

		} else if (this.exercise_type == 'flashcards') {
			sdp(this, data, 'required_mode', 'browse', ['browse', 'define', 'term', 'sentence'])
			// translate from old rm1, rm2, rm3, rm4 options
			if (!this.required_mode && data.required_modes) {
				this.required_mode = 'browse'
			}
		}

		// teacher_notes added July 2022
		sdp(this, data, 'teacher_notes', '')

		sdp(this, data, 'body', '')

		// legacy: if the body includes a k-exercise__exercise-title, strip it out...
		let body2 = this.body.replace(/<\w+ [^>]*class="[^"]*k-exercise__exercise-title[^"]*"[^>]*>(.*?)<\/\w+>/i, '')
		if (body2 != this.body) {
			// if the exercise doesn't have an exercise_title specified, make this the title
			if (empty(this.exercise_title)) this.exercise_title = RegExp.$1
			// else add the title as simple bold text at the start of the exercise
			else body2 = sr('<p><b>$1</b></p>$2', RegExp.$1, body2)
			this.body = body2
		}

		// strip blob urls from img tags, so that the browser won't waste processor cycles trying to display them; when we call exercise.render_images it will fill in fresh blob urls
		this.body = this.body.replace(/(img[^>]+src=)"blob:http.*?"/g, '$1""')
		this.teacher_notes = this.teacher_notes.replace(/(img[^>]+src=)"blob:http.*?"/g, '$1""')

		if (empty(data.parts)) data.parts = []
		if (empty(data.queries)) data.queries = {}
		this.parts = []
		this.queries = {}


		// parts don't have much in them, so we don't give them their own class
		this.legacy_fix_queries_in_parts(data, legacy_exercise_type)
		this.legacy_set_part_types(data)
		// this.legacy_update_part_structure(data)

		for (let part_index = 0; part_index < data.parts.length; ++part_index) {
			let o = {}

			// for most purposes we can refer to parts by index, but sometimes (e.g. with annotation queries) it's important to have a guid for them
			sdp(o, data.parts[part_index], 'uuid', U.new_uuid())

			if (this.exercise_type == 'freeform') {
				// change legacy 'mixed' parts to 'basic_content'; basic_content is now the default type
				if (data.parts[part_index] == 'mixed') data.parts[part_index] = 'basic_content'
				sdp(o, data.parts[part_index], 'part_type', 'basic_content', ['basic_content', 'interactive_reading', 'scaffolded_response', 'annotation', 'labeled_image', 'cr', 'video', 'mc', 'qq'])

				// by default, students have choice for doing dnd in scaffolded response
				sdp(o, data.parts[part_index], 'sr_dnd', 'choice', ['choice', 'on', 'off'])

				// by default, interactive_reading and auto_blanks are off
				sdp(o, data.parts[part_index], 'interactive_reading', 'off')
				sdp(o, data.parts[part_index], 'auto_blanks', 'off')
				sdp(o, data.parts[part_index], 'ab_count', 'normal')
				sdp(o, data.parts[part_index], 'ab_letters', '1')

				// scaffolded_response and labeled_image parts (and maybe other parts in the future) can include a prompt
				sdp(o, data.parts[part_index], 'part_prompt', '')

				// interactive reading can have alt text
				sdp(o, data.parts[part_index], 'alt_text', '')

				// added 4/1/2023: add a require_click_after_prompt flag, set to false by default. If this is true and the part has a prompt, we require them to click a button after seeing the prompt in order to see the queries/choices/CR area/video
				// currently we don't have a way to turn this to true, but we could implement one in the future
				sdp(o, data.parts[part_index], 'require_click_after_prompt', false)
			}

			this.parts.push(o)
		}

		// create Query class instances from data in initial queries records, setting stars_available along the way
		this.set_queries(data.queries, legacy_exercise_type)
	}

	set_queries(queries, legacy_exercise_type) {
		this.stars_available = 0

		this.queries = {}
		let n = 0
		for (let uuid in queries) {
			let q = queries[uuid]

			if (q.type == 'hangman') {
				// for hangman, some legacy_exercise_types require us to set some things special...
				if (legacy_exercise_type == 'default') {
					// console.log('hangman legacy:' + legacy_exercise_type)
					q.blank_letters = -1
					q.stars_per_letter = 1

				} else if (legacy_exercise_type == 'auto_blanks') {
					// console.log('hangman legacy:' + legacy_exercise_type)
					q.blank_letters = dv(q.blank_letters, 1)
					q.stars_per_letter = 1
				}

				q = new window.Hangman_Query(q)

			} else if (q.type == 'mc') q = new window.MC_Query(q)
			else if (q.type == 'numeric') q = new window.Numeric_Query(q)
			else if (q.type == 'cr') q = new window.CR_Query(q)
			else if (q.type == 'video') q = new window.Video_Query(q)
			else if (q.type == 'ir') q = new window.Interactive_Reading_Query(q)
			else if (q.type == 'annotation') q = new window.Annotation_Query(q)
			else if (q.type == 'bc') q = new window.Basic_Content_Query(q)
			else if (q.type == 'flashcard') q = new window.Flashcard_Query(q, this)
			else if (q.type == 'qq') q = new window.Quiz_Query(q)
			else if (q.type == 'irgame') q = new window.IR_Game_Query(q)
			else if (!empty(window[q.type + '_Query'])) q = new window[q.type + '_Query'](q)
			else q = new window.Misc_Query(q)

			q.index = n
			++n

			this.queries[uuid] = q

			// for ir_game exercises, skip hangman queries
			if (this.exercise_type == 'ir_game' && q.type == 'hangman') {
			} else {
				// each query type constructor calculates stars_available for the query
				this.stars_available += q.stars_available
			}
		}
	}

	n_queries() {
		let n = 0
		for (let uuid in this.queries) ++n
		return n
	}

	delete_student_activities() {
		// to delete the student activities for an exercise, clear the body, parts, and queries...
		this.body = ''
		this.parts = []
		this.queries = {}
		this.stars_available = 0

		// and set it to the freeform type
		this.exercise_type = 'freeform'
	}

	copy() {
		let cr = {}
		let body = this.body
		for (let key in this) {
			// skip body; we're dealing with that separately
			if (key == 'body') {
				continue

			// also skip original_activity_id and copied_from_exercise_id
			} else if (key == 'original_activity_id' || key == 'copied_from_exercise_id') {
				// original_activity_id will be filled in when the exercise is saved
				// copied_from_exercise_id is for when we duplicate an exercise *across activities*, whereas here we're duplicating within an activity, presumably with the express purpose of then changing the duplicate
				continue

			// for queries, we have to construct new uuids
			} else if (key == 'queries') {
				cr.queries = {}
				for (let uuid in this.queries) {
					let new_uuid = U.new_uuid()
					// copy the query to the new uuid
					cr.queries[new_uuid] = $.extend(true, {}, this.queries[uuid])
					cr.queries[new_uuid].uuid = new_uuid

					// for cr queries, we store copied_from_uuid so we can copy the original query's model when needed
					if (cr.queries[new_uuid].type == 'cr') {
						// if the query already has a copied_from_uuid value, keep it; that would mean that we're making a copy C of a query B that is itself a copy of query A,
						// and that B hasn't itself been edited -- so C should inherit the model from A.
						// Otherwise, we're making a copy B of query A that does have a model already, so A's uuid should be the copied_from_uuid value for B
						if (empty(cr.queries[new_uuid].copied_from_uuid)) {
							cr.queries[new_uuid].copied_from_uuid = uuid
						}
					}

					// replace the uuid in body
					body = body.replace(new RegExp(uuid), new_uuid)
				}

			// else copy the property, dealing with different variable types
			} else {
				if ($.isArray(this[key])) {
					// caution: will this work for arrays of objects? does this matter?
					cr[key] = $.merge([], this[key])
				} else if (typeof(this[key]) == 'object') {
					cr[key] = $.extend(true, {}, this[key])
				} else {
					cr[key] = this[key]
				}
			}
		}

		// put the body in cr and return
		cr.body = body
		return cr
	}

	student_activity_empty() {
		// exercise is empty if there's no text in the body and it doesn't include any queries or parts
		return empty($.trim(U.html_to_text(this.body))) && $.isEmptyObject(this.queries) && this.parts.length == 0
	}

	exercise_empty() {
		return this.student_activity_empty() && !this.teacher_notes
	}

	exercise_number() {
		return vapp.$store.getters.exercises.findIndex(x=>x.exercise_id == this.exercise_id) + 1
	}

	lesson_exercise_number() {
		// In the lesson version of Sparkl, some lesson parts don't have student activities. In the back end we still treat these as exercises,
		// but on the front end, we only use the term "Exercise" for a lesson part that has student activities. So if non-activity parts
		// are mixed in with with-activity parts, we have to calculate the student activity exercise number as follows here.
		let n = 0
		for (let exercise of vapp.$store.getters.exercises) {
			if (exercise.record.stars_available > 0 || (exercise.component && exercise.component.editor_showing)) {
				++n
				if (exercise.exercise_id == this.exercise_id) return n
			}
		}
		// if we get to here, return empty string, which means this exercise doesn't actually have any student activity.
		return ''
	}

	////////////////////////////////////////
	// the below fns are mostly for dealing legacy issues...

	// get the video query for the exercise (if there is one)
	get_video_query() {
		for (let uuid in this.queries) {
			if (this.queries[uuid].type == 'video') {
				return this.queries[uuid]
			}
		}
		return null
	}

	legacy_fix_exercise_types(data) {
		// legacy: translate "json_mode" to exercise_type json
		if (data.json_mode === true || data.json_mode === 'true') data.exercise_type = 'json'

		// legacy: translate exercise_type "review" to "freeform" (was) "worksheet"
		if (data.exercise_type == 'review') data.exercise_type = 'freeform'

		// legacy: translate exercise_type "worksheet" to "freeform"
		if (data.exercise_type == 'worksheet') data.exercise_type = 'freeform'

		// legacy: translate exercise_type "default" to "freeform"
		if (data.exercise_type == 'default') data.exercise_type = 'freeform'

		// legacy: translate exercise_type "auto_blanks" to "freeform"
		if (data.exercise_type == 'auto_blanks') data.exercise_type = 'freeform'

		// legacy: translate exercise_type "flashcard" to "concept_cards"
		if (data.exercise_type == 'flashcard') data.exercise_type = 'concept_cards'

		// legacy: translate exercise_type "video" to "freeform" and...
		if (data.exercise_type == 'video') {
			data.exercise_type = 'freeform'
			// find the video query in the exercise and add it as a new part at the start of the body
			for (let uuid in data.queries) {
				let q = data.queries[uuid]
				if (q.type == 'video') {
					// add to start of body, adding a part separator if the exercise already has parts
					if (!empty(data.body)) data.body = '<hr>' + data.body
					data.body = sr('<p>[[$1]]</p>$2', uuid, data.body)

					// set part_index for the query
					q.part_index = 0

					if (!data.parts) data.parts = []

					// add the part to the start of the parts array
					data.parts.unshift({part_type:'video'})

					// push part_index for all other queries forward one
					for (let ouuid in data.queries) {
						if (ouuid == uuid) continue
						data.queries[ouuid].part_index += 1
					}

					break
				}
			}
		}

		// legacy: translate exercise_type "image_labeling" to "freeform" and...
		if (data.exercise_type == 'image_labeling') {
			console.log('TRANSLATE IMAGE_LABELING')
			data.exercise_type = 'freeform'

			// set the first part to type 'labeled_image'
			if (!data.parts) data.parts = []

			if (!data.parts[0]) data.parts[0] = {part_type: 'labeled_image'}
			else data.parts[0].part_type = 'labeled_image'

			// set other parts to basic_content if not already set
			for (let i = 1; i < data.parts.length; ++i) {
				if (!data.parts[i]) data.parts[i] = {part_type: 'basic_content'}
				else if (!data.parts[i].part_type) data.parts[i].part_type = 'basic_content'
			}
		}

		// legacy: anything not in Exercise_Types gets default
		if (empty(window.Exercise_Types[data.exercise_type])) {
			console.log('LEGACY EXERCISE TYPE: ' + data.exercise_type)
			data.exercise_type = 'freeform'
		}
	}

	legacy_fix_queries_in_parts(data, legacy_exercise_type) {
		// for legacy 'default', 'worksheet', and 'auto_blanks' exercises, add part_index values to queries, and check if any queries have interactive_reading blanks
		if (legacy_exercise_type != this.exercise_type) {
			console.log('FIXING LEGACY EXERCISE TYPE PARTS ' + this.exercise_id)
			// if (data.exercise_id == 'e5c05f68-12aa-4b2c-b1de-b05dfdadba8f') debugger

			let part_bodies = this.body.split(/<hr>/)

			// legacy video queries (e.g.) may not actually have bodies
			if (part_bodies.length != data.parts.length) return

			for (let part_index = 0; part_index < data.parts.length; ++part_index) {
				let o = data.parts[part_index]
				let has_ir_blanks = false
				let part_body = part_bodies[part_index]

				// add part_index values to queries
				let uuids = part_body.match(/\[\[.*?\]\]/g)
				if (!empty(uuids)) for (let uuid of uuids) {
					uuid = uuid.replace(/\[\[(.*?)\]\]/, '$1')
					let q = data.queries[uuid]
					if (empty(q)) continue
					q.part_index = part_index
					if (q.type == 'hangman' || (q.type == 'mc' && q.mode == 'inline')) {
						has_ir_blanks = true
					}
				}

				// for legacy 'default' and 'worksheet' type, ...
				if (legacy_exercise_type == 'default' || legacy_exercise_type == 'worksheet') {
					// auto_blanks is 'off'
					o.auto_blanks = 'off'

					// interactive_reading is on if legacy_exercise_type is 'default' AND the part actually has IR blanks
					if (legacy_exercise_type == 'default' && has_ir_blanks) {
						o.interactive_reading = 'on'

						// add interactive_reading_query for the part
						let irq = new Interactive_Reading_Query({
							part_index: part_index,
						})

						// mark the irq with a special flag, stored in the "competency" field
						irq.competency = 'legacy'

						data.queries[irq.uuid] = irq

					} else {
						o.interactive_reading = 'off'
					}

				// for legacy 'auto_blanks' type, interactive_reading is always 'on' and auto_blanks is 'on'
				} else if (legacy_exercise_type == 'auto_blanks') {
					o.interactive_reading = 'on'
					o.auto_blanks = 'on'
					// the 'AUTO' blanks for these legacy exercises won't work, so they need to be re-saved

				} else if (o.interactive_reading == 'on') {
					// this fixes legacy video exercise parts
					o.part_type = 'interactive_reading'
				}
			}
		}
	}

	legacy_set_part_types(data) {
		// we only have to do this for freeform types
		if (this.exercise_type != 'freeform') return

		// if we've already assigned part_types to the parts, we don't have to do this again
		if (!data.parts[0] || data.parts[0].part_type) return

		console.log('UPDATING LEGACY PARTS ' + this.exercise_id)

		// go through each part body
		let part_bodies = this.body.split(/<hr>/)
		for (let i = 0; i < data.parts.length; ++i) {
			let part = data.parts[i]
			let part_body = part_bodies[i]
			// console.log(part_body)

			// get the list of queries in the part. if the part has exactly one query, then...
			let uuids = part_body.match(/\[\[.*?\]\]/g)
			if (uuids && uuids.length == 1) {
				let q = data.queries[uuids[0].replace(/\[\[(.*?)\]\]/, '$1')]
				// if the query is type cr, video, mc-standalone, or qq
				if (!empty(q) && (q.type == 'cr' || q.type == 'video' || (q.type == 'mc' && q.mode == 'standalone') || q.type == 'qq')) {
					// get text before and after the query
					let chunks = part_body.split(/\[\[.*?\]\]/)

					// if there is any text after the query, don't set it to this type
					let after = $.trim(U.html_to_text(chunks[1]))
					let before = $.trim(U.html_to_text(chunks[0]))
					if (!empty(before) || !empty(after)) {
						part.part_type = 'basic_content'
					} else {
						// label the part after the query type
						part.part_type = q.type

						// make sure that interactive_reading is off
						part.interactive_reading = 'off'
					}

					if (!empty(before)) {
						// if we found text before the query and the query didn't have a prompt, set the query prompt to this text
						if (empty(q.prompt)) q.prompt = before
						// but note that we don't remove the text from the exercise body
					}
				}
			}

			// if we didn't set a part_type above and the part includes a query that isn't of type numeric, hangman, and mc-inline queries, set it to 'basic_content'
			if (!part.part_type && !empty(uuids)) {
				for (let uuid of uuids) {
					let q = data.queries[uuid.replace(/\[\[(.*?)\]\]/, '$1')]
					if (empty(q) || !(q.type == 'hangman' || q.type == 'numeric' || (q.type == 'mc' && q.mode == 'inline'))) {
						part.part_type = 'basic_content'
					}
				}
			}

			// if we didn't set a part_type above...
			if (!part.part_type) {
				// if interactive_reading is on, label the part as 'interactive_reading'
				if (part.interactive_reading == 'on') part.part_type = 'interactive_reading'
				// else if it has at least one query, set it to 'scaffolded_response'
				else if (uuids && uuids.length > 0) part.part_type = 'scaffolded_response'
				// else set it to 'basic_content'
				else part.part_type = 'basic_content'
			}
		}
	}

	// reconstruct the exercise body with a new body for one part
	update_exercise_body_for_part(part_index, new_part_body) {
		let bps = this.body.split('<hr>')
		let ex_body = ''
		for (let i = 0; i < bps.length; ++i) {
			if (!empty(ex_body)) ex_body += '<hr>'

			if (i == part_index) ex_body += new_part_body
			else ex_body += bps[i]
		}
		return ex_body
	}

	// determine if the given part can be converted to a single-exercise part; if so, return the query and a possible prompt for the query
	get_single_query_part_type(part_index) {
		let part = this.parts[part_index]
		let part_body = this.body.split(/<hr>/)[part_index]
		// console.log(part_body)

		// get the list of queries in the part. if the part has exactly one query, then...
		let uuids = part_body.match(/\[\[.*?\]\]/g)
		if (uuids && uuids.length == 1) {
			let q = this.queries[uuids[0].replace(/\[\[(.*?)\]\]/, '$1')]
			// if the query is type cr, video, or mc-standalone,
			if (!empty(q) && (q.type == 'cr' || q.type == 'video' || (q.type == 'mc' && q.mode == 'standalone'))) {
				// get text before and after the query
				let chunks = part_body.split(/\[\[.*?\]\]/)

				// if there is any text after the query, it can't be converted
				let after_text = $.trim(U.html_to_text(chunks[1]))
				if (!empty(after_text)) return false

				// if there is text before the query...
				let before_text = $.trim(U.html_to_text(chunks[0]))
				let prompt_text = $.trim(U.html_to_text(q.prompt))
				// if there is no text before the query, or there is no prompt, or if the text before the query is identical to the query prompt, it can be converted to the query type
				if (empty(before_text) || empty(prompt_text) || (before_text == prompt_text)) {
					// chunks[0] might have an unclosed paragraph tag at the end; remove if so
					chunks[0] = chunks[0].replace(/<p>\s*$/, '')
					return {type:q.type, query:q, query_prompt:chunks[0]}
				}
			}
		}

		// if we get to here, this part shouldn't be converted to a single-part type
		return false
	}
}
window.Exercise_Record = Exercise_Record

class Exercise {
	constructor(data, record_data) {
		if (empty(data)) data = {}
		if (empty(record_data)) record_data = {}

		this.record = new window.Exercise_Record(record_data)

		// keys/values that are to be stored in the assignment's record of the exercise
		this.exercise_id = this.record.exercise_id
		sdp(this, data, 'disabled', false)

		// the exercise number "label" shown to students (1, 2, 3, etc.), skipping any exercises in the activity that don't have student activities or are disabled
		sdp(this, data, 'number', 0)

		// exercise avail(ability): 'open' or 'locked'; there may be different values for different groups
		// avail values should be gotten and set via get_avail and set_avail below
		this.avail = {}
		if (typeof(data.avail) == 'object' && !$.isArray(data.avail)) {
			// guard against avail coming in as an array instead of an object
			this.avail = data.avail
		}

		// timestamp when the exercise was last saved, represented as a string
		sdp(this, data, 'last_save_ts', '0')

		// this will get filled in with the exercise component when it's rendered
		this.component = {}

		// this will hold blob_urls for images that we render using render_images (below)
		this.image_blob_urls = {}
	}

	get_avail(group_id) {
		// if incoming group_id is empty, base on current_groups from vapp (assumes group_is_selected and current_groups are defined in vapp; we need it to work this way for studentapp and teacherapp to be able to use this)
		if (empty(group_id)) {
			// if no group is selected, base on the default group; if default_group not found, return 'open' (8/11/23: not sure if this is right, but it's the way it was before)
			if (!vapp.group_is_selected) {
				let default_group = vapp.$store.getters.default_group
				if (!default_group || empty(this.avail['g' + default_group.collection_id])) return 'open'
				else return this.avail['g' + default_group.collection_id]
			} else {
				// one or more groups are selected; return 'open' or 'locked' if all match, or 'mixed' otherwise
				let avail
				for (let group of vapp.current_groups) {
					let this_avail = this.avail['g' + group.collection_id] ? this.avail['g' + group.collection_id] : 'open'
					if (empty(avail)) avail = this_avail
					else if (avail != this_avail) avail = 'mixed'
				}
				return avail
			}

		// else base on incoming group_id; 'open' if not set
		} else {
			if (!empty(this.avail['g' + group_id])) {
				return this.avail['g' + group_id]
			} else {
				return 'open'
			}
		}
	}

	set_avail(avail, group_id) {
		vapp.$store.commit('set', [this.avail, 'g' + group_id, avail])
		// we must use store.commit to set these, so that it uses vue.set if necessary
	}

	render_images(flag) {
		// this is for legacy (pre-summer-2022) exercises, where we stored image blobs in the exercises...
		for (let uuid in this.record.images) {
			// if we've already created a blob_url, use it
			let blob_url = this.image_blob_urls[uuid]
			if (empty(blob_url)) {
				// if not, create one
				let blob = U.data_url_to_blob(this.record.images[uuid])
				blob_url = URL.createObjectURL(blob)
				// note that it's OK that image_blob_urls isn't responsive
				this.image_blob_urls[uuid] = blob_url
				// we don't want to call URL.revokeObjectURL(blob_url), because we keep using the same blobs
			}
			// fill in the src attributes for the img tags tied to this uuid; also remove the data-fr-image-pasted attribute at this time
			let jq = $(sr('[data-image-uuid=$1]', uuid))
			jq.attr('src', blob_url).removeAttr('data-fr-image-pasted')

			// if told to do so, add a click event to show the image in a new window
			if (flag == 'click_event') {
				console.log('render click event')
				jq.css('cursor', 'pointer').off('click').on('click',()=>{ window.open(blob_url) })
			}
		}

		// this is for the new (post-summer-2022) way we put images in exercises, by simply embedding the base-64 images in the body/prompt text
		if (flag == 'click_event' && vapp.app_name != 'sparkl_teacher') {
			U.enable_image_maximize($('html'))
		}
	}

	clean_up_images() {
		// dump any images that are no longer part of the exercise body or teacher_notes.
		// Note that if we later allow for saving images in prompts, we'll have to check for images in the prompts too
		for (let uuid in this.images) {
			if (this.body.indexOf(uuid) == -1 && this.teacher_notes.indexOf(uuid) == -1) {
				console.log('Removing image: ' + uuid)
				delete this.images[uuid]
			}
		}
	}

	apply_sparkl_label_wrappers($parent_jq, flag) {
		// this fn is in ImageLabelMixin, which is included in App.vue
		vapp.apply_sparkl_label_wrappers($parent_jq, this, flag)
	}
}
window.Exercise = Exercise

class Exercise_For_Teacher extends Exercise {
	constructor(data, record_data) {
		if (empty(data)) data = {}
		if (empty(record_data)) record_data = {}

		// if data.exercise_id is 'new', create a new uuid
		if (data.exercise_id == 'new') {
			data.exercise_id = U.new_uuid()
			record_data.exercise_id = data.exercise_id
		} else if (empty(data.exercise_id)) {
			throw new Error('Attempt to create exercise with no exercise_id; use “new” to create a new exercise_id.')
		}

		super(data, record_data)
	}
}
window.Exercise_For_Teacher = Exercise_For_Teacher

class Exercise_For_Student extends Exercise {
	constructor(data, record_data, status_data) {
		if (empty(data)) data = {}
		if (empty(record_data)) record_data = {}

		super(data, record_data)

		// kill trailing empty paragraph(s)
		if (!empty(this.record.body)) {
			this.record.body = this.record.body.replace(/\s*<p>((<br>)|\s)*<\/p>\s*$/, '')
		}

		// current status of the exercise in the student player
		this.status = new window.Exercise_Status(status_data, this.record)

		// game exercises receive scores for other students in the class; keep those here
		this.class_game_scores = []

		// create an exercise_key (used when we retry an exercise)
		this.exercise_key = U.new_uuid()
	}
}
window.Exercise_For_Student = Exercise_For_Student

///////////////////////////////////////////////////////

// this constructor should never be called directly; all queries should be created by a type constructor, below
class Query {
	constructor(data) {
		if (empty(data)) data = {}

		if (!empty(data.uuid)) this.uuid = data.uuid
		else this.uuid = U.new_uuid()

		sdp(this, data, 'stars_available', 0)
		sdp(this, data, 'part_index', -1)
		sdp(this, data, 'competency', '')

		// query version is always 0 to start; each time the query is edited, the version goes up. this allows us to correct query status for students who started/completed the query before an edit
		// this.version = 0
		sdp(this, data, 'version', 0)

		// every query must have a type, which will be set by the type constructor
		// every query type's constructor must set stars_available
	}

	// rule for determining if a query is a "reading blank"
	is_reading_blank_query() {
		return this.type == 'hangman' || this.type == 'numeric' || (this.type == 'mc' && this.mode == 'inline')
	}

	// determine if this query is equivalent to the incoming query
	equivalent_to(comp) {
		// to do this we strip the uuids, then compare everything else using JSON.stringify
		let a = Object.assign({}, this)
		let b = Object.assign({}, comp)
		delete a.uuid
		delete b.uuid

		// also strip index, if there (index may be added when an Exercise_Record is created)
		delete a.index
		delete b.index

		return (JSON.stringify(a) == JSON.stringify(b))
	}

	iframe_element(exercise_id, editor_identifier, title, action_btn_text, inner_html) {
		let iname = sr('qi-$1', this.uuid)
		if (editor_identifier) iname += '-' + editor_identifier

		let icl = 'k-query-iframe'

		///////////////////////////////////
		// construct html for iframe showing question
		let html = '<html>'
		html += '<head><link href="https://cdn.jsdelivr.net/npm/froala-editor@3.2.7/css/froala_editor.pkgd.min.css" rel="stylesheet" type="text/css" /><link href="https://cdn.jsdelivr.net/npm/froala-editor@3.2.7/css/froala_style.min.css" rel="stylesheet" type="text/css" /><link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet"></head>'
		// insert math styles
		html += `<style>${U.mathlive_core_css}</style>`
		html += '<body style="margin:0">'

		// if we get an exercise_id, the iframe is going to be shown inside a froala editor, so we style things differently and show an edit btn
		if (exercise_id) {
			html += '<div style="background-color:#eee; border-radius:6px; padding:6px; border:3px solid #FD8B24" class="fr-view">'
			let action_btn_style = 'float:right; margin:4px 0 0 8px; border-radius:6px; border:0; background-color:#333; color:#fff; padding:6px 8px; font-weight:bold; vertical-align:top; cursor:pointer; font-size:16px;'
			let head_style = 'margin-bottom:8px; font-family:' + window.exercise_iframe_preview_font_family + '; font-size:14px;'
			html += sr('<div style="$1">', head_style)
			if (exercise_id) {
				html += sr('<button style="$1" onclick="window.parent.vapp.iframe_query_edit(\'$2\',\'$3\')">$4</button>', action_btn_style, exercise_id, this.uuid, action_btn_text)
			}
			html += sr('<b>$1</b>', title)
			html += '</div>'
		} else {
			html += '<div class="fr-view">'
		}

		html += inner_html

		html += '</div>'
		html += '</body></html>'

		// escape quotation marks and dollar signs
		html = html.replace(/"/g, '&quot;')
		html = html.replace(/\$/g, '&dollar;')
		///////////////////////////////////

		let iframe = `<iframe data-type="${this.type}" data-uuid="${this.uuid}" name="${iname}" class="${icl}" srcdoc="${html}" onclick="vapp.query_clicked(this)"></iframe>`

		return iframe
	}

	resize_editor_element_iframe(editor_identifier) {
		// this is only needed for query types that use iframe previews
		if (!(this.type == 'cr' || this.type == 'video' || (this.type == 'mc' && this.mode == 'standalone'))) return

		// size iframe to fit the content
		// the iframe can be hidden and later resurface, so we unfortunately have to be constantly checking this...

		let size_iframe = function(iname, resize_uuid) {
			// if the resize_uuid has changed, return; resize_editor_element_iframe should have been called again for the query.
			let jq = $(sr('[name=$1]', iname))
			if (jq.attr('data-resize-uuid') != resize_uuid) return

			let win = window[iname]
			if (!win) return

			// console.log('size_iframe: ' + iname)

			let body = win.document.body
			if (body && body.clientHeight > 0 && body.offsetHeight > 40) {
				// we have to do this check on offsetHeight to deal with an issue that occurs when the exercise is edited, then the editor is closed

				if (editor_identifier == 'baseeditor') {
					// for some reason we need to add extra pixels in the editor
					jq.height(body.offsetHeight + 16)
				} else {
					jq.height(body.offsetHeight)
				}
			}

			setTimeout(x=>size_iframe(iname, resize_uuid), 0)
		}

		// get the name if the iframe
		let iname = sr('qi-$1', this.uuid)
		if (editor_identifier) iname += '-' + editor_identifier

		// add a "resize_uuid" attribute to the iframe to avoid calling the resize fn multiple times for the same query
		let jq = $(sr('[name=$1]', iname))
		let resize_uuid = U.new_uuid()
		jq.attr('data-resize-uuid', resize_uuid)

		size_iframe(iname, resize_uuid)
	}

	duplicate(exercise_record) {
		let q = window.create_query_object(this, exercise_record)
		// set copied_from_uuid whenever we duplicate a CR query
		if (q.type == 'cr') {
			q.copied_from_uuid = this.uuid
		}
		return q
	}
	
}
window.Query = Query

window.create_query_object = function(query_data, exercise_record) {
	// note that we only need exercise_record for flashcard queries

	if (query_data.type == 'hangman') return new window.Hangman_Query(query_data)
	else if (query_data.type == 'mc') return new window.MC_Query(query_data)
	else if (query_data.type == 'numeric') return new window.Numeric_Query(query_data)
	else if (query_data.type == 'cr') return new window.CR_Query(query_data)
	else if (query_data.type == 'video') return new window.Video_Query(query_data)
	else if (query_data.type == 'ir') return new window.Interactive_Reading_Query(query_data)
	else if (query_data.type == 'annotation') return new window.Annotation_Query(query_data)
	else if (query_data.type == 'bc') return new window.Basic_Content_Query(query_data)
	else if (query_data.type == 'flashcard') return new window.Flashcard_Query(query_data, exercise_record)
	else if (query_data.type == 'qq') return new window.Quiz_Query(query_data)
	else if (query_data.type == 'irgame') return new window.IR_Game_Query(query_data)
	else if (!empty(window[query_data.type + '_Query'])) return new window[query_data.type + '_Query'](query_data)
	else return new window.Misc_Query(query_data)
}

class Misc_Query extends Query {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)

		sdp(this, data, 'type', 'misc')	// this will be overwritten...

		// copy all non-standard props from data; if the query has a defined type, that type constructor will supercede this
		let o = this.get_custom_props(data)
		for (let prop in o) {
			this[prop] = o[prop]
		}
	}

	// to test for this, just use `query.is_misc_query` -- if the function exists, it's a misc query
	is_misc_query() {
		return true
	}

	get_custom_props(o) {
		let new_o = {}
		for (let prop in o) {
			if (['type', 'uuid', 'part_index', 'competency', 'stars_available', 'index'].indexOf(prop) == -1) {
				new_o[prop] = o[prop]
			}
		}
		return new_o
	}

	editor_element(editing) {
		let width = ((this.type+'').length + 3) + 'ch'
		let cl = (editing) ? 'k-query_being_edited' : ''
		let value = this.type.replace(/\_/g, ' ')
		return `<input type="text" data-type-family="misc" data-type="${this.type}" data-uuid="${this.uuid}" value="${value}" style="width:${width}" class="${cl}" readonly onclick="vapp.query_clicked(this)">`
		// let props = JSON.stringify(this.editor_element_props()).replace(/"/g, 'QqQ')
		// return sr('<input type="text" data-type="misc" data-uuid="$1" data-props="$2" value="$3" style="width:$4" class="$5" readonly onclick="vapp.query_clicked(this)">', this.uuid, props, this.type, width, cl)
	}

	generic_label() { return 'Miscellaneous Query' }
}
window.Misc_Query = Misc_Query

class Hangman_Query extends Query {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)

		// TEMP: if correct_answer is 'AUTO', set auto_blank, stars_per_letter, and blank_letters.
		// Added 4/26/2020; we can take this out after a while once legacy activities have time to be updated
		if (data.correct_answer == 'AUTO' && empty(data.stars_per_letter)) {
			data.auto_blank = true
			data.stars_per_letter = 1
			data.blank_letters = 1
		}

		this.type = 'hangman'

		sdp(this, data, 'correct_answer', '')
		sdp(this, data, 'auto_blank', false)

		// 'note' used for feedback only as of 8-2023
		sdp(this, data, 'note', '')

		// sdp(this, data, 'stars_per_letter', 2)
		sdp(this, data, 'stars_per_letter', 1)	// DEFAULT_POINT_CHANGE
		sdp(this, data, 'blank_letters', -1)

		this.set_stars_available()
	}

	default_blank_letters() {
		let letters = this.correct_answer.toLowerCase().replace(/[^a-z0-9]/g, '')
		let unique_letters = U.unique_chars(letters)
		let blank_letters_calc

		// 1, 2 letter: 1 blank
		// 3, 4, 5: 2 blanks
		// 6, 7, 8, 9: 3 blanks
		// 10, 11, 12: 4 blanks
		// 13 and more: 5 blanks
		if (letters.length == 1) blank_letters_calc = 1
		else if (letters.length <= 7) blank_letters_calc = Math.floor(letters.length / 2)
		else if (letters.length <= 12) blank_letters_calc = Math.floor(letters.length * 0.4)
		else blank_letters_calc = 5

		// but we can't go beyond unique_letters blanks
		if (blank_letters_calc > unique_letters.length) {
			blank_letters_calc = unique_letters.length
		}

		// if there are >= 8 letters and <= 13 letters...
		// console.log(sr('$1: $2', this.correct_answer, blank_letters_calc))
		return blank_letters_calc
	}

	set_stars_available() {
		// if blank_letters is -1, choose # of blank letters -- and thus stars_available -- based on correct_answer
		if (this.blank_letters == -1) {
			this.stars_available = this.default_blank_letters() * this.stars_per_letter

		} else {
			// else stars_available is blank_letters * stars_per_letter
			this.stars_available = this.blank_letters * this.stars_per_letter
		}
	}

	editor_element(editing) {
		let answer = this.correct_answer
		let cl = (editing) ? 'k-query_being_edited' : ''
		let width = (U.get_block_width(answer, 'k-sparkl-editor-query-hangman-sizer') + 8) + 'px'
		let note = JSON.stringify(this.note).replace(/"/g, 'QqQ')
		return `<input type="text" data-type="hangman" data-uuid="${this.uuid}" data-note="${note}" value="${answer}" style="width:${width}" class="${cl}" readonly onclick="vapp.query_clicked(this)">`
	}

	generic_label() { return 'Cloze Blank' }
}
window.Hangman_Query = Hangman_Query


class Numeric_Query extends Query {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)

		this.type = 'numeric'
		sdp(this, data, 'require_exact_match', false)

		// whether the choices are specified as plain text or latex
		sdp(this, data, 'choice_format', 'text', ['text', 'latex'])

		// optional template response, for latex format
		sdp(this, data, 'template_response', '')

		// 'note' added 8-2023, matches hangman; used for feedback
		sdp(this, data, 'note', '')

		// note that we may "encode" numeric answers; e.g. we may represent .25 as '1/4'
		// if we got a correct_answer value, auto-generate choices
		if (!empty(data.correct_answer)) {
			this.auto_generate_choices(data.correct_answer, 2)
		} else {
			sdp(this, data, 'choices', [''])
			sdp(this, data, 'correct_choice_index', 0)
		}

		// sdp(this, data, 'stars_available', 6)	// DEFAULT_POINT_CHANGE
		sdp(this, data, 'stars_available', 3)
	}

	auto_generate_choices(correct_answer, n_choices) {
		correct_answer = '' + correct_answer
		this.choices = [correct_answer]

		// if answer includes anything other than number, -, or ., we can't auto-generate choices
		if (correct_answer.search(/[^0-9.-]/) > -1) {
			this.choices.push('')
			this.correct_choice_index = 0
			return
		}

		for (let i = 1; i < n_choices; ++i) {
			// for non-fractions...
			if (correct_answer.indexOf('/') == -1) {
				// get # of decimal places
				let places = U.num_places(correct_answer)
				let multiplier = Math.pow(10, places)
				let max = Math.round( (correct_answer * multiplier) + (correct_answer * multiplier * 0.5) + 5 )
				let min = Math.round( (correct_answer * multiplier) - (correct_answer * multiplier * 0.5) - 5 )

				let dist
				let safety = 10
				do {
					dist = (U.random_int(min, max) / multiplier) + ''
					--safety
				} while (this.choices.findIndex(x=>x==dist) > -1 && safety >= 0)

				this.choices.push(dist)

			} else {
				// fraction
				let arr = correct_answer.split('/')

				let dist, distx
				let safety = 10
				do {
					dist = sr('$1/$2', arr[0]*1 + U.random_int(4), arr[1]*1 + U.random_int(4))
					--safety
				} while (this.choices.findIndex(x=>x==dist) > -1 && safety >= 0)

				this.choices.push(dist)
			}
		}

		// sort values and find correct_choice_index
		this.choices.sort((a,b)=>{
			return U.decode_numeric_query_answer(a) - U.decode_numeric_query_answer(b)
		})
		this.correct_choice_index = this.choices.findIndex(x=>x==correct_answer)

		// console.log(this.choices, this.correct_choice_index)
	}

	editor_element(editing) {
		let correct_answer = (this.correct_choice_index == -1) ? '—' : this.choices[this.correct_choice_index]
		let choices = JSON.stringify(this.choices).replace(/"/g, 'QqQ')
		let span_attributes = `data-query-editor-element data-froala-no-mathlive-replace data-type="numeric" data-uuid="${this.uuid}" data-choices="${choices}" data-choice_format="${this.choice_format}" data-correct_choice_index="${this.correct_choice_index}" onclick="vapp.query_clicked(this)"`
		return U.render_latex(`$${correct_answer}$`, span_attributes)
	}

	generic_label() { return 'Numeric Blank' }
}
window.Numeric_Query = Numeric_Query

class MC_Query extends Query {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)

		this.type = 'mc'
		sdp(this, data, 'choices', [])
		sdp(this, data, 'correct_choice_index', -1)
		sdp(this, data, 'feedback', [])
		sdp(this, data, 'shuffle_choices', true)
		sdp(this, data, 'not_sure_option', true)	// this is only relevant for 'standalone' type queries
		sdp(this, data, 'show_model', true)		// this is only relevant for 'standalone' type queries
		sdp(this, data, 'final_feedback', '')	// this is only relevant for 'standalone' type queries
		sdp(this, data, 'image_labels', false)
		sdp(this, data, 'prompt', '')

		// set mode -- default is inline
		sdp(this, data, 'mode', 'inline', ['inline', 'standalone'])

		// 'note' added 8-2023, matches hangman; used for feedback on inline queries only
		if (this.mode == 'inline') sdp(this, data, 'note', '')

		// We used to have a `conf` parameter, which determined if query requires a confidence judgment (by default no); removed 4/2022
		// default stars_available used to be 8 for standalone queries with confidence judgments, 4 for inline queries or standalone with no confidence
		// changed 9/10/2021 to defaults of 4 and 8, ignoring whether or not we have confidence

		// let default_stars = (this.mode == 'inline') ? 4 : 8		// DEFAULT_POINT_CHANGE
		let default_stars = (this.mode == 'inline') ? 2 : 4

		// we used to always use default_stars; now we allow teachers to change stars for standalone queries
		sdp(this, data, 'stars_available', default_stars)
	}

	editor_element(editor_identifier, exercise_id) {
		// inline mc's: just show a blank, like hangman.
		if (this.mode == 'inline' || editor_identifier === false) {
			// get a text version of the correct answer, in case it has html, allowing for no-correct-choice
			let correct_answer
			if (this.correct_choice_index == -1) correct_answer = '—'
			else correct_answer = this.choices[this.correct_choice_index]
			if (empty(correct_answer)) correct_answer = '___'

			let cl = (editor_identifier) ? 'k-query_being_edited' : ''
			// let width = (U.get_block_width(correct_answer, 'k-sparkl-editor-query-mc-sizer') + 16) + 'px'
			let choices = JSON.stringify(this.choices).replace(/"/g, 'QqQ').replace(/\$/g, 'DdD')

			let feedback = JSON.stringify(this.feedback).replace(/"/g, 'QqQ').replace(/\$/g, 'DdD')
			// note that mode and stars_available will be determined by the editor based on placement; but we add mode for styling purposes
			return `<span data-query-editor-element data-froala-no-mathlive-replace data-froala-not-editable data-type="mc" data-uuid="${this.uuid}" class="${cl}" data-choices="${choices}" data-correct_choice_index="${this.correct_choice_index}" data-feedback="${feedback}" data-shuffle_choices="${this.shuffle_choices}" data-mode="${this.mode}" data-image_labels="${this.image_labels}" readonly onclick="vapp.query_clicked(this)" data-prompt="${this.prompt}">${U.render_latex(correct_answer, 'data-not-clickable')}</span>`
			// return `<input type="text" data-type="mc" data-uuid="${this.uuid}" value="${correct_answer}" class="${cl}" data-choices="${choices}" data-correct_choice_index="${this.correct_choice_index}" data-feedback="${feedback}" data-shuffle_choices="${this.shuffle_choices}" data-mode="${this.mode}" data-image_labels="${this.image_labels}" readonly onclick="vapp.query_clicked(this)" style="width:${width}" data-prompt="${this.prompt}">`

		// standalone in an iframe
		} else {
			let html = ''

			// stars_available
			let s = sr('$1 Stars', this.stars_available)
			// shuffle
			if (this.shuffle_choices) s += ' | Shuffle choices for each student'
			else s += ' | Choices not shuffled'
			// no correct choice
			if (this.correct_choice_index == -1) s += ' | Any choice counted as correct'

			html += sr('<div style="margin-bottom:12px; font-family:' + window.exercise_iframe_preview_font_family + '; font-size:14px;">$1</div>', s)

			let prompt_style = 'margin-bottom:8px; font-family:' + window.exercise_iframe_preview_font_family + '; font-size:14px; '
			if (this.prompt) html += `<div style="${prompt_style}"><b>Prompt:</b> ${U.render_latex(this.prompt)}</div>`
			html += '<div style="clear:both"></div>'

			// html += '<div style="background-color:#FD8B24; padding:1px 0; border-radius:6px; font-family:' + window.exercise_iframe_preview_font_family + '; font-size:15px;">'
			html += '<div style="font-family:' + window.exercise_iframe_preview_font_family + '; font-size:15px;">'
			let choice_style = 'border:2px solid #FD8B24; background-color:#fff; border-radius:5px; margin:4px 0; padding:6px 6px 6px 42px; text-indent:-36px;'
			for (let i = 0; i < this.choices.length; ++i) {
				let choice = this.choices[i]
				let letter = 'ABCDEFGHIJKLMNOP'[i]
				let cor = ''
				if (this.correct_choice_index > -1) {
					// note unicode symbols for check and x
					if (this.correct_choice_index == i) cor = '<span style="color:#43a047; font-family:' + window.exercise_iframe_preview_font_family + '; font-size:20px; line-height:14px; margin:0 -2px 0 -1px">✔</span>'	// alt: ✓
					else cor = '<span style="color:#e53935; font-family:' + window.exercise_iframe_preview_font_family + '; font-size:20px; line-height:14px;">✘</span>'
				}
				html += `<div style="${choice_style}"><b>${cor} ${letter}.</b> ${U.render_latex(choice)}</div>`
			}
			html += '</div>'

			let final_feedback_style = 'padding-top:8px; font-family:' + window.exercise_iframe_preview_font_family + '; font-size:14px; '
			if (this.final_feedback) html += `<div style="${final_feedback_style}"><b>Final Feedback:</b> ${U.render_latex(this.final_feedback)}</div>`
			html += '<div style="clear:both"></div>'

			let action_btn_text = 'EDIT QUESTION'

			return this.iframe_element(exercise_id, editor_identifier, 'Multiple-Choice Question', action_btn_text, html)
		}
	}

	generic_label() {
		if (this.mode == 'inline') return 'Multiple Choice Blank'
		else return 'Multiple Choice'
	}
}
window.MC_Query = MC_Query

class CR_Query extends Query {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)

		this.type = 'cr'
		// if we have a legacy use_validator value, convert to validator_threshold
		if (data.use_validator+'' === 'true' && data.validator_threshold == null) data.validator_threshold = 1

		// if data comes in with an 'invalid_scoring' param, we know this is a legacy (pre-April-2021) query, so set wizard_stage to 'advanced' (meaning that the query has been fully configured)
		if (!empty(data.invalid_scoring)) data.wizard_stage = 'advanced'

		sdp(this, data, 'prompt', '')
		sdp(this, data, 'model_response', '')
		sdp(this, data, 'response_template', '')
		sdp(this, data, 'cr_hint', '')
		sdp(this, data, 'show_model_after_submit', true)
		// default stars_available was 10; changed to 12 on 9/10/21 so that it's evenly divided by the 4 SB categories
		sdp(this, data, 'stars_available', 12)
		sdp(this, data, 'answer_type', 'text', ['text', 'draw', 'flex', 'audio'])
		sdp(this, data, 'notes_mode', false)
		sdp(this, data, 'student_response_editing', 'default', ['default', 'stingy', 'lenient'])
		sdp(this, data, 'min_word_count', 1)
		sdp(this, data, 'min_audio_len', 5)
		sdp(this, data, 'max_audio_len', 30)
		// sdp(this, data, 'grading_categories', [])
		this.grading_categories = this.get_grading_categories()

		sdp(this, data, 'rubric', '')
		sdp(this, data, 'show_rubric_to_students', false)

		// 4/12/2021: translating from old 'validator_threshold', which was ['off', 'lenient', 'normal', 'stingy'], and 'invalid_scoring', which was ['full', 'half'],
		// to new 'initial_scoring_policy', which is ['no_sb_full_credit', 'sb_full_credit', 'sb_partial_credit']
		if (empty(data.initial_scoring_policy) && !empty(data.invalid_scoring)) {
			// if validator_threshold was off, initial_scoring_policy = 'no_sb_full_credit'
			if (data.validator_threshold == 'off' || data.validator_threshold === 0) {
				this.initial_scoring_policy = 'no_sb_full_credit'

			// else SB is on; translate invalid_scoring to sb_full_credit or sb_partial_credit
			} else if (data.invalid_scoring == 'full') {
				this.initial_scoring_policy = 'sb_full_credit'
			} else {
				this.initial_scoring_policy = 'sb_partial_credit'
			}

		} else {
			sdp(this, data, 'initial_scoring_policy', 'no_sb_full_credit', ['no_sb_full_credit', 'no_initial_credit', 'sb_full_credit', 'sb_partial_credit'])	// no_initial_credit value added 9/20/2023
		}

		sdp(this, data, 'require_revision', false)

		// setting for draw-type responses: whether or not to *immediately* show the image capture interface when the query is opened
		sdp(this, data, 'show_image_select', false)

		// use this to keep track of which parameters have been set up via the wizard, in CRQueryEditor
		sdp(this, data, 'wizard_stage', 'initial', ['initial', 'response_editing', 'scoring_policy', 'model', 'advanced'])

		// this isn't currently used, but we'll keep passing it through in case we bring it back later
		sdp(this, data, 'compare_to_exercises', 'off', ['on', 'off'])	// safer to have this "off" by default

		// really old legacy questions may have had a single comparison_text set
		if (!empty(data.comparison_text)) this.comparison_texts = [data.comparison_text]
		else sdp(this, data, 'comparison_texts', [])

		// added 7/9/2021 to allow for copying query models when queries are copied
		sdp(this, data, 'copied_from_uuid', '')
	}

	editor_element(editor_identifier, exercise_id) {
		let html = ''

		let s = sr('$1 Stars | ', this.stars_available)
		if (this.answer_type == 'text') {
			s += sr('Min. word count: $1 | ', this.min_word_count)
			if (this.initial_scoring_policy == 'no_sb_full_credit') {
				s += `<b>${vapp.$store.getters.app_noun}-Bot not used</b> for initial scoring; <b>full credit</b> initially awarded when the student submits.`
			} else if (this.initial_scoring_policy == 'no_initial_credit') {
				s += `<b>${vapp.$store.getters.app_noun}-Bot not used</b> for initial scoring; <b>0 ${vapp.$store.getters.star_noun_plural}</b> initially awarded when the student submits.`
			} else {
				if (this.initial_scoring_policy == 'sb_full_credit' && this.require_revision) {
					s += `<b>${vapp.$store.getters.app_noun}-Bot requires at least one revision</b> if it thinks a student response can be improved, but initially awards <b>full credit</b> once the student submits.`
				} else if (this.initial_scoring_policy == 'sb_full_credit' && !this.require_revision) {
					s += `<b>${vapp.$store.getters.app_noun}-Bot suggests that students revise</b> if it thinks a student response can be improved, but initially awards <b>full credit</b> once the student submits.`
				} else if (this.initial_scoring_policy == 'sb_partial_credit' && this.require_revision) {
					s += `<b>${vapp.$store.getters.app_noun}-Bot requires at least one revision</b> if it thinks a student response can be improved, and awards initial ${vapp.$store.getters.star_noun_plural} <b>based on ${vapp.$store.getters.app_noun}-Bot’s evaluation</b>.`
				} else if (this.initial_scoring_policy == 'sb_partial_credit' && !this.require_revision) {
					s += `<b>${vapp.$store.getters.app_noun}-Bot suggests that students revise</b> if it thinks a student response can be improved, and awards initial ${vapp.$store.getters.star_noun_plural} <b>based on ${vapp.$store.getters.app_noun}-Bot’s evaluation</b>.`
				}
			}
		} else if (this.answer_type == 'flex') {
			s += 'Answer Interface: Flex (text OR drawing area)'
		} else {
			s += 'Answer Interface: Drawing Area'
		}

		html += sr('<div style="margin-bottom:12px; font-family:' + window.exercise_iframe_preview_font_family + '; font-size:14px;">$1</div>', s)

		let prompt_style = 'font-family:' + window.exercise_iframe_preview_font_family + '; font-size:14px; '
		// html += sr('<div style="$1"><b>Prompt:</b> $2</div>', prompt_style, this.prompt??'[no prompt]')
		html += `<div style="${prompt_style}"><b>Prompt:</b> ${U.render_latex(this.prompt??'[no prompt]')}</div>`

		// let ta_style = 'width:calc(100% - 48px); height:30px; border:3px solid #FD8B24; border-radius:6px; padding:4px; font-family:' + window.exercise_iframe_preview_font_family + '; font-size:14px; resize:none; background-color:#eee;'
		// html += sr('<div><textarea style="$1" disabled>Student answer entered here</textarea></div>', ta_style)

		let model_style = 'margin-top:12px; font-family:' + window.exercise_iframe_preview_font_family + '; font-size:14px; '
		if (this.model_response) html += `<div style="${model_style}"><b>Model Response:</b> ${U.render_latex(this.model_response)}</div>`
		if (this.cr_hint) html += `<div style="${model_style}"><b>Hint:</b> ${U.render_latex(this.cr_hint)}</div>`
		if (this.response_template) html += `<div style="${model_style}"><b>Student Response Template:</b><div style="margin-top:8px">${U.render_latex(this.response_template)}</div></div>`
		if (this.rubric) html += `<div style="${model_style}"><b>Rubric:</b><div style="margin-top:8px">${U.render_latex(this.rubric)}</div></div>`

		let action_btn_text = 'EDIT QUESTION'

		let heading = 'Constructed Response Question'
		if (this.notes_mode) heading += ' <span style="font-weight:normal">(Notes mode)</span>'
		return this.iframe_element(exercise_id, editor_identifier, heading, action_btn_text, html)
	}

	generic_label() { return 'Constructed Response' }

	get_grading_categories() {
		// 7/9/2021: changing things so that grading_categories are fixed/calculated on the fly, not editable by teachers
		// so we ignore any pre-stored grading_categories, and always return the default categories

		// if grading_categories have been explicitly saved, return them
		// if (this.grading_categories.length > 0) {
		// 	return this.grading_categories
		// }

		// else construct default categories
		return [
			{value: "4", stars: this.stars_available},
			{value: "3", stars: Math.ceil(this.stars_available / 4 * 3)},
			{value: "2", stars: Math.ceil(this.stars_available / 2)},
			{value: "1", stars: Math.ceil(this.stars_available / 4)},
		]
	}
}
window.CR_Query = CR_Query

class Video_Query extends Query {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)

		this.type = 'video'
		sdp(this, data, 'video_id', '')		// example short video: 10rNMSTSFJg
		sdp(this, data, 'prompt', '')
		sdp(this, data, 'interaction_type', 'none', ['none', 'scratch', 'transcript'])
		sdp(this, data, 'transcript_breaks', 'off', ['on', 'off'])
		sdp(this, data, 'part_timestamps', [])
		// make sure part_timestamps are integers
		for (let i = 0; i < this.part_timestamps.length; ++i) this.part_timestamps[i] = this.part_timestamps[i] * 1
		sdp(this, data, 'stars_available', 5)
	}

	video_url() {
		return sr('https://youtu.be/$1', this.video_id)
	}

	video_thumbnail_url() {
		return sr('http://img.youtube.com/vi/$1/0.jpg', this.video_id)
	}

	editor_element(editor_identifier, exercise_id) {
		let html = ''

		let s = sr('$1 Stars | Video ID: $2', this.stars_available, this.video_id)
		html += sr('<div style="margin-bottom:12px; font-family:' + window.exercise_iframe_preview_font_family + '; font-size:14px;">$1</div>', s)

		let prompt_style = 'margin-bottom:12px; font-family:' + window.exercise_iframe_preview_font_family + '; font-size:14px; '
		if (this.prompt) html += `<div style="${prompt_style}"><b>Prompt:</b> ${U.render_latex(this.prompt)}</div>`

		html += sr('<div style="width:300px; height:225px; margin:10px auto; padding:5px; background-color:#000; border-radius:8px; cursor:pointer;" onclick="window.open(\'$1\')"><img src="$2" style="width:100%;height:100%;"></div>', this.video_url(), this.video_thumbnail_url())

		let action_btn_text = 'EDIT VIDEO PARAMETERS'

		return this.iframe_element(exercise_id, editor_identifier, 'Required Video', action_btn_text, html)
	}

	generic_label() { return 'Video' }
}
window.Video_Query = Video_Query

class Interactive_Reading_Query extends Query {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)

		// this is a "hidden" query that we use to keep track of how long the student reads the text prior to clicking the button to reveal queries
		this.type = 'ir'
		sdp(this, data, 'stars_available', 0)
	}

	editor_element(editing) {
		return ''
	}

	generic_label() { return 'Interactive Reading' }
}
window.Interactive_Reading_Query = Interactive_Reading_Query

class Basic_Content_Query extends Query {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)

		// this is a "hidden" query that we use to give students a star for completing a part that has no queries
		this.type = 'bc'
		sdp(this, data, 'stars_available', 1)

		// normally, we take the basic content query out if/when a basic content part gets a query. but if the teacher explicitly sets the number of stars for the part, we make it 'permanent'
		// this is so that a) they can set it to 1 star; and b) so if they remove it and later bring it back, student results coded against the query uuid will still be valid
		sdp(this, data, 'permanent', false)
	}

	editor_element(editing) {
		return ''
	}

	generic_label() { return 'Part Completion' }
}
window.Basic_Content_Query = Basic_Content_Query

class Annotation_Query extends Query {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)

		this.type = 'annotation'

		// if we have annotation_default_stars_available available in store.lst, use it 
		let default_stars_available = (vapp.$store.state.lst.annotation_default_stars_available ?? 4) * 1
		sdp(this, data, 'stars_available', default_stars_available)

		sdp(this, data, 'model_annotations', {})	// this will be a hash, indexed by part uuid, with each value of the hash an object of the form { highlights: '', notes: {} }
		sdp(this, data, 'model_highlight_text', [])	// this will be an array of arrays of strings -- one array of strings for each exercise part (here we don't need to index by part uuid)
	}

	editor_element(editing) {
		return ''
	}

	// return Boolean whether or not the query includes any model annotations
	has_model_annotations() {
		for (let uuid in this.model_annotations) {
			if (!empty(this.model_annotations[uuid].highlights)) return true
			// note that you can't have a note without a corresponding highlight, so we don't have to check .notes
		}
		return false
	}

	highlight_text_html(highlight_text) {
		// if highlight_text is empty, use this.model_highlight_text; otherwise use what was passed in (e.g. a student's highlight_text)
		if (empty(highlight_text)) highlight_text = this.model_highlight_text
		let s = ''
		for (let i = 0; i < highlight_text.length; ++i) {
			if (i > 0) s += '<span class="mx-2">|</span>'

			for (let j = 0; j < highlight_text[i].length; ++j) {
				if (j > 0) s += '<span> / </span>'

				let hltext = highlight_text[i][j]
				let color = 'orange'
				if (hltext.search(/^(\w+)::(.*)/) > -1) {
					color = RegExp.$1
					hltext = RegExp.$2
				}
				s += `<span class="k-highlighted k-highlighted-${color}">${hltext}</span>`
			}
		}
		return s
	}

	highlight_text_raw(highlight_text) {
		// if highlight_text is empty, use this.model_highlight_text; otherwise use what was passed in (e.g. a student's highlight_text)
		if (empty(highlight_text)) highlight_text = this.model_highlight_text
		let s = ''
		for (let i = 0; i < highlight_text.length; ++i) {
			for (let j = 0; j < highlight_text[i].length; ++j) {
				s += highlight_text[i][j].replace(/^(\w+)::(.*)/, '$2') + ' '
			}
		}
		return $.trim(s)
	}

	generic_label() { return 'Annotation' }
}
window.Annotation_Query = Annotation_Query

class IR_Game_Query extends Query {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)

		this.type = 'irgame'
		sdp(this, data, 'stars_available', 20)
	}

	editor_element(editing) {
		// this query doesn't appear in the exercise body
		''
	}

	generic_label() { return 'Rapid Reading Game' }
}
window.IR_Game_Query = IR_Game_Query

///////////////////////////////////////////////////////
class Activity_Display_Settings {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'sound_effects', 'on', ['on', 'off'])
		sdp(this, data, 'colors', 'more', ['more', 'less'])
		sdp(this, data, 'background_color', 'grey', ['grey', 'red', 'purple', 'blue', 'sky', 'teal', 'grass', 'pink', 'sun', 'white'])
		sdp(this, data, 'sparkl_bot', 'on', ['on', 'off'])
		sdp(this, data, 'font_size', 'smaller', ['bigger', 'smaller'])
		sdp(this, data, 'star_noun', 'star', ['star', 'point'])
		sdp(this, data, 'text_color', 'black', ['black', 'white'])
		sdp(this, data, 'text_font', 'serif', ['sans_serif', 'serif'])
		sdp(this, data, 'high_scores', 'on', ['on', 'off'])
		// sdp(this, data, 'text_to_speech', 'off', ['off', 'on'])

		// 4/3/2023: changed from "score_tracker" to "score_tracker_value", with default set to 'default', meaning that we determine this based on the completion_mode setting
		sdp(this, data, 'score_tracker_value', 'default', ['default', 'stars', 'completion'])
	}

	static game_mode_settings(game_mode_on) {
		if (game_mode_on) return {
			colors: 'more',
			sound_effects: 'on',
			sparkl_bot: 'on',
		} 
		return {
			colors: 'less',
			sound_effects: 'off',
			sparkl_bot: 'off',
		}
	}

	static score_tracker_computed_value(score_tracker_value, completion_mode) {
		if (score_tracker_value == 'default' || empty(score_tracker_value)) {
			if (completion_mode == 'yes' || completion_mode === true) return 'completion'
			else return 'stars'
		} else {
			return score_tracker_value
		}
	}
}
window.Activity_Display_Settings = Activity_Display_Settings

///////////////////////////////////////////////////////
class Activity_Status {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'started', false)
		sdp(this, data, 'complete', false)
		sdp(this, data, 'stars_available', 0)
		sdp(this, data, 'stars_attempted', 0)
		sdp(this, data, 'stars_earned', 0)
		sdp(this, data, 'time_spent', 0)

		sdp(this, data, 'initial_stars_earned', 0)
		
		sdp(this, data, 'teacher_grading_required', false)

		this.activity_display_settings = new Activity_Display_Settings(data.activity_display_settings)
		sdp(this, data, 'activity_display_settings_changed', 'no', ['no', 'yes'])

		// to make it easy to load from the DB, we store the user's screen_name in astatus
		sdp(this, data, 'screen_name', '')

		// record of messages (currently ones coming from the teacher; possibly more in the future) that the student has dismissed
		sdp(this, data, 'messages_dismissed', [])

		// experiment_mode: em_phase is:
		// 0 if experiment_mode is off or the student hasn't been assigned
		// 1 if experiment_mode is on and the student has been assigned to the "experiment" group (e.g. normal Sparkl)
		// 2 if experiment_mode is on, the student has just been assigned to the "control" group (no initial interactive reading),
		//   but the student hasn't started the first exercise
		// 3 if experiment_mode is on, the student has been assigned to the "control" group, and the student has started going through the exercises the first time
		// 4 if experiment mode is on, the student is in the control group, they've completed the first pass through the exercises,
		//   and now they're getting the interactive reading (or they're done)
		// the logic that uses this is in Exercise.vue
		sdp(this, data, 'em_phase', 0)
		if (this.em_phase > 0) {
			// for students in the control group, create a space to stash the amount of time the student initially spent reading
			sdp(this, data, 'em_read_time', 0)
		}
	}

	calculate_stars_earned(arr) {
		// arr may be an array of Exercise_Status records themselves, or Exercise objects that each include an Exercise_Status record

		for (let ex of arr) {

		}

	}
}
window.Activity_Status = Activity_Status

class Exercise_Status {
	// `status_data` is a previously-saved status object for a student that we're restoring
	// `exrecord` is the exercise record; we need it to construct default values
	constructor(status_data, exrecord) {
		let received_status_data = !empty(status_data)
		if (empty(status_data)) status_data = {}
		if (empty(status_data.pstatus)) status_data.pstatus = []
		if (empty(status_data.qstatus)) status_data.qstatus = {}
		if (empty(status_data.game_status)) status_data.game_status = {}

		sdp(this, status_data, 'active', false)
		sdp(this, status_data, 'started', false)
		sdp(this, status_data, 'complete', false)
		sdp(this, status_data, 'stars_earned', 0)
		sdp(this, status_data, 'stars_attempted', 0)
		sdp(this, status_data, 'time_spent', 0)
		sdp(this, status_data, 'initial_stars_earned', 0)
		sdp(this, status_data, 'retrying', false)
		sdp(this, status_data, 'retries', 0)
		sdp(this, status_data, 'vars', '')

		// status for exercise parts
		this.pstatus = []
		for (let i = 0; i < exrecord.parts.length; ++i) {
			let o = new window.Exercise_Part_Status(status_data.pstatus[i], i)
			this.pstatus.push(o)
		}

		// status for exercise queries; note that we always calculate stars_available and stars_earned fresh each time this is run, because queries might have been edited...
		this.stars_available = 0
		this.stars_earned = 0
		this.qstatus = {}
		for (let uuid in exrecord.queries) {
			let qr = exrecord.queries[uuid]
			if (empty(qr)) continue	// this probably indicates a query that was updated

			let qs
			if (qr.type == 'hangman') qs = new window.Hangman_Status(status_data.qstatus[uuid])
			else if (qr.type == 'mc') qs = new window.MC_Status(status_data.qstatus[uuid])
			else if (qr.type == 'numeric') qs = new window.Numeric_Status(status_data.qstatus[uuid])
			else if (qr.type == 'cr') qs = new window.CR_Status(status_data.qstatus[uuid])
			else if (qr.type == 'video') qs = new window.Video_Status(status_data.qstatus[uuid])
			else if (qr.type == 'ir') qs = new window.Interactive_Reading_Status(status_data.qstatus[uuid])
			else if (qr.type == 'bc') qs = new window.Basic_Content_Status(status_data.qstatus[uuid])
			else if (qr.type == 'annotation') qs = new window.Annotation_Status(status_data.qstatus[uuid])
			else if (qr.type == 'flashcard') qs = new window.Flashcard_Status(status_data.qstatus[uuid])
			else if (qr.type == 'qq') qs = new window.Quiz_Query_Status(status_data.qstatus[uuid], qr.mode)
			else if (qr.type == 'irgame') qs = new window.IR_Game_Status(status_data.qstatus[uuid])
			else if (!empty(window[qr.type + '_Status'])) qs = new window[qr.type + '_Status'](status_data.qstatus[uuid])
			else qs = new window.Misc_Status(status_data.qstatus[uuid])

			if (received_status_data && qr.type == 'ir' && qr.competency == 'legacy' && empty(status_data.qstatus[uuid])) {
				// LEGACY: activities created within a certain time window don't have proper interactive reading queries saved, so we create an IR query on the fly above
				if (this.started) {
					// ...so if we didn't get a qstatus entry for such queries, and the exercise is started, assume that the student did actually start and complete the query
					qs.started = true
					qs.complete = true
				}
			}

			// for ir_game exercises, skip hangman queries
			if (exrecord.exercise_type == 'ir_game' && qr.type == 'hangman') {
			} else {
				this.stars_available += qr.stars_available

				// calculate exstatus.stars_earned from qstatus.stars_earned, in case exercises get updated while students are taking them
				this.stars_earned += qs.stars_earned
			}

			// for some query types, ...
			if (['hangman', 'mc', 'numeric', 'video', 'qq'].includes(qr.type)) {
				// if the query version has changed since the student worked on it...
				if (qr.version != qs.query_version) {
					// if the query status is complete, give the student full credit -- if the teacher changed the query, there must have been some problem with it, so give the student the benefit of the doubt and say they would have gotten it correct if the query didn't have that issue
					// note that if a teacher wants to make sure all students re-do the query, they can delete and re-create the query, so that it has a new uuid
					if (qs.complete) {
						console.log(`CORRECTING query stars_earned from ${qs.stars_earned} to ${qr.stars_available} [query ${qr.uuid}]`)
						// also set initial_stars_earned to the original stars_earned (unless it was already set)
						if (qs.initial_stars_earned == -1) qs.initial_stars_earned = qs.stars_earned
						qs.stars_attempted = qs.stars_earned = qr.stars_available
					
					// if the query wasn't complete but was started, reset the query
					} else {	// if (qs.started) {
						if (qr.type == 'hangman') qs = new window.Hangman_Status(null)
						else if (qr.type == 'mc') qs = new window.MC_Status(null)
						else if (qr.type == 'numeric') qs = new window.Numeric_Status(null)
						else if (qr.type == 'video') qs = new window.Video_Status(null)
						else if (qr.type == 'qq') qs = new window.Quiz_Query_Status(null, qr.mode)
					}
				}
			}

			// set qs.query_version to the current version (whether this is a new status or it was updated above)
			qs.query_version = qr.version

			this.qstatus[uuid] = qs
		}

		// calculate queries_complete, n_queries, stars_earned, and stars_attempted
		this.queries_complete = 0
		let n_queries = 0
		let stars_earned = 0
		let stars_attempted = 0
		for (let uuid in this.qstatus) {
			if (this.qstatus[uuid].complete) ++this.queries_complete
			stars_earned += this.qstatus[uuid].stars_earned
			stars_attempted += this.qstatus[uuid].stars_attempted
			++n_queries
		}

		// now for freeform exercises (which includes interactive reading), do some checks to try to correct things that might get out of whack, e.g. if the teacher has updated the exercise since the student was last active
		// for freeform types, check part complete/showing records (we might want to do this for other types, but I'm not sure enough at this point to commit to that -- 2022/01/19)
		if (exrecord.exercise_type == 'freeform') {
			for (let i = 0; i < this.pstatus.length; ++i) {
				let part_record = exrecord.parts[i]
				let o = this.pstatus[i]

				// for IR parts, if at least one query is started, make sure that the IR query is complete (the IR query's id might change if the exercise is edited)
				if (part_record.part_type == 'interactive_reading') {
					let at_least_one_query_complete = false
					let ir_query_uuid
					for (let uuid in this.qstatus) {
						if (exrecord.queries[uuid].part_index == i) {
							if (exrecord.queries[uuid].type == 'ir') ir_query_uuid = uuid
							else if (this.qstatus[uuid].complete) at_least_one_query_complete = true
						}
					}
					// if at least one query is complete...
					if (at_least_one_query_complete) {
						if (ir_query_uuid) {
							// then if the IR query *isn't* complete, set it to complete
							if (!this.qstatus[ir_query_uuid].complete) {
								console.log(`CORRECTING IR QUERY COMPLETE (setting to true) for ex ${exrecord.exercise_id}, part ${i}`)
								this.qstatus[ir_query_uuid].complete = true
							}
						} else {
							// if this happens, something is wrong; there should always be an ir query
							console.log('!! at_least_one_query_complete is true, but didn’t have an ir_query_uuid')
						}
					
					// else no queries are complete...
					} else {
						// so set this part's pstatus.queries_available to false, so that the user will see the button to initiate the first query
						// if we don't do this, the user gets stuck -- they can't click anything to see the first query
						if (this.pstatus[i].queries_available) {
							this.pstatus[i].queries_available = false
							console.log(`CORRECTING PSTATUS.QUERIES_AVAILABLE (setting to false) for ex ${exrecord.exercise_id}, part ${i}`)
						}
					}
				}

				// if all the part's queries are complete, the part should be complete (and showing), because all freeform parts must have at least a query (at the least they should have a Basic_Content_Query)
				let all_queries_complete = true
				for (let uuid in this.qstatus) {
					if (exrecord.queries[uuid].part_index == i && !this.qstatus[uuid].complete) {
						all_queries_complete = false
					}
				}
				if (all_queries_complete) {
					if (!o.complete) console.log(sr('CORRECTING PSTATUS.COMPLETE (setting to true) for ex $1, part $2', exrecord.exercise_id, i))
					o.showing = true
					o.complete = true
				}

				// if this isn't part 1 and it isn't showing, and all previous parts are complete, this part should be showing
				if (i > 0 && !o.showing) {
					let all_previous_parts_complete = true
					for (let j = 0; j < i; ++j) {
						if (!this.pstatus[j].complete) {
							all_previous_parts_complete = false
						}
					}
					if (all_previous_parts_complete) {
						if (!o.showing) console.log(sr('CORRECTING PSTATUS.SHOWING for ex $1, part $2', exrecord.exercise_id, i))
						o.showing = true
					}
				}
			}
		}

		// correct exercise stars_earned/stars_attempted if needed
		if (this.stars_earned != stars_earned) {
			console.log(`CORRECTING EXSTATUS.STARS_EARNED (was ${this.stars_earned}, now ${stars_earned}) for ex ${exrecord.exercise_id}`)
			this.stars_earned = stars_earned
		}
		if (this.stars_attempted != stars_attempted) {
			console.log(`CORRECTING EXSTATUS.STARS_ATTEMPTED (was ${this.stars_attempted}, now ${stars_attempted}) for ex ${exrecord.exercise_id}`)
			this.stars_attempted = stars_attempted
		}

		// if all queries are complete but the exercise isn't being counted as complete -- or at least one query is not complete but the exercise is being counted as complete -- correct exstatus.complete here
		if (n_queries > 0 && this.queries_complete >= n_queries) {
			if (!this.complete) console.log("CORRECTING EXSTATUS.COMPLETE (to true) for ex " + exrecord.exercise_id)
			this.complete = true
		} else {
			if (this.complete) console.log("CORRECTING EXSTATUS.COMPLETE (to false) for ex " + exrecord.exercise_id)
			this.complete = false
		}

		// exercise types can have extra fields
		if (exrecord.exercise_type == 'sb_game') {
			sdp(this, status_data, 'likes_given', [])	// user_ids of other students whose responses this student liked
			sdp(this, status_data, 'initial_validator_score', -1)
			sdp(this, status_data, 'final_validator_score', -1)

		} else if (exrecord.exercise_type == 'ir_game') {
			sdp(this, status_data, 'query_completion_count', {})
			sdp(this, status_data, 'reading_done_timestamp', 0)
			sdp(this, status_data, 'answering_done_timestamp', 0)
			// things that we want to go to other students for high score lists go in game_status
			this.game_status = {}
			sdp(this.game_status, status_data.game_status, 'game_stage', 'not_yet_open')	// 'not_yet_open', 'reading', 'answering', 'game_over'
			sdp(this.game_status, status_data.game_status, 'score', 0)
		}
	}
}
window.Exercise_Status = Exercise_Status

class Exercise_Part_Status {
	constructor(data, i) {
		if (empty(data)) data = {}

		// first part is always showing
		let default_showing = (i == 0) ? true : false
		sdp(this, data, 'showing', default_showing)
		// a part can be showing but not yet active -- i.e. in a video exercise -- but by default all parts are active as soon as they're shown
		sdp(this, data, 'active', true)
		sdp(this, data, 'sr_dnd', false)	// for scaffolded response queries: true if student has chosen to use drag and drop
		sdp(this, data, 'skipped', false)
		sdp(this, data, 'queries_available', false)
		sdp(this, data, 'complete', false)
	}
}
window.Exercise_Part_Status = Exercise_Part_Status

class Query_Status {
	constructor(data) {
		if (empty(data)) data = {}
		// this.uuid = data.uuid

		sdp(this, data, 'available', false)
		sdp(this, data, 'active', false)
		sdp(this, data, 'started', false)
		sdp(this, data, 'complete', false)
		sdp(this, data, 'stars_earned', 0)
		sdp(this, data, 'stars_attempted', 0)
		sdp(this, data, 'initial_stars_earned', -1)		// -1 means the query hasn't been retried, so it's the same as stars_earned
		sdp(this, data, 'time_spent', 0)
		sdp(this, data, 'query_version', 0)

		// TODO: keep track of original_stars_earned
	}
}
window.Query_Status = Query_Status

class Misc_Status extends Query_Status {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)
		// copy all non-standard props from data; if the query has a defined type, that type constructor will supercede this
		let o = this.get_custom_props(data)
		for (let prop in o) {
			this[prop] = o[prop]
		}
	}

	get_custom_props(o) {
		let new_o = {}
		for (let prop in o) {
			if (['available', 'active', 'started', 'complete', 'stars_earned', 'initial_stars_earned', 'time_spent'].indexOf(prop) == -1) {
				new_o[prop] = o[prop]
			}
		}
		return new_o
	}
}
window.Misc_Status = Misc_Status

class Hangman_Status extends Query_Status {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)

		sdp(this, data, 'content', '')
		sdp(this, data, 'prefilled_letters', [])
		sdp(this, data, 'letter_choices', [])
		sdp(this, data, 'guesses', [])
		sdp(this, data, 'initial_guesses', [])
		sdp(this, data, 'last_guess_incorrect', false)

		// for auto-blank hangman queries
		sdp(this, data, 'word', '')
		sdp(this, data, 'index', -1)
	}
}
window.Hangman_Status = Hangman_Status

class Numeric_Status extends Query_Status {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)

		sdp(this, data, 'numeric_guess', '')			// student's initially-entered guess using the keyboard, e.g. "6" or "1/4"
		sdp(this, data, 'numeric_correct', false)				// true or false
		sdp(this, data, 'chosen_choice_index', null)			// MC choice, also used for srdd
		sdp(this, data, 'all_chosen_choices', [])			// subsequent MC choices, if any have been made
		sdp(this, data, 'initial_numeric_guess', '')
		sdp(this, data, 'initial_numeric_correct', false)
	}
}
window.Numeric_Status = Numeric_Status

class MC_Status extends Query_Status {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)

		sdp(this, data, 'choice_order', [])				// null or integer
		sdp(this, data, 'chosen_choice_index', null)	// null or integer
		sdp(this, data, 'correct', false)				// true or false
		sdp(this, data, 'all_chosen_choices', [])
		sdp(this, data, 'initial_chosen_choice_index', null)	// null or integer
		sdp(this, data, 'initial_correct', false)				// true or false
		sdp(this, data, 'srdd_response', '')	// used for sr dragndrop
	}
}
window.MC_Status = MC_Status

class CR_Status extends Query_Status {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)

		sdp(this, data, 'saved', false)
		sdp(this, data, 'response', '')
		sdp(this, data, 'comment', '')
		sdp(this, data, 'teacher_scored', false)
		sdp(this, data, 'versions', 0)
		sdp(this, data, 'initial_response', '')		// if the student revises, save initial response here

		// response_submitted and model_queries_complete were added 6/17/2021. For legacy items, if complete is true, response_submitted must also be true. this.complete will have been set by super()
		if (this.complete) {
			this.response_submitted = true
		} else {
			sdp(this, data, 'response_submitted', false)
		}
		sdp(this, data, 'model_queries_complete', false)

		sdp(this, data, 'submission_timestamp', 0)
	}
}
window.CR_Status = CR_Status

class Video_Status extends Query_Status {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)

		sdp(this, data, 'max_time', 0)
	}
}
window.Video_Status = Video_Status

class Interactive_Reading_Status extends Query_Status {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)
	}
}
window.Interactive_Reading_Status = Interactive_Reading_Status

class Basic_Content_Status extends Query_Status {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)
	}
}
window.Basic_Content_Status = Basic_Content_Status

class Annotation_Status extends Query_Status {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)

		// store the user's initial annotations/highlight_text, even if they take two tries before finishing the query
		sdp(this, data, 'annotations', {})	// this will be a hash, indexed by part uuid, with each value of the hash an object of the form { highlights: '', notes: {} }
		sdp(this, data, 'highlight_text', [])	// this will be an array of arrays of strings -- one array of strings for each exercise part (here we don't need to index by part uuid)
		sdp(this, data, 'teacher_scored', false)

		// store here the try_1 status: empty (not yet completed), 'correct', or ('incorrect-hl' [highlighting was incorrect] or 'incorrect-arr' [highlighting was correct but arrows were incorrect])
		sdp(this, data, 'try_1_status', '', ['', 'correct', 'incorrect-hl', 'incorrect-arr'])

		// note: if try_1_status is true, the user must have annotations/highlights, because you can't submit without highlighting something
	}
}
window.Annotation_Status = Annotation_Status

class IR_Game_Status extends Query_Status {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)
	}
}
window.IR_Game_Status = IR_Game_Status


///////////////////////////////
class Flashcard_Query extends Query {
	constructor(data, exrecord) {
		if (empty(data)) data = {}
		super(data)

		this.type = 'flashcard'

		sdp(this, data, 'term', '')
		sdp(this, data, 'definition', '')
		// we used to allow for multiple sentences; now just do one
		if ($.isArray(data.sentences)) data.sentences = data.sentences[0]
		sdp(this, data, 'sentence', '')

		sdp(this, exrecord, 'required_mode', 'browse')

		// stars_available per query depends on required_mode…
		this.stars_available = 0
		if (exrecord.required_mode == 'browse') this.stars_available += 1	 // browse
		if (exrecord.required_mode == 'define') this.stars_available += 1	 // definition quiz
		if (exrecord.required_mode == 'term') this.stars_available += 1	 // term quiz
		if (exrecord.required_mode == 'sentence') this.stars_available += 1	 // sentence quiz
	}

	editor_element() {
		return ''
	}

	// parse incoming html to create a query
	from_html_definition(html) {

	}

	// create a standardized html definition for the query
	to_html_definition() {
	}

	generic_label() { return 'Flashcard Term' }
	report_label() { return 'Flashcard Term' }
	report_label_short() { return 'Flashcard' }
	report_abbreviation() { return 'F' }
}
window.Flashcard_Query = Flashcard_Query
