<template>
	<div @click="show_collection($event)" class="k-collection-tile" :class="collection_tile_css"
		:style="U.collection_color_style(tile_collection, false)">
		<div :style="{ 'color': U.get_contrast_color(U.get_collection_color(tile_collection)) }"
			class="k-collection-tile-title">
			<div v-html="collection_tile_title"></div>
		</div>
		<div class="k-collection-tile-message-count"
			v-if="unread_messages && (!course || !course.viewing_origin_teacher_activities)">
			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-icon v-on="on" color="white">far fa-comment</v-icon>
					<div v-on="on" class="k-collection-tile-message-count-text">{{ unread_messages }}</div>
				</template>
				<span v-html="unread_messages_tooltip"></span>
			</v-tooltip>
		</div>
		<div class="k-collection-tile-activity-count"
			v-if="activity_count && (!course || !course.viewing_origin_teacher_activities)">
			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<div v-on="on" class="k-cvlp-unit__status white">{{ activity_count }}</div>
				</template>
				<span v-html="activity_count_tooltip"></span>
			</v-tooltip>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
	components: {},
	props: {
		tile_collection: { type: Object, required: true },
		active_collection: { type: Object, required: true },
	},
	data() {
		return {
		}
	},
	computed: {
		...mapState(['user_info', 'all_courses', 'messages', 'my_activities', 'my_activity_results']),
		...mapGetters(['studentish_role', 'manage_assignments']),
		course() {
			// SF: This is just a convenience, as the methods migrated from FavoriteTile used 'course' instead of 'tile_colllection'
			// It can be cleaned up later with a find-replace on course to tile_collection
			return this.tile_collection
		},
		collection_tile_title() {
			let s = ''
			// start with icon indicating collection type
			if (this.tile_collection.collection_type == 'course') s += '<i class="fas fa-chalkboard"></i>'
			else if (this.tile_collection.collection_type == 'repo') s += '<i class="fas fa-diagram-project"></i>'
			else if (this.tile_collection.collection_type == 'pd') s += '<i class="fas fa-user-graduate"></i>'
			else s += '<i class="fas fa-cubes-stacked"></i>'
			// add <wbr> tags after slashes
			s += this.tile_collection.title.replace(/\//g, '/<wbr>')
			// if (tile_collection.active == 'no') {
			// 	s += '<br><div class="red mt-1 mx-auto" style="padding:2px; display:inline-block; font-weight:normal">Inactive</div>'
			// }
			return s
		},
		collection_tile_css() {
			if (!this.active_collection) return ''
			let s = ''

			if (this.active_collection.course_code == this.tile_collection.course_code) s += ' k-collection-tile-course-showing'

			if (this.tile_collection.title.length > 50) s += ' k-lp-tile-extra-long-title'
			else if (this.tile_collection.title.length > 30) s += ' k-lp-tile-long-title'
			if (this.tile_collection.active == 'no') s += ' k-collection-tile-inactive'

			return s
		},
		unread_messages() {
			let n = 0
			for (let message of this.messages) {
				if (message.course_code !== this.course.course_code) continue
				if (!message.is_read && message.message_id !== 0) ++n
			}
			return n
		},
		unread_messages_tooltip() {
			return sr('$1 unread $2', this.unread_messages, U.ps('message', this.unread_messages, 'messages'))
		},
		activity_count() {
			if (!this.manage_assignments) return 0
			let n = 0
			for (let activity of this.my_activities) {
				if (activity.course_code == this.course.course_code) {
					// for students, don't count complete activities or activities that aren't available
					if (this.studentish_role) {
						if (this.my_activity_results[activity.activity_id]?.complete() || !activity.available_to_students()) {
							continue
						}
						// for teachers, only count currently due activities that I've created
					} else {
						if (activity.creator_user_id != this.user_info.user_id) continue
						if (!activity.currently_due()) continue
					}
					++n
				}
			}
			return n
		},
		activity_count_tooltip() {
			if (this.studentish_role) {
				return sr('$1 incomplete $2', this.activity_count, U.ps('activity', this.activity_count, 'activities'))
			} else {
				return sr('$1 $2 currently available and due', this.activity_count, U.ps('activity', this.activity_count, 'activities'))
			}
		},
	},
	methods: {
		show_collection(event, unit_id) {
			if (empty(unit_id)) unit_id = 0

			// DEBUG: if you hold down the (mac) command and option key while clicking, just show the collection data in the console
			if (event.metaKey && event.altKey) {
				this.log_object(collection)
				return
			}
			let lp = this.all_courses.find(x => x.course_code == this.tile_collection.course_code)
			let path = lp.vue_route(unit_id)
			if (path != this.$router.currentRoute.path) {
				this.$router.push({ path: path })
			}
		},

		log_object(o) {
			let s = ''
			for (let key in o) {
				s += sr('$1: $2\n', key, JSON.stringify(o[key]))
			}
			console.log(s)
		},
	}
}
</script>

<style lang="scss">
.k-collection-tile {
	position: relative;
	cursor: pointer;
	text-align: left;
	display: flex;
	justify-content: center;
	flex-direction: row;
	align-items: center;
	width: 100px;
	height: 100px;
	min-width: 100px;
	border-radius: 16px;
	opacity: 0.6;
	margin: 0 6px;
	background-color: #f8f8f8;

	&.k-collection-tile-course-showing {
		background-color: #eee;
		opacity: 1.0;
		box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;

		.k-collection-tile-title {
			font-weight: bold;
		}
	}

	&.k-collection-tile-inactive {
		border: 2px dashed #ccc;
	}

	.k-collection-tile-title {
		display: flex;
		align-items: center;
		color: #fff;
		flex: 1 1 100px;
		height: 100px;
		border-radius: 16px;
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		padding: 8px;

		.fas {
			font-size: 11px;
			margin-right: 4px;
		}
	}

	.k-collection-tile-message-count {
		position: absolute;
		left: 8px;
		bottom: 3px;

		>.v-icon {
			font-size: 18px;
			transform: scaleX(0.85) // this makes it so the bubble is a circle
		}

		.k-collection-tile-message-count-text {
			position: absolute;
			display: inline-block;
			font-size: 9px;
			top: 7px;
			left: 0;
			right: 0;
			text-align: center;
			font-weight: 900;
			color: #fff;
		}
	}

	.k-collection-tile-activity-count {
		position: absolute;
		right: 6px;
		bottom: 6px;

		.k-cvlp-unit__status {
			display: inline-block;
			font-size: 8px;
			width: 16px;
			height: 16px;
			line-height: 16px;
			border-radius: 16px;
			text-align: center;
			font-weight: 900;
			margin: 0 1px;
		}
	}
}
</style>
