class Coteacher {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'coteacher_id', 0)
		sdp(this, data, 'coteacher_user_id', 0)
		sdp(this, data, 'origin_teacher_user_id', 0)
		sdp(this, data, 'first_name', '')
		sdp(this, data, 'last_name', '')
		sdp(this, data, 'email', '')
		sdp(this, data, 'permission_level', 0)
		sdp(this, data, 'course_code', '')
	}
}

window.Coteacher = Coteacher
