<template>
	<v-dialog v-model="dialog_open" max-width="600" persistent scrollable>
		<v-card>
			<v-card-title style="border-bottom:1px solid #999"><b>Content Item Feedback</b></v-card-title>
			<v-card-text style="font-size:16px">
				<div style="font-size:14px; line-height:18px;">
					<div v-if="item" class="mt-1"><b>Content item title:</b> <span v-html="item_title"></span></div>
					<div v-if="learning_progression" class="mt-2"><b>Learning Progression/Collection title:</b> <span v-html="learning_progression.title"></span></div>
					<div v-if="lp_unit" class="mt-2"><b>Unit/Sub-collection title:</b> <span v-html="lp_unit.title"></span></div>
				</div>
				<div class="">
					<div class="mt-5 k-issue-report-textarea-wrapper">
						<div class="mb-1">Please enter your feedback about this content item:</div>
						<v-textarea outlined hide-details dense label="" v-model="description" auto-grow rows="2" background-color="#fff" style="line-height:12px"></v-textarea>
					</div>
					<div class="mt-3">
						<div class="mb-2">If you are having an issue with the content, please take a screenshot of the issue and upload it here if possible:</div>
						<div class="text-center mx-auto" style="max-width:420px;">
							<v-btn v-if="!screenshot" small color="primary" @click="show_image_paster"><v-icon small class="mr-1">fas fa-paste</v-icon>Paste Image</v-btn>
							<img v-if="screenshot" :src="screenshot" class="elevation-2" style="width:100%" />
							<div v-if="screenshot">
								<v-btn small color="secondary" @click="show_image_paster"><v-icon small class="mr-1">fas fa-paste</v-icon>New</v-btn>
								<v-btn small color="secondary" class="ml-2" @click="clear_image"><v-icon small class="mr-1">fas fa-times</v-icon>Clear</v-btn>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-5 text-center" v-if="description"><b class="pink--text text--darken-3">Click “SUBMIT FEEDBACK” to record your feedback and report it to a {{site_config.app_name}} administrator.</b></div>
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-btn color="secondary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-times</v-icon>Cancel</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary" :disabled="!description" @click="submit_issue">Submit Feedback<v-icon small class="ml-2">fas fa-check</v-icon></v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

// This assessment doesn't seem to be working. The quick red fox jumps over the lazy brown dog. I'm just making this longer to see how it fits in the table. (Note that this isn't an actual issue!)

export default {
	props: {
		item: { required: false, default() { return ''} },
		learning_progression: { required: false, default() { return ''} },
		lp_unit: { required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		description: '',
		screenshot: '',
	}},
	computed: {
		...mapState(['user_info', 'site_config']),
		...mapGetters([]),
		item_title() {
			// item could be a lesson or resource
			if (this.item.lesson_title) return this.item.lesson_title
			else return this.item.description
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		show_image_paster() {
			let cancel_paster = x => {
				$(document).off('paste.show_image_paster')
				this.$cancelDialogs()
			}

			$(document).on('paste.show_image_paster', e => {
				// console.log('in onpaste...', e)
				e.preventDefault()

				let items = oprop(e, 'originalEvent', 'clipboardData', 'items')
				let IMAGE_MIME_REGEX = /^image\/(p?jpeg|gif|png)$/i;
				if (!empty(items) && items.length > 0 && IMAGE_MIME_REGEX.test(items[0].type)) {
					let data_url = U.create_image_data_url(items[0].getAsFile(), {
						max_width: 1000,
						max_height: 1000,
						compression_level: 0.75,
						callback_fn: o=>{
							// console.log('got the image!', o.img_url.substr(0,100))
							// console.log(o.img_url)
							this.screenshot = o.img_url
							cancel_paster()
						}
					})
				} else {
					this.$alert('You didn’t paste an image! Try again, or cancel.')
				}
			})

			this.$alert({
			    title: 'Feedback Screenshot',
			    text: 'Copy to your clipboard a screenshot of the image, then paste (cmd-V [Mac] or ctrl-V [Windows]) the image here.',
			    acceptText: 'Cancel',
				// dialogMaxWidth: 800
			}).then(y => {
				cancel_paster()
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		clear_image() {
			this.screenshot = ''
		},

		submit_issue() {
			if (!this.description) {
				this.$alert('Please enter your feedback.')
				return
			}

			// submit to service
			let payload = {
				site_issue_id: 0,
				user_id: this.user_info.user_id,
				creator_user_id: this.user_info.user_id,
				status: 'submitted',
				description: this.description,
				screenshot: this.screenshot,
			}
			if (this.item) {
				if (this.item.lesson_id) {
					payload.asset_type = 'lesson'
					payload.asset_id = this.item.lesson_id
				} else {
					payload.asset_type = 'resource'
					payload.asset_id = this.item.resource_id
				}
			}
			if (this.learning_progression) payload.course_code = this.learning_progression.course_code
			if (this.lp_unit) payload.lp_unit_id = this.lp_unit.lp_unit_id

			// U.loading_start()
			// setTimeout(x=>{
			// 	U.loading_stop()
			// 	this.$alert('Your feedback has been submitted. Thanks!').then(x=>this.$emit('dialog_cancel'))
			// }, 200)

			// TODO: to implement...
			U.loading_start()
			U.ajax('save_site_issue', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					this.$alert('Error submitting issue.')
					return
				}
				this.$alert('Your feedback has been submitted. Thanks!').then(x=>this.$emit('dialog_cancel'))
			});
		},
	}
}
</script>

<style lang="scss">
.k-issue-report-textarea-wrapper textarea {
	font-size:14px!important;
	line-height:18px!important;
	margin-bottom:6px;
}
</style>
