<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<div v-if="maximized" class="k-sparkl-overlay-scrim"></div>
	<div :class="maximized?'k-sparkl-maximized':''">
		<div class="k-sparkl-inline-outer">
			<iframe :name="'google_iframe-'+google_embed_id" class="k-sparkl-inline-iframe"></iframe>
		</div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	// components: { TemplateComponent },
	props: {
		activity: { required: true },
		initial_content: { required: false, default() { return '' } },	// this can be sent in to create an activity with the given initial content
		context: { required: false, default() { return 'view' } },		// the other current option is 'edit'; not currently doing anything, but inherited from sparkl
		force_maximize: { required: false, default() { return false } },
		role_override: { required: false, default() { return '' } },	// send in 'student' to force the student view
	},
	data() { return {
		google_embed_id: U.new_uuid(),
		maximized: this.force_maximize,
	}},
	computed: {
		...mapState(['user_info', 'single_item']),
		...mapGetters(['studentish_role']),
		activity_id() { return this.activity.activity_id },
		tool_activity_id() { return this.activity.tool_activity_id },
		lti_resource_link_id() { return this.activity.lti_resource_link_id },
		activity_title() { return this.activity.activity_title },
		lti_context_id() {
			// for activities created after ~8/4/2023, the activity will include the lti_context_id_hc to use
			if (!empty(this.activity.lti_context_id_hc)) return this.activity.lti_context_id_hc
			// if not there, use this older version
			return 'henryconnects_' + this.activity.creator_user_id
		},
		lti_context_title() {
			// for now return this as the class title
			return `HenryConnects LTI Context for user ${this.activity.creator_user_id}`;
		},
		openable_in_new_window() {
			// if we're already open in single_item mode, don't show the button again
			if (this.single_item) return false

			// in edit context, we need to communicate with the HC frame, so don't allow this
			if (this.context == 'edit') return false

			// keep students focused on a single activity
			if (this.studentish_role) return false

			// if we get to here allow to be opened in a new tab
			return true
		},
	},
	watch: {
	},
	created() {
		if (this.force_maximize) {
			this.$store.commit('set', ['activity_embed_maximized', true])
			$('html').css('overflow', 'hidden')
			vapp.toggle_google_translate_btn('hide')

			// control bar is in App.vue so that it can appear above everything else
			vapp.$refs.sparkl_embed_control_bar.show(this, this.context)
		}
	},
	mounted() {
		window['google_iframe-'+this.google_embed_id].document.write(`<div style="margin:24px; text-align:center; font-family:Roboto, sans-serif; font-size:18px;"><b>Loading Google Assignment...</b></div>`)
		this.lti_launch()
	},
	methods: {
		lti_launch() {
			U.loading_start()
			let payload = { 
				email: this.user_info.email, 
				context_id: this.lti_context_id,
				context_title: this.lti_context_title,
				activity_id: this.activity_id,
			}
			// if this student's results need to go into the IC gradebook, add relevant params
			this.activity.add_gradebook_data_to_payload(payload)

			// debug
			// payload.debug_google_resource_launches = 'yes'

			U.ajax('get_google_lti_launch', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					vapp.ping()		// call ping to check if the session is expired
					vapp.$alert('An error occurred when attempting to open the Google Assignment.')
					return
				}

				// console.log('got lti_form:', result.lti_form)
				// we should return back the lti launch form; write it out to the iframe
				window['google_iframe-'+this.google_embed_id].document.write(result.lti_form)
				// let gw = window.open()
				// gw.document.write(result.lti_form)
			});
		},

		reload_iframe() {
			this.lti_launch()
		},

		close_btn_clicked() {
			vapp.$refs.sparkl_embed_control_bar.hide()
			this.$store.commit('set', ['activity_embed_maximized', false])
			$('html').css('overflow', '')
			vapp.toggle_google_translate_btn('show')
			this.$emit('close_google')
		},

		open_in_new_window() {
			// currently the only situation where we allow opening in a new window is when you've clicked to admin the activity, so always send the flag to show the activity right away
			// (see ActivityView for where the search string gets parsed)
			let url = window.location.origin + '/activity/' + this.activity.activity_id + '?admin'
			window.open(url, 'google_tab-'+this.activity.activity_id)
		},
	},
}
</script>

<style lang="scss">
</style>