<template><div class="k-fwcc-doc-item" :class="document_class" @click.stop="view_framework">
	<div class="k-fwcc-doc-title text-left">
		<span class="k-fwcc-doc-title-text" v-html="doc.title"></span>
		<v-spacer />
		<div v-if="image_src" class="k-fwcc-framework-image-wrapper"><img :src="image_src" /></div>
	</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import goTo from 'vuetify/lib/services/goto'

export default {
	components: { },
	props: {
		framework_record: { type: Object, required: true },
		case_tree_lsdoc_identifier: { type: String, required: false, default() { return '' }},
	},
	data() { return {
	}},
	computed: {
		...mapState(['user_info']),
		doc() { return this.framework_record.json.CFDocument },
		color_class() {
			return U.framework_color(this.doc.identifier)
			// e.g. 'k-framework-color-0', which sets background-color to a light version; add -med, -dark, -border, or -text if necessary
		},

		document_class() {
			let s = 'k-fw-document-card-' + this.doc.identifier

			// add class if this framework's tree is currently showing, or if another framework's tree is showing
			if (this.doc.identifier == this.case_tree_lsdoc_identifier) {
				s += ' k-fwcc-doc-item-showing'	//  elevation-2'
			} else if (this.case_tree_lsdoc_identifier) {
				s += ' k-fwcc-doc-item-other-item-showing'
			}

			s += ' ' + this.color_class + '-dark'
			s += ' ' + this.color_class + '-text'

			return s
		},
		image_src() {
			return U.framework_image_src(this.framework_record.ss_framework_data)
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		view_framework() {
			vapp.$refs.satchel.execute('show').then(()=>{
				vapp.$refs.satchel.execute('load_framework', {framework_identifier:this.doc.identifier}).then(()=>{
				})
			})
		},
	}
}
</script>

<style lang="scss">
.k-fwcc-doc-item {
	padding:12px;
	margin:8px;
	border-radius:8px;
	width:300px;
	min-height:72px;
	cursor:pointer;
}

.k-fwcc-doc-title {
	font-size:16px;
	display:flex;
	align-items: top;
	line-height:18px;
	color:#fff;
}

.k-fwcc-doc-item:hover {
	.k-fwcc-doc-title-text {
		text-decoration:underline;
	}
}

.k-fwcc-doc-item-showing {
	.k-fwcc-doc-title-text {
		font-weight:bold;
	}
}

.k-fwcc-doc-item-other-item-showing {
	opacity:0.5;
}

.k-fwcc-framework-image-wrapper {
	width: 83px;
    height: 83px;
    border-radius: 6px;
    background-color: #fff;
	// background-color:rgba(255,255,255,0.9);
    padding: 3px;
	margin-left:8px;
    // margin: 4px 8px 0 8px;
	img {
		width: 77px;
		height:77px;
		border-radius:5px;
		opacity:0.8;
	}
}
</style>
