import '@/js/utilities.js'	// do this first so router et al have access to U functions
import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import highcharts from '@/plugins/highcharts'
import DialogPromise from '@/components/vuetify-dialog-promise-pwet'
Vue.use(DialogPromise)

// this makes our utility functions that are in the "U" global container accessble from within vue html properties -- i.e. `v-html="U.render_latex(component_part.lcp_teacher_content)"
Vue.prototype.U = U

import date from 'date-and-time'
window.date = date

// jquery
window.$ = require('jquery')
window.jQuery = window.$

import marked from 'marked'
window.marked = marked

// katex
// import 'katex/dist/katex.css'
// import katex from 'katex'
// window.katex = katex

// https://github.com/arnog/mathlive/blob/master/src/vue-mathlive.js
// npm i mathlive
import * as MathLive from 'mathlive/dist/mathlive.mjs';
window.MathLive = MathLive
Vue.use(MathLive);

import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)

// import froala component, custom froala fns, and FroalaWrapper component; to use: <froala-wrapper />
import froala from '@/components/FroalaWrapper/froala-plugin'
import FroalaWrapper from '@/components/FroalaWrapper/FroalaWrapper'
Vue.component('froala-wrapper', FroalaWrapper)

///////////////////
import VueDraggableResizable from '@/components/vue-draggable-resizable-spedcol'
import '@/components/vue-draggable-resizable-spedcol/components/vue-draggable-resizable.css'
Vue.component('vue-draggable-resizable', VueDraggableResizable)

import VueWorker from 'vue-worker'
Vue.use(VueWorker)
////////////////////

import VueVisible from 'vue-visible'
Vue.use(VueVisible)

import '@/js/google_translate.js'
import '@/js/textfill.js'
import '@/js/mathlive_core_css.js'
import '@/scss/district-portal-base.scss'
import '@/scss/case-framework-colors.scss'
import '@/scss/responsive.scss'

// data structures
import '@/js/user_info.js'
import '@/js/resources.js'
import '@/js/lessons.js'
import '@/js/directives.js'
import '@/js/messages.js'
import '@/js/courses.js'
import '@/js/learning_progressions.js'
import '@/js/links.js'
import '@/js/coteachers.js'

import '@/js/case_utilities.js'
import '@/js/case_data_structures.js'
import '@/js/framework_icons.js'
import '@/js/search_fns.js'

import '@/js/sample_data.js'

Vue.config.productionTip = false

new Vue({
	vuetify,
	store,
	router,
	render: h => h(App)
}).$mount('#app')
