<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><v-dialog v-model="dialog_open" persistent max-width="800px"><v-card>
	<v-card-title>
		<div>Add New User Record</div>
	</v-card-title>
	<v-card-text style="color:#000;">
		<div>Enter an email, first name, and last name to create a user record. The user will have standard “staff” rights; you can then give the user additional site priviledges if you wish.</div>
		<div class="mt-3"><v-text-field dense outlined hide-details label="Email Address" v-model="email"></v-text-field></div>
		<div class="mt-3"><v-text-field dense outlined hide-details label="First Name" v-model="first_name"></v-text-field></div>
		<div class="mt-3"><v-text-field dense outlined hide-details label="Last Name" v-model="last_name"></v-text-field></div>
	</v-card-text>
	<v-card-actions class="pl-4 pr-4 pb-4">
		<v-btn color="secondary" @click="cancel_clicked" class="mr-1"><v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
		<v-btn color="primary" @click="save_edits" class="k-tight-btn"><v-icon small class="mr-2">fas fa-check</v-icon> Create User Record</v-btn>
	</v-card-actions>
</v-card></v-dialog></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	props: {
	},
	data() { return {
		dialog_open: true,
		first_name: '',
		last_name: '',
		email: '',
	}},
	computed: {
		...mapState(['user_info']),
	},
	created() {
		// this.system_role = this.user.system_role
	},
	mounted() {
		// DEBUG
		vapp.user_edit = this
	},
	methods: {
		show_help() { vapp.show_help('roles_system') },

		save_edits(flag) {
			this.first_name = $.trim(this.first_name)
			this.last_name = $.trim(this.last_name)
			this.email = $.trim(this.email)
			if (empty(this.email) || empty(this.first_name) || empty(this.last_name)) {
				this.$alert('You must specify an email address and the first and last name for the user.')
				return
			}

			let payload = {
				user_id: this.user_info.user_id,
				email: this.email,
				first_name: this.first_name,
				last_name: this.last_name,
			}

			U.ajax('admin_create_user', payload, result=>{
				if (result.status != 'ok') this.$alert(result.status)
				else this.$alert('User record successfully created. The user can sign in using the “Magic Password.”')
			})
		},

		cancel_clicked() {
			this.$emit('editor_cancel')
		},
	}
}
</script>

<style lang="scss">
</style>
