class Link {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'href', '')
		sdp(this, data, 'text', '')
	}
}
window.Link = Link

class Announcement_Link extends Link {
	constructor(data) {
		if (empty(data)) data = {}
		super(data)

		sdp(this, data, 'date', '')
	}
}
window.Announcement_Link = Announcement_Link
