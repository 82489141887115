<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-page-wrapper">
	<div class="d-flex align-center">
		<v-icon @click="return_to_admin_main" large color="primary" class="mr-2">fas fa-file-code</v-icon>
		<h2 class="k-page-title">Lesson Masters</h2>
		<v-spacer />
	</div>

	<div v-if="lesson_masters" style="clear:both">
		<div>
			<div class="text-center mb-6"><v-btn color="primary" @click="create_new_lesson_master"><v-icon small class="mr-2">fas fa-plus</v-icon> Create New Lesson Master</v-btn></div>
			<div v-if="lesson_masters.length==0">You have not created any lesson masters.</div>
			<div v-else class="d-flex justify-center flex-wrap">
				<div v-for="(lesson) in lesson_masters" :key="lesson.lesson_id" class="k-admin-lesson-masters-lesson-card-wrapper ma-3"><v-card class="k-admin-lesson-masters-lesson-card">
					<v-card-text class="k-lesson-card-text">
						<div class="k-lesson-title"><b v-html="lesson.lesson_title"></b></div>
						<div v-for="(component) in lesson.lesson_plan" :key="component.uuid" class="k-lesson-component k-lesson-component-showing elevation-1">
							<div class="k-lesson-component-title">
								<div class="k-lesson-component-toggle-holder"><v-btn icon x-small color="indigo darken-2"><v-icon>fas fa-caret-down</v-icon></v-btn></div>
								<div class="k-lesson-component-title-text" :class="'k-lesson-component-title-text-'+component.lc_weight">{{component.lc_title}}</div>
								<div v-if="component.lc_weight!=0" class="k-lesson-component-title-weight" :style="'flex-basis:' + component.lc_weight + '%'">{{component.lc_weight}}%</div>
							</div>
							<div class="k-lesson-component-guidance" v-html="component.lc_guidance"></div>
							<div class="mt-3 k-lesson-component-default-content" v-if="component.lc_default_content">
								<div class="float-left mr-2"><b>“Looks like…”</b></div>
								<div v-html="component.lc_default_content"></div>
							</div>
						</div>
					</v-card-text>
					<v-card-actions class="pt-2 ma-0 pb-2">
						<v-btn color="red" dark @click="delete_lesson_master(lesson)"><v-icon small class="mr-2">fas fa-trash-alt</v-icon>Delete</v-btn>
						<v-spacer/>
						<v-btn color="primary" dark @click="edit_lesson_master(lesson)"><v-icon small class="mr-2">fas fa-edit</v-icon>Edit</v-btn>
					</v-card-actions>
				</v-card></div>
			</div>
		</div>

		<LessonEditorDialog v-if="lesson_being_edited" :original_lesson="lesson_being_edited" lesson_class="master" @edit_lesson_cancel="edit_lesson_cancel" @edit_lesson_saved="edit_lesson_saved" />

	</div>

</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import LessonEditorDialog from '../lessons/LessonEditorDialog'

export default {
	components: { LessonEditorDialog },
	props: {
	},
	data() { return {
		lesson_being_edited: null,
	}},
	computed: {
		...mapState(['user_info', 'lesson_masters']),
	},
	created() {
		this.$store.dispatch('get_lesson_masters', true)
	},
	mounted() {
	},
	methods: {
		edit_lesson_master(lesson) {
			this.lesson_being_edited = lesson
		},

		create_new_lesson_master() {
			this.lesson_being_edited = 'new'
		},

		edit_lesson_cancel() {
			U.ajax('reset_checked_out_for_edit', {user_id: this.user_info.user_id, lesson_id: this.lesson_being_edited.lesson_id})
			this.lesson_being_edited = null
		},

		edit_lesson_saved(edited_lesson) {
			// the lesson editor will have saved the lesson to the db; splice or push it to unit lessons
			let index = this.lesson_masters.findIndex(x=>x.lesson_id == edited_lesson.lesson_id)
			if (index == -1) {
				this.$store.commit('set', [this.lesson_masters, 'UNSHIFT', new Lesson(edited_lesson)])
			} else {
				this.$store.commit('set', [this.lesson_masters, 'SPLICE', index, new Lesson(edited_lesson)])
			}
		},

		delete_lesson_master(lesson) {
			this.$confirm({
			    title: 'Are you sure?',
			    text: sr('Are you sure you want to delete Lesson Master “$1”?', lesson.lesson_title),
			    acceptText: 'Delete',
				acceptColor: 'red',
			    cancelText: 'Cancel',
			}).then(y => {
				U.loading_start()
				U.ajax('admin_delete_lesson_master', {user_id: this.user_info.user_id, lesson_master_id: lesson.lesson_id}, result=>{
					U.loading_stop()
					if (result.status == 'master_has_children') {
						this.$alert('You cannot delete this Lesson Master, because lessons exist in the system that are based on it.')
						return
					}

					if (result.status != 'ok') {
						console.log('Error in admin ajax call'); vapp.ping(); return;
					}

					// delete from lesson_masters
					let index = this.lesson_masters.findIndex(x=>x.lesson_id == lesson.lesson_id)
					if (index > -1) {
						this.$store.commit('set', [this.lesson_masters, 'SPLICE', index])
					}
				});

			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		return_to_admin_main() {
			this.$router.push({ path: '/' })
		}
	}
}
</script>

<style lang="scss">
.k-admin-lesson-masters-lesson-card-wrapper {
	flex: 0 1 600px;
}

.k-admin-lesson-masters-lesson-card {
	border-radius:12px!important;
	padding:8px;
}

</style>
