<!--
This component is opened up from the CollectionUnitEdit component when adding a new instance unit
to a flex unit.
-->
<template>
	<div>
		<v-dialog v-model="dialog_open" max-width="900px" persistent scrollable
			class="k-collection-instance-unit-editor">
			<v-card>
				<v-card-title style="border-bottom:1px solid #999">
					<b>Edit Instance Unit</b>
				</v-card-title>
				<v-card-text class="mt-3" style="font-size:16px; color:#000;">
					<!-- instance unit title -->
					<v-row>
						<v-col cols="2">
							<b>Instance Unit Title:</b>
						</v-col>
						<v-col cols="9">
							<v-text-field v-model="instance_unit.title" background-color="#fff" dense hide-details
								outlined label="">
							</v-text-field>
						</v-col>
					</v-row>

					<!-- Access privileges, only available once instance unit exists in DB -->
					<div v-if="!is_new_instance_unit">
						<v-row>
							<v-col cols="2">
								<b>Manage Access:</b>
							</v-col>
							<v-col cols="9">
								<v-btn x-small class="k-tight-btn elevation-0" color="#555" dark
									@click="grant_access_to_user">
									<v-icon x-small class="mr-1">fas fa-plus</v-icon>
									Grant access to user
								</v-btn>
								<v-btn x-small class="k-tight-btn elevation-0" color="#555" dark
									@click="grant_access_to_domain">
									<v-icon x-small class="mr-1">fas fa-plus</v-icon>
									Grant access to domain
								</v-btn>
							</v-col>
						</v-row>
						<v-data-table :headers="headers" :items="rows">
							<template v-slot:item="{ item }">
								<tr>
									<td>
										<v-btn v-visible="!is_domain(item)" icon x-small class="mr-2"
											@click="edit_user(item)">
											<v-icon>fas fa-edit</v-icon>
										</v-btn>
										<v-btn icon x-small class="mr-2" @click="remove_user_access(item)">
											<v-icon>fas fa-trash</v-icon>
										</v-btn>
										<span v-html="item.user"></span>
									</td>
									<td><v-icon small v-if="item.admin">fas fa-check</v-icon></td>
									<td><v-icon small v-if="item.edit">fas fa-check</v-icon></td>
									<td><v-icon small v-if="item.view">fas fa-check</v-icon></td>
								</tr>
							</template>

						</v-data-table>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="secondary" @click="cancel_editor" class="mr-1">
						<v-icon small class="mr-2">fas fa-times</v-icon> Cancel</v-btn>
					<v-btn color="primary" @click="save_instance_unit">
						<v-icon small class="mr-2">fas fa-check</v-icon> Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<CollectionInstanceUnitUserEdit v-if="show_user_editor" :user="user_editing"
			:lp_unit_id="instance_unit.lp_unit_id" @user_editor_cancel="user_editor_cancel"
			@user_edit_saved="user_edit_saved" />
	</div>
</template>

<script>
import { mapState } from 'vuex'
import CollectionInstanceUnitUserEdit from './CollectionInstanceUnitUserEdit.vue'

export default {
	components: { CollectionInstanceUnitUserEdit },
	props: {
		collection: { type: Object, required: true },
		flex_unit: { type: Object, required: true },
		original_instance_unit: { type: Object, required: false },
	},
	data() {
		return {
			dialog_open: true,
			// create a new unit to edit here; then we copy it into the original_unit when user saves
			instance_unit: new LP_Unit(this.original_instance_unit),
			user_rights: [],
			domain_rights: [],
			headers: [
				{ text: 'User', align: 'left', sortable: true, value: 'user' },
				{ text: 'Admin', align: 'left', sortable: true, value: 'admin' },
				{ text: 'Edit', align: 'left', sortable: true, value: 'edit' },
				{ text: 'View', align: 'left', sortable: true, value: 'view' },
			],
			show_user_editor: false,
			user_editing: {},
		}
	},
	computed: {
		...mapState(['user_info']),
		is_new_instance_unit() {
			// If original_instance_unit was passed in as a prop, we are editing an existing instance unit
			// If not and we are working with the default empty object, then it is a brand new instance unit 
			if (!this.original_instance_unit) return true
			return Object.keys(this.original_instance_unit).length === 0;
		},

		rows() {
			const create_row = ({ admin_rights_id, identifier, last_name, first_name, email, rights }) => {
				const admin = rights.startsWith("admin")
				const edit = admin || rights.startsWith("edit")
				const view = admin || edit || rights.startsWith("view")
				// For domain level privileges, the domain (as stored in identifier column in db) will be the 'user' in the data table
				const user = last_name ? `${last_name}, ${first_name} (${email})` : identifier
				return { admin_rights_id, identifier, user, admin, edit, view }
			}

			const domain_rows = Object.values(this.domain_rights).map(create_row)
			const user_rows = Object.values(this.user_rights).map(create_row)

			return [...domain_rows, ...user_rows]
		}
	},
	created() { },
	mounted() {
		this.get_rights_for_instance_unit()
	},
	methods: {
		cancel_editor() {
			this.$nextTick(() => this.$emit('restore_editor'))
			this.dialog_open = false
		},
		get_rights_for_instance_unit() {
			return new Promise((resolve, reject) => {
				const payload = {
					user_id: this.user_info.user_id,
					instance_unit_id: this.instance_unit.lp_unit_id
				}
				U.ajax('get_rights_for_instance_unit', payload, result => {
					if (result.user_rights) this.user_rights = result.user_rights
					if (result.domain_rights) this.domain_rights = result.domain_rights
					resolve()
				})
			}).catch(error => {
				console.log(error)
			})
		},
		save_instance_unit() {
			return new Promise((resolve, reject) => {
				if (this.collection.units.length > 1 && empty(this.instance_unit.title)) {
					this.$alert('The instance unit must have a title.')
					reject()
					return
				}

				if (this.is_new_instance_unit) {
					this.$emit('save_instance_unit', this.instance_unit)

				} else {
					this.$emit('save_instance_unit', this.instance_unit, this.original_instance_unit)

				}
				resolve()
			}).catch(error => {
			})
		},
		grant_access_to_user() {
			this.$prompt({
				title: 'Grant access to user',
				text: 'Enter user email:',
				promptType: 'autocomplete',
				serviceName: 'email_search',
				acceptText: 'Add User',
			}).then(email => {
				email = $.trim(email).toLowerCase().replace(/^(\S+).*/, '$1')
				if (empty(email) || email.indexOf('@') == -1) {
					this.$alert('Please enter a valid user email.').then(x => { this.new_user_clicked() })
					return
				}
				const payload = {
					user_id: this.user_info.user_id,
					lp_unit_id: this.instance_unit.lp_unit_id,
					recipient_email: email,
					rights_level: 'view',
				}
				U.ajax('edit_instance_unit_rights', payload, result => {
					this.get_rights_for_instance_unit()
				})
			}).catch(n => { console.log(n) }).finally(f => { });
		},

		grant_access_to_domain() {
			this.$prompt({
				title: 'Grant access to domain.',
				text: 'Please enter a valid domain format, e.g., henry.k12.ga.us:',
				acceptText: 'Add Domain',
			}).then(domain => {
				const formatted_domain = domain.replace(/^\*\@/, '')
				// Regex for domain validation
				const domain_regex = /^[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/
				if (!domain_regex.test(formatted_domain)) {
					this.$alert("Please enter a valid domain format, e.g., henry.k12.ga.us").then(x => { this.grant_access_to_domain() })
					return
				}
				const payload = {
					user_id: this.user_info.user_id,
					lp_unit_id: this.instance_unit.lp_unit_id,
					domain: domain,
					rights_level: 'view',
				}
				U.ajax('edit_instance_unit_rights', payload, result => {
					this.get_rights_for_instance_unit()
				})
			}).catch(n => { console.log(n) }).finally(f => { });
		},

		edit_user(identifier) {
			this.show_user_editor = true
			this.user_editing = identifier
		},
		user_edit_saved() {
			this.get_rights_for_instance_unit()
			this.user_editor_cancel()
		},
		user_editor_cancel() {
			this.show_user_editor = false
			this.user_editing = {}
		},
		is_domain(identifier) {
			return identifier.user.startsWith('*@')
		},
		remove_user_access(identifier) {
			const prefix = 'Are you sure you want to remove access for'
			const suffix = `to the ${this.instance_unit.title} instance unit?`
			const prompt = this.is_domain(identifier) ? `${prefix} the ${identifier.user} domain ${suffix}` : `${prefix} ${identifier.user} ${suffix}`
			this.$confirm({
				text: prompt,
				title: 'Are you sure?',
				acceptText: 'Delete',
				acceptColor: 'red',
			}).then(y => {
				U.loading_start()
				let payload = {
					user_id: this.user_info.user_id,
					lp_unit_id: this.instance_unit.lp_unit_id,
					delete_right: true,
					admin_rights_id: identifier.admin_rights_id
				}
				U.ajax('edit_instance_unit_rights', payload, result => {
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error in ajax call'); vapp.ping(); return;
					}
					this.get_rights_for_instance_unit()
				})
			}).catch(n => { console.log(n) }).finally(f => { })
		},
	},
}
</script>

<style lang="scss">
.k-collection-instance-unit-editor {
	padding: 5px;
	margin: 10px 0;
	border-radius: 4px;
	background-color: #eee;
}
</style>
