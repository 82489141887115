<!-- Part of the SPARKL educational activity system, Copyright 2022 by Pepper Williams -->
<template><div class="k-page-wrapper">
	<div class="d-flex align-center">
		<v-icon @click="return_to_admin_main" large color="primary" class="mr-2">fas fa-person-digging</v-icon>
		<h2 class="k-page-title">Course Editing Logs</h2>

		<v-spacer />
		<v-text-field
			v-model="search"
			prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle"
			label="Search" single-line hide-details outlined dense background-color="#fff" style="flex:0 1 300px"
		></v-text-field>
		
		<div class="ml-4 mr-2">Date Range:</div>
		<v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
			<template v-slot:activator="{ on, attrs }"><v-text-field v-model="date_range_text" label="" outlined dense hide-details background-color="#fff" readonly prepend-inner-icon="fa-calendar-days" v-bind="attrs" v-on="on"></v-text-field></template>
			<v-date-picker v-model="dates" range no-title scrollable>
				<!-- <v-spacer></v-spacer>
				<v-btn text color="primary" @click="menu=false">Cancel</v-btn>
				<v-btn text color="primary" @click="$refs.menu.save(dates)">OK</v-btn> -->
			</v-date-picker>
		</v-menu>
		<v-btn color="primary" class="ml-2" @click="get_edit_log_data">Load Logs<v-icon small class="ml-2">fas fa-arrow-right</v-icon></v-btn>
	</div>

	<div v-if="!logs_loaded" class="text-center my-3"><i>Choose a date range and click “LOAD LOGS” to view course editing activity.</i></div>
	<div v-if="logs_loaded" class="mt-3">
		<v-data-table light
			:headers="headers"
			:items="entries"
			:sort-by="['timestamp']"
			:sort-desc="[true]"
			:must-sort="true"
			:custom-filter="table_search_filter"
			:search="search"
			hide-default-footerx
			:footer-props="footer_options"
			:items-per-page="100"
			class="k-admin-edit-log-table"
		>
			<template v-slot:item="{ item }"><tr>
				<td style="white-space:nowrap">{{item.timestamp}}</td>
				<td class="text-center">{{item.edit_time}}</td>
				<td>{{item.subject}}</td>
				<td v-html="item.code_title"></td>
				<td class="text-center" style="white-space:nowrap" v-html="item.name_email"></td>
				<td class="text-center">{{item.changed}}</td>
			</tr></template>
		</v-data-table>
	</div>

</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: {  },
	props: {
	},
	data() { return {
		logs_loaded: false,
		dates: [],
		menu: false,
		entries: [],
		search: '',
		headers: [
			{ text: 'Start Time', align: 'left', sortable: true, value:'timestamp' },
			{ text: 'Time Spent', align: 'center', sortable: true, value:'edit_time' },
			{ text: 'Subject', align: 'left', sortable: true, value:'subject' },
			{ text: 'Course Code/Title', align: 'left', sortable: true, value:'code_title' },
			{ text: 'Editor (click to email)', align: 'center', sortable: true, value:'name_email' },
			{ text: 'Course Changed?', align: 'center', sortable: true, value:'changed' },
		],
		footer_options: {
			itemsPerPageOptions: [10,50,100,-1],
		},
	}},
	computed: {
		...mapState(['user_info']),
		date_range_text() {
			return this.dates.join(' – ')
		},
	},
	created() {
		this.dates = [
			((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000 - 7*24*60*60*1000)).toISOString().substr(0, 10)),
			((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10))
		]
	},
	mounted() {
	},
	methods: {
		get_edit_log_data() {
			let payload = {user_id: this.user_info.user_id}

			// date_from is dates[0] unaltered; for date_to, add 1 to the day, so that returned records from that day will be included
			payload.date_from = this.dates[0]
			let arr = this.dates[1].split('-')
			arr[2] = arr[2] * 1 + 1
			if (arr[2] < 10) arr[2] = '0' + arr[2]	// pad day with leading 0 if necessary
			payload.date_to = arr.join('-')

			U.loading_start()
			U.ajax('admin_get_edit_log_data', payload, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					this.$alert('Error in admin ajax call'); vapp.ping(); return;
				}

				console.log(result)
				this.entries = []
				for (let log of result.logs) {
					// skip commongoodlt emails
					// if (log.email.indexOf('commongoodlt') > -1) continue;

					let edit_time = '–'
					if (log.checkin_timestamp) {
						edit_time = U.time_string((Math.round(new Date(log.checkin_timestamp).getTime() - new Date(log.timestamp).getTime()) / 1000), true)
					}
					let o = {
						log: log,
						timestamp: log.timestamp.replace(/:\d\d$/, ''),
						edit_time: edit_time,
						subject: log.subject,
						code_title: sr('<b class="grey--text text--darken-2">$1:</b> $2', log.course_code, log.title),
						name_email: sr('<a href="mailto:$3">$1 $2</a>', log.first_name, log.last_name, log.email),
						type: log.type,
						changed: log.has_archive || 'no',
					}
					o.code_title_search = o.code_title.toLowerCase()
					o.name_email_search = o.name_email.toLowerCase()
					this.entries.push(o)
				}

				this.logs_loaded = true
			});
		},

		table_search_filter(value, search, item) {
			// value is the value of the column (we can ignore this); search is the search string (could be empty)
			// RETURN FALSE TO HIDE THE ITEM

			// if search is empty, always return true, so the row will SHOW
			if (empty(search)) return true

			search = search.toLowerCase()
			let re = new RegExp(search, 'i')

			if (item.code_title_search.search(re) > -1) return true
			if (item.name_email_search.search(re) > -1) return true
			if (item.type.search(re) > -1) return true

			// if we get to here return false
			return false
		},

		return_to_admin_main() {
			this.$router.push({ path: '/' })
		}
	}
}
</script>

<style lang="scss">
.k-admin-edit-log-table {
	td { line-height:18px; }
}
</style>
